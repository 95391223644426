import {get, post} from '../helpers/ajax';
import {AxiosResponse} from "axios";
import {NoteModel} from "../models/noteModel";

export async function getNoteModels(): Promise<NoteModel[]> {
    let response: AxiosResponse<NoteModel[]> = await get<NoteModel[]>(`/noteModel/get`);

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function saveNoteModel(noteModel: NoteModel): Promise<NoteModel | null> {
    let response: AxiosResponse<NoteModel> = await post<NoteModel>(`/noteModel/save`, {noteModel});

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return null;
}

export async function removeNoteModel(noteModelId: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>(`/noteModel/remove`, {noteModelId});

    if (response.status === 200)
        return response.data;
    else
        return false;
}
