import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {Box} from "@mui/material";
import Menu from "../menu/menu";
import TopBar from "../topBar/topBar";
import Main from "../main/main";
import AppRouter from "../../app/router/router";
import {logException} from "../../../controllers/system";

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        redirect: state.app.redirect
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Layout extends React.Component<ReduxType> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    render() {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', overflow: 'auto' }}>
                <TopBar />
                <Box sx={{display: 'flex', height: '100%', overflow: 'auto'}}>
                    <Menu />
                    <Main>
                        <AppRouter />
                    </Main>
                </Box>
            </Box>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
