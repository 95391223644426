import {io, Socket} from "socket.io-client";
import configs from "../constants/configs";

export enum Actions {
    IDENTIFY = 'IDENTIFY',
    NEW_USER_NOTIFICATION = 'NEW_USER_NOTIFICATION'
}

let _userId: string;
let _socket: Socket;
let _errors: number = 0;
let _listeners: any = {};

export function connect(): Promise<void> {
    return new Promise((resolve) => {
        _socket = io(configs.baseUrl);
        _socket.on("connect", () => {
            _errors = 0;
            if (_userId) identify(_userId);
            resolve();
        });
        _socket.on("disconnect", (reason: string) => {
            if (reason === "io server disconnect") {
                reconnect();
            }
        });
        for (let listener in _listeners) _socket.on(listener, _listeners[listener]);
    });
}

function reconnect() {
    if (_errors <= 5) {
        _errors++;
        _socket.connect();
        if (!_socket.connected) {
            window.setTimeout(reconnect, 1000);
        }
    } else {
        connect().then(() => {identify(_userId);});
    }
}

export function identify(userId: string) {
    _userId = userId;
    _socket.emit(Actions.IDENTIFY, _userId);
}

export function on(action: Actions, listener: (...args: any[]) => void) {
    _listeners[action] = listener;
    _socket.on(action, listener);
}

export function emit(action: Actions, ...args: any[]) {
    _socket.emit(action, ...args);
}
