import React from 'react';
import {connect} from 'react-redux';
import {Drawer, List, Toolbar, Box, ListItem, ListItemIcon, ListItemText, Divider} from '@mui/material';
import {AppDispatch, RootState} from "../../../store/store";
import {IMenuItem, IMenuSeparator, menu} from "../../../constants/menu";
import {Link} from "react-router-dom";
import {localize} from "../../../helpers/localization";
import {ROLES} from "../../../constants/roles";
import {darkTheme} from "../../app/theme/root/dark";
import {ThemeContext} from '@emotion/react';
import {logException} from "../../../controllers/system";

type IProps = {
}

type IState = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        menuState: state.app.menuState,
        userRole: state.user.role
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

const drawerWidth = '250px';
let separatorCount: number = 0;

class Menu extends React.Component<ReduxType, IState> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    renderMenuItem(item: IMenuItem) {
        const Icon = item.icon;
        return (
            <Link to={item.route} key={item.name}>
                <ListItem button>
                    <ListItemIcon>
                        <Icon/>
                    </ListItemIcon>
                    <ListItemText sx={{color: 'text.primary'}} primary={localize(item.title, this.props.lang)} />
                </ListItem>
            </Link>
        );
    }

    renderSeparator(separator: IMenuSeparator) {
        separatorCount++;
        return (<Divider key={`separator${separatorCount}`} />);
    }

    render() {
        return (
            <ThemeContext.Provider value={darkTheme}>
                <Drawer
                    variant="persistent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                    open={this.props.menuState === 'open'}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            {menu.map((item: IMenuItem | IMenuSeparator) => {
                                if ("separator" in (item as any)) {
                                    return this.renderSeparator(item as IMenuSeparator);
                                } else {
                                    if ((item as IMenuItem).roles.indexOf(this.props.userRole as ROLES) !== -1) {
                                        return this.renderMenuItem(item as IMenuItem);
                                    } else {
                                        return null;
                                    }
                                }
                            })}
                        </List>
                    </Box>
                </Drawer>
            </ThemeContext.Provider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
