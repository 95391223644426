import {Patient, PATIENT_FILES} from "../models/patient";
import {AxiosResponse} from "axios";
import {get, post, upload, open} from "../helpers/ajax";
import {ORGANIZATION_LINK} from "../constants/enums";
import {File} from '../models/file';

export async function getPatient(patientId: string): Promise<Patient | null> {
    let response: AxiosResponse<Patient> = await get<Patient>(`/patient/${patientId}`);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export interface IFindPatientFilter {
    active?: boolean,
    name?: string,
    search?: string,
    birthdate?: Date,
    professionalUserId?: string
}

export async function getPatients(filter: IFindPatientFilter = {active: true}): Promise<Patient[]> {
    let response: AxiosResponse<Patient[]> = await post<Patient[]>('/patient/find', filter);

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function savePatient(patient: Patient): Promise<Patient | null> {
    let response: AxiosResponse<Patient> = await post<Patient>('/patient/save', patient);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export function formatFileNumber(fileNumber: number | undefined): string {
    return (`${fileNumber || ''}`).padStart(5, '0');
}

export function formatRAMQNo(ramqNo: string | undefined): string {
    let rawRamqNo: string = (`${ramqNo || ''}`).replace(/ /g, '');
    let formattedRamqNo: string = '';
    for (let charIndex = 0; charIndex < rawRamqNo.length; charIndex++) {
        if (rawRamqNo.charAt(charIndex) !== ' ') {
            if (charIndex % 4 === 0) {
                formattedRamqNo += ' ';
            }
            formattedRamqNo += rawRamqNo.charAt(charIndex);
        }
    }
    return formattedRamqNo;
}

export interface IMeetingCount {
    accountId: string
    contractCode: string
    contractExtension: boolean
    totalMeetings: number
    tookMeetings: number
    remainingMeetings: number
}

export async function getRemainingMeetings(date:Date, patientId: string, accountId: string, organizationLink?: ORGANIZATION_LINK, useExtensions: boolean = false): Promise<IMeetingCount | null> {
    let response: AxiosResponse<IMeetingCount> = await post<IMeetingCount>(`/patient/get-remaining-meetings`, {date, patientId, accountId, organizationLink, useExtensions});

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export interface RemainingMeetingsFilter {
    dateFrom: Date,
    dateTo: Date,
    patientId?: string | undefined,
    accountId?: string | undefined,
    paidByPatient?: boolean | undefined
}
export async function getRemainingMeetingsSummary(filter: RemainingMeetingsFilter): Promise<any | null> {
    let response: AxiosResponse<any> = await post<any>(`/patient/get-remaining-meetings-summary`, filter);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export async function hasUnusedContractExtension(date: Date, patientId: string, accountId: string): Promise<boolean> {
    try {
        let response: AxiosResponse<IMeetingCount> = await post<IMeetingCount>(`/patient/has-unused-extension`, {date, patientId, accountId});

        if (response.status === 200 && response.data)
            return true;
        else
            return false;
    } catch(ex) {
        return false;
    }
}

export async function uploadAttachedFiles(patientId: string, files: FileList, type: PATIENT_FILES): Promise<File<PATIENT_FILES> | null> {
    let file: File<PATIENT_FILES> | null = null;

    if (files.length === 1) {
        let formData = new FormData();
        formData.append("fileupload", files[0]);

        let response: AxiosResponse<File<PATIENT_FILES> | null> = await upload(`/patient/upload-file/${patientId}/${type}`, formData);

        if (response.status === 200 && response.data)
            file = response.data;
    }

    return file;
}

export async function downloadAttachedFile(patientId: string, fileName: string, filePath: string): Promise<any> {
    open(`/patient/download-file/${patientId}/${encodeURIComponent(filePath)}/${fileName}`);
}
