import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {IRouter} from "../../../app/router/router";
import withRouter from "../../../app/router/withRouter";
import {Autocomplete, AutocompleteValue, Box, IconButton, TextField} from "@mui/material";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification, setLoading, setPreventClose} from "../../../../store/actions/app";
import {logException} from "../../../../controllers/system";
import SendIcon from '@mui/icons-material/Send';
import {localize} from "../../../../helpers/localization";
import {Email} from "../../../../models/email";
import User from '../../../../models/user';
import {getUserFullName} from "../../../../controllers/user";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import * as emailController from "../../../../controllers/email";
import ReactDOM from "react-dom";

type IState = {
    toUserIds: string[],
    subject: string,
    message: string
}

type IProps = {
    originalEmail: Email | undefined,
    users: User[],
    onEmailSent: () => void
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat,
        signatureBase64: state.user.signatureBase64
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        },
        setLoading: (loading: boolean) => {
            dispatch(setLoading(loading));
        },
        setPreventClose: (preventClose: boolean) => {
            dispatch(setPreventClose(preventClose));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class WriteEmail extends React.Component<ReduxType> {
    public readonly state: IState = {
        toUserIds: [],
        subject: '',
        message: ''
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    getUserFullName(userId: string): string {
        for (let user of this.props.users) {
            if (user._id === userId) return getUserFullName(user);
        }
        return '';
    }

    componentDidMount() {
        if (this.props.originalEmail) {
            this.setState((state: IState) => {
                let subject = '';
                let message = '';
                let toUserIds: string[] = [];
                if (this.props.originalEmail) {
                    toUserIds = [this.props.originalEmail.fromUserId as string];
                    subject = this.props.originalEmail.subject || '';
                    if (!subject.startsWith('RE: ')) subject = `RE: ${subject}`;
                    message = this.props.originalEmail.message || '';
                    if (message.length > 0) {
                        message = `<br/><br/>-------------------<br/>`;
                        message += `${localize('From', this.props.lang)}: ${this.getUserFullName(this.props.originalEmail.fromUserId as string)}`;
                        message += ` ${localize('on', this.props.lang)} ${dayjs(this.props.originalEmail.sentOn).format(this.props.dateFormat)}`;
                        message += ` ${localize('at', this.props.lang)} ${dayjs(this.props.originalEmail.sentOn).format('HH:mm')}`;
                        message += `<br/><br/>${this.props.originalEmail.message}`;
                    }
                }
                return {
                    ...state,
                    toUserIds,
                    subject,
                    message
                }
            });
        } else {
            if (this.props.signatureBase64) {
                let e = document.createElement('div');
                ReactDOM.render(
                    (
                        <>
                            <p><br/></p>
                            <p><br/></p>
                            <p>
                                <img alt={'signature'} src={`data:image/png;base64,${this.props.signatureBase64}`} />
                            </p>
                        </>
                    ),
                    e,
                    () => {
                        let message = e.innerHTML;
                        this.setState((state: IState) => {return {...state, message};});
                    }
                );
            }
        }
    }

    componentWillUnmount() {
        this.props.setPreventClose(false);
    }

    getOriginalConsignees() {
        if (this.props.originalEmail) {
            for (let user of this.props.users) {
                if (user._id === this.props.originalEmail.fromUserId) return [user];
            }
            return [];
        } else {
            return [];
        }
    }

    async send(): Promise<boolean> {
        let allSentSuccess: boolean = true;
        this.props.setLoading(true);
        for (let toUserId of this.state.toUserIds) {
            let email: Email = new Email({
                toUserId,
                subject: this.state.subject,
                message: this.state.message
            });
            let sendSuccess: boolean = await emailController.send(email);
            if (!sendSuccess) allSentSuccess = false;
        }
        this.props.setLoading(false);
        this.props.setAppNotification({severity: 'success', message: 'Message sent successfully'});
        this.props.onEmailSent();
        this.props.setPreventClose(false);
        return allSentSuccess;
    }

    onChange(property: string, value: any) {
        this.props.setPreventClose(true);
        this.setState((state: IState) => {
            let newState: any = {...state};
            newState[property] = value;
            return newState;
        });
    }

    onConsigneesChange(users: User[]) {
        this.setState((state: IState) => {
            return {
                ...state,
                toUserIds: users.map((user) => user._id)
            };
        })
    }

    render() {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 1,
                width: '100%',
                height: '100%',
                borderLeft: 1,
                borderColor: 'grey.600'
            }}>
                <Box sx={{pb:1}}>
                    <IconButton onClick={this.send.bind(this)}>
                        <SendIcon />
                    </IconButton>
                </Box>
                <Box sx={{pb:2}}>
                    <Autocomplete
                        multiple
                        id="toUsers"
                        options={this.props.users}
                        getOptionLabel={(user) => getUserFullName(user)}
                        defaultValue={this.getOriginalConsignees()}
                        onChange={((e: any, value: AutocompleteValue<any, any, any, any>) => {this.onConsigneesChange(value);})}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={localize('Consignees', this.props.lang)}
                            />
                        )}
                    />
                </Box>
                <Box sx={{pb:1}}>
                    <TextField
                        id={'subject'}
                        sx={{width: '100%'}}
                        label={localize('Subject', this.props.lang)}
                        value={this.state.subject}
                        onChange={((e: any) => {this.onChange('subject', e.target.value)})}
                    />
                </Box>
                <Box sx={{pb:1, flexGrow: 1}}>
                    <ReactQuill
                        theme="snow"
                        value={this.state.message}
                        onChange={(newValue: string) => {this.onChange('message', newValue);}}
                        style={{backgroundColor: 'white', height: '90%', color: 'black'}}/>
                </Box>
            </Box>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WriteEmail));
