import {AnyAction} from 'redux';
import initialState from "../initialState";
import {REDIRECT, SET_MENU_STATE, SET_APP_NOTIFICATION, SET_LOADING, SET_PREVENTCLOSE} from "../actions/app";

export default function session(state = initialState.app, action: AnyAction) {
    switch(action.type) {
        case SET_MENU_STATE:
            return {
                ...state,
                menuState: action.payload.menuState
            };
        case SET_APP_NOTIFICATION:
            return {
                ...state,
                notification: action.payload.appNotification
            };
        case REDIRECT:
            return {
                ...state,
                redirect: action.payload.url
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            };
        case SET_PREVENTCLOSE:
            return {
                ...state,
                preventClose: action.payload.preventClose
            };
        default:
            return state;
    }
}
