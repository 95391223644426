import {connect} from "react-redux";
import {AppDispatch, RootState} from "../../../../store/store";
import imgLogo from "../../../../assets/img/GRAP.webp";
import * as styled from "./topBar.styled";

interface IProps {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

function TopBar(props: ReduxType) {
    return (
        <styled.LogoContainer style={{flexGrow: 1}}>
            <img alt={'logo'} src={imgLogo}/>
        </styled.LogoContainer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
