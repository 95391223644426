import React from 'react';
import {connect} from 'react-redux';
import {
    Box,
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle, FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import {AppDispatch, RootState} from "../../../../store/store";
import Text from "../../../app/text/text";
import {COMMUNICATION_TYPE, ContactCommunication} from "../../../../models/contactRequest";
import {localize} from "../../../../helpers/localization";
import {logException} from "../../../../controllers/system";

type IProps = {
    onSaved: (communication: ContactCommunication) => void
}

type IState = {
    open: boolean,
    communication: ContactCommunication
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        userId: state.user._id
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class AddCommunication extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        open: false,
        communication: {
            userId: this.props.userId,
            date: new Date()
        }
    }

    private date: Date = new Date();

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog() {
        this.setState((state) => {
            return {
                ...state,
                open: true
            };
        });
    }

    closeDialog() {
        this.setState((state) => {
            return {
                ...state,
                open: false
            };
        });
    }

    onChange(event: any) {
        this.setState((state: IState) => {
            let communication: any = {};

            switch(event.target.name) {
                case 'type': {
                    communication = {
                        ...state.communication,
                        type: event.target.value
                    };
                    break;
                }
                case 'result': {
                    communication = {
                        ...state.communication,
                        result: event.target.value
                    };
                    break;
                }
            }

            return {
                ...state,
                communication: {
                    ...state.communication,
                    ...communication
                }
            };
        });
    }

    save() {
        this.props.onSaved(this.state.communication);
        this.closeDialog();
    }

    render() {
        return (
            <Dialog open={this.state.open}>
                <DialogTitle><Text>Add communication</Text></DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{p: 2}}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel htmlFor="type"><Text>Communicate by</Text></InputLabel>
                                <Select
                                    defaultValue=""
                                    id="type"
                                    name="type"
                                    label={localize('Communicate by', this.props.lang)}
                                    onChange={this.onChange.bind(this)}
                                >
                                    {(Object.keys(COMMUNICATION_TYPE)).map((key) => {
                                        return (
                                            <MenuItem key={key} value={key}>
                                                <em><Text>{key}</Text></em>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{p: 2, width: '350px'}}>
                            <TextField
                                id={'result'}
                                name={'result'}
                                sx={{width: '100%'}}
                                multiline
                                rows={3}
                                label={localize('Communication\'s result', this.props.lang)}
                                onChange={this.onChange.bind(this)}/>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} sx={{mr: 2}} color={'error'} onClick={this.closeDialog.bind(this)}><Text>Cancel</Text></Button>
                    <Button variant={'contained'} color={'success'} onClick={this.save.bind(this)}><Text>Save</Text></Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(AddCommunication);
