import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {logException} from "../../../../controllers/system";
import * as userController from "../../../../controllers/user";
import {setUserSettings} from "../../../../store/actions/user";
import {localize} from "../../../../helpers/localization";
import User, {UserSettings} from "../../../../models/user";
import {getUser} from "../../../../controllers/user";

type IState = {
    userSettings?: UserSettings
}

type IProps = {
    visible: boolean,
    userId?: string
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        currentUserId: state.user._id,
        currentUserSettings: state.user.settings || new UserSettings({})
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        },
        setUserSettings: (userSettings: UserSettings) => {
            dispatch(setUserSettings(userSettings));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class UserSettingsView extends React.Component<ReduxType, IState> {
    public state: IState = {
    };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.setSettings();
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.userId !== prevProps.userId || JSON.stringify(this.props.currentUserSettings) !== JSON.stringify(prevProps.currentUserSettings)) {
            this.setSettings();
        }
    }

    setSettings() {
        if (this.props.userId) {
            getUser(this.props.userId).then((user: User | null) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        userSettings: user?.settings
                    };
                });
            });
        } else {
            this.setState((state: IState) => {
                return {
                    ...state,
                    userSettings: this.props.currentUserSettings
                };
            });
        }
    }

    changeUserSetting(setting: string, value: any) {
        let userSettings: any = {
            ...this.state.userSettings
        };
        userSettings[setting] = value;

        if (!this.props.userId || this.props.userId === this.props.currentUserId) {
            this.props.setUserSettings(userSettings);
        } else {
            userController.setUserSettings(this.props.userId, userSettings).then(() => {
                this.setSettings();
            })
        }
    }

    render() {
        if (!this.props.visible) return null;
        return (
            <React.Fragment>
                <Box sx={{p:2}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.userSettings?.canBeScheduled === true}
                                onChange={(e) => {this.changeUserSetting('canBeScheduled', e.target.checked)}}
                            />
                        }
                        label={localize('Can be scheduled', this.props.lang)}
                    />
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsView);
