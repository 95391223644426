import {ScheduleException} from "../models/schedule";
import {AxiosResponse} from "axios";
import {get, post} from "../helpers/ajax";
import {MissedRate} from "../models/missedRate";

export async function getScheduleExceptions(): Promise<ScheduleException[]> {
    let exceptions: ScheduleException[] = [];

    try {
        let response: AxiosResponse<ScheduleException[]> = await get<ScheduleException[]>('/system/system-exceptions');

        if (response.status === 200 && response.data && Array.isArray(response.data)) {
            exceptions = response.data;
        }
    } catch(ex) {

    }

    return exceptions;
}

export async function saveScheduleExceptions(exceptions: ScheduleException[]): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post<boolean>('/system/system-exceptions', {exceptions});

        if (response.status === 200 && response.data)
            return response.data;
        else
            return false;
    } catch(ex) {
        return false;
    }
}

export async function getMissedRates(): Promise<MissedRate[]> {
    let missedRates: MissedRate[] = [];

    try {
        let response: AxiosResponse<MissedRate[]> = await get<MissedRate[]>('/system/missed-rates');

        if (response.status === 200 && response.data && Array.isArray(response.data)) {
            missedRates = response.data;
        }
    } catch(ex) {

    }

    return missedRates;
}

export async function saveMissedRates(missedRates: MissedRate[]): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post<boolean>('/system/missed-rates', {missedRates});

        if (response.status === 200 && response.data)
            return response.data;
        else
            return false;
    } catch(ex) {
        return false;
    }
}

export async function getEnv(): Promise<string> {
    try {
        let response: AxiosResponse<string> = await get<string>('/external/system/env');

        if (response.status === 200 && response.data)
            return response.data;
        else
            return 'production';
    } catch(ex) {
        return 'production';
    }
}

export function logException(exception: any): void {
    try {
        post<string>('/external/system/logException', exception);
    } catch(ex) {

    }
}

export async function checkLock(category: string, id: string): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post<boolean>('/system/check-lock', {category, id});

        if (response.status === 200 && response.data)
            return response.data;
        else
            return false;
    } catch(ex) {
        return false;
    }
}

export async function reserveLock(category: string, id: string): Promise<string | undefined> {
    try {
        let response: AxiosResponse<string | undefined> = await post<string | undefined>('/system/reserve-lock', {category, id});

        if (response.status === 200 && response.data)
            return response.data;
        else
            return undefined;
    } catch(ex) {
        return undefined;
    }
}

export async function preserveLock(lockId: string, category: string, id: string): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post<boolean>('/system/preserve-lock', {lockId, category, id});

        if (response.status === 200 && response.data)
            return response.data;
        else
            return false;
    } catch(ex) {
        return false;
    }
}

export async function releaseLock(lockId: string, category: string, id: string): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post<boolean>('/system/release-lock', {lockId, category, id});

        if (response.status === 200 && response.data)
            return response.data;
        else
            return false;
    } catch(ex) {
        return false;
    }
}

export async function getConfig(configName: string): Promise<string> {
    try {
        let response: AxiosResponse<string> = await get<string>(`/external/system/config/${configName}`);

        if (response.status === 200 && response.data)
            return response.data;
        else
            return '';
    } catch(ex) {
        return '';
    }
}
