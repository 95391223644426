import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {IRouter} from "../../app/router/router";
import withRouter from "../../app/router/withRouter";
import {Box, CircularProgress, IconButton, Tooltip} from "@mui/material";
import {IAppNotification} from "../../../models/appNotification";
import {setAppNotification} from "../../../store/actions/app";
import {logException} from "../../../controllers/system";
import EditNoteIcon from '@mui/icons-material/EditNote';
import RefreshIcon from '@mui/icons-material/Refresh';
import {localize} from "../../../helpers/localization";
import EmailList from "./components/emailList";
import ViewEmail from "./components/viewEmail";
import {Email} from "../../../models/email";
import User from "../../../models/user";
import {getUsers} from "../../../controllers/user";
import * as emailController from "../../../controllers/email";
import WriteEmail from "./components/writeEmail";
import ConfirmDialog from "../../ui/confirmDialog/confirmDialog";
import {refreshUnreadEmails} from "../../../store/actions/user";

type IState = {
    loading: boolean,
    emails: Array<Email>,
    selectedEmail?: Email,
    replyingEmail?: Email,
    users: User[],
    writing: boolean
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        },
        refreshUnreadEmails: () => {
            dispatch(refreshUnreadEmails());
        },
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class Emails extends React.Component<ReduxType> {
    private confirmLeaveWritingDialogRef: RefObject<any>;
    private selectingEmail: Email | undefined = undefined;

    public readonly state: IState = {
        loading: false,
        emails: [],
        users: [],
        writing: false
    }

    constructor(props: ReduxType) {
        super(props);
        this.confirmLeaveWritingDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState((state: IState) => {
            return {
                ...state,
                loading: true,
                selectedEmail: undefined
            };
        }, () => {
            this.loadUsers().then(() => {
                this.loadEmails();
            });
        });
    }

    loadUsers(): Promise<void> {
        return new Promise((resolve) => {
            getUsers({}).then((users: User[]) => {
                this.setState(
                    (state: IState) => {
                        return {
                            ...state,
                            users
                        };
                    },
                    () => {
                        resolve();
                    }
                );
            });
        });
    }

    async loadEmails(): Promise<void> {
        await this.setLoading(true, null);
        let emails: Email[] = await emailController.getEmails();
        await this.setLoading(false, emails);
        if (this.selectingEmail) {
            this.onEmailSelection(this.selectingEmail);
            this.selectingEmail = undefined;
        }
    }

    setLoading(loading: boolean, emails: Email[] | null): Promise<void> {
        return new Promise((resolve) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    loading,
                    emails,
                    selectedEmail: null
                };
            }, resolve);
        });
    }

    newEmail(replyingEmail?: Email) {
        this.setState((state: IState) => {
            return {
                ...state,
                selectedEmail: null,
                writing: true,
                replyingEmail
            }
        });
    }

    onEmailSent() {
        this.refresh(true);
        this.props.refreshUnreadEmails();
    }

    async deleteEmail(emailId: string) {
        await emailController.remove(emailId);
        await this.loadEmails();
        this.props.refreshUnreadEmails();
    }

    refresh(force: boolean = false) {
        if (this.state.writing && force !== true) {
            this.confirmLeavingWriting(undefined);
        } else {
            this.setState((state: IState) => {
                return {
                    ...state,
                    selectedEmail: null,
                    writing: false,
                    replyingEmail: null
                };
            }, () => {
                this.loadEmails();
            });
        }
    }

    onEmailSelection(selectedEmail: Email, force: boolean = false) {
        if (this.state.writing && force !== true) {
            this.confirmLeavingWriting(selectedEmail);
        } else {
            this.setState((state: IState) => {
                let emails: Email[] = state.emails;
                for (let email of emails) {
                    if (email._id === selectedEmail._id) email.readOn = new Date();
                }
                return {
                    ...state,
                    emails,
                    selectedEmail
                };
            }, () => {
                emailController.read(selectedEmail._id as string).then(() => {
                    this.props.refreshUnreadEmails();
                });
            });
        }
    }

    confirmLeavingWriting(selectingEmail: Email | undefined) {
        this.selectingEmail = selectingEmail;
        this.confirmLeaveWritingDialogRef.current.openDialog();
    }

    render() {
        return (
            <React.Fragment>
                <Box sx={{display: 'flex', width: '100%', height: '100%', flexDirection: 'column', 'img': {maxWidth: '250px !important'}}}>
                    <Box sx={{display:'flex', borderBottom: 1, borderColor: 'grey.600', pb:1, pt: 1}}>
                        <Tooltip title={localize('New email', this.props.lang)}>
                            <IconButton onClick={this.newEmail.bind(this, undefined)}>
                                <EditNoteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={localize('Refresh', this.props.lang)}>
                            <IconButton onClick={this.refresh.bind(this, false)}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{display: 'flex', flexGrow: 1}}>
                        <Box sx={{width: 200}}>
                            {
                                !this.state.loading ?
                                    <EmailList
                                        emails={this.state.emails}
                                        selectedEmailId={this.state.selectedEmail?._id}
                                        onEmailSelection={this.onEmailSelection.bind(this)}
                                        users={this.state.users}
                                    />
                                    :
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                            }
                        </Box>
                        <Box sx={{flexGrow: 1}}>
                            {
                                this.state.writing ?
                                    <WriteEmail
                                        users={this.state.users}
                                        originalEmail={this.state.replyingEmail}
                                        onEmailSent={this.onEmailSent.bind(this)}
                                    />
                                    :
                                    this.state.selectedEmail ?
                                        <ViewEmail
                                            email={this.state.selectedEmail}
                                            users={this.state.users}
                                            onNewEmail={this.newEmail.bind(this)}
                                            onEmailDeleted={this.deleteEmail.bind(this)}
                                        />
                                        : null
                            }
                        </Box>
                    </Box>
                </Box>
                <ConfirmDialog
                    ref={this.confirmLeaveWritingDialogRef}
                    title={localize('Discard draft', this.props.lang)}
                    text={localize('Are you sure you want to discard your draft message?', this.props.lang)}
                    onAgree={this.refresh.bind(this, true)}
                />
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Emails));
