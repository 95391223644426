import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, List, ListItem, ListItemButton, Tab, Tabs} from "@mui/material";
import {logException} from "../../../../controllers/system";
import User from "../../../../models/user";
import {getUserFullName, getUsers, saveUserSchedule} from "../../../../controllers/user";
import Schedule from "../../profile/components/schedule";
import ScheduleModel from "../../../../models/schedule";
import {ROLES} from "../../../../constants/roles";
import {saveSchedule} from "../../../../store/actions/user";
import {localize} from "../../../../helpers/localization";
import Rates from "./rates";
import ProfessionalLicence from "../../profile/components/licence";
import UserSettingsView from "../../profile/components/userSettings";

type IState = {
    professionals: User[],
    selectedProfessional: User | null,
    panel: number
}

type IProps = {
    visible: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        userId: state.user._id,
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        saveSchedule: (userId: string, schedule: ScheduleModel) => {
            dispatch(saveSchedule(userId, schedule));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Professionals extends React.Component<ReduxType, IState> {
    public state: IState = {
        professionals: [],
        selectedProfessional: null,
        panel: 0
    }

    private saving: boolean = false;

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadProfessionals();
    }

    loadProfessionals() {
        getUsers({contrator: false, roles: [ROLES.Professional, ROLES.Administrator]}).then(( professionals: User[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    professionals
                };
            });
        });
    }

    onSelectProfesionnal(selectedProfessional: User | null) {
        this.setState((state: IState) => {
            return {
                ...state,
                selectedProfessional
            };
        });
    }

    saveSchedule(schedule: ScheduleModel) {
        if (this.state.selectedProfessional?._id === this.props.userId) {
            this.props.saveSchedule(this.state.selectedProfessional?._id as string, schedule);
        } else {
            saveUserSchedule(this.state.selectedProfessional?._id as string, schedule);
        }
    }

    setPanel(panel: number) {
        this.setState((state: IState) => {
            return {
                ...state,
                panel
            };
        });
    }

    render() {
        if (!this.props.visible) return null;
        return (
            <Box sx={{display: 'flex', p:2, height: '100%', overflow: 'auto'}}>
                <Box sx={{height: '100%', overflowY: 'auto', overflowX: 'visible'}}>
                    <List sx={{borderRight: 1, borderColor: 'divider', height: '100%', overflowY: 'auto', overflowX: 'visible'}}>
                        {
                            this.state.professionals.map((professional: User) => {
                                return (
                                    <ListItem key={professional._id} disablePadding>
                                        <ListItemButton
                                            onClick={this.onSelectProfesionnal.bind(this, professional)}
                                            selected={this.state.selectedProfessional?._id === professional._id}
                                        >
                                            {getUserFullName(professional)}
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </Box>
                <Box sx={{height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={this.state.panel}>
                            <Tab label={localize('Schedule', this.props.lang)} onClick={this.setPanel.bind(this, 0)} />
                            <Tab label={localize('Rates', this.props.lang)} onClick={this.setPanel.bind(this, 1)} />
                            <Tab label={localize('Identity', this.props.lang)} onClick={this.setPanel.bind(this, 2)} />
                            <Tab label={localize('Settings', this.props.lang)} onClick={this.setPanel.bind(this, 3)} />
                        </Tabs>
                    </Box>
                    <Box sx={{display: (this.state.panel === 0 ? 'block' : 'none'), flewGrow: 1}}>
                        {
                            this.state.selectedProfessional ?
                                <Schedule
                                    visible={true} userId={this.state.selectedProfessional?._id as string}
                                    schedule={this.state.selectedProfessional?.schedule?.base || []}
                                    saveSchedule={this.saveSchedule.bind(this)} />
                                : null
                        }
                    </Box>
                    <Box sx={{display: (this.state.panel === 1 ? 'block' : 'none'), flewGrow: 1}}>
                        {
                            this.state.selectedProfessional ?
                                <Rates
                                    professionnal={this.state.selectedProfessional}
                                />
                                : null
                        }
                    </Box>
                    <Box sx={{display: (this.state.panel === 2 ? 'block' : 'none'), flewGrow: 1}}>
                        <ProfessionalLicence visible={true} userId={this.state.selectedProfessional?._id} licence={this.state.selectedProfessional?.professionalLicenceNumber} />
                    </Box>
                    <Box sx={{display: (this.state.panel === 3 ? 'block' : 'none'), flewGrow: 1}}>
                        <UserSettingsView visible={this.state.panel === 3 && this.state.selectedProfessional?._id !== undefined} userId={this.state.selectedProfessional?._id} />
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Professionals);
