import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import ChangePassword from "./components/changePassword";
import ChangeName from "./components/changeName";
import {logException} from "../../../controllers/system";

type IProps = {
}

type IState = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        user: state.user
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Wizard extends React.Component<ReduxType, IState> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    render() {
        if (!this.props.user) return null;
        return (
            <React.Fragment>
                {
                    this.props.user.changePassword ?
                        <ChangePassword open={true}/>
                        :
                        !this.props.user.firstName || !this.props.user.lastName ?
                            <ChangeName />
                            :
                            null
                }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
