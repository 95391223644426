import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {
    Avatar,
    Box,
    Divider,
    Grid,
    Link,
    ListItemAvatar,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button, MenuItem, Checkbox, ToggleButton, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import Text from "../../app/text/text";
import {
    DataGrid,
    GridCallbackDetails,
    GridColDef,
    MuiEvent,
    GridActionsCellItem,
    GridRowParams, GridRenderCellParams
} from "@mui/x-data-grid";
import {getMUILocale, localize} from "../../../helpers/localization";
import {
    findRelatedPatients,
    getContactRequest,
    getContactRequests, matchContactRequestEnums,
    saveContactRequest
} from "../../../controllers/contactRequests";
import {COMMUNICATION_TYPE, ContactCommunication, ContactRequest} from "../../../models/contactRequest";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {formatNumber, trimNumber} from "../../../helpers/phone";
import FullScreenDialog from "../../ui/fullScreenDialog/fullScreenDialog";
import ConfirmDialog from "../../ui/confirmDialog/confirmDialog";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SmsIcon from '@mui/icons-material/Sms';
import LanguageIcon from '@mui/icons-material/Language';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import querystring from 'query-string';
import {
    CIVIL_STATUS,
    DIRECTED_BY,
    EMPLOYEE_STATUS, ORGANIZATION_LINK,
    SERVICES,
    USED_SERVICE_BEFORE
} from "../../../constants/enums";
import dayjs from "dayjs";
import {getEnumValue, getEnumValues} from "../../../helpers/enums";
import AddCommunication from "./addCommunication/addCommunication";
import {Account} from "../../../models/account";
import {getActiveAccounts} from "../../../controllers/account";
import {sanitize} from "../../../helpers/string";
import {IAppNotification} from "../../../models/appNotification";
import {setAppNotification} from "../../../store/actions/app";
import {Parent, PARENT_RELATION, Patient, PATIENT_STATUS} from "../../../models/patient";
import LinkPatient from "./linkPatient/linkPatient";
import {formatRAMQNo, savePatient} from "../../../controllers/patients";
import withRouter from "../../app/router/withRouter";
import {IRouter} from "../../app/router/router";
import ContactRequestReport from "../../reports/contactRequest/contactRequest";
import {logException} from "../../../controllers/system";
import {getRelationText, isMinor} from "../../../helpers/patient";
import ParentDialog from "../patients/components/parent";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportViewer from "../../reports/reportViewer";
import {dayjsOrAny} from "../../../helpers/dayjs";
import User from "../../../models/user";
import {getUsers} from "../../../controllers/user";

type IProps = {
};

type IState = {
    data: any[],
    accounts: Account[],
    loading: boolean,
    changed: boolean,
    viewContactRequest: ContactRequest | null,
    formValidation: any,
    viewDeletedContactRequests: boolean,
    accordionSections: Array<number>,
    users: User[]
};

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class ContactRequests extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        data: [],
        accounts: [],
        loading: false,
        changed: false,
        viewContactRequest: null,
        formValidation: {},
        viewDeletedContactRequests: false,
        accordionSections: [0, 0, 0],
        users: []
    }

    private editDialogRef: RefObject<any>;
    private confirmDeleteDialogRef: RefObject<any>;
    private confirmCloseDialogRef: RefObject<any>;
    private addCommunicationDialogRef: RefObject<any>;
    private linkPatientDialogRef: RefObject<any>;
    private parent1DialogRef: RefObject<any>;
    private parent2DialogRef: RefObject<any>;
    private reportRef: RefObject<any>;
    private changeTimeout: number | null;
    private indexedAccounts: any;

    constructor(props: any) {
        super(props);
        this.editDialogRef = React.createRef<any>();
        this.confirmDeleteDialogRef = React.createRef<any>();
        this.confirmCloseDialogRef = React.createRef<any>();
        this.addCommunicationDialogRef = React.createRef<any>();
        this.linkPatientDialogRef = React.createRef<any>();
        this.parent1DialogRef = React.createRef();
        this.parent2DialogRef = React.createRef();
        this.reportRef = React.createRef<any>();
        this.changeTimeout = null;
        this.indexedAccounts = {};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.getOrganisations(() => {
            this.getData();
            this.loadQueryContactRequest();
            this.loadUsers();
        });
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.router.location !== this.props.router.location && this.state.viewContactRequest === null) this.loadQueryContactRequest();
    }

    async loadUsers() {
        let users: User[] = await getUsers();
        this.setState((state: IState) => {
            return {
                ...state,
                users
            };
        });
    }

    closeEditDialogRequest(): boolean {
        if (this.state.changed) {
            this.confirmCloseDialogRef.current?.openDialog();
            return false;
        } else {
            this.closeEditDialog();
            return true;
        }
    }

    closeEditDialog() {
        this.setState((state: IState) => {
            return {...state, changed: false, viewContactRequest: null}
        }, () => {
            this.editDialogRef.current.closeDialog();
        });
    }

    getActivePhoneNumber(contactRequest: ContactRequest): Array<string | undefined> {
        let phone: string | undefined = undefined;
        let ext: string | undefined = undefined;
        phone = contactRequest.phoneCell;
        if (!phone) phone = contactRequest.phoneHome;
        if (!phone) {
            phone = contactRequest.phoneWork;
            ext = contactRequest.phoneWorkExt;
        }
        return [`${formatNumber(phone || '')}${ext ? ` ext. ${ext}` : ''}`, phone, ext];
    }

    getColumns(): (GridColDef | GridActionsColDef)[] {
        return [
            {
                field: 'confirmationNumber',
                headerName: localize('Confirmation number', this.props.lang),
                minWidth: 100,
                flex: 1
            },
            {
                field: 'createdOn',
                headerName: localize('Received on', this.props.lang),
                minWidth: 40,
                flex: 1,
                valueFormatter: (x: any) => Intl.DateTimeFormat('fr', {month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}).format(new Date(x.value))
            },
            {
                field: 'firstName',
                headerName: localize('First name', this.props.lang),
                minWidth: 100,
                flex: 1
            },
            {
                field: 'lastName',
                headerName: localize('Last name', this.props.lang),
                minWidth: 100,
                flex: 1
            },
            {
                field: 'organization',
                headerName: localize('Organization', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams): React.ReactNode => {
                    if (parseInt(params.value) !== -1) {
                        return (
                            <span>{params.value && this.indexedAccounts[params.value] ? this.indexedAccounts[params.value].name : params.value}</span>
                        );
                    } else {
                        return (
                            <span>{localize('On the patient\'s account', this.props.lang)}</span>
                        );
                    }
                }
            },
            {
                field: 'email',
                headerName: localize('Email', this.props.lang),
                minWidth: 100,
                flex: 1.5,
                renderCell: (params: GridRenderCellParams): React.ReactNode => {
                    return (
                        <Link href={`mailto:${params.value}`}>{params.value}</Link>
                    );
                }
            },
            {
                field: 'phoneWork',
                headerName: localize('Phone number', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams): React.ReactNode => {
                    let activePhoneNumber: Array<string | undefined> = this.getActivePhoneNumber(params.row);
                    return (
                        <Link href={`tel:${trimNumber(activePhoneNumber[1] || '')}`}>{activePhoneNumber[0]}</Link>
                    );
                }
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 30,
                flex: 0.25,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<VisibilityIcon />}
                        label="Delete"
                        onClick={this.viewContactDetails.bind(this, params.row as ContactRequest)}
                    />
                ]
            }
        ];
    }

    getData() {
        this.setState((state) => {
            return {
                ...state,
                loading: true,
                data: []
            }
        }, () => {
            getContactRequests({
                transmitted: false,
                deleted: this.state.viewDeletedContactRequests
            }).then((contactRequests: ContactRequest[]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        data: contactRequests,
                        loading: false
                    }
                });
            });
        });
    }

    viewDeletedContactRequests(viewDeletedContactRequests: boolean) {
        this.setState((state: IState) => {
            return {
                ...state,
                viewDeletedContactRequests
            };
        }, () => {
            this.getData();
        });
    }

    onRowClick(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) {
        if ((event.target as any).nodeName !== 'A') this.viewContactDetails(params.row as ContactRequest);
    }

    viewContactDetails(data: ContactRequest) {
        this.setState((state: IState) => {
            data = matchContactRequestEnums(data);
            data.organization = this.getOrganisation(data.organization || '')?._id || undefined;
            return {
                ...state,
                viewContactRequest: Object.assign({}, data),
                accordionSections: [0, 0, 0]
            };
        }, () => {
            this.editDialogRef.current.openDialog();
            this.validateContactRequest();
        });
    }

    onContactDetailsButtonClick(key: string) {
        switch(key) {
            case 'delete':
                this.confirmDeleteDialogRef.current?.openDialog();
                break;

            case 'save':
                this.saveContactRequest();
                break;

            case 'transmit':
                this.transmitContactRequest();
                break;

            case 'print':
                this.reportRef.current?.run();
                break;

            case 'restore':
                this.restoreContactRequest();
                break;
        }
    }

    deleteContactRequest() {
        this.changeContactRequest('archivedOn', new Date(), this.saveContactRequest.bind(this, true));
    }

    saveContactRequest(force: boolean = false) {
        return new Promise<void>(((resolve, reject) => {
            if (force || this.validateContactRequest()) {
                let contactRequest: ContactRequest = new ContactRequest({
                    ...this.state.viewContactRequest
                });
                saveContactRequest(contactRequest).then((contactRequest: ContactRequest | null) => {
                    if (contactRequest) {
                        this.props.setAppNotification({
                            severity: 'success',
                            message: 'Successfully saved'
                        });
                        this.setState((state: IState) => {
                            return {...state, changed: false}
                        }, () => {
                            this.getData();
                            resolve();
                        });
                    } else {
                        this.props.setAppNotification({
                            severity: 'error',
                            message: 'An error occurred, please try again'
                        });
                        reject();
                    }
                });
            }
        }));
    }

    restoreContactRequest() {
        this.changeContactRequest('archivedOn', null, this.saveContactRequest.bind(this, true));
    }

    transmitContactRequest() {
        if (this.validateContactRequest()) {
            findRelatedPatients(this.state.viewContactRequest?._id as string).then((relatedPatients: Patient[]) => {
                if (relatedPatients.length > 0) {
                    this.linkPatientDialogRef.current?.openDialog(this.state.viewContactRequest, relatedPatients);
                } else {
                    this.onRelatedPatientSelected(undefined);
                }
            });
        }
    }

    validateContactRequest(): boolean {
        if (this.state.viewContactRequest) {
            let cr: ContactRequest = this.state.viewContactRequest;
            let formValidation: any = {};

            if (cr.organization) {
                if (isNaN(parseInt(cr.organization))) formValidation.organization = 'error';
            } else {
                formValidation.organization = 'error';
                formValidation.accordion_1_0 = 'error';
            }

            if (cr.ramqNo && (cr.ramqNo).replace(/ /g, '').length !== 0 && (cr.ramqNo).replace(/ /g, '').length !== 12) {
                formValidation.ramqNo = 'error';
                formValidation.accordion_0_2 = 'error';
            }

            if (cr.ramqExpirationMonth && (cr.ramqExpirationMonth <= 0 || cr.ramqExpirationMonth > 12)) {
                formValidation.ramqExpirationMonth = 'error';
                formValidation.accordion_0_2 = 'error';
            }

            if (cr.ramqExpirationYear && (cr.ramqExpirationYear <= 2000 || cr.ramqExpirationYear > 2500)) {
                formValidation.ramqExpirationYear = 'error';
                formValidation.accordion_0_2 = 'error';
            }

            if (this.valueIsSet(cr.civilStatusOriginal) && !this.valueIsNumber(cr.civilStatus))
                formValidation.civilStatus = 'warning';

            if (this.valueIsSet(cr.organizationLinkOriginal) && !this.valueIsNumber(cr.organizationLink))
                formValidation.organizationLink = 'warning';

            if (this.valueIsSet(cr.employeeStatusOriginal) && !this.valueIsNumber(cr.employeeStatus))
                formValidation.employeeStatus = 'warning';

            if (this.valueIsSet(cr.requestedServiceOriginal) && !this.valueIsNumber(cr.requestedService))
                formValidation.requestedService = 'warning';

            if (this.valueIsSet(cr.usedServicePastOriginal) && !this.valueIsNumber(cr.usedServicePast))
                formValidation.usedServicePast = 'warning';

            if (this.valueIsSet(cr.directedByOriginal) && !this.valueIsNumber(cr.directedBy))
                formValidation.directedBy = 'warning';

            this.setState((state: IState) => {
                return {
                    ...state,
                    formValidation
                };
            });

            return Object.keys(formValidation).length === 0;
        } else {
            return false;
        }
    }

    valueIsSet(value: any): boolean {
        return !(value === null || value === undefined || value === '');
    }

    valueIsNumber(value: any): boolean {
        return (value && !isNaN(parseInt(value)));
    }

    onRelatedPatientSelected(patient: Patient | undefined) {
        if (!patient) {
            patient = new Patient({
                firstName: this.state.viewContactRequest?.firstName,
                lastName: this.state.viewContactRequest?.lastName,
                birthdate: this.state.viewContactRequest?.birthdate,
                address: this.state.viewContactRequest?.address,
                postalCode: this.state.viewContactRequest?.postalCode,
                email: this.state.viewContactRequest?.email,
                phoneWork: this.state.viewContactRequest?.phoneWork,
                phoneWorkExt: this.state.viewContactRequest?.phoneWorkExt,
                phoneHome: this.state.viewContactRequest?.phoneHome,
                phoneCell: this.state.viewContactRequest?.phoneCell,
                civilStatus: this.state.viewContactRequest?.civilStatus,
                status: PATIENT_STATUS.ACTIVE,
                eventDate: this.state.viewContactRequest?.eventDate,
                fileNumberIVACCSST: this.state.viewContactRequest?.fileNumberIVACCSST,
                parent1: this.state.viewContactRequest?.parent1,
                parent2: this.state.viewContactRequest?.parent2,
                personalNote: this.state.viewContactRequest?.personalNote,
                accountId: this.state.viewContactRequest?.organization,
                organizationLink: this.state.viewContactRequest?.organizationLink,
                ramqNo: this.state.viewContactRequest?.ramqNo,
                ramqExpirationMonth: this.state.viewContactRequest?.ramqExpirationMonth,
                ramqExpirationYear: this.state.viewContactRequest?.ramqExpirationYear
            });
        }
        if (!patient._id) {
            savePatient(patient).then((patient: Patient | null) => {
                if (patient) {
                    this.takeMeeting(patient);
                } else {
                    this.props.setAppNotification({severity: 'error', message: localize('An error occured while selecting patient. Please try again', this.props.lang)});
                }
            })
        } else {
            this.takeMeeting(patient);
        }
    }

    takeMeeting(patient: Patient) {
        this.setState((state: IState) => {
            (state.viewContactRequest as ContactRequest).patientId = patient._id;
            return {
                ...state
            };
        }, () => {
            this.saveContactRequest().then(() => {
                this.props.router.navigate(`/meetings?contactRequest=${this.state.viewContactRequest?._id}&patient=${patient._id}`);
            });
        });
    }

    changeContactRequest(name: string, value: any, callback?: () => void) {
        if ((this.state.viewContactRequest as any)[name] === value) return;
        this.setState((state: any) => {
            switch(name) {
                case 'ramqExpirationMonth':
                case 'ramqExpirationYear':
                    value = parseInt(value);
                    if (isNaN(value)) value = undefined;
            }
            state.viewContactRequest[name] = value;
            return {
                ...state,
                changed: true
            };
        }, callback);
    }

    addCommunication() {
        this.addCommunicationDialogRef.current?.openDialog();
    }

    onNewCommunication(communication: ContactCommunication) {
        this.setState((state: IState) => {
            let communications: ContactCommunication[] = Object.assign([], this.state.viewContactRequest?.communications || []);
            communications.push(communication);
            return {
                ...state,
                changed: true,
                viewContactRequest: {
                    ...state.viewContactRequest,
                    communications
                }
            };
        });
    }

    getOrganisations(callback: () => void) {
        getActiveAccounts().then((accounts: Account[]) => {
            this.indexedAccounts = {};
            for (let account of accounts) if (account._id) this.indexedAccounts[account._id] = account;
            this.setState((state: IState) => {
                return {
                    ...state,
                    accounts
                };
            }, callback);
        });
    }

    getOrganisation(value: string | undefined): Account | undefined {
        if (!value) return undefined;
        if (value === '-1') return {_id: '-1', name: localize('On the patient\'s account', this.props.lang)};
        if (this.indexedAccounts[value]) return this.indexedAccounts[value];
        for (let account of this.state.accounts) {
            if (account._id === value || sanitize(account.name || '') === sanitize(value || '')) return account;
        }
        return undefined;
    }

    loadQueryContactRequest() {
        let params: any = querystring.parse(window.location.search);

        if (params.id) {
            getContactRequest(params.id).then((contactRequest: ContactRequest | null) => {
                if (contactRequest) this.viewContactDetails(contactRequest);
            })
        }
    }

    isIVACCSST(): boolean {
        return (
            this.state.viewContactRequest?.organization !== undefined
            && this.indexedAccounts[this.state.viewContactRequest?.organization] !== undefined
            && (['IVAC', 'CNESST']).indexOf(this.indexedAccounts[this.state.viewContactRequest?.organization].name) > -1
        );
    }

    onAccordionChange(section: number, accordion: number, isExpanded: boolean) {
        if (!isExpanded) accordion = -1;
        this.setState((state: IState) => {
            let accordionSections: Array<number> = state.accordionSections;
            accordionSections[section] = accordion;
            return {
                ...state,
                accordionSections
            };
        });
    }

    render() {
        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }} height={'100%'}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant={'h4'}>
                                <Text>Contact requests</Text>
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box>
                                <Checkbox checked={this.state.viewDeletedContactRequests} onChange={(e) => {this.viewDeletedContactRequests(e.target.checked)}} />
                            </Box>
                            <Box>
                                <Typography><Text>View deleted contact requests</Text></Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1, '& .waiting': {backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.light} }}>
                        <DataGrid
                            columns={this.getColumns()}
                            rows={this.state.data}
                            getRowId={x => x._id}
                            loading={this.state.loading}
                            localeText={getMUILocale(this.props.lang).components.MuiDataGrid.defaultProps.localeText}
                            onRowClick={this.onRowClick.bind(this)}
                            getRowClassName={(params) => {return params.row.waiting ? 'waiting' : ''}}
                        />
                    </Box>
                </Box>
                <FullScreenDialog
                    ref={this.editDialogRef}
                    title={localize('Contact request', this.props.lang)}
                    onButtonClick={this.onContactDetailsButtonClick.bind(this)}
                    onCloseRequest={this.closeEditDialogRequest.bind(this)}
                    buttons={!this.state.viewContactRequest?.archivedOn ? [
                        {
                            key: 'waiting',
                            component: <ToggleButton
                                value="check"
                                selected={this.state.viewContactRequest?.waiting || false}
                                onChange={() => {
                                    this.changeContactRequest('waiting', !(this.state.viewContactRequest?.waiting || false))
                                }}
                                color={'secondary'}
                                size={'small'}
                            >
                                <Box sx={{display: 'flex', flexAlign: 'center'}}>
                                        {this.state.viewContactRequest?.waiting ?
                                            <CheckIcon sx={{mr: 1}} />
                                            : null
                                        }
                                        <Text>Waiting</Text>
                                </Box>
                            </ToggleButton>
                        },
                        {key: 'print', text: localize('Print', this.props.lang), color: 'info', keepDialogOpen: true, disabled: this.state.changed},
                        {key: 'delete', text: localize('Delete', this.props.lang), color: 'error', keepDialogOpen: true, disabled: this.state.changed},
                        {key: 'save', text: localize('Save', this.props.lang), color: 'success', keepDialogOpen: true, disabled: !this.state.changed},
                        {key: 'transmit', text: localize('Transmit', this.props.lang), color: 'warning', keepDialogOpen: true, disabled: this.state.changed}
                    ] : [
                        {key: 'restore', text: localize('Restore', this.props.lang), color: 'warning', keepDialogOpen: true, disabled: this.state.changed}
                    ]}
                >
                    {this.state.viewContactRequest ?
                        <Box sx={{p: 3}} component={"form"} noValidate autoComplete={'off'}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Accordion expanded={this.state.accordionSections[0] === 0} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(0, 0, isExpanded)}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{
                                                        backgroundColor: this.state.formValidation.accordion_0_0 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                                    }}
                                                >
                                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                        <Text>Patient</Text>
                                                    </Typography>
                                                    <Typography sx={{ color: 'text.secondary' }}>{this.state.viewContactRequest.firstName || ''} {this.state.viewContactRequest.lastName || ''}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                id={'firstName'}
                                                                sx={{width: '100%'}}
                                                                label={localize('First name', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.firstName}
                                                                onBlur={((e: any) => {this.changeContactRequest('firstName', e.target.value)})}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                id={'lastName'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Last name', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.lastName}
                                                                onBlur={((e: any) => {this.changeContactRequest('lastName', e.target.value)})} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                sx={{width: '100%'}}
                                                                label={localize('Birthdate', this.props.lang)}
                                                                value={dayjsOrAny(this.state.viewContactRequest.birthdate)}
                                                                format={this.props.dateFormat}
                                                                onChange={((newValue: any) => {this.changeContactRequest('birthdate', newValue)})}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                id="civilStatus"
                                                                sx={[{width: '100%'},
                                                                    (theme) => (
                                                                        this.state.formValidation.civilStatus === 'warning' ? {
                                                                            '& fieldset': {
                                                                                borderColor: theme.palette.warning.main
                                                                            },
                                                                        } : null)
                                                                ]}
                                                                select
                                                                label={localize('Civil status', this.props.lang)}
                                                                value={this.state.viewContactRequest.civilStatus || ''}
                                                                helperText={this.state.viewContactRequest.civilStatusOriginal}
                                                                onChange={((e: any) => {this.changeContactRequest('civilStatus', getEnumValue(CIVIL_STATUS, e.target.value))})}
                                                            >
                                                                {getEnumValues(CIVIL_STATUS).map((value: number) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {localize(CIVIL_STATUS[value], this.props.lang)}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id={'address'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Address', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.address}
                                                                onBlur={((e: any) => {this.changeContactRequest('address', e.target.value)})} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                id={'postalCode'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Postal code', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.postalCode}
                                                                onBlur={((e: any) => {this.changeContactRequest('postalCode', e.target.value)})} />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={this.state.accordionSections[0] === 1} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(0, 1, isExpanded)}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{
                                                        backgroundColor: this.state.formValidation.accordion_0_1 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                                    }}
                                                >
                                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                        <Text>Contact</Text>
                                                    </Typography>
                                                    <Typography sx={{ color: 'text.secondary' }}>
                                                        {this.getActivePhoneNumber(this.state.viewContactRequest)[0]}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id={'email'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Email', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.email}
                                                                onBlur={((e: any) => {this.changeContactRequest('email', e.target.value)})} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id={'phoneCell'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Cell Phone', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.phoneCell}
                                                                onBlur={((e: any) => {this.changeContactRequest('phoneCell', e.target.value)})} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box sx={{width: '100%', display: 'flex'}}>
                                                                <Box sx={{width: '80%', pr: 3}}>
                                                                    <TextField
                                                                        id={'phoneWork'}
                                                                        sx={{width: '100%'}}
                                                                        label={localize('Phone at work', this.props.lang)}
                                                                        defaultValue={this.state.viewContactRequest.phoneWork}
                                                                        onBlur={((e: any) => {this.changeContactRequest('phoneWork', e.target.value)})} />
                                                                </Box>
                                                                <Box sx={{width: '20%'}}>
                                                                    <TextField
                                                                        id={'phoneWorkExt'}
                                                                        sx={{width: '100%'}}
                                                                        label={localize('Ext.', this.props.lang)}
                                                                        defaultValue={this.state.viewContactRequest.phoneWorkExt}
                                                                        onBlur={((e: any) => {this.changeContactRequest('phoneWorkExt', e.target.value)})} />
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id={'phoneHome'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Phone at home', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.phoneHome}
                                                                onBlur={((e: any) => {this.changeContactRequest('phoneHome', e.target.value)})} />
                                                        </Grid>
                                                        <Grid sx={{p: 2}} item xs={12} md={6}>
                                                            <Button
                                                                sx={{width: '100%'}}
                                                                variant={'contained'}
                                                                color={isMinor(this.state.viewContactRequest.birthdate) ? 'warning' : 'info'}
                                                                onClick={() => {
                                                                    this.parent1DialogRef.current?.openDialog();
                                                                }}
                                                            >
                                                                {
                                                                    this.state.viewContactRequest?.parent1?.relation ?
                                                                        getRelationText(this.state.viewContactRequest?.parent1?.relation as PARENT_RELATION, this.props.lang)
                                                                        : <Text>Parent 1</Text>
                                                                }
                                                            </Button>
                                                        </Grid>
                                                        <Grid sx={{p: 2}} item xs={12} md={6}>
                                                            <Button
                                                                sx={{width: '100%'}}
                                                                variant={'contained'}
                                                                color={isMinor(this.state.viewContactRequest.birthdate) ? 'warning' : 'info'}
                                                                onClick={() => {
                                                                    this.parent2DialogRef.current?.openDialog();
                                                                }}
                                                            >
                                                                {
                                                                    this.state.viewContactRequest?.parent2?.relation ?
                                                                        getRelationText(this.state.viewContactRequest?.parent2?.relation as PARENT_RELATION, this.props.lang)
                                                                        : <Text>Parent 2</Text>
                                                                }
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion
                                                expanded={this.state.accordionSections[0] === 2}
                                                onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(0, 2, isExpanded)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{
                                                        backgroundColor: this.state.formValidation.accordion_0_2 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                                    }}
                                                >
                                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                        <Text>RAMQ</Text>
                                                    </Typography>
                                                    <Typography sx={{ color: 'text.secondary' }}>
                                                        {formatRAMQNo(this.state.viewContactRequest.ramqNo)}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} sx={{pr: 2}}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id={'ramqNo'}
                                                                label={localize('RAMQ Number', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.ramqNo}
                                                                onBlur={((e: any) => {this.changeContactRequest('ramqNo', e.target.value)})}
                                                                error={this.state.formValidation.ramqNo === 'error'}
                                                                sx={{width: '100%'}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id={'ramqExpireMonth'}
                                                                label={localize('Expir. month', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.ramqExpirationMonth}
                                                                onBlur={((e: any) => {this.changeContactRequest('ramqExpirationMonth', e.target.value)})}
                                                                type={"number"}
                                                                error={this.state.formValidation.ramqExpirationMonth === 'error'}
                                                                sx={{width: '100%'}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id={'ramqExpirationYear'}
                                                                label={localize('Expir. year', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.ramqExpirationYear}
                                                                onBlur={((e: any) => {this.changeContactRequest('ramqExpirationYear', e.target.value)})}
                                                                type={"number"}
                                                                error={this.state.formValidation.ramqExpirationYear === 'error'}
                                                                sx={{width: '100%'}}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>

                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Accordion expanded={this.state.accordionSections[1] === 0} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(1, 0, isExpanded)}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{
                                                        backgroundColor: this.state.formValidation.accordion_1_0 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                                    }}
                                                >
                                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                        <Text>Organization</Text>
                                                    </Typography>
                                                    <Typography sx={{ color: 'text.secondary' }}>

                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} sx={{pr: 2}}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id="organization"
                                                                sx={{width: '100%'}}
                                                                select
                                                                error={this.state.formValidation.organization === 'error'}
                                                                label={localize('Organization', this.props.lang)}
                                                                value={this.state.viewContactRequest.organization || ''}
                                                                helperText={this.state.viewContactRequest.organizationOriginal}
                                                                onChange={((e: any) => {this.changeContactRequest('organization', e.target.value)})}
                                                            >
                                                                <MenuItem value={'-1'}>
                                                                    {localize('On the patient\'s account', this.props.lang)}
                                                                </MenuItem>
                                                                <Divider />
                                                                {this.state.accounts.map((acc: Account) => (
                                                                    <MenuItem key={acc._id} value={acc._id}>
                                                                        {acc.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id="organizationLink"
                                                                sx={[{width: '100%'},
                                                                    (theme) => (
                                                                        this.state.formValidation.organizationLink === 'warning' ? {
                                                                            '& fieldset': {
                                                                                borderColor: theme.palette.warning.main
                                                                            },
                                                                        } : null)
                                                                ]}
                                                                select
                                                                label={localize('Organization link', this.props.lang)}
                                                                value={this.state.viewContactRequest.organizationLink || ''}
                                                                helperText={this.state.viewContactRequest.organizationLinkOriginal}
                                                                onChange={((e: any) => {this.changeContactRequest('organizationLink', getEnumValue(ORGANIZATION_LINK, e.target.value))})}
                                                            >
                                                                {getEnumValues(ORGANIZATION_LINK).map((value: number) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {localize(ORGANIZATION_LINK[value], this.props.lang)}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id={'employeeName'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Employee name', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.employeeName}
                                                                onBlur={((e: any) => {this.changeContactRequest('employeeName', e.target.value)})} />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id="employeeStatus"
                                                                sx={[{width: '100%'},
                                                                    (theme) => (
                                                                        this.state.formValidation.employeeStatus === 'warning' ? {
                                                                            '& fieldset': {
                                                                                borderColor: theme.palette.warning.main
                                                                            },
                                                                        } : null)
                                                                ]}
                                                                select
                                                                label={localize('Employee status', this.props.lang)}
                                                                value={this.state.viewContactRequest.employeeStatus || ''}
                                                                helperText={this.state.viewContactRequest.employeeStatusOriginal}
                                                                onChange={((e: any) => {this.changeContactRequest('employeeStatus', getEnumValue(EMPLOYEE_STATUS, e.target.value))})}
                                                            >
                                                                {getEnumValues(EMPLOYEE_STATUS).map((value: number) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {localize(EMPLOYEE_STATUS[value], this.props.lang)}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id={'employmentTime'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Employee time', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.employmentTime}
                                                                onBlur={((e: any) => {this.changeContactRequest('employmentTime', e.target.value)})} />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={this.state.accordionSections[1] === 1} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(1, 1, isExpanded)}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{
                                                        backgroundColor: this.state.formValidation.accordion_1_1 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                                    }}
                                                >
                                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                        <Text>Service</Text>
                                                    </Typography>
                                                    <Typography sx={{ color: 'text.secondary' }}>

                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} sx={{pr: 2}}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id="requestedService"
                                                                sx={[{width: '100%'},
                                                                    (theme) => (
                                                                        this.state.formValidation.requestedService === 'warning' ? {
                                                                            '& fieldset': {
                                                                                borderColor: theme.palette.warning.main
                                                                            },
                                                                        } : null)
                                                                ]}
                                                                select
                                                                label={localize('Requested service', this.props.lang)}
                                                                value={this.state.viewContactRequest.requestedService || ''}
                                                                helperText={this.state.viewContactRequest.requestedServiceOriginal}
                                                                onChange={((e: any) => {this.changeContactRequest('requestedService', getEnumValue(SERVICES, e.target.value))})}
                                                            >
                                                                {getEnumValues(SERVICES).map((value: number) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {localize(SERVICES[value], this.props.lang)}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="usedServicePast"
                                                                sx={[{width: '100%'},
                                                                    (theme) => (
                                                                        this.state.formValidation.usedServicePast === 'warning' ? {
                                                                            '& fieldset': {
                                                                                borderColor: theme.palette.warning.main
                                                                            },
                                                                        } : null)
                                                                ]}
                                                                select
                                                                label={localize('Used service past', this.props.lang)}
                                                                value={this.state.viewContactRequest.usedServicePast || ''}
                                                                helperText={this.state.viewContactRequest.usedServicePastOriginal}
                                                                onChange={((e: any) => {this.changeContactRequest('usedServicePast', getEnumValue(USED_SERVICE_BEFORE, e.target.value))})}
                                                            >
                                                                {getEnumValues(USED_SERVICE_BEFORE).map((value: number) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {localize(USED_SERVICE_BEFORE[value], this.props.lang)}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <TextField
                                                                id="directedBy"
                                                                sx={[{width: '100%'},
                                                                    (theme) => (
                                                                        this.state.formValidation.directedBy === 'warning' ? {
                                                                            '& fieldset': {
                                                                                borderColor: theme.palette.warning.main
                                                                            },
                                                                        } : null)
                                                                ]}
                                                                select
                                                                label={localize('Directed by', this.props.lang)}
                                                                value={this.state.viewContactRequest.directedBy || ''}
                                                                helperText={this.state.viewContactRequest.directedByOriginal}
                                                                onChange={((e: any) => {this.changeContactRequest('directedBy', getEnumValue(DIRECTED_BY, e.target.value))})}
                                                            >
                                                                {getEnumValues(DIRECTED_BY).map((value: number) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {localize(DIRECTED_BY[value], this.props.lang)}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={this.state.accordionSections[1] === 2} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(1, 2, isExpanded)}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{
                                                        backgroundColor: this.state.formValidation.accordion_1_2 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                                    }}
                                                >
                                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                        <Text>IVAC/CSST</Text>
                                                    </Typography>
                                                    <Typography sx={{ color: 'text.secondary' }}>

                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} sx={{pr: 2}}>
                                                        <Grid item xs={12} md={6}>
                                                            <DatePicker
                                                                sx={{
                                                                    width: '100%',
                                                                    '&.warning .MuiOutlinedInput-root fieldset': {borderColor: '#ffa726'},
                                                                    '&.warning label': {color: '#ffa726'},
                                                                }}
                                                                label={localize('Event date', this.props.lang)}
                                                                value={dayjsOrAny(this.state.viewContactRequest.eventDate || null)}
                                                                format={this.props.dateFormat}
                                                                onChange={((newValue: any) => {this.changeContactRequest('eventDate', newValue)})}
                                                                slotProps={{textField: {className: this.isIVACCSST() ? 'warning' : undefined, color: this.isIVACCSST() ? 'warning' : undefined}}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id={'fileNumberIVACCSST'}
                                                                sx={{
                                                                    width: '100%',
                                                                    '&.warning .MuiOutlinedInput-root fieldset': {borderColor: '#ffa726'},
                                                                    '&.warning label': {color: '#ffa726'},
                                                                }}
                                                                className={this.isIVACCSST() ? 'warning' : undefined}
                                                                color={this.isIVACCSST() ? 'warning' : undefined}
                                                                label={localize('File number', this.props.lang)}
                                                                defaultValue={this.state.viewContactRequest.fileNumberIVACCSST}
                                                                onBlur={((e: any) => {this.changeContactRequest('fileNumberIVACCSST', e.target.value)})}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                                <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                                    <Box sx={{pr: 2}}>
                                                        <Typography variant={'caption'}>
                                                            <Text>Communications</Text>
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{pr: 2}}>
                                                        <Button onClick={this.addCommunication.bind(this)}>
                                                            <Text>Add communication</Text>
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                <Box sx={{flex: 1, overflow: 'auto', maxHeight: '250px'}}>
                                                    <List sx={{ width: '100%'}}>
                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <LanguageIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={localize('Request received', this.props.lang)} secondary={dayjs(this.state.viewContactRequest.createdOn).format(`${this.props.dateFormat} HH:mm`)} />
                                                        </ListItem>
                                                        {this.state.viewContactRequest.communications.map((communication, index) => {
                                                            return (
                                                                <React.Fragment key={index + '_f'}>
                                                                    <Divider key={index + '_d'} />
                                                                    <ListItem key={index + '_l'}>
                                                                        <ListItemAvatar>
                                                                            <Avatar>
                                                                                {
                                                                                    communication.type === COMMUNICATION_TYPE.PHONE ?
                                                                                        <PhoneIcon/>
                                                                                        : communication.type === COMMUNICATION_TYPE.SMS ?
                                                                                            <SmsIcon/>
                                                                                            :
                                                                                            communication.type === COMMUNICATION_TYPE.EMAIL ?
                                                                                                <AlternateEmailIcon/>
                                                                                                :
                                                                                                <RecordVoiceOverIcon/>
                                                                                }
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText primary={communication.result} secondary={dayjs(communication.date).format(`${this.props.dateFormat} HH:mm`)} />
                                                                    </ListItem>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </List>
                                                </Box>
                                                <Divider variant="middle" />
                                                <Box sx={{pt: 2}}>
                                                    <TextField
                                                        id={'personalNote'}
                                                        sx={{width: '100%'}}
                                                        multiline
                                                        rows={2}
                                                        label={localize('Personal note', this.props.lang)}
                                                        defaultValue={this.state.viewContactRequest.personalNote}
                                                        onBlur={((e: any) => {this.changeContactRequest('personalNote', e.target.value)})} />
                                                </Box>
                                                <Box sx={{pt: 2}}>
                                                    <TextField
                                                        id={'comments'}
                                                        sx={{width: '100%'}}
                                                        multiline
                                                        rows={4}
                                                        label={localize('Comments', this.props.lang)}
                                                        defaultValue={this.state.viewContactRequest.comments}
                                                        onBlur={((e: any) => {this.changeContactRequest('comments', e.target.value)})} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ReportViewer ref={this.reportRef} title={""} users={this.state.users}>
                                <ContactRequestReport
                                    contactRequest={this.state.viewContactRequest}
                                    accounts={this.state.accounts}
                                    lang={this.props.lang}
                                    dateFormat={this.props.dateFormat}
                                />
                            </ReportViewer>
                        </Box>
                        :
                        null
                    }
                </FullScreenDialog>
                <ConfirmDialog
                    ref={this.confirmDeleteDialogRef}
                    title={localize('Delete this contact requests?', this.props.lang)}
                    text={localize('This action will archive this contact request for 10 days. After this period, it will be removed forever. Do you want to continue?', this.props.lang)}
                    onAgree={this.deleteContactRequest.bind(this)}
                />
                <ConfirmDialog
                    ref={this.confirmCloseDialogRef}
                    title={localize('Unsaved changes', this.props.lang)}
                    text={localize('All unsaved changes will be lost. Do you want to continue?', this.props.lang)}
                    onAgree={this.closeEditDialog.bind(this)}
                    />
                <AddCommunication ref={this.addCommunicationDialogRef} onSaved={this.onNewCommunication.bind(this)}/>
                <LinkPatient ref={this.linkPatientDialogRef} onPatientSelected={this.onRelatedPatientSelected.bind(this)} />
                <ParentDialog
                    ref={this.parent1DialogRef}
                    readonly={false}
                    parent={this.state.viewContactRequest?.parent1}
                    title={localize('Parent 1', this.props.lang)}
                    onSave={(parent: Parent) => {
                        this.changeContactRequest('parent1', parent)
                    }}
                />
                <ParentDialog
                    ref={this.parent2DialogRef}
                    readonly={false}
                    parent={this.state.viewContactRequest?.parent2}
                    title={localize('Parent 2', this.props.lang)}
                    onSave={(parent: Parent) => {
                        this.changeContactRequest('parent2', parent)
                    }}
                />
            </React.Fragment>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactRequests));
