import dayjs from "dayjs";
import {File} from './file';
import {CIVIL_STATUS, ORGANIZATION_LINK} from "../constants/enums";
import {SignedDocumentAnswer} from './signedDocument';

export enum PATIENT_STATUS {
    ACTIVE = 1,
    INACTIVE = 2
}

export enum PATIENT_FILES {
    IVAC = 1,
    CNESST = 2,
    DOCUMENT = 3,
    PAYMENT_RECEIPT = 4
}

class Patient {
    public _id?: string = undefined;
    public accountId?: string = undefined;
    public professionalUserId?: string = undefined;
    public organizationLink?: ORGANIZATION_LINK = undefined;
    public fileNumber?: number = undefined;
    public firstName?: string = undefined;
    public lastName?: string = undefined;
    public birthdate?: Date = undefined;
    public address?: string = undefined;
    public postalCode?: string = undefined;
    public email?: string = undefined;
    public phoneWork?: string = undefined;
    public phoneWorkExt?: string = undefined;
    public phoneHome?: string = undefined;
    public phoneCell?: string = undefined;
    public civilStatus?: CIVIL_STATUS = undefined;
    public status?: PATIENT_STATUS = undefined;
    public notificationEmail?: boolean = undefined;
    public notificationSMS?: boolean = undefined;
    public attachedFiles?: File<PATIENT_FILES>[] = [];
    public medication?: string = undefined;
    public consultationReasons?: ConsultationReasons = undefined;
    public eventDate?: Date = undefined;
    public fileNumberIVACCSST?: string = undefined;
    public parent1?: Parent = undefined;
    public parent2?: Parent = undefined;
    public personalNote?: string = undefined;
    public ramqNo?: string = undefined;
    public ramqExpirationMonth?: number = undefined;
    public ramqExpirationYear?: number = undefined;
    public signedDocumentAnswers?: SignedDocumentAnswer[] = [];

    static collection: string = "patient";

    public constructor(init?:Partial<Patient>) {
        if (init && init.birthdate) init.birthdate = dayjs(init.birthdate).isValid() ?
            dayjs(init.birthdate).hour(0).minute(0).second(0).millisecond(0).toDate()
            :
            undefined;
        if (init?.parent1) init.parent1 = new Parent(init.parent1);
        if (init?.parent2) init.parent2 = new Parent(init.parent2);
        Object.assign(this, init);
    }
}

export enum PARENT_RELATION {
    MOTHER = 1,
    FATHER = 2,
    OTHER = 3
}

export class Parent {
    public firstName?: string = undefined;
    public lastName?: string = undefined;
    public birthdate?: Date = undefined;
    public phoneNumber?: string = undefined;
    public email?: string = undefined;
    public note?: string = undefined;
    public relation?: PARENT_RELATION = undefined;

    public constructor(init?:Partial<Parent>) {
        if (init && init.birthdate) init.birthdate = dayjs(init.birthdate).isValid() ?
            dayjs(init.birthdate).startOf('day').toDate()
            :
            undefined;
        Object.assign(this, init);
    }
}

export class ConsultationReasons {
    public reasons: ConsultationReason[] = [];
    public suicidal?: boolean = undefined;
    public doctorName?: string = undefined;
}

export enum ConsultationReasonType {
    ANXIOUS = 1,
    PERSONALITY= 2,
    MOOD = 3,
    ADAPTATION = 4,
    PROFESSIONAL = 5,
    COUPLE = 6,
    FAMILY = 7,
    LEGAL = 8,
    FINANCIAL = 9,
    ADDICTION = 10,
    GAMBLING = 11,
    GRIEF = 12,
    OTHER = 13
}

export class ConsultationReason {
    public type?: ConsultationReasonType = undefined;
    public importance?: number = 0;
    public notes?: string = undefined;
}

export {Patient};
