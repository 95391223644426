import {get} from '../helpers/ajax';
import {AxiosResponse} from "axios";

export async function getLink(linkUID: string): Promise<string | null> {
    let response: AxiosResponse<string | null> = await get<string | null>(`/external/system/link/${linkUID}`);

    if (response.status === 200)
        return response.data;
    else
        return null;
}
