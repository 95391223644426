import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import AccountsGrid from './components/grid';
import {Account, ACCOUNT_STATUS} from "../../../models/account";
import AccountDialog from "./components/dialog";
import {ROLES} from "../../../constants/roles";
import {IRouter} from "../../app/router/router";
import withRouter from "../../app/router/withRouter";
import {logException} from "../../../controllers/system";

const ALLOWED_ROLES: ROLES[] = [
    ROLES.Administrator,
    ROLES.Assistant
]

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        userRole: state.user.role
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class Accounts extends React.Component<ReduxType> {
    private accountsGridRef: RefObject<any>;
    private accountDialogRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.accountsGridRef = React.createRef();
        this.accountDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        if (!this.isAllowed()) {
            window.setTimeout(() => {
                this.props.router.navigate('/');
            }, 100);
        }
    }

    isAllowed(): boolean {
        return ALLOWED_ROLES.indexOf(this.props.userRole as ROLES) !== -1;
    }

    onAccountSelected(account: Account) {
        this.accountDialogRef.current.openDialog(account);
    }

    onAccountAdd() {
        this.accountDialogRef.current.openDialog(new Account({status: ACCOUNT_STATUS.ACTIVE}));
    }

    onAccountSave() {
        this.accountsGridRef.current.loadData();
    }

    render() {
        if (!this.isAllowed()) return null;
        return (
            <React.Fragment>
                <AccountsGrid
                    ref={this.accountsGridRef}
                    onAccountSelected={this.onAccountSelected.bind(this)}
                    onAccountAdd={this.onAccountAdd.bind(this)}
                />
                <AccountDialog
                    ref={this.accountDialogRef}
                    onSave={this.onAccountSave.bind(this)}
                />
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Accounts));
