import {COMMUNICATION_TYPE, ContactRequest} from "../models/contactRequest";
import {get, post} from "../helpers/ajax";
import {AxiosResponse} from "axios";
import {Patient} from "../models/patient";
import {getEnumValue} from "../helpers/enums";
import {
    CIVIL_STATUS,
    DIRECTED_BY,
    EMPLOYEE_STATUS,
    ORGANIZATION_LINK,
    SERVICES,
    USED_SERVICE_BEFORE
} from "../constants/enums";

export async function getContactRequest(contactRequestId: string): Promise<ContactRequest | null> {
    let response: AxiosResponse<ContactRequest | null> = await get<ContactRequest>(`/contact-request/${contactRequestId}`);

    if (response.status === 200 && response.data)
        return parseData(([response.data] as ContactRequest[]))[0];
    else
        return null;
}

interface IFindContactRequestFilter {
    page?: number;
    resultsPerPage?: number;
    createdFrom?: Date;
    createdTo?: Date;
    transmittedFrom?: Date;
    transmittedTo?: Date;
    transmitted?: boolean;
    deleted?: boolean;
    includeDeleted?: boolean;
}
export async function getContactRequests(filter: IFindContactRequestFilter): Promise<ContactRequest[]> {
    let response: AxiosResponse<ContactRequest[]> = await post<ContactRequest[]>(
        '/contact-request/find',
        filter);

    if (response.status === 200 && Array.isArray(response.data))
        return parseData(response.data);
    else
        return [];
}

function parseData(contactRequests: ContactRequest[]): ContactRequest[] {
    for(let contactRequest of contactRequests) {
        for (let communication of contactRequest.communications) {
            switch (communication.type as any) {
                case 'PHONE':
                    communication.type = COMMUNICATION_TYPE.PHONE;
                    break;
                case 'EMAIL':
                    communication.type = COMMUNICATION_TYPE.EMAIL;
                    break;
                case 'SMS':
                    communication.type = COMMUNICATION_TYPE.SMS;
                    break;
                case 'VOICE':
                    communication.type = COMMUNICATION_TYPE.VOICE;
                    break;
            }
        }
    }

    return contactRequests;
}

export async function saveContactRequest(contactRequest: ContactRequest): Promise<ContactRequest | null> {
    let response: AxiosResponse<ContactRequest> = await post<ContactRequest>('/contact-request/save', contactRequest);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export async function findRelatedPatients(contactRequestId: string): Promise<Patient[]> {
    let response: AxiosResponse<Patient[]> = await get<Patient[]>(`/contact-request/related-patients/${contactRequestId}`);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return [];
}

export function matchContactRequestEnums(contactRequest: ContactRequest): ContactRequest {
    if (isNaN(parseInt(`${contactRequest.civilStatus}`))) contactRequest.civilStatus = getEnumValue(CIVIL_STATUS, contactRequest.civilStatus);
    if (isNaN(parseInt(`${contactRequest.usedServicePast}`))) contactRequest.usedServicePast = getEnumValue(USED_SERVICE_BEFORE, contactRequest.usedServicePast);
    if (isNaN(parseInt(`${contactRequest.requestedService}`))) contactRequest.requestedService = getEnumValue(SERVICES, contactRequest.requestedService);
    if (isNaN(parseInt(`${contactRequest.organizationLink}`))) contactRequest.organizationLink = getEnumValue(ORGANIZATION_LINK, contactRequest.organizationLink);
    if (isNaN(parseInt(`${contactRequest.directedBy}`))) contactRequest.directedBy = getEnumValue(DIRECTED_BY, contactRequest.directedBy);
    if (isNaN(parseInt(`${contactRequest.employeeStatus}`))) contactRequest.employeeStatus = getEnumValue(EMPLOYEE_STATUS, contactRequest.employeeStatus);

    return contactRequest;
}

export type ContactRequestSummary = {
    receivedWOC: number,
    receivedWC: number,
    waiting: number,
    deleted: number,
    scheduled: number
}
export async function getSummary(userId?: string): Promise<ContactRequestSummary | null> {
    let response: AxiosResponse<ContactRequestSummary> = await post<ContactRequestSummary>('/contact-request/summary', userId);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export async function postInternalContactRequest(contactRequest: ContactRequest, recaptchaToken: string): Promise<ContactRequest | null> {
    let response: AxiosResponse<ContactRequest | null> = await post<ContactRequest | null>('/external/contact-request/internal', {contactRequest, recaptchaToken});

    if (response?.status === 200 && response?.data)
        return response.data;
    else
        return null;
}
