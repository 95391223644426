import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import configs from '../constants/configs';
import {store} from "../store/store";
import {logout} from "../store/actions/user";

function setUrl(url:string): string {
    if (url.indexOf('http') === -1) {
        url = `${configs.baseUrl}${url}`;
    }
    return url;
}

export function setBearer(bearerToken:string | null) {
    if (bearerToken) {
        axios.defaults.headers.common['Authorization'] = `bearer ${bearerToken}`;
    } else {
        axios.defaults.headers.common['Authorization'] = "";
    }
}

export async function get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    let response: any;
    try {
        response = await axios.get<T, R, D>(setUrl(url), config);
    } catch (ex:any) {
        checkCode(ex.toJSON().status);
    }

    return response;
}

export async function post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    let response: any;
    try {
        response = await axios.post<T, R, D>(setUrl(url), data, config);
    } catch (ex: any) {
        checkCode(ex.toJSON().status);
    }

    return response;
}

export async function open(url: string) {
    url = `${configs.baseUrl}${url}?auth=${store.getState().session.sessionId}`;
    window.open(url, '_blank');
}

export async function upload<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    if (!config) config = {};
    if (!config.headers) config.headers = {};
    if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'multipart/form-data';

    return post(url, data, config);
}

function checkCode(httpStatus: number) {
    switch (httpStatus) {
        case 403:
            if (store.getState().user && store.getState().user._id) {
                store.dispatch(logout());
            }
            break;
    }
}
