import * as ajax from '../helpers/ajax';
import * as localStorage from "../helpers/localStorage";
import {KEYS} from "../helpers/localStorage";
import {setBearer} from "../helpers/ajax";
import User from "../models/user";
import {AxiosResponse} from "axios";
import {TWOFA_TYPE} from "../models/twoFA";

export async function requestResetPasswordCode(username: string): Promise<boolean> {
    let result: AxiosResponse<string> = await ajax.post('/auth/request-reset-password-code', {username});

    return result.status === 200;
}

export async function request2FACode(userId: string, sessionId: string, type: TWOFA_TYPE): Promise<boolean> {
    try {
        let result: AxiosResponse<boolean> = await ajax.post('/auth/request-2fa', {userId, sessionId, type});

        return result.status === 200 && result.data;
    } catch(ex) {
        return false;
    }
}

export async function validate2FACode(userId: string, sessionId: string, twoFACode: string): Promise<boolean> {
    try {
        let result: AxiosResponse<boolean> = await ajax.post('/auth/validate-2fa', {userId, sessionId, twoFACode});

        return result.status === 200 && result.data;
    } catch(ex) {
        return false;
    }
}

export async function resetPassword(username: string, code: string): Promise<boolean> {
    try {
        let result: AxiosResponse<boolean> = await ajax.post('/auth/reset-password', {username, code});

        return result.status === 200;
    } catch(ex) {
        return false;
    }
}

export async function changePassword(userId: string, oldPassword: string, newPassword: string): Promise<boolean> {
    try {
        let result: AxiosResponse<boolean> = await ajax.post('/auth/change-password', {userId, oldPassword, newPassword});

        return result.status === 200;
    } catch(ex) {
        return false;
    }
}

export async function changeName(userId: string, firstName: string | undefined, lastName: string | undefined): Promise<boolean> {
    try {
        let result: AxiosResponse<boolean> = await ajax.post('/user/set-name', {userId, firstName, lastName});

        return result.status === 200;
    } catch(ex) {
        return false;
    }
}

export async function signin(username: string, password: string): Promise<SessionValidation | null> {
    try {
        let result: AxiosResponse<SessionValidation> = await ajax.post('/auth/signin', {username, password});

        if (result.status === 200 && result.data) {
            return result.data;
        } else {
            return null;
        }
    } catch(ex) {
        return null;
    }
}

async function requestSession(url: string, postData: any): Promise<SessionValidation | null> {
    let result = await ajax.post(url, postData);

    if (result.status === 200 && result.data) {
        setBearer(result.data.sessionId);
        localStorage.set(undefined, KEYS.SESSION_ID, result.data.sessionId);
        return result.data;
    } else {
        localStorage.set(undefined, KEYS.SESSION_ID, null);
        return null;
    }
}

export interface SessionValidation {
    sessionId: string,
    blockedBy2FA: boolean,
    user: User
}
export async function loadSession(): Promise<SessionValidation | null> {
    let sessionId: string | null = localStorage.get(undefined, KEYS.SESSION_ID);

    if (sessionId) {
        setBearer(sessionId);
        try {
            return await requestSession('/session/validate', {sessionId});
        } catch (ex) {
            return null;
        }
    } else {
        return null;
    }
}
