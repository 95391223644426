import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {IRouter} from "../../../components/app/router/router";
import withRouter from "../../../components/app/router/withRouter";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Step,
    StepLabel,
    Stepper,
    TextField, Typography
} from "@mui/material";
import {IAppNotification} from "../../../models/appNotification";
import {setAppNotification, setLoading} from "../../../store/actions/app";
import {logException} from "../../../controllers/system";
import Text from "../../../components/app/text/text";
import {localize} from "../../../helpers/localization";
import {ContactRequest as ContactRequestModel} from '../../../models/contactRequest';
import {dayjsOrAny} from "../../../helpers/dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {getEnumValue, getEnumValues} from "../../../helpers/enums";
import {
    CIVIL_STATUS, DIRECTED_BY,
    EMPLOYEE_STATUS,
    ORGANIZATION_LINK,
    SERVICES,
    USED_SERVICE_BEFORE
} from "../../../constants/enums";
import dayjs from "dayjs";
import {postInternalContactRequest} from "../../../controllers/contactRequests";
import AddressPicker from "../../../components/ui/addressPicker/addressPicker";
import Recaptcha from "../../../components/ui/recaptcha/recaptcha";

type IState = {
    activeStep: number,
    latestActivatedStep: number,
    formValidation: any,
    form: ContactRequestModel,
    eapRequest: boolean,
    confirmationNumber?: number,
    step0InError: boolean,
    step1InError: boolean,
    step2InError: boolean,
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        },
        setLoading: (loading: boolean) => {
            dispatch(setLoading(loading));
        },
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class ContactRequest extends React.Component<ReduxType> {
    public readonly state: IState = {
        activeStep: 0,
        latestActivatedStep: 0,
        formValidation: {},
        form: new ContactRequestModel(),
        eapRequest: false,
        step0InError: false,
        step1InError: false,
        step2InError: false,
    }
    private recaptchaToken: string | null = null;

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    async submitStep(stepModifier: number) {
        if (stepModifier > 0 && this.state.activeStep >= 3) {
            this.props.setLoading(true);
            let savedContactRequest: ContactRequestModel | null = await postInternalContactRequest(this.state.form, this.recaptchaToken as string);
            if (savedContactRequest) {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        confirmationNumber: savedContactRequest?.confirmationNumber,
                        activeStep: 4
                    };
                }, () => {
                    this.props.setLoading(false);
                });
            } else {
                this.props.setLoading(false);
                this.props.setAppNotification({severity: 'error', message: 'An error occurred, please try again'})
            }
        } else {
            this.setState((state: IState) => {
                let activeStep = state.activeStep += stepModifier;
                return {
                    ...state,
                    activeStep,
                    latestActivatedStep: state.latestActivatedStep > activeStep ? state.latestActivatedStep : activeStep
                };
            }, () => {
                this.validateForm();
            });
        }
    }

    onChange(property: string, value: any) {
        this.setState((state: IState) => {
            let form: any = state.form;
            form[property] = value;
            return {
                ...state,
                form
            }
        }, () => {
            this.validateForm();
        });
    }

    setEAPRequest(eapRequest: boolean) {
        this.setState((state: IState) => {
            return {
                ...state,
                eapRequest
            };
        });
    }

    validateForm() {
        let step0FormValidation: any = {};
        if (this.state.latestActivatedStep > 0 && this.state.activeStep >= 0) step0FormValidation = this.isStepInError(0);
        let step1FormValidation: any = {};
        if (this.state.latestActivatedStep > 1 && this.state.activeStep >= 1) step1FormValidation = this.isStepInError(1);
        let step2FormValidation: any = {};
        if (this.state.latestActivatedStep > 2 && this.state.activeStep >= 2) step2FormValidation = this.isStepInError(2);

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation: {
                    ...step0FormValidation,
                    ...step1FormValidation,
                    ...step2FormValidation,
                },
                step0InError: Object.keys(step0FormValidation).length > 0 && this.state.activeStep > 0,
                step1InError: Object.keys(step1FormValidation).length > 0 && this.state.activeStep > 1,
                step2InError: Object.keys(step2FormValidation).length > 0 && this.state.activeStep > 2,
            }
        });
    }

    isStepInError(stepIndex: number): any {
        let formValidation: any = {};

        switch(stepIndex) {
            case 0:
                if (!this.state.form.lastName) formValidation.lastName = 'error';
                if (!this.state.form.firstName) formValidation.firstName = 'error';
                if ((this.state.form.birthdate != null && !dayjs(this.state.form.birthdate).isValid())) formValidation.birthdate = 'error';
                break;

            case 1:
                if (!this.state.form.address) formValidation.address = 'error';
                if (!this.state.form.postalCode) formValidation.postalCode = 'error';
                if (!this.state.form.email) formValidation.email = 'error';
                break;

            case 2:
                if (this.state.form.usedServicePast === undefined) formValidation.usedServicePast = 'error';
                if (this.state.form.requestedService === undefined) formValidation.requestedService = 'error';
                break;

            default:
                return false;
        }

        return formValidation;
    }

    canSubmit() {
        return Object.keys(this.state.formValidation).length === 0;
    }

    onRecaptchaVerify(token: string) {
        this.recaptchaToken = token;
    }

    render() {
        return (
            <React.Fragment>
                <Recaptcha onVerify={this.onRecaptchaVerify.bind(this)}>
                    <Box sx={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '960px',
                            width: '100%',
                            height: '90%'
                        }}>
                            <Box>
                                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                    <Step>
                                        <StepLabel error={this.state.step0InError}>
                                            <Text>About you</Text>
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel error={this.state.step1InError}>
                                            <Text>Contact</Text>
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel error={this.state.step2InError}>
                                            <Text>Service</Text>
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            <Text>Comments</Text>
                                        </StepLabel>
                                    </Step>
                                </Stepper>
                            </Box>
                            {
                                this.state.confirmationNumber ?
                                    <Box>
                                        <Box sx={{p:2, textAlign: 'center'}}>
                                            <Typography variant={'h3'}>
                                                <Text>Thank you for contacting us</Text>
                                            </Typography>
                                        </Box>
                                        <Box sx={{p:1, textAlign: 'center'}}>
                                            <Typography variant={'h4'}>
                                                <Text>Here is your confirmation number</Text>:
                                            </Typography>
                                        </Box>
                                        <Box sx={{p:1, textAlign: 'center', color: '#37BE5F'}}>
                                            <Typography variant={'h4'}>
                                                {this.state.confirmationNumber}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    :
                                    <Box sx={(theme) => ({
                                            pt: 4,
                                            pb: 2,
                                            pl: 10,
                                            pr: 10,
                                            [theme.breakpoints.down('md')]: {
                                                pl: 2,
                                                pr: 2
                                            }
                                        })}>
                                        {
                                            this.state.activeStep === 0 ?
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={12} md={6} sx={{p: 2}}>
                                                            <TextField
                                                                required
                                                                error={this.state.formValidation.lastName === 'error'}
                                                                id={'lastName'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Last name', this.props.lang)}
                                                                value={this.state.form.lastName || ''}
                                                                onChange={(e) => this.onChange('lastName', e.target.value)}
                                                                autoComplete="family-name"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} sx={{p: 2}}>
                                                            <TextField
                                                                required
                                                                error={this.state.formValidation.firstName === 'error'}
                                                                id={'firstName'}
                                                                sx={{width: '100%'}}
                                                                label={localize('First name', this.props.lang)}
                                                                value={this.state.form.firstName || ''}
                                                                onChange={(e) => this.onChange('firstName', e.target.value)}
                                                                autoComplete="given-name"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} sx={{p: 2}}>
                                                            <DatePicker
                                                                sx={{width: '100%'}}
                                                                label={localize('Birthdate', this.props.lang)}
                                                                value={dayjsOrAny(this.state.form.birthdate)}
                                                                format={this.props.dateFormat}
                                                                onChange={((newValue: any) => {this.onChange('birthdate', newValue)})}
                                                                slotProps={{textField: { error: this.state.formValidation.birthdate === 'error', autoComplete: "bday"}}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} sx={{p: 2}}>
                                                            <TextField
                                                                id="civilStatus"
                                                                sx={[{width: '100%'},
                                                                    (theme) => (
                                                                        this.state.formValidation.civilStatus === 'warning' ? {
                                                                            '& fieldset': {
                                                                                borderColor: theme.palette.warning.main
                                                                            },
                                                                        } : null)
                                                                ]}
                                                                select
                                                                label={localize('Civil status', this.props.lang)}
                                                                value={this.state.form.civilStatus || ''}
                                                                onChange={((e: any) => {this.onChange('civilStatus', getEnumValue(CIVIL_STATUS, e.target.value))})}
                                                            >
                                                                {getEnumValues(CIVIL_STATUS).map((value: number) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {localize(CIVIL_STATUS[value], this.props.lang)}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                :
                                                null
                                        }
                                        {
                                            this.state.activeStep === 1 ?
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xs={12} sx={{p: 2}}>
                                                            <AddressPicker defaultValue={this.state.form.address} onChange={(value) => {this.onChange('address', value)}} />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} sx={{p: 2}}>
                                                            <TextField
                                                                required
                                                                error={this.state.formValidation.postalCode === 'error'}
                                                                id={'postalCode'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Postal code', this.props.lang)}
                                                                value={this.state.form.postalCode || ''}
                                                                onChange={(e) => this.onChange('postalCode', e.target.value)}
                                                                autoComplete="postal-code"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} sx={{p: 2}}>
                                                            <TextField
                                                                required
                                                                error={this.state.formValidation.email === 'error'}
                                                                id={'email'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Email', this.props.lang)}
                                                                value={this.state.form.email || ''}
                                                                onChange={(e) => this.onChange('email', e.target.value)}
                                                                autoComplete="email"
                                                                type="email"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} sx={{p: 2}}>
                                                            <TextField
                                                                error={this.state.formValidation.phoneCell === 'error'}
                                                                id={'phoneCell'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Cell Phone', this.props.lang)}
                                                                value={this.state.form.phoneCell || ''}
                                                                onChange={(e) => this.onChange('phoneCell', e.target.value)}
                                                                autoComplete="tel"
                                                                type="tel"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} sx={{p: 2}}>
                                                            <TextField
                                                                error={this.state.formValidation.phoneHome === 'error'}
                                                                id={'phoneHome'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Phone at home', this.props.lang)}
                                                                value={this.state.form.phoneHome || ''}
                                                                onChange={(e) => this.onChange('phoneHome', e.target.value)}
                                                                type="tel"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} sx={{p: 2}}>
                                                            <TextField
                                                                error={this.state.formValidation.phoneWork === 'error'}
                                                                id={'phoneWork'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Phone at work', this.props.lang)}
                                                                value={this.state.form.phoneWork || ''}
                                                                onChange={(e) => this.onChange('phoneWork', e.target.value)}
                                                                type="tel"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={3} sx={{p: 2}}>
                                                            <TextField
                                                                error={this.state.formValidation.phoneWorkExt === 'error'}
                                                                id={'phoneWorkExt'}
                                                                sx={{width: '100%'}}
                                                                label={localize('Ext.', this.props.lang)}
                                                                value={this.state.form.phoneWorkExt || ''}
                                                                onChange={(e) => this.onChange('phoneWorkExt', e.target.value)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                :
                                                null
                                        }
                                        {
                                            this.state.activeStep === 2 ?
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xs={12} sx={{p: 2}}>
                                                            <TextField
                                                                id="usedServicePast"
                                                                sx={[{width: '100%'},
                                                                    (theme) => (
                                                                        this.state.formValidation.usedServicePast === 'warning' ? {
                                                                            '& fieldset': {
                                                                                borderColor: theme.palette.warning.main
                                                                            },
                                                                        } : null)
                                                                ]}
                                                                error={this.state.formValidation.usedServicePast === 'error'}
                                                                select
                                                                required
                                                                label={localize('Q: Used service past', this.props.lang)}
                                                                value={this.state.form.usedServicePast || ''}
                                                                onChange={((e: any) => {this.onChange('usedServicePast', getEnumValue(USED_SERVICE_BEFORE, e.target.value))})}
                                                            >
                                                                {getEnumValues(USED_SERVICE_BEFORE).map((value: number) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {localize(USED_SERVICE_BEFORE[value], this.props.lang)}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{p: 2}}>
                                                            <TextField
                                                                id="requestedService"
                                                                sx={[{width: '100%'},
                                                                    (theme) => (
                                                                        this.state.formValidation.requestedService === 'warning' ? {
                                                                            '& fieldset': {
                                                                                borderColor: theme.palette.warning.main
                                                                            },
                                                                        } : null)
                                                                ]}
                                                                error={this.state.formValidation.requestedService === 'error'}
                                                                select
                                                                required
                                                                label={localize('Q: Requested service', this.props.lang)}
                                                                value={this.state.form.requestedService || ''}
                                                                onChange={((e: any) => {this.onChange('requestedService', getEnumValue(SERVICES, e.target.value))})}
                                                            >
                                                                {getEnumValues(SERVICES).map((value: number) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {localize(SERVICES[value], this.props.lang)}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{p: 2}}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox checked={this.state.eapRequest} onChange={(e) => this.setEAPRequest(e.target.checked)} />
                                                                }
                                                                label={localize('Employee Assistance Program (EAP)', this.props.lang)}
                                                            />
                                                        </Grid>
                                                        {
                                                            this.state.eapRequest ?
                                                                <React.Fragment>
                                                                    <Grid item xs={12} md={6} sx={{p: 2}}>
                                                                        <TextField
                                                                            id="organization"
                                                                            sx={{width: '100%'}}
                                                                            error={this.state.formValidation.organization === 'error'}
                                                                            label={localize('Name of employer or organization', this.props.lang)}
                                                                            value={this.state.form.organization || ''}
                                                                            onChange={((e: any) => {this.onChange('organization', e.target.value)})}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} sx={{p: 2}}>
                                                                        <TextField
                                                                            id="organizationLink"
                                                                            sx={[{width: '100%'},
                                                                                (theme) => (
                                                                                    this.state.formValidation.organizationLink === 'warning' ? {
                                                                                        '& fieldset': {
                                                                                            borderColor: theme.palette.warning.main
                                                                                        },
                                                                                    } : null)
                                                                            ]}
                                                                            select
                                                                            label={localize('Connection to the company or organization', this.props.lang)}
                                                                            value={this.state.form.organizationLink || ''}
                                                                            onChange={((e: any) => {this.onChange('organizationLink', getEnumValue(ORGANIZATION_LINK, e.target.value))})}
                                                                        >
                                                                            {getEnumValues(ORGANIZATION_LINK).map((value: number) => (
                                                                                <MenuItem key={value} value={value}>
                                                                                    {localize(ORGANIZATION_LINK[value], this.props.lang)}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} sx={{p: 2}}>
                                                                        <TextField
                                                                            id={'employeeName'}
                                                                            sx={{width: '100%'}}
                                                                            label={localize('Name of person employed by the company', this.props.lang)}
                                                                            defaultValue={this.state.form.employeeName}
                                                                            onBlur={((e: any) => {this.onChange('employeeName', e.target.value)})} />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} sx={{p: 2}}>
                                                                        <TextField
                                                                            id="employeeStatus"
                                                                            sx={[{width: '100%'},
                                                                                (theme) => (
                                                                                    this.state.formValidation.employeeStatus === 'warning' ? {
                                                                                        '& fieldset': {
                                                                                            borderColor: theme.palette.warning.main
                                                                                        },
                                                                                    } : null)
                                                                            ]}
                                                                            select
                                                                            label={localize('Employee status', this.props.lang)}
                                                                            value={this.state.form.employeeStatus || ''}
                                                                            onChange={((e: any) => {this.onChange('employeeStatus', getEnumValue(EMPLOYEE_STATUS, e.target.value))})}
                                                                        >
                                                                            {getEnumValues(EMPLOYEE_STATUS).map((value: number) => (
                                                                                <MenuItem key={value} value={value}>
                                                                                    {localize(EMPLOYEE_STATUS[value], this.props.lang)}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} sx={{p: 2}}>
                                                                        <TextField
                                                                            id={'employmentTime'}
                                                                            sx={{width: '100%'}}
                                                                            label={localize('How long have you been employed?', this.props.lang)}
                                                                            defaultValue={this.state.form.employmentTime}
                                                                            onBlur={((e: any) => {this.onChange('employmentTime', e.target.value)})} />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} sx={{p: 2}}>
                                                                        <TextField
                                                                            id="directedBy"
                                                                            sx={[{width: '100%'},
                                                                                (theme) => (
                                                                                    this.state.formValidation.directedBy === 'warning' ? {
                                                                                        '& fieldset': {
                                                                                            borderColor: theme.palette.warning.main
                                                                                        },
                                                                                    } : null)
                                                                            ]}
                                                                            select
                                                                            label={localize('Directed to EAP', this.props.lang)}
                                                                            value={this.state.form.directedBy || ''}
                                                                            onChange={((e: any) => {this.onChange('directedBy', getEnumValue(DIRECTED_BY, e.target.value))})}
                                                                        >
                                                                            {getEnumValues(DIRECTED_BY).map((value: number) => (
                                                                                <MenuItem key={value} value={value}>
                                                                                    {localize(DIRECTED_BY[value], this.props.lang)}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </Grid>
                                                                </React.Fragment>
                                                                :
                                                                null
                                                        }

                                                    </Grid>
                                                </Box>
                                                :
                                                null
                                        }
                                        {
                                            this.state.activeStep === 3 ?
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xs={12} sx={{p: 2}}>
                                                            <TextField
                                                                id={'comments'}
                                                                sx={{width: '100%'}}
                                                                multiline
                                                                rows={4}
                                                                label={localize('Your comments and reasons for your request', this.props.lang)}
                                                                defaultValue={this.state.form.comments}
                                                                onBlur={((e: any) => {this.onChange('comments', e.target.value)})} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                :
                                                null
                                        }
                                        <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                            <Box>
                                                {
                                                    this.state.activeStep > 0 ?
                                                        <Button variant="text" onClick={this.submitStep.bind(this, -1)}>
                                                            <Text>Previous_single</Text>
                                                        </Button>
                                                        :
                                                        null
                                                }
                                            </Box>
                                            <Box>
                                                <Button variant="text" onClick={this.submitStep.bind(this, 1)} disabled={this.state.activeStep >= 3 && !this.canSubmit()}>
                                                    <Text>
                                                        {
                                                            this.state.activeStep < 3 ?
                                                                'Next'
                                                                :
                                                                'Submit'
                                                        }
                                                    </Text>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                            }

                        </Box>
                    </Box>
                </Recaptcha>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactRequest));
