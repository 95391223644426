import React from 'react';
import {connect} from 'react-redux';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover
} from '@mui/material';
import {AppDispatch, RootState} from "../../../../store/store";
import {Patient} from "../../../../models/patient";
import Text from "../../../app/text/text";
import {ContactRequest} from "../../../../models/contactRequest";
import AddCircle from "@mui/icons-material/AddCircle";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import dayjs from "dayjs";
import {formatFileNumber} from "../../../../controllers/patients";
import {logException} from "../../../../controllers/system";

type IProps = {
    onPatientSelected: (patient?: Patient) => void
}

type IState = {
    open: boolean,
    patients: Patient[],
    contactRequest?: ContactRequest,
    displayPatientDetails: Patient | null,
    displayPatientAnchor: Element | null
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class LinkPatient extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        open: false,
        patients: [],
        contactRequest: undefined,
        displayPatientDetails: null,
        displayPatientAnchor: null
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog(contactRequest: ContactRequest, patients: Patient[]) {
        this.setState((state: IState) => {
            return {
                ...state,
                open: true,
                contactRequest,
                patients
            };
        });
    }

    closeDialog() {
        this.setState((state: IState) => {
            return {...state, open: false}
        });
    }

    displayPatientDetails(patient: Patient | null, target: Element | null = null) {
        this.setState((state: IState) => {
            return {
                ...state,
                displayPatientDetails: patient,
                displayPatientAnchor: patient !== null ? target : null
            }
        });
    }

    onPatientSelected(patient: Patient | undefined) {
        this.closeDialog();
        this.props.onPatientSelected(patient);
    }

    render() {
        return (
            <Dialog open={this.state.open}>
                <DialogTitle><Text>Link Patient</Text></DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Text>One or more patient correspond to the contact request. Please select the patient to set the meeting or click on "Create new patient"</Text>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{display: 'flex', flexDirection: 'column', p:2}}>
                                <Box sx={{pb:1}}>
                                    <FormLabel><Text>First name</Text></FormLabel>
                                    <div>{this.state.contactRequest?.firstName}</div>
                                </Box>
                                <Box sx={{pb:1}}>
                                    <FormLabel><Text>Last name</Text></FormLabel>
                                    <div>{this.state.contactRequest?.lastName}</div>
                                </Box>
                                <Box sx={{pb:1}}>
                                    <FormLabel><Text>Birthdate</Text></FormLabel>
                                    <div>{dayjs(this.state.contactRequest?.birthdate).format(this.props.dateFormat)}</div>
                                </Box>
                                <Box sx={{pb:1}}>
                                    <FormLabel><Text>Address</Text></FormLabel>
                                    <div>{this.state.contactRequest?.address}</div>
                                    <div>{this.state.contactRequest?.postalCode}</div>
                                </Box>
                                <Box sx={{pb:1}}>
                                    <FormLabel><Text>Email</Text></FormLabel>
                                    <div>{this.state.contactRequest?.email}</div>
                                </Box>
                                {this.state.contactRequest?.phoneCell ?
                                    <Box sx={{pb: 1}}>
                                        <FormLabel><Text>Cell Phone</Text></FormLabel>
                                        <div>{this.state.contactRequest?.phoneCell}</div>
                                    </Box>
                                    :
                                    null
                                }
                                {this.state.contactRequest?.phoneHome ?
                                    <Box sx={{pb: 1}}>
                                        <FormLabel><Text>Phone at home</Text></FormLabel>
                                        <div>{this.state.contactRequest?.phoneHome}</div>
                                    </Box>
                                    :
                                    null
                                }
                                {this.state.contactRequest?.phoneWork ?
                                    <Box sx={{pb: 1}}>
                                        <FormLabel><Text>Phone at work</Text></FormLabel>
                                        <div>{this.state.contactRequest?.phoneWork}{this.state.contactRequest?.phoneWorkExt ? ` ext. ${this.state.contactRequest?.phoneWorkExt}` : ''}</div>
                                    </Box>
                                    :
                                    null
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <List>
                                {
                                    this.state.patients.map((patient: Patient, index: number) => {
                                        return (
                                            <ListItem disablePadding key={index}>
                                                <ListItemButton onClick={() => {this.onPatientSelected(patient);}}>
                                                    <ListItemIcon onMouseEnter={(e) => {this.displayPatientDetails(patient, e.target as Element)}} onMouseLeave={() => {this.displayPatientDetails(null)}}>
                                                        <AssignmentIndIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        {patient.firstName} {patient.lastName}
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })
                                }
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {this.onPatientSelected(undefined);}}>
                                        <ListItemIcon>
                                            <AddCircle />
                                        </ListItemIcon>
                                        <ListItemText><Text>Create new patient</Text></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Popover
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={this.state.displayPatientDetails !== null}
                        anchorEl={this.state.displayPatientAnchor}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        //onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column', p:2}}>
                            <Box sx={{pb:1}}>
                                <FormLabel><Text>File number</Text></FormLabel>
                                <div>{formatFileNumber(this.state.displayPatientDetails?.fileNumber)}</div>
                            </Box>
                            <Box sx={{pb:1}}>
                                <FormLabel><Text>First name</Text></FormLabel>
                                <div>{this.state.displayPatientDetails?.firstName}</div>
                            </Box>
                            <Box sx={{pb:1}}>
                                <FormLabel><Text>Last name</Text></FormLabel>
                                <div>{this.state.displayPatientDetails?.lastName}</div>
                            </Box>
                            <Box sx={{pb:1}}>
                                <FormLabel><Text>Birthdate</Text></FormLabel>
                                <div>{dayjs(this.state.displayPatientDetails?.birthdate).format(this.props.dateFormat)}</div>
                            </Box>
                            <Box sx={{pb:1}}>
                                <FormLabel><Text>Address</Text></FormLabel>
                                <div>{this.state.displayPatientDetails?.address}</div>
                                <div>{this.state.displayPatientDetails?.postalCode}</div>
                            </Box>
                            <Box sx={{pb:1}}>
                                <FormLabel><Text>Email</Text></FormLabel>
                                <div>{this.state.displayPatientDetails?.email}</div>
                            </Box>
                            {this.state.displayPatientDetails?.phoneCell ?
                                <Box sx={{pb: 1}}>
                                    <FormLabel><Text>Cell Phone</Text></FormLabel>
                                    <div>{this.state.displayPatientDetails?.phoneCell}</div>
                                </Box>
                                :
                                null
                            }
                            {this.state.displayPatientDetails?.phoneHome ?
                                <Box sx={{pb: 1}}>
                                    <FormLabel><Text>Phone at home</Text></FormLabel>
                                    <div>{this.state.displayPatientDetails?.phoneHome}</div>
                                </Box>
                                :
                                null
                            }
                            {this.state.displayPatientDetails?.phoneWork ?
                                <Box sx={{pb: 1}}>
                                    <FormLabel><Text>Phone at work</Text></FormLabel>
                                    <div>{this.state.displayPatientDetails?.phoneWork}{this.state.displayPatientDetails?.phoneWorkExt ? ` ext. ${this.state.displayPatientDetails?.phoneWorkExt}` : ''}</div>
                                </Box>
                                :
                                null
                            }
                        </Box>
                    </Popover>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} sx={{mr: 2}} color={'error'} onClick={this.closeDialog.bind(this)}><Text>Cancel</Text></Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LinkPatient);
