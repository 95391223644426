import {AnyAction, Middleware} from "redux";
import {AUTH_CHECK, loggedIn, LOGIN, LOGOUT} from "../actions/user";
import * as authController from "../../controllers/auth";
import {SessionValidation} from "../../controllers/auth";
import {setBearer} from "../../helpers/ajax";
import * as localStorage from "../../helpers/localStorage";
import {KEYS} from "../../helpers/localStorage";

const user: Middleware<{} >= (store) => (next: Function) => (action: AnyAction) => {
    switch(action.type) {
        case AUTH_CHECK: {
            authController.loadSession().then((session: SessionValidation | null) => {
                action.payload.sessionId = session?.sessionId;
                action.payload.user = session?.user;
                next(action);
                store.dispatch(loggedIn());
            });
            break;
        }
        case LOGIN: {
            setBearer(action.payload.sessionId);
            localStorage.set(undefined, KEYS.SESSION_ID, action.payload.sessionId);
            next(action);
            store.dispatch(loggedIn());
            break;
        }
        case LOGOUT: {
            setBearer(null);
            localStorage.set(undefined, KEYS.SESSION_ID, null);
            next(action);
            break;
        }
        default:
            next(action);
    }
}

export default user;
