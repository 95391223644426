import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Button} from "@mui/material";
import Text from "../../../app/text/text";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import ChangePassword from "../../../ui/wizard/components/changePassword";
import {logException} from "../../../../controllers/system";

type IState = {
    changePassword: boolean
}

type IProps = {
    visible: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        userId: state.user._id
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Security extends React.Component<ReduxType, IState> {
    public state: IState = {
        changePassword: false
    };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openChangePassword() {
        this.setState((state: IState) => {
            return {...state, changePassword: true};
        });
    }

    closeChangePassword() {
        this.setState((state: IState) => {
            return {...state, changePassword: false};
        });
    }

    render() {
        if (!this.props.visible) return null;
        return (
            <React.Fragment>
                <Box sx={{p:2}}>
                    <Button variant={'contained'} onClick={this.openChangePassword.bind(this)}>
                        <Text>Change password</Text>
                    </Button>
                    <ChangePassword
                        canCancel={true}
                        open={this.state.changePassword}
                        onClose={this.closeChangePassword.bind(this)} />
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Security);
