import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {IRouter} from "../../../app/router/router";
import withRouter from "../../../app/router/withRouter";
import {Box, Divider, IconButton, Tooltip, Typography} from "@mui/material";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {logException} from "../../../../controllers/system";
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import DeleteIcon from '@mui/icons-material/Delete';
import {localize} from "../../../../helpers/localization";
import PrintIcon from '@mui/icons-material/Print';
import {Email} from "../../../../models/email";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import ReportViewer from "../../../reports/reportViewer";
import User from "../../../../models/user";
import EmailReport from "../../../reports/email/email";
import Text from "../../../app/text/text";
import {getUserFullName} from "../../../../controllers/user";
import dayjs from "dayjs";

type IState = {
    loading: boolean
}

type IProps = {
    email: Email,
    users: User[],
    onNewEmail: (emailOrigin?: Email) => Promise<void>,
    onEmailDeleted: (emailId: string) => Promise<void>
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class ViewEmail extends React.Component<ReduxType> {
    private confirmDeleteDialogRef: RefObject<any>;
    private reportEmailRef: RefObject<any>;

    public readonly state: IState = {
        loading: false
    }

    constructor(props: ReduxType) {
        super(props);
        this.confirmDeleteDialogRef = React.createRef();
        this.reportEmailRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState((state: IState) => {
            return {...state, loading: true};
        }, () => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    loading: false,
                };
            });
        });
    }

    async reply() {
        await this.props.onNewEmail(this.props.email);
    }

    replyAll() {

    }

    delete() {
        this.confirmDeleteDialogRef.current.openDialog();
    }

    print() {
        this.reportEmailRef.current?.run();
    }

    async deleteEmail() {
        if (!this.props.email._id) return;
        await this.props.onEmailDeleted(this.props.email._id);
    }

    getEmailSender(): string {
        for (let user of this.props.users) {
            if (user._id === this.props.email.fromUserId) {
                return getUserFullName(user);
            }
        }
        return '';
    }

    render() {
        if (!this.props.email) return null;
        return (
            <>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1,
                    width: '100%',
                    height: '100%',
                    borderLeft: 1,
                    borderColor: 'grey.600'
                }}>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex'}}>
                            <Tooltip title={localize('Reply', this.props.lang)}>
                                <IconButton onClick={this.reply.bind(this)}>
                                    <ReplyIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip sx={{display: 'none'}} title={localize('Reply All', this.props.lang)}>
                                <IconButton onClick={this.replyAll.bind(this)}>
                                    <ReplyAllIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Tooltip title={localize('Print', this.props.lang)}>
                                <IconButton onClick={this.print.bind(this)}>
                                    <PrintIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={localize('Delete', this.props.lang)}>
                                <IconButton onClick={this.delete.bind(this)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{p: 1, display: 'flex', justifyContent: 'space-between'}}>
                            <Box>
                                <Text>From</Text>: {this.getEmailSender()}
                            </Box>
                            <Box>
                                {dayjs(this.props.email.sentOn).format(`${this.props.dateFormat} HH:mm`)}
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{p: 1}}>
                            <Typography variant={'h6'}>{this.props.email.subject}</Typography>
                        </Box>
                        <Box sx={{p: 1}}>
                            <div dangerouslySetInnerHTML={{__html: this.props.email.message || ''}} />
                        </Box>
                    </Box>
                </Box>
                <ReportViewer
                    title={localize('Print email', this.props.lang)}
                    ref={this.reportEmailRef}
                    users={this.props.users}
                >
                    <EmailReport
                        users={this.props.users}
                        email={this.props.email}
                        dateFormat={this.props.dateFormat}
                        lang={this.props.lang} />
                </ReportViewer>
                <ConfirmDialog
                    ref={this.confirmDeleteDialogRef}
                    title={localize('Delete email', this.props.lang)}
                    text={localize('Are you sure you want to delete this email?', this.props.lang)}
                    onAgree={this.deleteEmail.bind(this)}
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewEmail));
