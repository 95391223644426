import string_sanitize from 'string-sanitizer';

export function sanitize(value: string) {
    return string_sanitize.sanitize(value).toLowerCase();
}

export function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function validateEmail(value: string): boolean {
    return (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(value);
}

export function validatePhone(value: string): boolean {
    let trimedValue: string = "";
    for (let index = 0; index < value.length; index++) {
        if (/^[0-9]*$/.test(value[index])) trimedValue += value[index];
    }
    return /^[0-9]*$/.test(trimedValue) && (trimedValue.length === 11 || trimedValue.length === 10);
}

export type PasswordValidation = {
    length: boolean,
    lowerChar: boolean,
    upperChar: boolean,
    numericChar: boolean
}
export function validatePassword(value: string): PasswordValidation {
    return {
        length: value.length >= 8 && value.length <= 20,
        lowerChar: /[a-z]/.test(value),
        upperChar: /[A-Z]/.test(value),
        numericChar: /[0-9]/.test(value)
    };
}
