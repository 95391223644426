import {get, post} from "../helpers/ajax";
import {AxiosResponse} from "axios";
import {Email} from "../models/email";

interface CountUnreadResponse {
    countUnread: number
}
export async function getUnreadEmailsCount(): Promise<number> {
    let response: AxiosResponse<CountUnreadResponse> = await get<CountUnreadResponse>('/email/count-unread');

    if (response && response.status === 200 && response.data)
        return response.data.countUnread || 0;
    else
        return 0;
}

export async function getEmails(): Promise<Email[]> {
    let response: AxiosResponse<Email[]> = await get<Email[]>('/email/getAll');

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function send(email: Email): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>('/email/send', {email});

    if (response.status === 200)
        return response.data;
    else
        return false;
}

export async function remove(emailId: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>('/email/remove', {emailId});

    if (response.status === 200)
        return response.data;
    else
        return false;
}

export async function read(emailId: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>('/email/read', {emailId});

    if (response && response.status === 200)
        return response.data;
    else
        return false;
}
