import dayjs from "dayjs";

class SignedDocument{
    public _id?: string = undefined;
    public title?: string = undefined;
    public document?: string = undefined;
    public enabled?: boolean = undefined;
    public sendOnPatientCreation?: boolean = undefined;

    public constructor(init?:Partial<SignedDocument>) {
        Object.assign(this, init);
    }
}

class SignedDocumentAnswer {
    public signedDocumentId?: string = undefined;
    public sentOn?: Date = undefined;
    public answer?: boolean = undefined;
    public answerDate?: Date = undefined;

    public constructor(init?:Partial<SignedDocumentAnswer>) {
        if (init && init.sentOn) init.sentOn = dayjs(init.sentOn).isValid() ? dayjs(init.sentOn).toDate() : undefined;
        if (init && init.answerDate) init.answerDate = dayjs(init.answerDate).isValid() ? dayjs(init.answerDate).toDate() : undefined;
        Object.assign(this, init);
    }
}

export {SignedDocument, SignedDocumentAnswer};
