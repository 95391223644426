let env = process.env.REACT_APP_ENVIRONMENT;

const baseUrls = {
  dev: 'http://localhost:7000/api',
  alpha: 'https://markets-163201.nn.r.appspot.com/api',
  beta: 'https://grap-server-beta-dot-shaped-producer-352014.nn.r.appspot.com/api',
  production: 'https://grap-server-prod-dot-shaped-producer-352014.nn.r.appspot.com/api',
}

const appUrls = {
  dev: 'http://localhost:3000',
  alpha: 'https://app-grap.e-onox.com',
  beta: 'https://app-beta.grapclinique.com',
  production: 'https://app.grapclinique.com',
}

const configs = {
  baseUrl: baseUrls[env],
  appUrl: appUrls[env]
}

module.exports = configs;
