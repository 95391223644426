class NoteModel {
    public _id?: string = undefined;
    public userId?: string = undefined;
    public generic?: boolean = undefined;
    public name?: string = undefined;
    public note?: string = undefined;

    public constructor(init?:Partial<NoteModel>) {
        Object.assign(this, init);
    }
}

export {NoteModel};
