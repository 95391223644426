import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {AppBar, Button, Dialog, IconButton, Typography, Toolbar, Slide, Menu, MenuItem} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import {logException} from "../../../controllers/system";

export interface DialogButton {
    component?: JSX.Element,
    key: string,
    text?: string,
    keepDialogOpen?: boolean,
    color?: ('inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'),
    disabled?: boolean,
    menu?: DialogButtonMenuItem[]
}

export interface DialogButtonMenuItem {
    text: string,
    onClick: () => void
}

type IProps = {
    title: string,
    children: JSX.Element | JSX.Element[] | null,
    onCloseRequest?: () => boolean,
    onClose?: () => void,
    buttons: (DialogButton | null)[],
    onButtonClick: (key: string) => void
}

type IState = {
    open: boolean,
    openMenu?: string
}

const mapStateToProps = (state: RootState) => {
    return {
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class FullScreenDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        open: false
    }

    private anchors: any = {};

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog(): Promise<void> {
        return new Promise((resolve) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    open: true
                };
            }, () => {resolve();});
        });

    }

    closeRequest() {
        if (this.props.onCloseRequest) {
            if (this.props.onCloseRequest()) this.closeDialog();
        } else {
            this.closeDialog();
        }
    }

    closeDialog() {
        this.anchors = [];
        this.setState((state: IState) => {
            return {
                ...state,
                open: false
            };
        });
    }

    buttonClicked(dialogButton: DialogButton) {
        this.props.onButtonClick(dialogButton.key);
        if (dialogButton.keepDialogOpen !== true) this.closeDialog();
    }

    getAnchor(key: string) {
        if (!this.anchors[key]) {
            this.anchors[key] = React.createRef();
        }
        return this.anchors[key];
    }

    openMenu(key: string | undefined) {
        this.setState((state: IState) => {
            return {
                ...state,
                openMenu: key
            };
        });
    }

    closeMenu() {
        this.openMenu(undefined);
    }

    clickMenu(handler: () => void) {
        handler();
        this.closeMenu();
    }

    render() {
        return (
            <Dialog
                fullScreen
                open={this.state.open}
                onClose={this.closeDialog.bind(this)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.closeRequest.bind(this)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {this.props.title}
                        </Typography>
                        {this.props.buttons.map((dialogButton: DialogButton | null) => {
                            if (!dialogButton) return null;
                            if (dialogButton.component) return (
                                <Fragment key={dialogButton.key}>
                                    {dialogButton.component}
                                </Fragment>
                            );
                            return (
                                <React.Fragment key={dialogButton.key}>
                                    <Button
                                        ref={this.getAnchor(dialogButton.key)}
                                        color={dialogButton.color || 'inherit'}
                                        disabled={dialogButton.disabled || undefined}
                                        onClick={
                                            !dialogButton.menu ? this.buttonClicked.bind(this, dialogButton)
                                                : this.openMenu.bind(this, dialogButton.key)
                                        }
                                        variant={'contained'}
                                        sx={{ml:1}}
                                    >
                                        {dialogButton.text}
                                    </Button>
                                    {dialogButton.menu ?
                                        <Menu
                                            anchorEl={this.getAnchor(dialogButton.key).current}
                                            open={this.state.openMenu === dialogButton.key}
                                            onClose={this.closeMenu.bind(this)}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            {
                                                dialogButton.menu.map((menuItem) => {
                                                    return (
                                                        <MenuItem key={menuItem.text} onClick={this.clickMenu.bind(this, menuItem.onClick)}>{menuItem.text}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Menu>
                                        : null
                                    }
                                </React.Fragment>
                            );
                        })}
                    </Toolbar>
                </AppBar>
                {this.props.children}
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FullScreenDialog);
