import {AnyAction, Middleware} from "redux";
import {AUTH_CHECK, LOGIN} from "../actions/user";
import {GET_CONFIG, SET_THEME, setConfig, setTheme} from "../actions/settings";
import * as localStorage from "../../helpers/localStorage";
import {KEYS} from "../../helpers/localStorage";
import {INIT_APP} from "../actions/app";
import {getConfig} from "../../controllers/system";

const settings: Middleware<{} >= (store: any) => (next: Function) => (action: AnyAction) => {
    switch(action.type) {
        case INIT_APP:
            let storageTheme = localStorage.get(undefined, KEYS.THEME);
            if (storageTheme && storageTheme !== store.getState().settings.theme) {
                store.dispatch(setTheme(storageTheme));
            }
            next(action);
            break;

        case AUTH_CHECK:
        case LOGIN:
            if (action.payload.user && action.payload.user.language) {
                action.payload.lang = action.payload.user.language.indexOf('en') !== -1 ? 'EN' : 'FR';
            }
            next(action);
            break;

        case SET_THEME:
            localStorage.set(undefined, KEYS.THEME, action.payload.theme);
            next(action);
            break;

        case GET_CONFIG:
            next(action);
            getConfig(action.payload.name).then((value: string) => {
                store.dispatch(setConfig(action.payload.name, value));
            });
            break;

        default:
            next(action);
    }
}

export default settings;
