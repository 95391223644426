import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {getMUILocale, localize} from "../../../../helpers/localization";
import {Account, ACCOUNT_STATUS} from "../../../../models/account";
import FullScreenDialog from "../../../ui/fullScreenDialog/fullScreenDialog";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import {Box, FormLabel, IconButton, TextField} from "@mui/material";
import Text from "../../../app/text/text";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef
} from "@mui/x-data-grid";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {Contract} from "../../../../models/contract";
import {randomString} from "../../../../helpers/random";
import {saveAccount} from "../../../../controllers/account";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import dayjs from "dayjs";
import {logException} from "../../../../controllers/system";
import AllowedPatientsDialog from "./allowedPatientsDialog";

type IState = {
    account: Account | null,
    changed: boolean,
    formValidation: any,
    allowedPatientsDialogVisible: boolean,
    editingContract: boolean
}

type IProps = {
    onSave: (account: Account) => void
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class AccountDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        account: null,
        changed: false,
        formValidation: {},
        allowedPatientsDialogVisible: false,
        editingContract: false
    }

    private editDialogRef: RefObject<any>;
    private confirmDeleteDialogRef: RefObject<any>;
    private confirmCloseDialogRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.editDialogRef = React.createRef();
        this.confirmDeleteDialogRef = React.createRef();
        this.confirmCloseDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog(account: Account) {
        this.setState((state: IState) => {
            return {
                ...state,
                account,
                changed: false,
                formValidation: {}
            };
        }, () => {
            this.editDialogRef.current.openDialog();
        });
    }

    closeDialogRequest(): boolean {
        if (this.state.changed) {
            this.confirmCloseDialogRef.current?.openDialog();
            return false
        } else {
            this.closeDialog();
            return true;
        }
    }

    closeDialog() {
        this.editDialogRef.current.closeDialog();
    }

    onAccountDetailButtonClick(key: string) {
        switch(key) {
            case 'deactivate':
                this.confirmDeleteDialogRef.current.openDialog();
                break;
            case 'save':
                this.saveAccount();
                break;
            case 'allowedPatients':
                this.setState((state: IState) => {
                    return {...state, allowedPatientsDialogVisible: true};
                });
                break;
        }
    }

    deactivateAccount() {
        this.setState((state: IState) => {
            let account: Account | null = state.account;
            if (account) account.status = ACCOUNT_STATUS.INACTIVE

            return {
                ...state,
                account
            };
        }, this.saveAccount.bind(this, () => {
            this.closeDialog();
        }));
    }

    getContractsColumns(): (GridColDef | GridActionsColDef)[] {
        return [
            {
                field: 'name',
                headerName: localize('Name', this.props.lang),
                minWidth: 100,
                flex: 1,
                editable: true
            },
            {
                field: 'openedOn',
                headerName: localize('Opened on', this.props.lang),
                minWidth: 100,
                flex: 1,
                type: 'date',
                valueFormatter: (x: any) => x.value ? dayjs(x.value).format(this.props.dateFormat) : '',
                editable: true
            },
            {
                field: 'closedOn',
                headerName: localize('Closed on', this.props.lang),
                minWidth: 100,
                flex: 1,
                type: 'date',
                valueFormatter: (x: any) => x.value ? dayjs(x.value).format(this.props.dateFormat) : '',
                editable: true
            },
            {
                field: 'eligibleEmployees',
                headerName: localize('Eligible employees', this.props.lang),
                minWidth: 100,
                flex: 1,
                type: 'number',
                editable: true
            },
            {
                field: 'extension',
                headerName: localize('Extension', this.props.lang),
                minWidth: 50,
                flex: 0.5,
                type: 'boolean',
                editable: true
            },
            {
                field: 'meetingsEmployee',
                headerName: localize('Employee\'s meets', this.props.lang),
                minWidth: 100,
                flex: 1,
                type: 'number',
                editable: true
            },
            {
                field: 'meetingsSpouse',
                headerName: localize('Spouse\'s meets', this.props.lang),
                minWidth: 100,
                flex: 1,
                type: 'number',
                editable: true
            },
            {
                field: 'meetingsChildren',
                headerName: localize('Children\'s meets', this.props.lang),
                minWidth: 100,
                flex: 1,
                type: 'number',
                editable: true
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 30,
                flex: 0.25,
                getActions: (params) => {
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label={localize('Delete', this.props.lang)}
                            onClick={this.deleteContract.bind(this, params.id)}
                            disabled={this.state.editingContract}
                        />
                    ];
                }
            }
        ];
    }

    addContract() {
        this.setState((state: IState) => {
            let account: Account = state.account as Account;
            account.contracts = Object.assign([], account.contracts || []);
            account.contracts.push(new Contract({code: this.generateNewContractCode()}));
            return {
                ...state,
                account,
                changed: true
            };
        });
    }

    generateNewContractCode(): string {
        let newContractCode: string = '';
        let isUnique: boolean = false;

        while(!isUnique) {
            newContractCode = randomString(8, true, false, false);
            isUnique = true;
            for(let contract of this.state.account?.contracts || []) {
                if (contract.code === newContractCode) {
                    isUnique = false;
                    break;
                }
            }
        }

        return newContractCode;
    }

    deleteContract(id: string | number, e: any) {
        e.stopPropagation();
        this.setState((state: IState) => {
            let contracts: Contract[] = Object.assign([], (state.account as Account).contracts);
            for(let index: number = 0; index < contracts.length || 0; index++) {
                if (contracts[index].code === id) {
                    contracts.splice(index, 1);
                    break;
                }
            }
            return {
                ...state,
                changed: true,
                account: {
                    ...state.account,
                    contracts
                }
            }
        });
    }

    updateContract(e: any) {
        let contract: Contract = e;
        this.setState((state: IState) => {
            let contracts: Contract[] = Object.assign([], (state.account as Account).contracts);
            for (let index: number = 0; index < contracts.length; index++) {
                if (contracts[index].code === contract.code) {
                    contracts[index] = contract;
                }
            }
            return {
                ...state,
                changed: true,
                editingContract: false,
                account: {
                    ...state.account,
                    contracts
                }
            };
        });
        return e;
    }

    setContractEditing(editingContract: boolean) {
        console.log('editingContract', editingContract);
        this.setState((state: IState) => {
            return {
                ...state,
                editingContract,
                changed: true
            }
        });
    }

    setAccountName(e: any) {
        let name: string = e.target?.value;
        this.setState((state: IState) => {
            return {
                ...state,
                changed: true,
                account: {
                    ...state.account,
                    name
                }
            };
        });
    }

    validateAccount(): boolean {
        let formValidation: any = {};

        if (this.state.account?.status !== ACCOUNT_STATUS.INACTIVE) {
            if (!this.state.account?.name || this.state.account?.name?.trim().length === 0) {
                formValidation.name = 'error';
            }
        }

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return Object.keys(formValidation).length === 0;
    }

    saveAccount(callback?: () => void) {
        if (this.validateAccount()) {
            saveAccount(this.state.account as Account).then((account: Account | null) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        changed: false
                    }
                }, () => {
                    this.props.setAppNotification({
                        severity: 'success',
                        message: 'Successfully saved'
                    });
                    this.props.onSave(account as Account);
                    if (callback) callback();
                });
            }).catch(() => {
                this.props.setAppNotification({
                    severity: 'error',
                    message: 'An error occurred, please try again'
                });
            });
        }
    }

    onAllowedPatientsChanged(account: Account) {
        this.setState((state: IState) => {
            return {
                ...state,
                account: {
                    ...state.account,
                    allowedPatients: account.allowedPatients
                },
                changed: true
            }
        });
    }

    onAllowedPatientsClosed() {
        this.setState((state: IState) => {
            return {...state, allowedPatientsDialogVisible: false};
        });
    }

    render() {
        return (
            <FullScreenDialog
                ref={this.editDialogRef}
                title={localize('Account', this.props.lang)}
                onButtonClick={this.onAccountDetailButtonClick.bind(this)}
                onCloseRequest={this.closeDialogRequest.bind(this)}
                buttons={[
                    this.state.account?._id ? {key: 'allowedPatients', text: localize('Allowed patients', this.props.lang), color: 'info', keepDialogOpen: true} : null,
                    this.state.account?._id ? {key: 'deactivate', text: localize('Deactivate', this.props.lang), color: 'error', keepDialogOpen: true, disabled: this.state.changed} : null,
                    {key: 'save', text: localize('Save', this.props.lang), color: 'success', keepDialogOpen: true, disabled: !this.state.changed || this.state.editingContract}
                ]}
            >
                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', minHeight: '60%;'}}>
                    <Box sx={{p: 2}}>
                        <TextField
                            required
                            error={this.state.formValidation.name === 'error'}
                            id={'name'}
                            sx={{width: '100%'}}
                            label={localize('Name', this.props.lang)}
                            defaultValue={this.state.account?.name}
                            onChange={this.setAccountName.bind(this)}
                        />
                    </Box>
                    <Box sx={{flex: 1, p: 2}}>
                        <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                            <Box>
                                <FormLabel><Text>Contracts</Text></FormLabel>
                            </Box>
                            <Box>
                                <IconButton color={'primary'} onClick={this.addContract.bind(this)}>
                                    <AddCircleIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <DataGrid
                            editMode={'row'}
                            columns={this.getContractsColumns()}
                            rows={this.state.account?.contracts || []}
                            getRowId={(row: any) => {return row.code}}
                            onRowEditStop={this.updateContract.bind(this)}
                            processRowUpdate={(newRow: any, oldRow: any) => this.updateContract(newRow)}
                            onProcessRowUpdateError={() => {}}
                            localeText={getMUILocale(this.props.lang).components.MuiDataGrid.defaultProps.localeText}
                            onRowEditStart={this.setContractEditing.bind(this, true)}
                        />
                    </Box>
                </Box>
                <ConfirmDialog
                    ref={this.confirmDeleteDialogRef}
                    title={localize('Deactivate this account?', this.props.lang)}
                    text={localize('Are you sure you want to deactivate this account?', this.props.lang)}
                    onAgree={this.deactivateAccount.bind(this)}
                />
                <ConfirmDialog
                    ref={this.confirmCloseDialogRef}
                    title={localize('Unsaved changes', this.props.lang)}
                    text={localize('All unsaved changes will be lost. Do you want to continue?', this.props.lang)}
                    onAgree={this.closeDialog.bind(this)}
                />
                <AllowedPatientsDialog
                    visible={this.state.allowedPatientsDialogVisible}
                    account={this.state.account}
                    saveChanges={false}
                    onSave={this.onAllowedPatientsChanged.bind(this)}
                    onClose={this.onAllowedPatientsClosed.bind(this)}
                />
            </FullScreenDialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(AccountDialog);
