import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, IconButton, InputAdornment, Link, TextField, Typography} from "@mui/material";
import Text from "../../../app/text/text";
import {
    DataGrid, GridActionsCellItem,
    GridCallbackDetails,
    GridColDef, GridRenderCellParams,
    GridRowParams,
    MuiEvent
} from "@mui/x-data-grid";
import {getMUILocale, localize} from "../../../../helpers/localization";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {Patient, PATIENT_STATUS} from "../../../../models/patient";
import {formatFileNumber, getPatients} from "../../../../controllers/patients";
import {formatNumber, trimNumber} from "../../../../helpers/phone";
import EditIcon from '@mui/icons-material/Edit';
import {ROLES} from "../../../../constants/roles";
import {logException} from "../../../../controllers/system";

type IState = {
    data: Patient[],
    loading: boolean,
    searchText: string
}

type IProps = {
    onPatientSelected: (patient: Patient) => void,
    onPatientAdd: () => void
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        userRole: state.user.role,
        userId: state.user._id
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class PatientsGrid extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        data: [],
        loading: false,
        searchText: ''
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState((state: IState) => {
            return {
                ...state,
                loading: true,
                data: []
            }
        }, () => {
            let filter: any = this.props.userRole === ROLES.Professional ? {professionalUserId: this.props.userId} : {};
            if (this.state.searchText) filter.search = this.state.searchText;
            getPatients(filter).then((patients: Patient[]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        loading: false,
                        data: patients
                    };
                });
            });
        });
    }

    filterResults(searchText: string) {
        this.setState((state: IState) => {
            return {
                ...state,
                searchText
            };
        }, () => {
            this.loadData();
        });
    }

    getColumns(): (GridColDef | GridActionsColDef)[] {
        return [
            {
                field: 'firstName',
                headerName: localize('First name', this.props.lang),
                minWidth: 100,
                flex: 1
            },
            {
                field: 'lastName',
                headerName: localize('Last name', this.props.lang),
                minWidth: 100,
                flex: 1
            },
            {
                field: 'fileNumber',
                headerName: localize('File number', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams): React.ReactNode => {
                    return (
                        <div style={{color: (params.row.status === (PATIENT_STATUS.INACTIVE as number) ? 'red' : 'inherit')}}>{formatFileNumber(params.value)}</div>
                    );
                }
            },
            {
                field: 'email',
                headerName: localize('Email', this.props.lang),
                minWidth: 100,
                flex: 1.5,
                renderCell: (params: GridRenderCellParams): React.ReactNode => {
                    return (
                        <Link href={`mailto:${params.value}`}>{params.value}</Link>
                    );
                }
            },
            {
                field: 'phoneWork',
                headerName: localize('Phone number', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams): React.ReactNode => {
                    let phone: string | null = null;
                    let ext: string | null = null;
                    phone = params.row.phoneCell;
                    if (!phone) phone = params.row.phoneHome;
                    if (!phone) {
                        phone = params.row.phoneWork;
                        ext = params.row.phoneWorkExt;
                    }

                    return (
                        <Link href={`tel:${trimNumber(phone || '')}`}>{formatNumber(phone || '')}{ext ? ` ext. ${ext}` : ''}</Link>
                    );
                }
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 30,
                flex: 0.25,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={localize('Edit', this.props.lang)}
                        onClick={this.props.onPatientSelected.bind(this, params.row as Patient)}
                    />
                ]
            }
        ];
    }

    onRowClick(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) {
        this.props.onPatientSelected(params.row as Patient);
    }

    onAddClick() {
        this.props.onPatientAdd();
    }

    render() {
        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }} height={'100%'}>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant={'h4'}>
                                <Text>Patients</Text>
                            </Typography>
                        </Box>
                        <Box hidden={this.props.userRole === ROLES.Professional}>
                            <IconButton color={'primary'} size={'large'} onClick={this.onAddClick.bind(this)}>
                                <AddCircleIcon sx={{fontSize: '2rem'}}/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{py: 3}}>
                        <TextField
                            sx={{width: '100%'}}
                            placeholder={localize('Search', this.props.lang)}
                            onChange={((e: any) => {this.filterResults(e.target.value)})}
                            value={this.state.searchText}
                            InputProps={{
                                endAdornment: this.state.searchText ? <InputAdornment position="end">
                                    <IconButton
                                        onClick={this.filterResults.bind(this, '')}
                                        edge="end"
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </InputAdornment> : undefined
                            }}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <DataGrid
                            columns={this.getColumns()}
                            rows={this.state.data}
                            getRowId={x => x._id}
                            loading={this.state.loading}
                            localeText={getMUILocale(this.props.lang).components.MuiDataGrid.defaultProps.localeText}
                            onRowClick={this.onRowClick.bind(this)}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PatientsGrid);
