export function trimNumber(phoneNumber: string): string {
    return phoneNumber.replace(/\D/g,'');
}

export function formatNumber(phoneNumber: string): string {
    let rawPhoneNumber: string = trimNumber(phoneNumber);
    let charsCount: number = rawPhoneNumber.length;
    if (charsCount === 10 || charsCount === 11) {
        if (charsCount === 11) rawPhoneNumber = rawPhoneNumber.substring(1, 11);
        return `(${rawPhoneNumber.substring(0, 3)}) ${rawPhoneNumber.substring(3, 6)}-${rawPhoneNumber.substring(6, 10)}`;
    } else {
        return phoneNumber;
    }
}
