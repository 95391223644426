import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Typography} from "@mui/material";
import Text from '../../../app/text/text';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {localize} from "../../../../helpers/localization";
import * as contactRequestController from '../../../../controllers/contactRequests';
import {withTheme} from "@mui/styles";
import {WithTheme} from "@mui/styles/withTheme/withTheme";
import {logException} from "../../../../controllers/system";
Chart.register(ArcElement, Tooltip, Legend);

type IProps = {
}

type IState = {
    data: number[]
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & WithTheme<unknown>;

class ContactRequests extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        data: [0, 0]
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        contactRequestController.getSummary().then((results: contactRequestController.ContactRequestSummary | null) => {
            if (results) {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        data: [
                            results.receivedWOC,
                            results.receivedWC,
                            results.waiting,
                            results.scheduled
                        ]
                    }
                });
            }
        });
    }

    render() {
        return (
            <Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Typography variant={'h5'}><Text>Meetings requests</Text></Typography>
                </Box>
                <Box sx={{flexGrow: 1, color: 'white'}}>
                    <Doughnut data={{
                        labels: [
                            localize('Received without comm', this.props.lang),
                            localize('Received with comm', this.props.lang),
                            localize('Waiting', this.props.lang),
                            `${localize('Scheduled', this.props.lang)} (24H)`
                        ],
                        datasets: [
                            {
                                label: localize('Meetings requests', this.props.lang),
                                data: this.state.data,
                                backgroundColor: [
                                    '#90caf9',
                                    '#09599a',
                                    '#ab47bc',
                                    '#66bb6a',
                                ],
                                borderColor: [
                                    '#90caf9',
                                    '#09599a',
                                    '#ab47bc',
                                    '#66bb6a',
                                ],
                                borderWidth: 1,
                            },
                        ],
                    }}
                    options={{
                        plugins: {
                            legend: {
                                labels: {
                                    color: (this.props.theme as any).palette.text.primary
                                }
                            }
                        }
                    }}
                    />
                </Box>
            </Box>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ContactRequests));
