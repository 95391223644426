import React from "react";
import Dashboard from "../../views/dashboard/dashboard";
import ContactRequests from "../../views/contactRequests/contactRequests";
import Meetings from "../../views/meetings/meetings";
import Accounts from "../../views/accounts/accounts";
import Patients from "../../views/patients/patients";
import Configurations from "../../views/configurations/configurations";
import Logs from "../../views/logs/logs";
import {Routes, Route} from "react-router-dom";
import Reports from "../../views/reports/reports";
import Profile from "../../views/profile/profile";
import {logException} from "../../../controllers/system";
import Announcements from "../../views/announcements/announcements";
import Emails from "../../views/emails/emails";

export interface IRouter {
    router: {
        location: any,
        navigate: (url: string) => void,
        params: any,
        searchParams: any
    }
}

class AppRouter extends React.Component<any, any> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    render() {
        return (
            <Routes>
                <Route path={'/'} element={<Dashboard/>} />
                <Route path={'/contact-requests'} element={<ContactRequests/>} />
                <Route path={'/meetings'} element={<Meetings/>} />
                <Route path={'/accounts'} element={<Accounts/>} />
                <Route path={'/announcements'} element={<Announcements/>} />
                <Route path={'/patients'} element={<Patients/>} />
                <Route path={'/configurations'} element={<Configurations/>} />
                <Route path={'/reports'} element={<Reports/>} />
                <Route path={'/logs'} element={<Logs/>} />
                <Route path={'/profile'} element={<Profile/>} />
                <Route path={'/emails'} element={<Emails/>} />
            </Routes>
        );
    }
}

export default AppRouter
