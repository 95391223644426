import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, TextField} from "@mui/material";
import {localize} from "../../../../helpers/localization";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {getUser, setLicence} from "../../../../controllers/user";
import {logException} from "../../../../controllers/system";
import User from "../../../../models/user";

type IState = {
    licence: string | null
}

type IProps = {
    userId: string | undefined,
    visible: boolean,
    licence?: string
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class ProfessionalLicence extends React.Component<ReduxType, IState> {
    public state: IState = {
        licence: null
    }

    private savingTimeout: number | undefined = undefined;

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.getLicence(this.props.licence);
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.userId !== prevProps.userId) {
            this.getLicence(this.props.licence);
        }
    }

    getLicence(licence?: string) {
        if (!this.props.userId) return;
        if (!licence) {
            getUser(this.props.userId as string).then((user: User | null) => {
                this.setState((state: IState) => {
                    return {...state, licence: user?.professionalLicenceNumber || null};
                });
            });
        } else {
            this.setState((state: IState) => {
                return {...state, licence};
            });
        }
    }

    saveLicence(licence: string) {
        if (this.savingTimeout) {
            window.clearTimeout(this.savingTimeout);
            this.savingTimeout = undefined;
        }

        this.setState((state: IState) => {
            return {...state, licence};
        }, () => {
            this.savingTimeout = window.setTimeout(() => {
                setLicence(this.props.userId as string, this.state.licence || '');
            }, 500);
        });

    }

    render() {
        if (!this.props.visible || !this.props.userId) return null;
        return (
            <React.Fragment>
                <Box sx={{p:2}}>
                    <TextField
                        label={localize('Licence number', this.props.lang)}
                        type={'text'}
                        sx={{width: '100%'}}
                        value={this.state.licence || ''}
                        onChange={(e) => {this.saveLicence(e.target.value)}}
                    />
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalLicence);
