import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Button, IconButton, TextField} from "@mui/material";
import {localize} from "../../../../helpers/localization";
import Text from '../../../app/text/text';
import {logException} from "../../../../controllers/system";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import User, {Rate} from "../../../../models/user";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {saveUserRates} from "../../../../controllers/user";
import {dayjsOrAny} from "../../../../helpers/dayjs";

type IState = {
    rates: Rate[],
    formValidation: any
}

type IProps = {
    professionnal: User
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Rates extends React.Component<ReduxType, IState> {
    public state: IState = {
        rates: [],
        formValidation: {}
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        if (this.props.professionnal) {
            this.setRates(this.props.professionnal.professionalRates, true);
        }
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.professionnal && (!prevProps.professionnal || prevProps.professionnal?._id !== this.props.professionnal._id)) {
            this.setRates(this.props.professionnal.professionalRates, true);
        }
    }

    addRate() {
        let rates = this.state.rates;
        let lastDate: Date = dayjs().startOf('day').toDate();

        if (Array.isArray(this.state.rates)) {
            for (let rate of this.state.rates) {
                if (rate && dayjs(rate.from).isValid()) {
                    lastDate = dayjs(rate.from).add(1, 'day').startOf('day').toDate();
                }
            }
        }

        rates.push(new Rate({
            from: lastDate,
            rate: 0
        }));
        this.setRates(rates);
    }
    removeRate(rateIndex: number) {
        let rates = this.state.rates;
        rates.splice(rateIndex, 1);
        this.setRates(rates);
    }

    changeRate(prop: string, value: any, index: number) {
        let rates = this.state.rates;

        switch(prop) {
            case 'from':
                if (!dayjs(value).isValid()) value = new Date();
                value = dayjs(value).startOf('day').toDate();
                break;
        }

        (rates[index] as any)[prop] = value;
        this.setRates(rates);
    }

    setRates(rates: Rate[], loading: boolean = false) {
        this.setState((state: IState) => {
            return {
                ...state,
                rates
            };
        }, () => {
            if (!loading && this.validateForm()) {
                this.saveRates();
            }
        });
    }

    validateForm(): boolean {
        let formValidation: any = {};
        let lastDate: Date | undefined = undefined;

        for (let index = 0; index < this.state.rates.length; index++) {
            let rate = this.state.rates[index];
            if (!rate.from) {
                formValidation[index] = formValidation[index] || {};
                formValidation[index].from = 'error';
            } else {
                if (!lastDate) {
                    lastDate = rate.from;
                } else {
                    if (dayjs(rate.from).diff(lastDate, 'day') <= 0) {
                        formValidation[index] = formValidation[index] || {};
                        formValidation[index].from = 'error';
                    } else {
                        lastDate = rate.from;
                    }
                }
            }

            if (!rate.rate || isNaN(rate.rate) || rate.rate < 0) {
                formValidation[index] = formValidation[index] || {};
                formValidation[index].rate = 'error';
            }
        }

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return Object.keys(formValidation).length === 0;
    }

    saveRates() {
        saveUserRates(this.props.professionnal._id as string, this.state.rates);
    }

    render() {
        return (
            <React.Fragment>
                <Box sx={{display: 'flex', flexDirection: 'column', p:2, height: '100%'}}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', pb: 1}}>
                        <Button color={'primary'} variant={'contained'} onClick={this.addRate.bind(this)}><Text>Add</Text></Button>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        {
                            this.state.rates.map((rate: Rate, index: number) => {
                                return (
                                    <Box sx={{pb: 3, display: 'flex'}} key={index}>
                                        <Box sx={{pr: 3, flexGrow: 1}}>
                                            <DatePicker
                                                sx={{width: '100%'}}
                                                label={localize('From', this.props.lang)}
                                                value={dayjsOrAny(rate.from)}
                                                format={this.props.dateFormat}
                                                onChange={((newValue: any) => {this.changeRate('from', newValue, index)})}
                                                slotProps={{textField: { error: this.state.formValidation[index]?.from === 'error'}}}
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                id="filled-number"
                                                label={localize('Rate', this.props.lang)}
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={rate.rate || 0}
                                                onChange={(e) => {
                                                    let newValue: number = parseFloat(e.target.value);
                                                    if (isNaN(newValue)) newValue = 0;
                                                    this.changeRate('rate', newValue, index);
                                                }}
                                                error={this.state.formValidation[index]?.rate === 'error'}
                                            />
                                        </Box>
                                        <Box>
                                            <IconButton size={'small'} color={'error'} onClick={this.removeRate.bind(this, index, -1)}>
                                                <IndeterminateCheckBoxIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                );
                            })
                        }
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rates);
