import React from 'react';
import {connect} from 'react-redux';
import {
    Box,
    DialogTitle,
    DialogActions, Button, DialogContent, Dialog, TextField
} from '@mui/material';
import {AppDispatch, RootState} from "../../../../store/store";
import {localize} from "../../../../helpers/localization";
import Text from "../../../app/text/text";
import {changeName} from "../../../../controllers/auth";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {refreshUser} from "../../../../store/actions/user";
import {logException} from "../../../../controllers/system";

type IProps = {
}

type IState = {
    formValidation: any,
    form: any
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        user: state.user
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification) => {
            dispatch(setAppNotification(appNotification));
        },
        refreshUser: () => {
            dispatch(refreshUser());
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class ChangeName extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        formValidation: {},
        form: {}
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.validateForm();
    }

    onChange(property: string, value: string) {
        this.setState((state: IState) => {
            let form: any = Object.assign({}, state.form);
            form[property] = value;

            return {
                ...state,
                form
            };
        }, this.validateForm.bind(this));
    }

    validateForm(): boolean {
        let formValidation: any = {};

        if (!this.state.form.firstName) formValidation.firstName = 'error';
        if (!this.state.form.lastName) formValidation.lastName = 'error';

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return Object.keys(formValidation).length === 0;
    }

    save() {
        if (this.validateForm()) {
            changeName(this.props.user._id, this.state.form.firstName, this.state.form.lastName).then((success: boolean) => {
                if (success) {
                    this.props.setAppNotification({severity: 'success', message: 'Profile changed successfully'});
                    this.props.refreshUser();
                } else {
                    this.props.setAppNotification({severity: 'error', message: 'An error occured. Please make sure to fill all fields'});
                }
            });
        }
    }

    render() {
        return (
            <Dialog open={true}>
                <DialogTitle><Text>Please complete your profile</Text></DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{p:2}}>
                            <TextField
                                sx={{width: '100%'}}
                                label={localize('First name', this.props.lang)}
                                onChange={(e) => {this.onChange('firstName', e.target.value)}}
                                defaultValue={this.props.user.firstName}
                            />
                        </Box>
                        <Box sx={{p:2}}>
                            <TextField
                                sx={{width: '100%'}}
                                label={localize('Last name', this.props.lang)}
                                onChange={(e) => {this.onChange('lastName', e.target.value)}}
                                defaultValue={this.props.user.lastName}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color={'success'} onClick={this.save.bind(this)} disabled={Object.keys(this.state.formValidation).length > 0}><Text>Save</Text></Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeName);
