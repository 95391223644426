import {AnyAction} from 'redux';
import initialState from "../initialState";
import {
    AUTH_CHECK,
    LOGIN,
    LOGOUT, REFRESH_USER,
    REFRESH_USER_NOTIFICATIONS,
    SAVE_SCHEDULE,
    SET_PASSWORD_CHANGED, SET_NOTIFICATION_PREFERENCES, SET_USER_SETTINGS, REFRESH_UNREAD_EMAILS
} from "../actions/user";


export default function user(state = initialState.user, action: AnyAction) {
    switch(action.type) {
        case AUTH_CHECK:
        case LOGIN:
            return {
                ...state,
                ...action.payload.user
            };
        case LOGOUT:
            return {
                ...initialState.user
            };
        case REFRESH_USER_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload.userNotifications
            };
        case REFRESH_UNREAD_EMAILS:
            return {
                ...state,
                unreadEmailsCount: action.payload.unreadEmailsCount
            };
        case SAVE_SCHEDULE:
            return {
                ...state,
                schedule: action.payload.schedule
            }
        case SET_PASSWORD_CHANGED:
            return {
                ...state,
                changePassword: false
            }
        case REFRESH_USER:
            return {
                ...state,
                ...action.payload.user
            };
        case SET_NOTIFICATION_PREFERENCES:
            return {
                ...state,
                notificationPreferences: action.payload.notificationPreferences
            };
        case SET_USER_SETTINGS:
            return {
                ...state,
                settings: action.payload.userSettings
            };
        default:
            return state;
    }
}
