import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import Scheduler from "./components/scheduler";
import withRouter from "../../app/router/withRouter";
import {IRouter} from "../../app/router/router";
import querystring from 'query-string';
import AddMeetingDialog from "./components/meetingDialog";
import {ContactRequest} from "../../../models/contactRequest";
import {Patient} from "../../../models/patient";
import {getContactRequest} from "../../../controllers/contactRequests";
import {getPatient} from "../../../controllers/patients";
import Meeting, {MeetingWithPatient} from "../../../models/meeting";
import {getMeeting, scheduleBestMeeting} from "../../../controllers/meetings";
import User from "../../../models/user";
import {getUsers} from "../../../controllers/user";
import {ROLES} from "../../../constants/roles";
import {getActiveAccounts} from "../../../controllers/account";
import {Account} from "../../../models/account";
import {setLoading} from "../../../store/actions/app";
import {logException} from "../../../controllers/system";
import {Place} from "../../../models/place";
import {getPlaces} from "../../../controllers/place";
import * as signedDocumentController from '../../../controllers/signedDocument';
import {SignedDocument} from "../../../models/signedDocument";

type IState = {
    contactRequest?: ContactRequest,
    patient?: Patient,
    professionals: User[],
    accounts: Account[],
    places: Place[],
    signedDocuments: SignedDocument[]
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        user: state.user
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setLoading: (loading: boolean) => {
            dispatch(setLoading(loading));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class Meetings extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        professionals: [],
        accounts: [],
        places: [],
        signedDocuments: []
    }
    private addMeetingDialogRef: RefObject<any>;
    private schedulerRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.addMeetingDialogRef = React.createRef();
        this.schedulerRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadPlaces().then(() => {
            this.loadSignedDocuments().then(() => {
                this.loadProfessionals().then(() => {
                    this.loadAccounts().then(this.loadQueryData.bind(this));
                });
            });

        });
    }

    loadProfessionals(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if ((this.props.user.role as ROLES) !== ROLES.Professional) {
                getUsers({active: true, roles: [ROLES.Professional as number, ROLES.Administrator as number]}).then((professionals: User[]) => {
                    this.setProfessionals(professionals).then(resolve);
                }).catch(reject);
            } else {
                this.setProfessionals([this.props.user]).then(resolve);
            }
        });
    }

    setProfessionals(professionals: User[]) {
        return new Promise<void>((resolve) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    professionals
                };
            }, resolve);
        });
    }

    loadAccounts(): Promise<void> {
        return new Promise<void>((resolve => {
            getActiveAccounts().then((accounts: Account[]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        accounts
                    };
                }, resolve);
            });
        }));
    }

    loadPlaces(): Promise<void> {
        return new Promise<void>((resolve => {
            getPlaces().then((places: Place[]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        places
                    };
                }, resolve);
            });
        }));
    }

    loadSignedDocuments() {
        return new Promise<void>((resolve) => {
            signedDocumentController.getAll().then((signedDocuments: SignedDocument[]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        signedDocuments
                    };
                }, resolve);
            });
        });
    }

    loadQueryData() {
        let params: any = querystring.parse(window.location.search);
        if (params.contactRequest || params.patient) {
            // load data from contact request and patient (if requested)
            let contactRequest: ContactRequest | null | undefined = undefined;
            let patient: Patient | null | undefined = undefined;

            if (params.contactRequest) {
                getContactRequest(params.contactRequest).then((loadedContactRequest: ContactRequest | null) => {
                    contactRequest = loadedContactRequest;
                    whenAllDone();
                });
            } else {
                contactRequest = null;
            }

            if (params.patient) {
                getPatient(params.patient).then((loadedPatient: Patient | null) => {
                    patient = loadedPatient;
                    whenAllDone();
                });
            } else {
                patient = null;
            }

            // once both loaded, display add meeting dialog
            let whenAllDone = () => {
                if (contactRequest !== undefined && patient !== undefined) {
                    this.setState((state: IState) => {
                        return {
                            ...state,
                            contactRequest: contactRequest || undefined,
                            patient: patient || undefined
                        }
                    }, this.scheduleBestMeeting.bind(this));
                }
            }
        } else if (params.meeting) {
            getMeeting(params.meeting).then((meeting: MeetingWithPatient | null) => {
                if (meeting) {
                    this.onMeetingSelected(meeting);
                }
            });
        }
    }

    scheduleBestMeeting() {
        if (this.state.patient) {
            this.props.setLoading(true);
            scheduleBestMeeting(this.state.patient).then((meeting: Meeting | null) => {
                this.props.setLoading(false);
                this.addMeetingDialogRef.current?.openDialog(this.state.patient, this.state.contactRequest, meeting);
            });
        }
    }

    onMeetingChanged() {
        if (this.state.patient) {
            this.props.router.navigate('/meetings');
            this.setState((state: IState) => {
                return {
                    ...state,
                    patient: undefined,
                    contactRequest: undefined
                }
            }, () => {
                this.schedulerRef.current?.refreshSchedule();
            })
        } else {
            this.schedulerRef.current?.refreshSchedule();
        }
    }

    onAddClick(meeting?: Meeting) {
        if (this.state.patient && !meeting) {
            this.scheduleBestMeeting();
        } else {
            this.addMeetingDialogRef.current?.openDialog(this.state.patient || undefined, this.state.contactRequest || undefined, meeting);
        }
    }

    onMeetingSelected(meeting: Meeting) {
        this.addMeetingDialogRef.current?.openDialog(undefined, undefined, meeting);
    }

    render() {
        return (
            <React.Fragment>
                <Scheduler
                    ref={this.schedulerRef}
                    professionals={this.state.professionals}
                    onAddClick={this.onAddClick.bind(this)}
                    onMeetingSelected={this.onMeetingSelected.bind(this)}
                    places={this.state.places}
                />
                <AddMeetingDialog
                    ref={this.addMeetingDialogRef}
                    professionals={this.state.professionals}
                    accounts={this.state.accounts}
                    onMeetingChanged={this.onMeetingChanged.bind(this)}
                    places={this.state.places}
                    signedDocuments={this.state.signedDocuments}
                />
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Meetings));
