import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {IRouter} from "../../../app/router/router";
import withRouter from "../../../app/router/withRouter";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {logException} from "../../../../controllers/system";
import {Email} from "../../../../models/email";
import {Box, List, ListItemButton, ListItemText, Typography} from "@mui/material";
import {darken} from "@mui/material/styles";
import User from "../../../../models/user";
import {localize} from "../../../../helpers/localization";
import dayjs from "dayjs";

type IState = {
    loading: boolean
}

type IProps = {
    emails: Array<Email>,
    selectedEmailId: string,
    onEmailSelection: (email: Email) => void,
    users: User[]
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class EmailList extends React.Component<ReduxType> {
    public readonly state: IState = {
        loading: false
    }
    private indexedUsers: any = {};

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadData();
        this.indexUsers();
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<{}>, snapshot?: any) {
        this.indexUsers();
    }

    loadData() {
        this.setState((state: IState) => {
            return {...state, loading: true};
        }, () => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    loading: false,
                };
            });
        });
    }

    onEmailSelected(email: Email) {
        this.props.onEmailSelection(email);
    }

    indexUsers() {
        if (Object.keys(this.indexedUsers).length === this.props.users.length) return;
        this.indexedUsers = {};
        for (let user of this.props.users) {
            this.indexedUsers[user._id || ''] = user;
        }
    }

    getUser(userId: string): User {
        return this.indexedUsers[userId];
    }

    render() {
        if (this.props.users.length === 0) return null;
        this.indexUsers();
        return (
            <React.Fragment>
                <List component={'nav'}>
                    {
                        this.props.emails.map((email) => {
                            let userFrom = this.getUser(email.fromUserId as string);
                            return (
                                <ListItemButton
                                    key={email._id}
                                    selected={email._id === this.props.selectedEmailId}
                                    onClick={(event) => this.onEmailSelected(email)}
                                    sx={{borderBottom: 1, borderColor: 'grey.600', backgroundColor: (theme) => !email.readOn ? darken(theme.palette.info.main, 0.5) : 'inherited'}}
                                >
                                    <Box sx={{width: '100%;'}}>
                                        <Box>
                                            <Typography variant="caption">
                                                {userFrom.firstName} {userFrom.lastName}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <ListItemText
                                                primary={email.subject || localize('Empty subject', this.props.lang)}
                                            />
                                        </Box>
                                        <Box sx={{textAlign: 'right'}}>
                                            <Typography variant="caption">
                                                {dayjs(email.sentOn).format('MM-DD HH:mm')}
                                            </Typography>
                                        </Box>
                                    </Box>

                                </ListItemButton>
                            );
                        })
                    }

                </List>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmailList));
