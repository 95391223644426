import dayjs from "dayjs";

export interface IScheduleDay {
    slots?: IScheduleSlot[],
    working: boolean
}

export interface IScheduleSlot {
    start?: string,
    end?: string
}

export interface IScheduleException {
    date?: Date,
    note?: string,
    schedule?: IScheduleDay
}

export class ScheduleException implements IScheduleException{
    public _id?: string = undefined;
    public date?: Date = undefined;
    public note?: string = undefined;
    public schedule?: IScheduleDay = undefined;

    static collection: string = "scheduleException";

    public constructor(init?:Partial<ScheduleException>) {
        if (init && init.date) init.date = dayjs(init.date).isValid() ? dayjs(init.date).toDate() : undefined;
        Object.assign(this, init);
    }
}

export default class Schedule {
    public base: IScheduleDay[] = []
    public exceptions: IScheduleException[] = []

    public constructor(init?:Partial<Schedule>) {
        if (init) {
            initExceptions(init);
        }
        Object.assign(this, init);
    }
}

function initExceptions(init:Partial<Schedule>) {
    if (!init.exceptions) return;
    for (let exception of init.exceptions) {
        if (dayjs(exception.date).isValid()) exception.date = dayjs(exception.date).toDate();
    }
}
