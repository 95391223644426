import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {IRouter} from "../../../components/app/router/router";
import withRouter from "../../../components/app/router/withRouter";
import {setLoading} from "../../../store/actions/app";
import {logException} from "../../../controllers/system";
import querystring from "query-string";
import {getLink} from "../../../controllers/link";
import {returnToHome} from "../../../helpers/location";

type IState = {
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setLoading: (loading: boolean) => {
            dispatch(setLoading(loading));
        },
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class RedirectLink extends React.Component<ReduxType> {
    public readonly state: IState = {
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        let params: any = querystring.parse(window.location.search);
        this.props.setLoading(true);
        this.loadData(params.lid);
    }

    async loadData(linkUID: string) {
        let url: string | null = await getLink(linkUID);
        if (url) {
            window.location.href = url;
        } else {
            returnToHome();
        }
    }

    render() {
        return <></>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RedirectLink));
