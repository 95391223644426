import React, {RefObject, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {getMUILocale, localize} from "../../../../helpers/localization";
import FullScreenDialog from "../../../ui/fullScreenDialog/fullScreenDialog";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import {
    Backdrop,
    Box, CircularProgress, IconButton
} from "@mui/material";
import {setAppNotification} from "../../../../store/actions/app";
import {IAppNotification} from "../../../../models/appNotification";
import {Account, AllowedPatient} from "../../../../models/account";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef
} from "@mui/x-data-grid";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from "dayjs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {deepCopy} from "../../../../helpers/object";
import {randomString} from "../../../../helpers/random";
import {importAllowedPatients} from "../../../../controllers/account";

type IProps = {
    visible: boolean,
    account: Account | null,
    saveChanges: boolean,
    onSave: (account: Account) => void,
    onClose: () => void
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

const AllowedPatientsDialog = React.forwardRef((props: ReduxType, ref: React.Ref<unknown>) => {
    const [uploading, setUploading] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);
    const [allowedPatients, setAllowedPatients] = useState<AllowedPatient[]>([]);

    const editDialogRef: RefObject<any> = React.createRef<any>();
    const confirmCloseDialogRef: RefObject<any> = React.createRef<any>();
    const importFileUpload: RefObject<any> = React.createRef<any>();

    useEffect(() => {
        if ( props.visible) {
            setVisible();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible])

    const setVisible = () => {
        setAllowedPatients(deepCopy((props.account as any).allowedPatients || []));
        setChanged(false);
        editDialogRef.current.openDialog();
    }

    const closeDialogRequest = (): boolean => {
        if (changed) {
            confirmCloseDialogRef.current?.openDialog();
            return false
        } else {
            closeDialog();
            return true;
        }
    }

    const closeDialog = () => {
        editDialogRef.current.closeDialog();
        props.onClose();
    }

    const savePatient = (callback? : () => void) => {
        if (changed) {
            let account: Account = new Account({
                ...props.account,
                allowedPatients
            })

            props.onSave(account as Account);
            if (callback) callback();
            setChanged(false);
            closeDialog();
        }
    }

    const onModalButtonClick = (key: string) => {
        switch(key) {
            case 'save':
                savePatient();
                break;
            case 'import':
                importFileUpload.current.click();
                break;
        }
    }

    const addAllowedPatient = () => {
        let newAllowedPatients = deepCopy(allowedPatients);
        newAllowedPatients.push(new AllowedPatient({
            index: parseInt(randomString(10, false, false, true))
        }));
        setAllowedPatients(newAllowedPatients);
    }

    const removeAllowedPatient = (allowedPatientToRemove: AllowedPatient) => {
        let changedAllowedPatients = deepCopy(allowedPatients);
        for (let index = 0; index < changedAllowedPatients.length; index++) {
            if (changedAllowedPatients[index].index === allowedPatientToRemove.index) {
                changedAllowedPatients.splice(index, 1);
                break;
            }
        }
        setAllowedPatients(changedAllowedPatients);
        setChanged(true);
    }

    const updateAllowedPatient = (e: any) => {
        setChanged(true);
        let changedAllowedPatients = deepCopy(allowedPatients);
        for (let index = 0; index < changedAllowedPatients.length; index++) {
            if (changedAllowedPatients[index].index === e.index) {
                changedAllowedPatients[index] = e;
                setAllowedPatients(changedAllowedPatients);
                return;
            }
        }
    }

    const getColumns = (): (GridColDef | GridActionsColDef)[] => {
        return [
            {
                field: 'firstName',
                headerName: localize('First name', props.lang),
                minWidth: 100,
                flex: 1,
                editable: true
            },
            {
                field: 'lastName',
                headerName: localize('Last name', props.lang),
                minWidth: 100,
                flex: 1,
                editable: true
            },
            {
                field: 'birthdate',
                headerName: localize('Birthdate', props.lang),
                minWidth: 100,
                flex: 1,
                type: 'date',
                valueFormatter: (x: any) => x.value ? dayjs(x.value).format(props.dateFormat) : '',
                editable: true
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 30,
                flex: 0.25,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label={localize('Remove', props.lang)}
                        onClick={() => removeAllowedPatient(params.row as AllowedPatient)}
                    />
                ]
            }
        ];
    }

    const uploadFile = async (files: FileList | null) => {
        if (files) {
            setUploading(true);
            let allowedPatientsResult: AllowedPatient[] = await importAllowedPatients(props.account?._id as string, files);

            if (allowedPatientsResult.length > 0) {
                setUploading(false);
                setAllowedPatients(allowedPatientsResult);
                setChanged(true);
                props.setAppNotification({severity: 'success', message:'File imported successfully'})
            } else {
                props.setAppNotification({severity: 'error', message: 'An error occurred, please try again'})
            }
        }
    }

    return (
        <FullScreenDialog
            ref={editDialogRef}
            title={localize('Allowed patients', props.lang)}
            onButtonClick={(key: string) => onModalButtonClick(key)}
            onCloseRequest={() => closeDialogRequest()}
            buttons={[
                {key: 'import', text: localize('Import', props.lang), color: 'warning', keepDialogOpen: true},
                {key: 'save', text: localize('Apply', props.lang), color: 'success', keepDialogOpen: true, disabled: !changed}
            ]}
        >
            <Box sx={{p: 2, display: 'flex', flexDirection: 'column', height: '90%;'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <IconButton color={'primary'} onClick={() => addAllowedPatient()}>
                        <AddCircleIcon />
                    </IconButton>
                </Box>
                <DataGrid
                    getRowId={x => x.index}
                    columns={getColumns()}
                    rows={allowedPatients}
                    localeText={getMUILocale(props.lang).components.MuiDataGrid.defaultProps.localeText}
                    editMode="row"
                    processRowUpdate={(newRow: any, oldRow: any) => updateAllowedPatient(newRow)}
                    onProcessRowUpdateError={() => {}}
                />
            </Box>
            <ConfirmDialog
                ref={confirmCloseDialogRef}
                title={localize('Unsaved changes', props.lang)}
                text={localize('All unsaved changes will be lost. Do you want to continue?', props.lang)}
                onAgree={() => closeDialog()}
            />
            <input
                ref={importFileUpload}
                type="file"
                accept="xlsx"
                hidden
                onChange={(e) => {uploadFile(e.target.files)}}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={uploading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FullScreenDialog>
    );
})

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(AllowedPatientsDialog);
