import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {
    Box
} from "@mui/material";
import Calendar from "@toast-ui/react-calendar";
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import User from "../../../../models/user";
import {getUserFullName} from "../../../../controllers/user";
import dayjs from "dayjs";
import {localize} from "../../../../helpers/localization";
import Meeting, {
    COMMUNCATION_CHANNEL,
    MEETING_STATUS,
    MeetingWithPatient,
    PATIENT_ANSWER
} from "../../../../models/meeting";
import {getMeetings} from "../../../../controllers/meetings";
import {IScheduleDay, IScheduleSlot} from "../../../../models/schedule";
import {logException} from "../../../../controllers/system";
import {formatFileNumber} from "../../../../controllers/patients";
import {Place} from "../../../../models/place";
import {getPlaceAcronym} from "../../../../controllers/place";
import {renderToString} from "react-dom/server";

type IState = {
    meetings: Meeting[],
    schedules: any[],
    calendars: any[]
}

type IProps = {
    professional: User | null,
    places: Place[],
    date: Date
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

const calendarTemplates = {
    milestone: (schedule: any) => {
        return `<span style="color:#fff;background-color: ${schedule.bgColor};">${
            schedule.title
        }</span>`;
    },
    milestoneTitle: () => {
        return localize('Milestone', 'fr');
    },
    allday: (schedule: any) => {
        return `${schedule.title}`;
    },
    alldayTitle: () => {
        return '';
    },
    timegridDisplayPrimaryTime: (data: any) => {
        let time = dayjs(data.time);
        return dayjs().startOf('day').hour(time.hour()).minute(time.minute()).format('HH:mm');
    },
    timegridNowIndicatorLabel: (data: any) => {
        return dayjs(data.time).format('HH:mm');
    },
    weekDayName: (model: any) => {
        return `<span class="tui-full-calendar-dayname-date-area" style="color: #bbb;">
                    <span class="tui-full-calendar-dayname-date">${model.date}</span>&nbsp;&nbsp;<span class="tui-full-calendar-dayname-name">${dayjs().day(model.day).format('ddd')}</span>
                </span>`;
    },
    timegridDisplayTime: (props: any) => {
        return 'test';
    },
    time: (schedule: any) => {
        return schedule.title;
    }
}

class OneDaySchedule extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        meetings: [],
        schedules: [],
        calendars: []
    }

    private calendarRef: React.RefObject<any>;
    private indexedPlaces: any = {};

    constructor(props: ReduxType) {
        super(props);
        this.calendarRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    indexPlaces() {
        this.indexedPlaces = {};
        for (let place of this.props.places) {
            this.indexedPlaces[place._id as string] = place;
        }
    }

    setCalendars(): Promise<void> {
        return new Promise((resolve) => {
            if (!this.props.professional) return;
            let calendars: any[] = [];
            this.setState((state: IState) => {
                if (this.props.professional) {
                    calendars.push({
                        id: this.props.professional._id,
                        name: getUserFullName(this.props.professional),
                        color: 'white',
                        backgroundColor: this.props.professional.color,
                        borderColor: this.props.professional.color
                    });
                }
                return {
                    ...state,
                    calendars
                };
            }, resolve);
        });

    }

    componentDidMount() {
        this.calendarRef.current.calendarInstance.setDate(dayjs(this.props.date).toDate());
        this.indexPlaces();
        this.setCalendars();
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        let refreshSchedule: boolean = false;
        if ((!prevProps.professional && this.props.professional)
            || (prevProps.professional && !this.props.professional)
            || (`${prevProps.professional?._id}` !== `${this.props.professional?._id}`)
        ) {
            this.indexPlaces();
            this.setCalendars();
            refreshSchedule = true;
        }
        if (prevProps.date !== this.props.date) {
            this.calendarRef.current.calendarInstance.setDate(dayjs(this.props.date).toDate());
            refreshSchedule = true;
        }

        if (refreshSchedule) this.refreshSchedule();
    }

    refreshSchedule() {
        this.getSchedules().then((schedules: any[]) => {
            this.setState((state:IState) => {
                return {
                    ...state,
                    schedules
                };
            });
        });
    }

    getSchedules(): Promise<any[]> {
        return new Promise((resolve) => {
            let schedules: any[] = [];
            let schedule: any = this.getProfessionalSchedule();
            if (schedule) schedules.push(schedule);
            this.loadMeetings().then((meetings: any[]) => {
                schedules = schedules.concat(meetings);
                resolve(schedules);
            });
        });
    }

    getProfessionalSchedule(): any {
        let schedule: any | undefined = undefined;

        let day: dayjs.Dayjs = dayjs(this.props.date);

        let startHr: number = 8,
            startMin: number = 0,
            endHr: number = 17,
            endMin: number = 0;

        if (this.props.professional) {
            if (this.props.professional.schedule && Array.isArray(this.props.professional.schedule.base)) {
                if (!(this.props.professional.schedule.base[day.day()].working === false || !Array.isArray(this.props.professional.schedule.base[day.day()].slots))) {
                    let scheduleDay: IScheduleDay = this.props.professional.schedule.base[day.day()] as IScheduleDay;

                    if (scheduleDay.slots) {
                        let startSlot: IScheduleSlot = scheduleDay.slots[0] as IScheduleSlot;

                        let dayStart: dayjs.Dayjs = dayjs(startSlot.start);
                        if (dayStart.isValid()) {
                            startHr = dayStart.hour();
                            startMin = dayStart.minute();
                        }
                        let dayEnd: dayjs.Dayjs = dayjs(scheduleDay.slots[scheduleDay.slots?.length - 1].end);
                        if (dayEnd.isValid()) {
                            endHr = dayEnd.hour();
                            endMin = dayEnd.minute();
                        }
                    }
                }
            }


            let start: dayjs.Dayjs = day.hour(startHr).minute(startMin).second(0).millisecond(0);
            let end: dayjs.Dayjs = day.hour(endHr).minute(endMin).second(0).millisecond(0);
            schedule = {
                id: this.props.professional._id,
                calendarId: this.props.professional._id,
                title: `${getUserFullName(this.props.professional)} ${start.format('HH:mm')} - ${end.format('HH:mm')}`,
                category: 'allday',
                start: start.toISOString(),
                end: end.toISOString()
            };
        }

        return schedule;
    }

    getMeetingTitle(meeting: MeetingWithPatient): string {
        if (!meeting.isBlock) {
            let icon: JSX.Element | null = null;
            let patientAnswer: JSX.Element | null = null;
            switch (meeting.patientAnswer) {
                case PATIENT_ANSWER.Canceled:
                    patientAnswer = (
                        <i
                            className={'fa-solid fa-triangle-exclamation fa-beat-fade'}
                            style={{marginRight: '0.5em'}}
                        ></i>
                    )
                    break;
                case PATIENT_ANSWER.Confirmed:
                    patientAnswer = (
                        <i
                            className={'fa-solid fa-check'}
                            style={{marginRight: '0.5em'}}
                        ></i>
                    )
                    break;
            }

            switch (meeting.communicationChannel) {
                case COMMUNCATION_CHANNEL.Person:
                    icon = <i className={'fa-solid fa-building'} style={{marginRight: '0.5em'}}></i>;
                    if (meeting.placeId) {
                    }
                    break;
                case COMMUNCATION_CHANNEL.Phone:
                    icon = <i className={'fa-solid fa-phone'} style={{marginRight: '0.5em'}}></i>;
                    break;
                case COMMUNCATION_CHANNEL.Visio:
                    icon = <i className={'fa-solid fa-camera-web'} style={{marginRight: '0.5em'}}></i>;
                    break;
            }

            return (renderToString(
                <div style={{whiteSpace: 'initial', fontWeight: 'bold'}}>
                    {patientAnswer}
                    {icon}
                    {meeting.placeId ? `(${getPlaceAcronym(this.indexedPlaces[meeting.placeId])}) ` : null}
                    {meeting.paidByPatient ? <i className="fa-solid fa-dollar-sign"></i> : null}{meeting.paidByPatient ? ' ' : null}
                    {this.getMeetingStatusTitle(meeting)}
                    {formatFileNumber(meeting.patient?.fileNumber)}<span> - </span>
                    {meeting.patient?.firstName}<span>&nbsp;</span>{meeting.patient?.lastName}
                </div>
            ));
        } else {
            return `${localize('Absence', this.props.lang)}${(meeting.note ? ` - ${meeting.note}` : '')}`;
        }
    }

    getMeetingStatusTitle(meeting: Meeting): string {
        switch (meeting.status as MEETING_STATUS) {
            case MEETING_STATUS.Missed:
                return `${localize('Missed', this.props.lang)} `;
            case MEETING_STATUS.Canceled:
                return `${localize('Canceled', this.props.lang)} `;
            default:
                return '';
        }
    }

    loadMeetings(): Promise<any[]> {
        return new Promise((resolve => {
            if (this.props.professional) {
                getMeetings({
                    dateFrom: dayjs(this.props.date).startOf('day').toDate(),
                    dateTo: dayjs(this.props.date).endOf('day').toDate(),
                    userIds: [this.props.professional._id as string],
                    meetingStatus: [
                        MEETING_STATUS.Created,
                        MEETING_STATUS.Confirmed,
                        MEETING_STATUS.Completed,
                        MEETING_STATUS.Reported,
                        MEETING_STATUS.Missed
                    ]
                }, false).then((meetings: MeetingWithPatient[]) => {
                    this.setState((state: IState) => {
                        return {
                            ...state,
                            meetings
                        }
                    }, () => {
                        resolve(meetings.map((meeting) => {
                            return {
                                id: meeting._id,
                                calendarId: meeting.professionalUserId,
                                title: this.getMeetingTitle(meeting),
                                category: 'time',
                                start: dayjs(meeting.timeFrom).toISOString(),
                                end: dayjs(meeting.timeTo).toISOString(),
                                isPending: (meeting.status as MEETING_STATUS) === MEETING_STATUS.Missed || meeting.isBlock
                            };
                        }));
                    });

                });
            } else {
                resolve([]);
            }
        }));
    }

    render() {
        return (
            <Box sx={{color: 'black', height: '100%'}}>
                <Calendar
                    ref={this.calendarRef}
                    usageStatistics={false}
                    height={'100%'}
                    calendars={this.state.calendars}
                    template={calendarTemplates}
                    view={'day'}
                    isReadOnly={true}
                    week={{
                        narrowWeekend: true,
                        startDayOfWeek: 1,
                        hourStart: 7,
                        hourEnd: 21,
                        taskView: false
                    }}
                    events={this.state.schedules}
                    gridSelection={{
                        enableClick: false,
                        enableDblClick: false
                    }}
                />
            </Box>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(OneDaySchedule);
