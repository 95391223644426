import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import PatientsGrid from './components/grid';
import PatientDialog from "./components/dialog";
import {Patient, PATIENT_STATUS} from "../../../models/patient";
import {ROLES} from "../../../constants/roles";
import withRouter from "../../app/router/withRouter";
import {IRouter} from "../../app/router/router";
import User from "../../../models/user";
import {Account} from "../../../models/account";
import {getActiveAccounts} from "../../../controllers/account";
import {getUsers} from "../../../controllers/user";
import querystring from 'query-string';
import {getPatient} from "../../../controllers/patients";
import {logException} from "../../../controllers/system";
import {Place} from "../../../models/place";
import {getPlaces} from "../../../controllers/place";
import * as signedDocumentController from '../../../controllers/signedDocument';
import {SignedDocument} from "../../../models/signedDocument";

const ALLOWED_ROLES: ROLES[] = [
    ROLES.Administrator,
    ROLES.Professional,
    ROLES.Assistant
]

type IState = {
    professionals: User[],
    accounts: Account[],
    places: Place[],
    signedDocuments: SignedDocument[]
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        userRole: state.user.role
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class Patients extends React.Component<ReduxType> {
    public readonly state: IState = {
        professionals: [],
        accounts: [],
        places: [],
        signedDocuments: []
    }
    private patientsGridRef: RefObject<any>;
    private patientDialogRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.patientsGridRef = React.createRef();
        this.patientDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        if (!this.isAllowed()) {
            window.setTimeout(() => {
                this.props.router.navigate('/');
            }, 100);
        } else {
            this.loadAccounts();
            this.loadProfessionals();
            this.loadPlaces();
            this.loadSignedDocuments();
            this.loadQueryParameters();
        }
    }

    loadAccounts() {
        getActiveAccounts().then((accounts: Account[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    accounts
                };
            });
        });
    }

    loadPlaces() {
        getPlaces().then((places: Place[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    places
                };
            });
        });
    }

    loadSignedDocuments() {
        signedDocumentController.getAll().then((signedDocuments: SignedDocument[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    signedDocuments
                };
            });
        });
    }

    loadProfessionals() {
        getUsers({roles: [ROLES.Professional as number, ROLES.Administrator as number]}).then((professionals: User[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    professionals
                };
            });
        });
    }

    loadQueryParameters() {
        let params: any = querystring.parse(window.location.search);

        if (params.id) {
            getPatient(params.id).then((patient: Patient | null) => {
                if (patient) {
                    this.onPatientSelected(patient);
                }
            });
        }
    }

    isAllowed(): boolean {
        return ALLOWED_ROLES.indexOf(this.props.userRole as ROLES) !== -1;
    }

    onPatientSelected(patient: Patient) {
        this.patientDialogRef.current.openDialog(patient);
    }

    onPatientAdd() {
        this.patientDialogRef.current.openDialog(new Patient({status: PATIENT_STATUS.ACTIVE}));
    }

    onPatientSave() {
        this.patientsGridRef.current.loadData();
    }

    render() {
        if (!this.isAllowed()) return null;
        return (
            <React.Fragment>
                <PatientsGrid
                    ref={this.patientsGridRef}
                    onPatientSelected={this.onPatientSelected.bind(this)}
                    onPatientAdd={this.onPatientAdd.bind(this)}
                />
                <PatientDialog
                    ref={this.patientDialogRef}
                    accounts={this.state.accounts}
                    professionals={this.state.professionals}
                    places={this.state.places}
                    signedDocuments={this.state.signedDocuments}
                    onSave={this.onPatientSave.bind(this)}
                />
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Patients));
