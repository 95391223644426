import {Action, createAction} from "../action";

export const SET_THEME = "SET_THEME";
export function setTheme(theme: string): Action<string, any> {
    return createAction(
        SET_THEME,
        {
            theme
        }
    );
}

export const SET_LANG = "SET_LANG";
export function setLang(lang: string): Action<string, any> {
    return createAction(
        SET_LANG,
        {
            lang
        }
    );
}

export const GET_CONFIG = "GET_CONFIG";
export function getConfig(name: string): Action<string, any> {
    return createAction(
        GET_CONFIG,
        {
            name
        }
    );
}

export const SET_CONFIG = "SET_CONFIG";
export function setConfig(name: string, value: string): Action<string, any> {
    return createAction(
        SET_CONFIG,
        {
            name,
            value
        }
    );
}
