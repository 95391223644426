import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Button, IconButton, TextField} from "@mui/material";
import {localize} from "../../../../helpers/localization";
import Text from '../../../app/text/text';
import {getMissedRates, logException, saveMissedRates} from "../../../../controllers/system";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {MissedRate} from "../../../../models/missedRate";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {dayjsOrAny} from "../../../../helpers/dayjs";

type IState = {
    missedRates: MissedRate[],
    formValidation: any
}

type IProps = {
    visible: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class MissedRates extends React.Component<ReduxType, IState> {
    public state: IState = {
        missedRates: [],
        formValidation: {}
    }

    private saveTimeout: number | null = null;
    private saving: boolean = false;

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadMissedRates();
    }

    loadMissedRates() {
        getMissedRates().then(( missedRates: MissedRate[]) => {
            this.setMissedRates(missedRates);
        });
    }

    refreshMissedRates() {
        this.setState((state: IState) => {
            return {
                ...state,
                missedRates: []
            };
        }, () => {
            this.loadMissedRates();
        });
    }

    setMissedRates(missedRates: MissedRate[]) {
        this.setState((state: IState) => {
            return {
                ...state,
                missedRates
            };
        });
    }

    saveMissedRates() {
        if (this.validateForm()) {
            if (!this.saving) {
                this.saving = true;
                saveMissedRates(this.state.missedRates).then((success: boolean) => {
                    this.saving = false;
                });
            } else {
                if (this.saveTimeout) {
                    window.clearTimeout(this.saveTimeout);
                    this.saveTimeout = null;
                }
                this.saveTimeout = window.setTimeout(this.saveMissedRates.bind(this), 500);
            }
        }
    }

    addMissedRate() {
        this.setState((state: IState) => {
            let missedRates: MissedRate[] = state.missedRates;
            missedRates.push(new MissedRate({
                from: dayjs(this.getLastDate()).add(1, 'day').toDate()
            }));
            return {...state, missedRates};
        });
    }

    getLastDate(): Date {
        let lastDate: Date | undefined = undefined;

        for (let missedRate of this.state.missedRates) {
            if (!lastDate) {
                lastDate = missedRate.from;
            } else {
                if (dayjs(missedRate.from).isAfter(lastDate)) {
                    lastDate = missedRate.from;
                }
            }
        }

        return lastDate || new Date();
    }

    onChange(property: string, value: any, index: number) {
        this.setState((state: IState) => {
            let missedRate: any = {...state.missedRates[index]};

            switch (property) {
                case 'from':
                    if (dayjs(value).isValid()) {
                        value = dayjs(value).startOf('day').toDate();
                    } else {
                        value = undefined;
                    }
                    break;

                case 'rate':
                    value = parseFloat(`${value}`);
                    if (isNaN(value)) value = 0;
                    break;
            }

            missedRate[property] = value;
            state.missedRates[index] = missedRate;
            return {
                ...state
            };
        }, () => {
            this.saveMissedRates();
        });
    }

    removeMissedRate(index: number) {
        let missedRates: MissedRate[] = this.state.missedRates;
        missedRates.splice(index, 1);
        this.setMissedRates(missedRates);
    }

    validateForm(): boolean {
        let formValidation: any = {};
        let lastDate: Date | undefined = undefined;

        for (let index = 0; index < this.state.missedRates.length; index++) {
            let missedRate: MissedRate = this.state.missedRates[index];
            if (!dayjs(missedRate.from).isValid()) {
                formValidation[index] = formValidation[index] || {};
                formValidation[index].from = 'error';
            } else {
                if (lastDate) {
                    if (dayjs(missedRate.from).isBefore(lastDate)) {
                        formValidation[index] = formValidation[index] || {};
                        formValidation[index].from = 'error';
                    }
                }
                lastDate = missedRate.from;
            }
            if (isNaN(parseFloat(`${missedRate.rate}`))) {
                formValidation[index] = formValidation[index] || {};
                formValidation[index].rate = 'error';
            }
        }

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return Object.keys(formValidation).length === 0;
    }

    render() {
        if (!this.props.visible) return null;
        return (
            <React.Fragment>
                <Box sx={{display: 'flex', flexDirection: 'column', p:2, height: '100%'}}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', pb: 1}}>
                        <Button color={'primary'} variant={'contained'} onClick={this.addMissedRate.bind(this)}><Text>Add</Text></Button>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        {
                            this.state.missedRates.map((missedRate: MissedRate, index: number) => {
                                return (
                                    <Box sx={{pb: 3, display: 'flex'}} key={index}>
                                        <Box sx={{pr: 3, flexGrow: 1}}>
                                            <DatePicker
                                                sx={{width: '100%'}}
                                                label={localize('From', this.props.lang)}
                                                value={dayjsOrAny(missedRate.from)}
                                                format={this.props.dateFormat}
                                                onChange={((newValue: any) => {this.onChange('from', newValue, index)})}
                                                slotProps={{textField: { error: this.state.formValidation[index]?.from === 'error'}}}
                                            />
                                        </Box>
                                        <Box sx={{pr: 3, flexGrow: 1}}>
                                            <TextField
                                                label={localize('Rate', this.props.lang)}
                                                type={'number'}
                                                sx={{width: '100%'}}
                                                value={missedRate.rate || 0}
                                                onChange={(e) => {this.onChange('rate', e.target.value, index)}}
                                                error={this.state.formValidation[index]?.rate === 'error'}
                                            />
                                        </Box>
                                        <Box>
                                            <IconButton color={'error'} onClick={this.removeMissedRate.bind(this, index)}>
                                                <IndeterminateCheckBoxIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                );
                            })
                        }
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MissedRates);
