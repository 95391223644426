import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {IRouter} from "../../../components/app/router/router";
import withRouter from "../../../components/app/router/withRouter";
import {IAppNotification} from "../../../models/appNotification";
import {setAppNotification, setLoading} from "../../../store/actions/app";
import {logException} from "../../../controllers/system";
import {Box, Button, Typography} from "@mui/material";
import Text from "../../../components/app/text/text";
import {checkWelcomeCode} from "../../../controllers/meetings";

type IState = {
    step: number,
    meetingCode: string,
    validating: boolean,
    codeHasFocus: boolean,
    success: boolean | undefined
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        },
        setLoading: (loading: boolean) => {
            dispatch(setLoading(loading));
        },
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class WelcomePatient extends React.Component<ReduxType> {
    public readonly state: IState = {
        step: 1,
        meetingCode: '',
        validating: false,
        codeHasFocus: false,
        success: undefined
    }
    private resetTimeout: number;

    constructor(props: ReduxType) {
        super(props);
        this.resetTimeout = 0;
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    setMeetingCode(meetingCode: string) {
        this.setState((state: IState) => {
            return {
                ...state,
                meetingCode
            };
        }, this.checkMeetingCode);
    }

    checkMeetingCode() {
        if (this.state.meetingCode.length === 5) {
            this.setState((state: IState) => {
                return {
                    ...state,
                    validating: true
                };
            }, () => {
                checkWelcomeCode(parseInt(this.state.meetingCode)).then((success: boolean) => {
                    this.setState((state: IState) => {
                        return {
                            ...state,
                            success,
                            codeHasFocus: false,
                            meetingCode: '',
                            validating: false
                        };
                    }, ()=> {
                        this.resetTimeout = window.setTimeout(() => {
                            this.resetSuccess();
                        }, 10000);
                    });
                });
            });
        }
    }

    resetSuccess() {
        window.clearTimeout(this.resetTimeout);
        this.setState((state: IState) => {
            return {
                success: undefined
            };
        });
    }

    setCodeFocus(codeHasFocus: boolean) {
        this.setState((state: IState) => {
            return {
                ...state,
                codeHasFocus
            };
        });
    }

    render() {
        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '90%'
                    }}>
                        {
                            this.state.success === undefined ?
                                <div style={{
                                    display: this.state.step === 1 ? 'block' : 'none',
                                    textAlign: 'center'
                                }}>
                                    <Typography
                                        variant={'h1'}
                                        sx={{display: this.state.codeHasFocus ? 'none' : 'block'}}
                                    >
                                        <Text>Welcome</Text>
                                    </Typography>
                                    <Typography
                                        variant={'h3'}
                                        sx={{display: this.state.codeHasFocus ? 'none' : 'block'}}
                                    >
                                        <Text>Please enter your meeting code here</Text>
                                    </Typography>
                                    <Box sx={{pt: 3, width: '100%'}}>
                                        <input
                                            id="code"
                                            style={{
                                                width: '90%',
                                                height: '28vw',
                                                fontSize: '22vw',
                                                textAlign: 'center'
                                            }}
                                            disabled={this.state.validating}
                                            type={'number'}
                                            maxLength={5}
                                            value={this.state.meetingCode}
                                            onChange={((e: any) => {
                                                this.setMeetingCode(e.target.value)
                                            })}
                                            onFocus={this.setCodeFocus.bind(this, true)}
                                            onBlur={this.setCodeFocus.bind(this, false)}
                                        />
                                    </Box>
                                </div>
                                :
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <div style={{
                                        textAlign: 'center',
                                        backgroundColor: this.state.success? '#2e7d32' : '#d50000',
                                        borderColor: 'white',
                                        borderStyle: 'solid',
                                        borderWidth: '2px',
                                        padding: '1em'
                                    }}>

                                                <Typography
                                                    variant={'h3'}
                                                    sx={{color: 'white'}}
                                                >
                                                    {
                                                        this.state.success ?
                                                            <Text>Thank you! Please have a seat</Text>
                                                            :
                                                            <Text>Sorry, this code is not valid</Text>
                                                    }
                                                </Typography>
                                    </div>
                                    <div style={{padding: '1em', textAlign: 'center'}}>
                                        <Button
                                            onClick={this.resetSuccess.bind(this)}
                                            color={'primary'}
                                            variant={'contained'}
                                            size={'large'}
                                        >
                                            <Text>Return</Text>
                                        </Button>
                                    </div>
                                </div>
                        }
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WelcomePatient));
