import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {localize} from "../../../helpers/localization";
import {logException} from "../../../controllers/system";

type IProps = {
    children: React.ReactNode
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Text extends React.Component<ReduxType> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    render() {
        const localized: string = localize(this.props.children as string, this.props.lang);
        return (
            <React.Fragment>
                {localized}
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Text);
