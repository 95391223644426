import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {localize} from "../../../helpers/localization";
import {logException} from "../../../controllers/system";

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        preventClose: state.app.preventClose
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class PreventClose extends React.Component<ReduxType> {

    componentDidMount() {
        window.onbeforeunload = this.needToPreventClose.bind(this);
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    needToPreventClose() {
        if (this.props.preventClose) {
            return localize('Your changes will be lost. Are you sure you want to leave this page?', this.props.lang);
        } else {
            return null;
        }
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreventClose);
