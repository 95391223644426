import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {logException} from "../../../../controllers/system";
import {setNotificationPreferences} from "../../../../store/actions/user";
import {localize} from "../../../../helpers/localization";
import {NotificationPreferences} from "../../../../models/user";

type IState = {
}

type IProps = {
    visible: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        userId: state.user._id,
        notificationPreferences: state.user.notificationPreferences || new NotificationPreferences()
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        },
        setNotificationPreferences: (notificationPreferences: NotificationPreferences) => {
            dispatch(setNotificationPreferences(notificationPreferences));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class NotificationSettings extends React.Component<ReduxType, IState> {
    public state: IState = {
    };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    changeNotificationPreferences(preference: string, active: boolean) {
        let notificationPreferences: any = {
            ...this.props.notificationPreferences
        };
        notificationPreferences[preference] = active;
        this.props.setNotificationPreferences(notificationPreferences);
    }

    render() {
        if (!this.props.visible) return null;
        return (
            <React.Fragment>
                <Box sx={{p:2}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.notificationPreferences.missingReports !== false}
                                onChange={(e) => {this.changeNotificationPreferences('missingReports', e.target.checked)}}
                            />
                        }
                        label={localize('Missing reports', this.props.lang)}
                    />

                </Box>
                <Box sx={{p:2}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.notificationPreferences.missingCycleReports !== false}
                                onChange={(e) => {this.changeNotificationPreferences('missingCycleReports', e.target.checked)}}
                            />
                        }
                        label={localize('Missing cycle reports', this.props.lang)}
                    />

                </Box>
                <Box sx={{p:2}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.notificationPreferences.unfinishedCycle !== false}
                                onChange={(e) => {this.changeNotificationPreferences('unfinishedCycle', e.target.checked)}}
                            />
                        }
                        label={localize('Unfinished cycles', this.props.lang)}
                    />

                </Box>
                <Box sx={{p:2}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.notificationPreferences.unpaidMeetings !== false}
                                onChange={(e) => {this.changeNotificationPreferences('unpaidMeetings', e.target.checked)}}
                            />
                        }
                        label={localize('Unpaid meetings', this.props.lang)}
                    />

                </Box>
                <Box sx={{p:2}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.notificationPreferences.newContactRequest !== false}
                                onChange={(e) => {this.changeNotificationPreferences('newContactRequest', e.target.checked)}}
                            />
                        }
                        label={localize('New contact requests', this.props.lang)}
                    />

                </Box>
                <Box sx={{p:2}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.notificationPreferences.smsMessages !== false}
                                onChange={(e) => {this.changeNotificationPreferences('smsMessages', e.target.checked)}}
                            />
                        }
                        label={localize('Inbound sms messages', this.props.lang)}
                    />

                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
