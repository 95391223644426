import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {localize} from "../../../../helpers/localization";
import FullScreenDialog from "../../../ui/fullScreenDialog/fullScreenDialog";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import Text from "../../../app/text/text";
import {
    Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup,
    TextField
} from "@mui/material";
import {logException} from "../../../../controllers/system";
import {Parent} from "../../../../models/patient"
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {dayjsOrAny} from "../../../../helpers/dayjs";

type IState = {
    parent?: Parent,
    changed: boolean
}

type IProps = {
    title: string,
    parent: Parent | undefined,
    readonly: boolean,
    onSave: (parent: Parent) => void,
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class ParentDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        changed: false
    }

    private editDialogRef: RefObject<any>;
    private confirmCloseDialogRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.editDialogRef = React.createRef();
        this.confirmCloseDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog() {
        this.setState((state: IState) => {
            return {
                ...state,
                changed: false,
                parent: new Parent(this.props.parent)
            };
        }, () => {
            this.editDialogRef.current.openDialog();
        });
    }

    closeDialogRequest(): boolean {
        if (this.state.changed) {
            this.confirmCloseDialogRef.current?.openDialog();
            return false
        } else {
            this.closeDialog();
            return true;
        }
    }

    closeDialog() {
        this.editDialogRef.current.closeDialog();
    }

    saveParent() {
        this.props.onSave(new Parent(this.state.parent));
        this.closeDialog();
    }

    onChange(property: string, value: any) {
        this.setState((state: IState) => {
            let parent: any = state.parent;
            parent[property] = value;
            return {
                ...state,
                changed: true,
                parent
            };
        });
    }

    onModalButtonClick(key: string) {
        switch(key) {
            case 'apply':
                this.saveParent();
                break;
        }
    }

    render() {
        return (
            <FullScreenDialog
                ref={this.editDialogRef}
                title={this.props.title}
                onButtonClick={this.onModalButtonClick.bind(this)}
                onCloseRequest={this.closeDialogRequest.bind(this)}
                buttons={[
                    {key: 'apply', text: localize('Apply', this.props.lang), color: 'success', keepDialogOpen: true, disabled: !this.state.changed}
                ]}
            >
                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', height: '90%;'}}>
                    <Grid container>
                        <Grid item sx={{p: 2}} xs={12}>
                            <FormControl>
                                <FormLabel id="relation-label"><Text>Relationship</Text></FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="relation-label"
                                    name="relation"
                                    value={this.state.parent?.relation}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {this.onChange('relation', parseInt((event.target as HTMLInputElement).value))}}
                                >
                                    <FormControlLabel disabled={this.props.readonly} value="1" control={<Radio />} label={localize('Mother', this.props.lang)} />
                                    <FormControlLabel disabled={this.props.readonly} value="2" control={<Radio />} label={localize('Father', this.props.lang)} />
                                    <FormControlLabel disabled={this.props.readonly} value="3" control={<Radio />} label={localize('Other', this.props.lang)} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item sx={{p: 2}} xs={12} md={4}>
                            <TextField
                                id={'firstName'}
                                sx={{width: '100%'}}
                                label={localize('First name', this.props.lang)}
                                defaultValue={this.state.parent?.firstName}
                                onChange={((e: any) => {this.onChange('firstName', e.target.value)})}
                                InputProps={{
                                    readOnly: this.props.readonly,
                                }}
                            />
                        </Grid>
                        <Grid item sx={{p: 2}} xs={12} md={4}>
                            <TextField
                                id={'lastName'}
                                sx={{width: '100%'}}
                                label={localize('Last name', this.props.lang)}
                                defaultValue={this.state.parent?.lastName}
                                onChange={((e: any) => {this.onChange('lastName', e.target.value)})}
                                InputProps={{
                                    readOnly: this.props.readonly,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sx={{p: 2}} xs={12} md={4}>
                            <DatePicker
                                sx={{width: '100%'}}
                                label={localize('Birthdate', this.props.lang)}
                                value={dayjsOrAny(this.state.parent?.birthdate || null)}
                                format={this.props.dateFormat}
                                readOnly={this.props.readonly}
                                onChange={((newValue: any) => {
                                    this.onChange('birthdate', dayjs(newValue).toDate());
                                })}
                            />
                        </Grid>
                        <Grid item sx={{p: 2}} xs={12} md={4}>
                            <TextField
                                id={'phoneNumber'}
                                sx={{width: '100%'}}
                                label={localize('Phone number', this.props.lang)}
                                defaultValue={this.state.parent?.phoneNumber}
                                onChange={((e: any) => {this.onChange('phoneNumber', e.target.value)})}
                                InputProps={{
                                    readOnly: this.props.readonly,
                                }}
                            />
                        </Grid>
                        <Grid item sx={{p: 2}} xs={12} md={4}>
                            <TextField
                                id={'email'}
                                sx={{width: '100%'}}
                                label={localize('Email', this.props.lang)}
                                defaultValue={this.state.parent?.email}
                                onChange={((e: any) => {this.onChange('email', e.target.value)})}
                                InputProps={{
                                    readOnly: this.props.readonly,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sx={{p: 2}} xs={12}>
                            <TextField
                                id={'note'}
                                sx={{width: '100%'}}
                                label={localize('Note', this.props.lang)}
                                defaultValue={this.state.parent?.note}
                                onChange={((e: any) => {this.onChange('note', e.target.value)})}
                                multiline={true}
                                rows={3}
                                InputProps={{
                                    readOnly: this.props.readonly,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <ConfirmDialog
                    ref={this.confirmCloseDialogRef}
                    title={localize('Unsaved changes', this.props.lang)}
                    text={localize('All unsaved changes will be lost. Do you want to continue?', this.props.lang)}
                    onAgree={this.closeDialog.bind(this)}
                />
            </FullScreenDialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ParentDialog);
