import {get} from '../helpers/ajax';
import {AxiosResponse} from "axios";
import {Log} from "../models/log";

export async function getLogs(): Promise<Log[]> {
    let response: AxiosResponse<Log[]> = await get<Log[]>(`/log/get/2000`);

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}
