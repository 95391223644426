import {AnyAction, Middleware} from "redux";
import {
    LOGGED_IN, REFRESH_UNREAD_EMAILS, REFRESH_USER,
    REFRESH_USER_NOTIFICATIONS,
    refreshUserNotifications, SAVE_SCHEDULE, SET_NOTIFICATION_PREFERENCES,
    SET_USER_NOTIFICATION_READ, SET_USER_SETTINGS
} from "../actions/user";
import {INIT_SOCKET} from "../actions/app";
import * as socket from '../../controllers/socket';
import {Actions} from '../../controllers/socket';
import {
    getUser,
    getUserNotifications,
    saveUserSchedule,
    setNotificationPreferences,
    setUserNotificationRead, setUserSettings
} from "../../controllers/user";
import {UserNotification} from "../../models/userNotification";
import User from "../../models/user";
import {getUnreadEmailsCount} from "../../controllers/email";

const user: Middleware<{}>= (store: any) => (next: Function) => (action: AnyAction) => {
    switch(action.type) {
        case LOGGED_IN: {
            next(action);
            store.dispatch(refreshUserNotifications());
            //let userId: string | undefined = store.getState().user._id;
            //if (userId) socket.identify(userId);
            break;
        }
        case INIT_SOCKET: {
            next(action);
            socket.on(Actions.NEW_USER_NOTIFICATION, () => {
                store.dispatch(refreshUserNotifications());
            });
            break;
        }
        case REFRESH_USER_NOTIFICATIONS: {
            if (store.getState().user._id) {
                getUserNotifications().then((userNotifications: UserNotification[]) => {
                    action.payload.userNotifications = userNotifications;
                    next(action);
                });
            } else {
                next(action);
            }
            break;
        }
        case REFRESH_UNREAD_EMAILS: {
            if (store.getState().user._id) {
                getUnreadEmailsCount().then((unreadEmailsCount: number) => {
                    action.payload.unreadEmailsCount = unreadEmailsCount;
                    next(action);
                });
            } else {
                next(action);
            }
            break;
        }
        case SET_USER_NOTIFICATION_READ: {
            setUserNotificationRead(action.payload.userNotificationId).then(() => {
                store.dispatch(refreshUserNotifications());
            });
            break;
        }
        case SAVE_SCHEDULE: {
            saveUserSchedule(action.payload.userId, action.payload.schedule).then((success: boolean) => {
                next(action);
            });
            break;
        }
        case SET_NOTIFICATION_PREFERENCES: {
            setNotificationPreferences(action.payload.notificationPreferences).then(() => {
                next(action);
            })
            break;
        }
        case SET_USER_SETTINGS: {
            setUserSettings(store.getState().user._id, action.payload.userSettings).then(() => {
                next(action);
            })
            break;
        }
        case REFRESH_USER: {
            getUser(store.getState().user._id).then((user: User | null) => {
                if (user) {
                    action.payload = (action.payload || {});
                    action.payload.user = user;
                    next(action);
                }
            });
            break;
        }
        default:
            next(action);
    }
}

export default user;
