import {Account, AllowedPatient} from "../models/account";
import {get, post, upload} from "../helpers/ajax";
import {AxiosResponse} from "axios";

export async function getActiveAccounts(): Promise<Account[]> {
    let response: AxiosResponse<Account[]> = await post<Account[]>('/account/find', {active: true});

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function saveAccount(account: Account): Promise<Account | null> {
    let response: AxiosResponse<Account> = await post<Account>('/account/save', account);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export async function isPatientAllowedForAccount(accountId: string, patientId: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await get<boolean>(`/account/is-patient-allowed/${accountId}/${patientId}`);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return false;
}

export function indexAccounts(accounts: Account[]): [any, string[]] {
    let indexedAccounts: any = {};
    let systemAccounts: string[] = [];

    for (let account of accounts) {
        indexedAccounts[account._id as string] = account;
        if (account.system) systemAccounts.push(account._id as string);
    }

    return [indexedAccounts, systemAccounts];
}

export async function importAllowedPatients(accountId: string, files: FileList): Promise<AllowedPatient[]> {
    let results: AllowedPatient[] = [];

    if (files.length === 1) {
        let formData = new FormData();
        formData.append("fileupload", files[0]);

        let response: AxiosResponse<AllowedPatient[]> = await upload(`/account/import-allowed-patients/${accountId}`, formData);

        if (response.status === 200 && response.data)
            results = response.data;
    }

    return results;
}

export async function getAccountStatistics(accountId: string, from: Date, to: Date): Promise<any> {
    let response: AxiosResponse<any> = await post<any>(`/account/stats/${accountId}`, {from, to});

    if (response.status === 200 && response.data)
        return response.data;
    else
        return false;
}
