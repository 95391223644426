import {AnyAction} from 'redux';
import initialState from "../initialState";
import {AUTH_CHECK, LOGIN} from "../actions/user";
import {SET_CONFIG, SET_THEME} from "../actions/settings";


export default function settings(state = initialState.settings, action: AnyAction) {
    switch(action.type) {
        case AUTH_CHECK:
        case LOGIN:
            return {
                ...state,
                lang: action.payload.lang || 'FR'
            };
        case SET_THEME:
            return {
                ...state,
                theme: action.payload.theme
            }
        case SET_CONFIG:
            let newConfig: any = {};
            newConfig[action.payload.name] = action.payload.value;
            return {
                ...state,
                configs: {
                    ...state.configs,
                    ...newConfig
                }
            }
        default:
            return state;
    }
}
