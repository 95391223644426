import {PreloadedState} from "redux";

let initialState: PreloadedState<any> = {
    app: {
        menuState: 'open',
        notification: null,
        redirect: null,
        loading: false,
        preventClose: false
    },
    user: {
        authChecked: false,
        notifications: [],
        unreadEmailsCount: 0
    },
    session: {
        initialized: false,
        key: undefined
    },
    settings: {
        lang: 'FR',
        theme: 'dark',
        dateFormat: 'DD/MM/YYYY',
        configs: {}
    }
};

export default initialState;
