import dayjs from "dayjs";

export enum IMPORTANCE {
    None = 0,
    Low = 1,
    Medium = 2,
    High = 3
}

class Announcement{
    public _id?: string = undefined;
    public date: Date = new Date();
    public userId?: string = undefined;
    public expiryDate?: Date = undefined;
    public title?: string = undefined;
    public message?: string = undefined;
    public importance: IMPORTANCE = IMPORTANCE.None;

    public constructor(init?:Partial<Announcement>) {
        if (init && init.date) init.date = dayjs(init.date).isValid() ? dayjs(init.date).toDate() : undefined;
        if (init && init.expiryDate) init.expiryDate = dayjs(init.expiryDate).isValid() ? dayjs(init.expiryDate).toDate() : undefined;
        Object.assign(this, init);
    }
}

export {Announcement};
