import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {getMUILocale, localize} from "../../../../helpers/localization";
import FullScreenDialog from "../../../ui/fullScreenDialog/fullScreenDialog";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox, Divider,
    Grid,
    IconButton, Link,
    ListItemIcon,
    ListItemText, Menu,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification, setLoading} from "../../../../store/actions/app";
import {Parent, PARENT_RELATION, Patient, PATIENT_FILES, PATIENT_STATUS} from "../../../../models/patient";
import {
    downloadAttachedFile,
    formatFileNumber, formatRAMQNo, getPatient,
    savePatient,
    uploadAttachedFiles
} from '../../../../controllers/patients';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {getEnumValues} from "../../../../helpers/enums";
import {CIVIL_STATUS, ORGANIZATION_LINK} from "../../../../constants/enums";
import dayjs from "dayjs";
import {ROLES} from "../../../../constants/roles";
import {DataGrid, GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import Text from '../../../app/text/text';
import Meeting from "../../../../models/meeting";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Account} from "../../../../models/account";
import VisibilityIcon from '@mui/icons-material/Visibility';
import User from "../../../../models/user";
import {getUserFullName} from "../../../../controllers/user";
import {getMeetings, scheduleBestMeeting} from "../../../../controllers/meetings";
import AddMeetingDialog from "../../meetings/components/meetingDialog";
import EventIcon from '@mui/icons-material/Event';
import {File} from '../../../../models/file';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {validateEmail, validatePhone} from "../../../../helpers/string";
import {formatNumber} from "../../../../helpers/phone";
import ContactRequestReport from "../../../reports/contactRequest/contactRequest";
import PrintIcon from '@mui/icons-material/Print';
import {getContactRequest} from "../../../../controllers/contactRequests";
import {ContactRequest} from "../../../../models/contactRequest";
import {logException} from "../../../../controllers/system";
import AdditionalInfoDialog from "./additionalInfo";
import {Place} from "../../../../models/place";
import ParentDialog from "./parent";
import {getRelationText, isMinor} from "../../../../helpers/patient";
import ConsultationReasons from "./consultationReasons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PatientReport from "../../../reports/patient/patient";
import ReportViewer from "../../../reports/reportViewer";
import {dayjsOrAny} from "../../../../helpers/dayjs";
import * as signedDocumentController from '../../../../controllers/signedDocument';
import {SignedDocument, SignedDocumentAnswer} from "../../../../models/signedDocument";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

type IState = {
    readonly: boolean,
    openUploadMenu: boolean,
    patient: Patient | null,
    changed: boolean,
    formValidation: any,
    meetings: Meeting[],
    viewContactRequest?: ContactRequest,
    accordionSections: Array<number>
}

type IProps = {
    onSave: (patient: Patient) => void,
    professionals: User[],
    accounts: Account[],
    places: Place[],
    signedDocuments: SignedDocument[]
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat,
        userRole: state.user.role
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        },
        setLoading: (loading: boolean) => {
            dispatch(setLoading(loading));
        },
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class PatientDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        readonly: false,
        openUploadMenu: false,
        patient: null,
        changed: false,
        formValidation: {},
        meetings: [],
        accordionSections: [0, 0, 0]
    }

    private editDialogRef: RefObject<any>;
    private confirmDeleteDialogRef: RefObject<any>;
    private confirmReactivateDialogRef: RefObject<any>;
    private confirmCloseDialogRef: RefObject<any>;
    private confirmSendDocumentDialogRef: RefObject<any>;
    private meetingDialogRef: RefObject<any>;
    private medicationDialogRef: RefObject<any>;
    private consultationReasonDialogRef: RefObject<any>;
    private parent1DialogRef: RefObject<any>;
    private parent2DialogRef: RefObject<any>;
    private uploadButtonRef: RefObject<any>;
    private contactRequestReportRef: RefObject<any>;
    private patientReportRef: RefObject<any>;
    private indexedAccounts: any = {};
    private signedDocumentIdToSend: string | undefined = undefined;

    constructor(props: ReduxType) {
        super(props);
        this.editDialogRef = React.createRef();
        this.confirmDeleteDialogRef = React.createRef();
        this.confirmReactivateDialogRef = React.createRef();
        this.confirmCloseDialogRef = React.createRef();
        this.confirmSendDocumentDialogRef = React.createRef();
        this.meetingDialogRef = React.createRef();
        this.medicationDialogRef = React.createRef();
        this.consultationReasonDialogRef = React.createRef();
        this.parent1DialogRef = React.createRef();
        this.parent2DialogRef = React.createRef();
        this.uploadButtonRef = React.createRef();
        this.contactRequestReportRef = React.createRef();
        this.patientReportRef = React.createRef();
        this.state.readonly = props.userRole as ROLES === ROLES.Professional;
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog(patient: Patient, reloading: boolean = false) {
        this.indexAccounts();
        this.setState((state: IState) => {
            return {
                ...state,
                patient: Object.assign({}, patient),
                changed: false,
                formValidation: {},
                meetings: [],
                accordionSections: [0, 0, 0]
            };
        }, () => {
            this.editDialogRef.current.openDialog();
            if (!reloading) {
                this.reloadPatient();
            } else {
                this.loadMeetings();
            }
        });
    }

    closeDialogRequest(): boolean {
        if (this.state.changed) {
            this.confirmCloseDialogRef.current?.openDialog();
            return false
        } else {
            this.closeDialog();
            return true;
        }
    }

    closeDialog() {
        this.editDialogRef.current.closeDialog();
    }

    async reloadPatient() {
        if (!this.state.changed && this.state.patient && this.state.patient._id) {
            this.props.setLoading(true);
            let patient = await getPatient(this.state.patient._id);
            this.props.setLoading(false);
            if (patient) {
                this.openDialog(patient, true);
            }
        }
    }

    onPatientDetailButtonClick(key: string) {
        switch(key) {
            case 'medication':
                this.openMedicationDialog();
                break;
            case 'consultationReason':
                this.openConsultationReasongDialog();
                break;
            case 'deactivate':
                this.confirmDeleteDialogRef.current.openDialog();
                break;
            case 'reactivate':
                this.confirmReactivateDialogRef.current.openDialog();
                break;
            case 'save':
                this.savePatient();
                break;
            case 'print':
                this.printPatientReport();
                break;
        }
    }

    deactivatePatient() {
        this.setState((state: IState) => {
            let patient: Patient | null = state.patient;
            if (patient) patient.status = PATIENT_STATUS.INACTIVE

            return {
                ...state,
                patient
            };
        }, this.savePatient.bind(this, () => {
            this.closeDialog();
        }));
    }

    reactivatePatient() {
        this.setState((state: IState) => {
            let patient: Patient | null = state.patient;
            if (patient) patient.status = PATIENT_STATUS.ACTIVE

            return {
                ...state,
                patient
            };
        }, this.savePatient.bind(this, () => {
            this.closeDialog();
        }));
    }

    validatePatient(): boolean {
        let formValidation: any = {};

        if (this.state.patient?.status !== PATIENT_STATUS.INACTIVE) {
            if (!this.state.patient?.firstName || this.state.patient?.firstName?.trim().length === 0) {
                formValidation.firstName = 'error';
                formValidation.accordion_0_0 = 'error';
            }
            if (!this.state.patient?.lastName || this.state.patient?.lastName?.trim().length === 0) {
                formValidation.lastName = 'error';
                formValidation.accordion_0_0 = 'error';
            }
            if (!this.state.patient?.birthdate) {
                formValidation.birthdate = 'error';
                formValidation.accordion_0_0 = 'error';
            }
            if (!this.state.patient?.address || this.state.patient?.address?.trim().length === 0) {
                formValidation.address = 'error';
                formValidation.accordion_0_0 = 'error';
            }
            if (!this.state.patient?.postalCode || this.state.patient?.postalCode?.trim().length === 0) {
                formValidation.postalCode = 'error';
                formValidation.accordion_0_0 = 'error';
            }
            if (this.state.patient?.email && !validateEmail(this.state.patient?.email)) {
                formValidation.email = 'error';
                formValidation.accordion_0_1 = 'error';
            }
            if (this.state.patient?.phoneCell && !validatePhone(this.state.patient?.phoneCell)) {
                formValidation.phoneCell = 'error';
                formValidation.accordion_0_1 = 'error';
            }
            if (this.state.patient?.phoneHome && !validatePhone(this.state.patient?.phoneHome)) {
                formValidation.phoneHome = 'error';
                formValidation.accordion_0_1 = 'error';
            }
            if (this.state.patient?.phoneWork && !validatePhone(this.state.patient?.phoneWork)) {
                formValidation.phoneWork = 'error';
                formValidation.accordion_0_1 = 'error';
            }
            if (this.state.patient?.ramqNo && (this.state.patient?.ramqNo).replace(/ /g, '').length !== 0 && (this.state.patient?.ramqNo).replace(/ /g, '').length !== 12) {
                formValidation.ramqNo = 'error';
                formValidation.accordion_0_2 = 'error';
            }

            if (this.state.patient?.ramqExpirationMonth && (this.state.patient?.ramqExpirationMonth <= 0 || this.state.patient?.ramqExpirationMonth > 12)) {
                formValidation.ramqExpirationMonth = 'error';
                formValidation.accordion_0_2 = 'error';
            }

            if (this.state.patient?.ramqExpirationYear && (this.state.patient?.ramqExpirationYear <= 2000 || this.state.patient?.ramqExpirationYear > 2500)) {
                formValidation.ramqExpirationYear = 'error';
                formValidation.accordion_0_2 = 'error';
            }
        }

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return Object.keys(formValidation).length === 0;
    }

    savePatient(callback? : () => void) {
        if (this.validatePatient()) {
            savePatient(this.state.patient as Patient).then((patient: Patient | null) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        changed: false
                    };
                }, () => {
                    this.props.setAppNotification({
                        severity: 'success',
                        message: 'Successfully saved'
                    });
                    this.props.onSave(patient as Patient);
                    if (callback) callback();
                });
            }).catch(() => {
                this.props.setAppNotification({
                    severity: 'error',
                    message: 'An error occurred, please try again'
                });
            });
        }
    }

    onChange(property: string, value: any, change: boolean = true) {
        this.setState((state: IState) => {
            let patient: any = Object.assign({}, state.patient);
            patient[property] = value;

            return {
                ...state,
                patient,
                changed: state.changed || change
            }
        });
    }

    getMeetingsColumns(): (GridColDef | GridActionsColDef)[] {
        return [
            {
                field: 'date',
                headerName: localize('Date', this.props.lang),
                minWidth: 100,
                flex: 1,
                type: 'date',
                valueFormatter: (x: any) => x.value ? dayjs(x.value).format(this.props.dateFormat) : ''
            },
            {
                field: 'accountId',
                headerName: localize('Account', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params => {
                    if (!params.row.paidByPatient && params.row.accountId) {
                        for (let account of this.props.accounts) {
                            if (account._id === params.row.accountId) {
                                return account.name;
                            }
                        }
                        return "a";
                    } else {
                        return localize('On the patient\'s account', this.props.lang);
                    }
                })
            },
            {
                field: 'professionalUserId',
                headerName: localize('Professional', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params => {
                    for (let professional of this.props.professionals) {
                        if (professional._id === params.row.professionalUserId) {
                            return getUserFullName(professional);
                        }
                    }
                    return "";
                })
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 30,
                flex: 0.5,
                getActions: (params) => {
                    return ([
                        <GridActionsCellItem
                            icon={<VisibilityIcon />}
                            label={localize('View', this.props.lang)}
                            onClick={this.onMeetingSelected.bind(this, params.row as Meeting)}
                        />,
                        <GridActionsCellItem
                            icon={<PrintIcon />}
                            label={localize('Print contact request', this.props.lang)}
                            onClick={this.printContactRequest.bind(this, params.row.contactRequestId)}
                            disabled={!params.row.contactRequestId}
                        />
                    ]);
                }
            }
        ];
    }

    onMeetingSelected(meeting: Meeting) {
        this.meetingDialogRef.current.openDialog(this.state.patient, undefined, meeting);
    }

    loadMeetings() {
        if (this.state.patient?._id) {
            getMeetings({patientId: this.state.patient._id}, true, -1).then((meetings: Meeting[]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        meetings
                    };
                });
            });
        }
    }

    onMeetingChanged() {
        this.loadMeetings();
    }

    addMeeting() {
        if (this.state.patient && this.state.patient._id) {
            scheduleBestMeeting(this.state.patient).then((meeting: Meeting | null) => {
                this.meetingDialogRef.current.openDialog(this.state.patient, undefined, meeting);
            });
        }
    }

    async uploadFile(files: FileList | null, type: PATIENT_FILES) {
        if (files) {
            let file: File<PATIENT_FILES> | null = await uploadAttachedFiles(this.state.patient?._id as string, files, type);

            if (file) {
                this.setState((state: IState) => {
                    let patient: any = Object.assign({}, state.patient);
                    patient.attachedFiles = patient.attachedFiles || [];
                    patient.attachedFiles.push(file);
                    return {
                        ...state,
                        patient,
                        changed: true
                    };
                });
            }
        }

        this.setState((state: IState) => {
            return {
                ...state,
                openUploadMenu: false
            };
        });
    }

    deleteFile(fileIndex: number) {
        this.setState((state: IState) => {
            let patient: any = Object.assign({}, state.patient);
            patient.attachedFiles.splice(fileIndex, 1);
            return {
                ...state,
                changed: true,
                patient
            };
        })
    }

    downloadFile(fileName: string, filePath: string) {
        downloadAttachedFile(this.state.patient?._id as string, fileName, filePath);
    }

    openUploadMenu() {
        this.setState((state: IState) => {return{...state, openUploadMenu: true}});
    }

    closeUploadMenu() {
        this.setState((state: IState) => {return{...state, openUploadMenu: false}});
    }

    openMedicationDialog() {
        this.medicationDialogRef.current?.openDialog(this.state.patient);
    }

    openConsultationReasongDialog() {
        this.consultationReasonDialogRef.current?.openDialog(this.state.patient);
    }

    printContactRequest(contactRequestId: string) {
        getContactRequest(contactRequestId).then((contactRequest: ContactRequest | null) => {
            if (contactRequest) {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        viewContactRequest: contactRequest
                    };
                }, () => {
                    this.contactRequestReportRef.current?.run();
                });
            }
        })
    }

    isIVACCSST(): boolean {
        return (
            this.state.patient !== undefined
            && this.state.patient?.accountId !== undefined
            && this.indexedAccounts[this.state.patient?.accountId] !== undefined
            && (['IVAC', 'CNESST']).indexOf(this.indexedAccounts[this.state.patient?.accountId].name) > -1
        );
    }

    indexAccounts() {
        this.indexedAccounts = {};
        for (let account of this.props.accounts) if (account._id) this.indexedAccounts[account._id] = account;
    }

    onAccordionChange(section: number, accordion: number, isExpanded: boolean) {
        if (!isExpanded) accordion = -1;
        this.setState((state: IState) => {
            let accordionSections: Array<number> = state.accordionSections;
            accordionSections[section] = accordion;
            return {
                ...state,
                accordionSections
            };
        });
    }

    getActivePhoneNumber(): Array<string | undefined> {
        let phone: string | undefined = undefined;
        let ext: string | undefined = undefined;
        phone = this.state.patient?.phoneCell;
        if (!phone) phone = this.state.patient?.phoneHome;
        if (!phone) {
            phone = this.state.patient?.phoneWork;
            ext = this.state.patient?.phoneWorkExt;
        }
        return [`${formatNumber(phone || '')}${ext ? ` ext. ${ext}` : ''}`, phone, ext];
    }

    printPatientReport() {
        this.patientReportRef.current?.run();
    }

    confirmSendDocument(signedDocumentId: string | undefined) {
        if (!this.state.changed) {
            this.signedDocumentIdToSend = signedDocumentId;
            this.confirmSendDocumentDialogRef.current.openDialog();
        } else {
            this.props.setAppNotification({severity: 'warning', message: 'You must save your change before sending a document'});
        }
    }

    async sendDocument() {
        if (this.state.patient && this.state.patient._id && this.signedDocumentIdToSend) {
            let success: boolean = await signedDocumentController.send(this.state.patient._id, this.signedDocumentIdToSend);
            this.signedDocumentIdToSend = undefined;
            if (success) {
                this.props.setAppNotification({severity: 'success', message: 'Document sent successfully'});
                await this.reloadPatient();
            } else {
                this.props.setAppNotification({
                    severity: 'error',
                    message: 'An error occured while sending message. Please try again later.'
                });
            }
        }
    }

    getDocumentTitle(documentId: string): string {
        return this.props.signedDocuments.filter((signedDocument: SignedDocument) => {return signedDocument._id as string === documentId})[0].title || '';
    }

    render() {
        return (
            <FullScreenDialog
                ref={this.editDialogRef}
                title={`${localize('Patient', this.props.lang)} ${this.state.patient?.fileNumber ? ` - ${formatFileNumber(this.state.patient?.fileNumber)}` : ''}`}
                onButtonClick={this.onPatientDetailButtonClick.bind(this)}
                onCloseRequest={this.closeDialogRequest.bind(this)}
                buttons={[
                    this.state.patient?._id ? {key: 'print', text: localize('Print', this.props.lang), color: 'info', keepDialogOpen: true, disabled: this.state.changed} : null,
                    this.state.patient?._id ? {key: 'medication', text: localize('Medication', this.props.lang), color: 'info', keepDialogOpen: true, disabled: this.state.readonly || this.state.changed} : null,
                    this.state.patient?._id ? {key: 'consultationReason', text: localize('Consultation reason', this.props.lang), color: 'info', keepDialogOpen: true, disabled: this.state.readonly || this.state.changed} : null,
                    this.state.patient?._id && this.state.patient?.status === PATIENT_STATUS.ACTIVE ? {key: 'deactivate', text: localize('Deactivate', this.props.lang), color: 'error', keepDialogOpen: true, disabled: this.state.readonly || this.state.changed} : null,
                    this.state.patient?._id && this.state.patient?.status === PATIENT_STATUS.INACTIVE ? {key: 'reactivate', text: localize('Reactivate', this.props.lang), color: 'warning', keepDialogOpen: true, disabled: this.state.readonly || this.state.changed} : null,
                    {key: 'save', text: localize('Save', this.props.lang), color: 'success', keepDialogOpen: true, disabled: !this.state.changed}
                ]}
            >
                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', height: '90%;'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Accordion expanded={this.state.accordionSections[0] === 0} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(0, 0, isExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        backgroundColor: this.state.formValidation.accordion_0_0 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <Text>Informations</Text>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{this.state.patient?.firstName || ''} {this.state.patient?.lastName || ''}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item sx={{p: 2}} xs={12} md={6}>
                                            <TextField
                                                required
                                                error={this.state.formValidation.firstName === 'error'}
                                                id={'firstName'}
                                                sx={{width: '100%'}}
                                                label={localize('First name', this.props.lang)}
                                                defaultValue={this.state.patient?.firstName}
                                                onChange={((e: any) => {this.onChange('firstName', e.target.value)})}
                                                InputProps={{
                                                    readOnly: this.state.readonly,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12} md={6}>
                                            <TextField
                                                required
                                                error={this.state.formValidation.lastName === 'error'}
                                                id={'lastName'}
                                                sx={{width: '100%'}}
                                                label={localize('Last name', this.props.lang)}
                                                defaultValue={this.state.patient?.lastName}
                                                onChange={((e: any) => {this.onChange('lastName', e.target.value)})}
                                                InputProps={{
                                                    readOnly: this.state.readonly,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12} md={6}>
                                            <DatePicker
                                                sx={{width: '100%'}}
                                                label={localize('Birthdate', this.props.lang)}
                                                value={dayjsOrAny(this.state.patient?.birthdate)}
                                                format={this.props.dateFormat}
                                                readOnly={this.state.readonly}
                                                onChange={((newValue: any) => {
                                                    this.onChange('birthdate', dayjs(newValue).toDate());
                                                })}
                                                slotProps={{textField: { required: true, error: this.state.formValidation.birthdate === 'error'}}}
                                            />
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12} md={6}>
                                            <TextField
                                                id="civilStatus"
                                                sx={{width: '100%'}}
                                                select
                                                label={localize('Civil status', this.props.lang)}
                                                value={this.state.patient?.civilStatus || ''}
                                                onChange={((e: any) => {this.onChange('civilStatus', e.target.value)})}
                                                InputProps={{
                                                    readOnly: this.state.readonly,
                                                }}
                                            >
                                                {getEnumValues(CIVIL_STATUS).map((value: number) => (
                                                    <MenuItem key={value} value={value}>
                                                        {localize(CIVIL_STATUS[value], this.props.lang)}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <TextField
                                                required
                                                error={this.state.formValidation.address === 'error'}
                                                id={'address'}
                                                sx={{width: '100%'}}
                                                label={localize('Address', this.props.lang)}
                                                defaultValue={this.state.patient?.address}
                                                onChange={((e: any) => {this.onChange('address', e.target.value)})}
                                                InputProps={{
                                                    readOnly: this.state.readonly,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12} md={6}>
                                            <TextField
                                                required
                                                error={this.state.formValidation.postalCode === 'error'}
                                                id={'postalCode'}
                                                sx={{width: '100%'}}
                                                label={localize('Postal code', this.props.lang)}
                                                defaultValue={this.state.patient?.postalCode}
                                                onChange={((e: any) => {this.onChange('postalCode', e.target.value)})}
                                                InputProps={{
                                                    readOnly: this.state.readonly,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.accordionSections[0] === 1} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(0, 1, isExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        backgroundColor: this.state.formValidation.accordion_0_1 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <Text>Contact</Text>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{this.getActivePhoneNumber()[0]}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <TextField
                                                error={this.state.formValidation.email === 'error'}
                                                id={'email'}
                                                sx={{width: '100%'}}
                                                label={localize('Email', this.props.lang)}
                                                defaultValue={this.state.patient?.email}
                                                onChange={((e: any) => {this.onChange('email', e.target.value)})}
                                                InputProps={{
                                                    readOnly: this.state.readonly,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <TextField
                                                id={'phoneCell'}
                                                error={this.state.formValidation.phoneCell === 'error'}
                                                sx={{width: '100%'}}
                                                label={localize('Cell Phone', this.props.lang)}
                                                defaultValue={(this.state.patient?.phoneCell ? formatNumber(this.state.patient?.phoneCell) : undefined)}
                                                onChange={((e: any) => {this.onChange('phoneCell', e.target.value)})}
                                                InputProps={{
                                                    readOnly: this.state.readonly,
                                                }} />
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <Box sx={{display: 'flex', width: '100%'}}>
                                                <Box sx={{width: '80%', pr: 3}}>
                                                    <TextField
                                                        id={'phoneWork'}
                                                        error={this.state.formValidation.phoneWork === 'error'}
                                                        sx={{width: '100%'}}
                                                        label={localize('Phone at work', this.props.lang)}
                                                        defaultValue={(this.state.patient?.phoneWork ? formatNumber(this.state.patient?.phoneWork) : undefined)}
                                                        onChange={((e: any) => {this.onChange('phoneWork', e.target.value)})}
                                                        InputProps={{
                                                            readOnly: this.state.readonly,
                                                        }} />
                                                </Box>
                                                <Box sx={{width: '20%'}}>
                                                    <TextField
                                                        id={'phoneWorkExt'}
                                                        sx={{width: '100%'}}
                                                        label={localize('Ext.', this.props.lang)}
                                                        defaultValue={(this.state.patient?.phoneWorkExt ? this.state.patient?.phoneWorkExt : undefined)}
                                                        onChange={((e: any) => {this.onChange('phoneWorkExt', e.target.value)})}
                                                        InputProps={{
                                                            readOnly: this.state.readonly,
                                                        }} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <TextField
                                                id={'phoneHome'}
                                                error={this.state.formValidation.phoneHome === 'error'}
                                                sx={{width: '100%'}}
                                                label={localize('Phone at home', this.props.lang)}
                                                defaultValue={(this.state.patient?.phoneHome ? formatNumber(this.state.patient?.phoneHome) : undefined)}
                                                onChange={((e: any) => {this.onChange('phoneHome', e.target.value)})}
                                                InputProps={{
                                                    readOnly: this.state.readonly,
                                                }} />
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12} md={6}>
                                            <Button
                                                sx={{width: '100%'}}
                                                variant={'contained'}
                                                color={isMinor(this.state.patient?.birthdate) ? 'warning' : 'info'}
                                                onClick={() => {
                                                    this.parent1DialogRef.current?.openDialog();
                                                }}
                                            >
                                                {
                                                    this.state.patient?.parent1?.relation ?
                                                        getRelationText(this.state.patient?.parent1?.relation as PARENT_RELATION, this.props.lang)
                                                        : <Text>Parent 1</Text>
                                                }
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12} md={6}>
                                            <Button
                                                sx={{width: '100%'}}
                                                variant={'contained'}
                                                color={isMinor(this.state.patient?.birthdate) ? 'warning' : 'info'}
                                                onClick={() => {
                                                    this.parent2DialogRef.current?.openDialog();
                                                }}
                                            >
                                                {
                                                    this.state.patient?.parent2?.relation ?
                                                        getRelationText(this.state.patient?.parent2?.relation as PARENT_RELATION, this.props.lang)
                                                        : <Text>Parent 2</Text>
                                                }
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{pl: 2, pr: 2}} xs={12} md={6}>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <Box>
                                                    <Checkbox
                                                        checked={this.state.patient?.notificationEmail}
                                                        onChange={(e) => {this.onChange('notificationEmail', e.target.checked)}}
                                                        disabled={!this.state.patient?.email}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Typography><Text>Email notifications</Text></Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item sx={{pl: 2, pr: 2}} xs={12} md={6}>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <Box>
                                                    <Checkbox
                                                        checked={this.state.patient?.notificationSMS}
                                                        onChange={(e) => {this.onChange('notificationSMS', e.target.checked)}}
                                                        disabled={!this.state.patient?.phoneCell}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Typography><Text>SMS notifications</Text></Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.accordionSections[0] === 2} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(0, 2, isExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        backgroundColor: this.state.formValidation.accordion_0_2 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <Text>RAMQ</Text>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{formatRAMQNo(this.state.patient?.ramqNo)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} sx={{pr: 2}}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id={'ramqNo'}
                                                label={localize('RAMQ Number', this.props.lang)}
                                                defaultValue={this.state.patient?.ramqNo}
                                                onBlur={((e: any) => {this.onChange('ramqNo', e.target.value)})}
                                                error={this.state.formValidation.ramqNo === 'error'}
                                                sx={{width: '100%'}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id={'ramqExpireMonth'}
                                                label={localize('Expir. month', this.props.lang)}
                                                defaultValue={this.state.patient?.ramqExpirationMonth}
                                                onBlur={((e: any) => {this.onChange('ramqExpirationMonth', e.target.value)})}
                                                type={"number"}
                                                error={this.state.formValidation.ramqExpirationMonth === 'error'}
                                                sx={{width: '100%'}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id={'ramqExpirationYear'}
                                                label={localize('Expir. year', this.props.lang)}
                                                defaultValue={this.state.patient?.ramqExpirationYear}
                                                onBlur={((e: any) => {this.onChange('ramqExpirationYear', e.target.value)})}
                                                type={"number"}
                                                error={this.state.formValidation.ramqExpirationYear === 'error'}
                                                sx={{width: '100%'}}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Accordion expanded={this.state.accordionSections[1] === 0} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(1, 0, isExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        backgroundColor: this.state.formValidation.accordion_1_0 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <Text>Organization</Text>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <TextField
                                                id="organization"
                                                sx={{width: '100%'}}
                                                select
                                                label={localize('Organization', this.props.lang)}
                                                value={this.state.patient?.accountId || ''}
                                                onChange={((e: any) => {this.onChange('accountId', e.target.value)})}
                                            >
                                                {this.props.accounts.map((acc: Account) => (
                                                    <MenuItem key={acc._id} value={acc._id}>
                                                        {acc.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <TextField
                                                id="organizationLink"
                                                sx={{width: '100%'}}
                                                select
                                                label={localize('Organization link', this.props.lang)}
                                                value={this.state.patient?.organizationLink || ''}
                                                onChange={((e: any) => {this.onChange('organizationLink', e.target.value)})}
                                                error={this.state.formValidation.organizationLink === 'error'}
                                            >
                                                {getEnumValues(ORGANIZATION_LINK).map((value: number) => (
                                                    <MenuItem key={value} value={value}>
                                                        {localize(ORGANIZATION_LINK[value], this.props.lang)}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.accordionSections[1] === 1} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(1, 1, isExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        backgroundColor: this.state.formValidation.accordion_1_1 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <Text>IVAC/CSST</Text>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <TextField
                                                id={'fileNumberIVACCSST'}
                                                sx={{
                                                    width: '100%',
                                                    '&.warning .MuiOutlinedInput-root fieldset': {borderColor: '#ffa726'},
                                                    '&.warning label': {color: '#ffa726'},
                                                }}
                                                className={this.isIVACCSST() ? 'warning' : undefined}
                                                color={this.isIVACCSST() ? 'warning' : undefined}
                                                label={localize('File number IVAC/CSST', this.props.lang)}
                                                defaultValue={this.state.patient?.fileNumberIVACCSST}
                                                onBlur={((e: any) => {this.onChange('fileNumberIVACCSST', e.target.value)})}
                                            />
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <DatePicker
                                                sx={{
                                                    width: '100%',
                                                    '&.warning .MuiOutlinedInput-root fieldset': {borderColor: '#ffa726'},
                                                    '&.warning label': {color: '#ffa726'},
                                                }}
                                                label={localize('Event date', this.props.lang)}
                                                value={dayjsOrAny(this.state.patient?.eventDate)}
                                                format={this.props.dateFormat}
                                                onChange={((newValue: any) => {this.onChange('eventDate', newValue)})}
                                                slotProps={{textField: { className: this.isIVACCSST() ? 'warning' : undefined, color: this.isIVACCSST() ? 'warning' : undefined}}}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.accordionSections[1] === 2} onChange={(event: React.SyntheticEvent, isExpanded: boolean) => this.onAccordionChange(1, 2, isExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        backgroundColor: this.state.formValidation.accordion_1_2 === 'error' ? (theme) => `error.${theme.palette.mode}` : undefined
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <Text>Signed Documents</Text>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <Box>
                                                <Text>Send documents</Text>
                                            </Box>
                                            <Divider />
                                            <Box sx={{p: 1}}>
                                                {
                                                    this.props.signedDocuments.length > 0 ?
                                                        this.props.signedDocuments.map((signedDocument: SignedDocument) => {
                                                            if (!signedDocument.enabled) return null;
                                                            return (
                                                                <Box key={signedDocument._id} sx={{p:1, display: 'inline-block'}}>
                                                                    <Button
                                                                        key={signedDocument.title}
                                                                        variant={'contained'}
                                                                        color={'primary'}
                                                                        onClick={this.confirmSendDocument.bind(this, signedDocument._id)}
                                                                        disabled={!this.state.patient?._id}
                                                                    >
                                                                        {signedDocument.title}
                                                                    </Button>
                                                                </Box>
                                                            );
                                                        })
                                                        :
                                                        <Text>No signed document configured</Text>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item sx={{p: 2}} xs={12}>
                                            <Box>
                                                <Text>Received answers</Text>
                                            </Box>
                                            <Divider />
                                            <Box sx={{p: 1}}>
                                                {
                                                    this.state.patient?.signedDocumentAnswers && this.state.patient?.signedDocumentAnswers.length > 0 ?
                                                        this.state.patient?.signedDocumentAnswers
                                                            .sort((a1, a2) => {
                                                                return dayjs(a1.answerDate).isBefore(a2.answerDate) ? -1
                                                                    : dayjs(a2.answerDate).isBefore(a1.answerDate) ? 1
                                                                        : 0;
                                                            })
                                                            .map((answer: SignedDocumentAnswer, index) => {
                                                            return <Box key={index} sx={{display: 'flex'}}>
                                                                <Box sx={{width: '40%', overflow: 'none', textAlign: 'left'}}>
                                                                    {this.getDocumentTitle(answer.signedDocumentId as string)}
                                                                </Box>
                                                                <Box sx={{width: '50%', overflow: 'none', textAlign: 'left'}}>
                                                                    {dayjs(answer.answerDate || answer.sentOn).format(`${this.props.dateFormat} HH:mm`)}
                                                                </Box>
                                                                <Box sx={{width: '10%', overflow: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                    {
                                                                        !answer.answerDate ?
                                                                            <ScheduleSendIcon color={'primary'} />
                                                                            :
                                                                            answer.answer ?
                                                                                <CheckBoxIcon color={'success'} />
                                                                                :
                                                                                <DisabledByDefaultIcon color={'error'} />
                                                                    }
                                                                </Box>
                                                            </Box>;
                                                        })
                                                    :
                                                    <Text>No answer received</Text>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        id="professionalUserId"
                                        sx={{width: '100%'}}
                                        select
                                        label={localize('Professional', this.props.lang)}
                                        value={this.state.patient?.professionalUserId || ''}
                                        onChange={((e: any) => {this.onChange('professionalUserId', e.target.value)})}
                                    >
                                        {this.props.professionals.map((professional: User) => {
                                            if (professional.settings?.canBeScheduled !== true && this.state.patient?.professionalUserId !== professional._id) return null;
                                            return (
                                                <MenuItem key={professional._id} value={professional._id}>
                                                    {getUserFullName(professional)}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>
                                <Grid item sx={{pt: 2}} xs={12}>
                                    <TextField
                                        id={'personalNote'}
                                        sx={{width: '100%'}}
                                        multiline
                                        rows={2}
                                        label={localize('Personal note', this.props.lang)}
                                        defaultValue={this.state.patient?.personalNote}
                                        onBlur={((e: any) => {this.onChange('personalNote', e.target.value)})} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{p: 2}}>
                        <hr style={{width: '100%'}}/>
                    </Box>
                    <Grid sx={{flexGrow: 1}} container>
                        <Grid item xs={12} md={8} sx={{height: '100%'}}>
                            <Box sx={{p:2, height: '100%', minHeight: '250px'}}>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>
                                        <Typography><Text>Previous meetings</Text></Typography>
                                    </Box>
                                    <Box hidden={!this.state.patient || !this.state.patient._id}>
                                        <IconButton color={'info'} onClick={this.addMeeting.bind(this)}>
                                            <EventIcon />
                                        </IconButton>
                                    </Box>
                                </Box>

                                <DataGrid
                                    columns={this.getMeetingsColumns()}
                                    rows={this.state.meetings || []}
                                    getRowId={(row: any) => {return row._id}}
                                    localeText={getMUILocale(this.props.lang).components.MuiDataGrid.defaultProps.localeText}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} hidden={!this.state.patient || !this.state.patient._id}>
                            <Box sx={{p: 2}}>
                                <Box sx={{display: 'flex'}}>
                                    <Box sx={{flexGrow: 1}}>
                                        <Typography><Text>Attached files</Text></Typography>
                                    </Box>
                                    <Box sx={{pl: 3}}>
                                        <Button variant={'contained'} ref={this.uploadButtonRef} onClick={this.openUploadMenu.bind(this)}>
                                            <Text>Upload</Text>
                                        </Button>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={this.uploadButtonRef.current}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={this.state.openUploadMenu}
                                            onClose={this.closeUploadMenu.bind(this)}
                                        >
                                            {getEnumValues(PATIENT_FILES).map((value: number, index: number) => (
                                                <MenuItem component={'label'} key={index}>
                                                    <ListItemIcon>
                                                        <UploadFileIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Text>{PATIENT_FILES[value]}</Text>
                                                    </ListItemText>
                                                    <input
                                                        type="file"
                                                        hidden
                                                        onChange={(e) => {this.uploadFile(e.target.files, value as PATIENT_FILES)}}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{p: 2}}>
                                {this.state.patient && this.state.patient._id && Array.isArray(this.state.patient.attachedFiles) && this.state.patient.attachedFiles.length > 0 ?
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        {this.state.patient.attachedFiles.map((attachedFile: File<PATIENT_FILES>, fileIndex: number) => {
                                                return (
                                                    <Box sx={{display: 'flex', width: '100%', alignItems: 'center'}} key={fileIndex}>
                                                        <Box sx={{p: 1, width: '25%'}}>
                                                            <Text>{PATIENT_FILES[attachedFile.type as number]}</Text>
                                                        </Box>
                                                        <Box sx={{p: 1, width: '25%'}}>
                                                            {dayjs(attachedFile.createdOn).format(this.props.dateFormat)}
                                                        </Box>
                                                        <Box sx={{p: 1, width: '40%', flexGrow: 1}}>
                                                            <Link onClick={this.downloadFile.bind(this, attachedFile.filename as string, attachedFile.filepath as string)} href={'#'}>
                                                                {attachedFile.filename}
                                                            </Link>
                                                        </Box>
                                                        <Box sx={{p: 1, width: '10%'}}>
                                                            <IconButton onClick={this.deleteFile.bind(this, fileIndex)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                    </Box>
                                    :
                                    <Typography sx={{fontStyle: 'italic'}}><Text>No files attached</Text></Typography>
                                }
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
                <ConfirmDialog
                    ref={this.confirmDeleteDialogRef}
                    title={localize('Deactivate this patient?', this.props.lang)}
                    text={localize('Are you sure you want to deactivate this patient?', this.props.lang)}
                    onAgree={this.deactivatePatient.bind(this)}
                />
                <ConfirmDialog
                    ref={this.confirmReactivateDialogRef}
                    title={localize('Reactivate this patient?', this.props.lang)}
                    text={localize('Are you sure you want to reactivate this patient?', this.props.lang)}
                    onAgree={this.reactivatePatient.bind(this)}
                />
                <ConfirmDialog
                    ref={this.confirmCloseDialogRef}
                    title={localize('Unsaved changes', this.props.lang)}
                    text={localize('All unsaved changes will be lost. Do you want to continue?', this.props.lang)}
                    onAgree={this.closeDialog.bind(this)}
                />
                <ConfirmDialog
                    ref={this.confirmSendDocumentDialogRef}
                    title={localize('Send document', this.props.lang)}
                    text={localize('Are you sure you want to send this document?', this.props.lang)}
                    onAgree={this.sendDocument.bind(this)}
                />
                <AddMeetingDialog
                    ref={this.meetingDialogRef}
                    professionals={this.props.professionals}
                    accounts={this.props.accounts}
                    places={this.props.places}
                    signedDocuments={this.props.signedDocuments}
                    onMeetingChanged={this.onMeetingChanged.bind(this)}
                />
                <AdditionalInfoDialog
                    ref={this.medicationDialogRef}
                    patient={this.state.patient as Patient}
                    infoLabel={localize('Medication', this.props.lang)}
                    infoAttr={'medication'}
                    onSave={(patient: Patient) => {
                            this.onChange('medication', patient.medication);
                        }
                    }
                    saveChanges={false}
                />
                <ConsultationReasons
                    ref={this.consultationReasonDialogRef}
                    patient={this.state.patient as Patient}
                    onSave={(patient: Patient) => {
                            this.onChange('consultationReasons', patient.consultationReasons);
                        }
                    }
                    saveChanges={false}
                />
                <ParentDialog
                    ref={this.parent1DialogRef}
                    readonly={this.state.readonly}
                    parent={this.state.patient?.parent1}
                    title={localize('Parent 1', this.props.lang)}
                    onSave={(parent: Parent) => {
                        this.onChange('parent1', parent)
                    }}
                />
                <ParentDialog
                    ref={this.parent2DialogRef}
                    readonly={this.state.readonly}
                    parent={this.state.patient?.parent2}
                    title={localize('Parent 2', this.props.lang)}
                    onSave={(parent: Parent) => {
                        this.onChange('parent2', parent)
                    }}
                />

                <ReportViewer ref={this.contactRequestReportRef} title={""} users={this.props.professionals}>
                    <ContactRequestReport
                        contactRequest={this.state.viewContactRequest}
                        accounts={this.props.accounts}
                        lang={this.props.lang}
                        dateFormat={this.props.dateFormat}
                    />
                </ReportViewer>
                <ReportViewer ref={this.patientReportRef} title={""} users={this.props.professionals}>
                    <PatientReport
                        patient={this.state.patient}
                        accounts={this.props.accounts}
                        professionnals={this.props.professionals}
                        lang={this.props.lang}
                        dateFormat={this.props.dateFormat}
                    />
                </ReportViewer>
            </FullScreenDialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PatientDialog);
