import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Button, IconButton, TextField} from "@mui/material";
import {localize} from "../../../../helpers/localization";
import Text from '../../../app/text/text';
import {logException} from "../../../../controllers/system";
import {getPlaces, removePlace, savePlaces} from "../../../../controllers/place";
import {Place} from "../../../../models/place";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

type IState = {
    places: Place[]
}

type IProps = {
    visible: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Places extends React.Component<ReduxType, IState> {
    public state: IState = {
        places: []
    }

    private saveTimeout: number | null = null;
    private saving: boolean = false;

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadPlaces();
    }

    loadPlaces() {
        getPlaces().then(( places: Place[]) => {
            this.setPlaces(places);
        });
    }

    refreshPlaces() {
        this.setState((state: IState) => {
            return {
                ...state,
                places: []
            };
        }, () => {
            this.loadPlaces();
        });
    }

    setPlaces(places: Place[]) {
        this.setState((state: IState) => {
            return {
                ...state,
                places
            };
        });
    }

    savePlaces() {
        if (!this.saving) {
            this.saving = true;
            savePlaces(this.state.places).then((places: Place[]) => {
                this.saving = false;
                this.setPlaces(places);
            });
        } else {
            if (this.saveTimeout) {
                window.clearTimeout(this.saveTimeout);
                this.saveTimeout = null;
            }
            this.saveTimeout = window.setTimeout(this.savePlaces.bind(this), 500);
        }
    }

    addPlace() {
        this.setState((state: IState) => {
            let places: Place[] = state.places;
            places.push(new Place());
            return {...state, places};
        });
    }

    onChange(property: string, index: number, value: string) {
        this.setState((state: IState) => {
            let place: any = {...state.places[index]};
            place[property] = value;
            state.places[index] = place;
            return {
                ...state
            };
        }, () => {
            this.savePlaces();
        });
    }

    removePlace(index: number) {
        removePlace(this.state.places[index]._id as string).then((result: boolean) => {
            this.refreshPlaces();
        });
    }

    render() {
        if (!this.props.visible) return null;
        return (
            <React.Fragment>
                <Box sx={{display: 'flex', flexDirection: 'column', p:2, height: '100%'}}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', pb: 1}}>
                        <Button color={'primary'} variant={'contained'} onClick={this.addPlace.bind(this)}><Text>Add</Text></Button>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        {
                            this.state.places.map((place: Place, index: number) => {
                                return (
                                    <Box sx={{pb: 3, display: 'flex'}} key={index}>
                                        <Box sx={{pr: 3, flexGrow: 1}}>
                                            <TextField
                                                id={`place${index}`}
                                                sx={{width: '100%'}}
                                                label={localize('Place name', this.props.lang)}
                                                defaultValue={place.name}
                                                onChange={((e: any) => {this.onChange('name', index, e.target.value)})}
                                            />
                                        </Box>
                                        <Box>
                                            <IconButton color={'error'} onClick={this.removePlace.bind(this, index)}>
                                                <IndeterminateCheckBoxIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                );
                            })
                        }
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Places);
