class MissedRate{
    public _id?: string = undefined;
    public from?: Date = undefined;
    public rate?: number = undefined;


    public constructor(init?:Partial<MissedRate>) {
        Object.assign(this, init);
    }
}

export {MissedRate};
