import {Patient} from "../models/patient";
import Meeting, {MEETING_STATUS, MeetingWithPatient} from "../models/meeting";
import {AxiosResponse} from "axios";
import {get, post} from "../helpers/ajax";
import FollowUp from "../models/followUp";
import {SortDirection} from "../helpers/db";
import {FollowUpHistory} from "../models/followUpHistory";
import dayjs from "dayjs";

export async function scheduleBestMeeting(patient: Patient): Promise<Meeting | null> {
    try {
        let response: AxiosResponse<Meeting> = await post<Meeting>('/meeting/schedule-best-meeting', {patientId: patient._id});

        if (response.status === 200 && response.data) {
            return response.data;
        } else {
            return null;
        }
    } catch (ex) {
        return null;
    }
}

export interface MeetingCreationResult {
    success: boolean,
    confirmationNeeded?: string,
    error?: string,
    meeting?: Meeting
}

export async function saveMeeting(meeting: Meeting): Promise<MeetingCreationResult | null> {
    let response: AxiosResponse<MeetingCreationResult> = await post<MeetingCreationResult>('/meeting/save', meeting);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export async function setMeetingStatus(meetingId: string, status: MEETING_STATUS): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>('/meeting/set-status', {meetingId, status});

    if (response.status === 200 && response.data)
        return response.data;
    else
        return false;
}

export async function saveFollowUp(meetingId: string, followUp: FollowUp): Promise<boolean | null> {
    let response: AxiosResponse<boolean> = await post<boolean>(`/meeting/followUp/${meetingId}`, followUp);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export async function saveFollowUpHistory(meetingId: string, note: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>(`/meeting/followUpHistory/${meetingId}`, {note});

    if (response.status === 200 && response.data)
        return response.data;
    else
        return false;
}

export async function getFollowUpHistory(meetingId: string): Promise<FollowUpHistory[]> {
    let response: AxiosResponse<FollowUpHistory[]> = await get<FollowUpHistory[]>(`/meeting/followUpHistory/${meetingId}`);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return [];
}

export async function decryptNote(note: string): Promise<string> {
    let response: AxiosResponse<string> = await post<string>(`/meeting/decrypt-note`, {note});

    if (response.status === 200 && response.data)
        return response.data;
    else
        return '';
}

export interface IMeetingFilter {
    _id?: string | undefined,
    dateFrom?: Date | undefined,
    dateTo?: Date | undefined,
    userIds?: string[] | undefined,
    patientId?: string | undefined,
    accountId?: string | undefined,
    meetingStatus?: MEETING_STATUS[] | undefined,
    paidByPatient?: boolean | undefined,
    paid?: boolean | undefined,
    excludeAbsence?: boolean | undefined,
}

export async function getMeeting(meetingId: string): Promise<MeetingWithPatient | null> {
    let meetings: MeetingWithPatient[] = await getMeetings({_id: meetingId})
    if (meetings.length === 1) return meetings[0];
    return null;
}

export async function getMeetings(filter: IMeetingFilter = {}, decryptNotes: boolean = true, sort: SortDirection | undefined = undefined): Promise<MeetingWithPatient[]> {
    let response: AxiosResponse<MeetingWithPatient[]> = await post<MeetingWithPatient[]>('/meeting/find', {filter, sort, decryptNotes});

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export type MeetingSummary = {
    coming: number,
    missingFollowUp: number,
    done: number
}
export async function getSummary(userId?: string): Promise<MeetingSummary | null> {
    let response: AxiosResponse<MeetingSummary> = await post<MeetingSummary>('/meeting/summary', {userId});

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export function getMeetingDuration(meeting: Meeting): number {
    if (meeting.followUp?.duration) {
        return meeting.followUp?.duration || 0;
    } else {
        if (meeting.timeFrom && meeting.timeTo && dayjs(meeting.timeFrom).isValid() && dayjs(meeting.timeTo).isValid()) {
            return Math.abs(dayjs(meeting.timeTo).diff(dayjs(meeting.timeFrom), 'seconds'));
        } else {
            return 0;
        }
    }
}

export function getMeetingCount(duration: number): number {
    duration = duration / 60 / 60;
    let hours = parseInt(`${duration}`);
    let minutes = duration - hours;

    if (minutes > 0.05) {
        if (minutes <= 0.55) minutes = 0.5;
        else minutes = 1;
    } else {
        minutes = 0;
    }
    return hours + minutes;
}

export type MeetingHoursSummary = {
    day: number,
    currentPeriodDays: number,
    currentPeriodAcc: number,
    plannedPeriodDays: number,
    plannedPeriodAcc: number,
    lastPeriodDays: number,
    lastPeriodAcc: number
}
export async function getMeetingHoursSummary(period: 'week' | 'month'): Promise<Array<MeetingHoursSummary>> {
    let response: AxiosResponse<Array<MeetingHoursSummary>> = await post<Array<MeetingHoursSummary>>('/meeting/hours-summary', {period});

    if (response.status === 200 && response.data)
        return response.data;
    else
        return [];
}

type ConfirmPresenceResponse = {
    success: boolean
};
export async function checkWelcomeCode(welcomeCode: number): Promise<boolean> {
    let response: AxiosResponse<ConfirmPresenceResponse> = await get<ConfirmPresenceResponse>( `/external/meeting/confirm-presence/${welcomeCode}`);

    if (response?.status === 200 && response?.data.success)
        return response.data.success;
    else
        return false;
}
