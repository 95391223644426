import React from 'react';
import {connect} from 'react-redux';
import {
    Box,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Dialog,
    Typography
} from '@mui/material';
import {AppDispatch, RootState} from "../../../../store/store";
import {localize} from "../../../../helpers/localization";
import Text from "../../../app/text/text";
import {PasswordValidation, validatePassword} from "../../../../helpers/string";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {IAppNotification} from "../../../../models/appNotification";
import {changePassword} from "../../../../controllers/auth";
import {setAppNotification} from "../../../../store/actions/app";
import {setPasswordChanged} from "../../../../store/actions/user";
import {logException} from "../../../../controllers/system";

type IProps = {
    canCancel?: boolean,
    onClose?: () => void,
    open: boolean
}

type IState = {
    form: any,
    valid: boolean,
    passwordValidation: PasswordValidation,
    passwordMatch: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        user: state.user
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification) => {
            dispatch(setAppNotification(appNotification));
        },
        setPasswordChanged: () => {
            dispatch(setPasswordChanged());
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class ChangePassword extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        form: {
            newPassword: "",
            confirmPassword: ""
        },
        valid: false,
        passwordValidation: {
            length: false,
            lowerChar: false,
            upperChar: false,
            numericChar: false
        },
        passwordMatch: false
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    onChange(property: string, value: string) {
        this.setState((state: IState) => {
            let form: any = Object.assign({}, state.form);
            form[property] = value;
            let passwordValidation: PasswordValidation = validatePassword(form.newPassword);
            let passwordMatch: boolean = form.newPassword !== "" && form.newPassword === form.confirmPassword;
            let valid: boolean = passwordMatch
                && passwordValidation.length
                && passwordValidation.lowerChar
                && passwordValidation.upperChar
                && passwordValidation.numericChar
                && form.oldPassword;

            return {
                ...state,
                form,
                passwordValidation,
                passwordMatch,
                valid
            };
        });
    }

    changePassword() {
        changePassword(this.props.user._id, this.state.form.oldPassword, this.state.form.newPassword).then((success: boolean) => {
            if (success) {
                this.props.setAppNotification({severity: 'success', message: 'Password changed successfully'});
                this.props.setPasswordChanged();
                if (this.props.onClose) this.props.onClose();
            } else {
                this.props.setAppNotification({severity: 'error', message: 'An error occured. Please check your old password'});
            }
        });
    }

    render() {
        return (
            <Dialog open={this.props.open}>
                <DialogTitle><Text>Change password required</Text></DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{p: 2}}>
                            <TextField
                                sx={{width: '100%'}}
                                label={localize('Old password', this.props.lang)}
                                type={'password'}
                                onChange={(e) => {this.onChange('oldPassword', e.target.value)}}/>
                        </Box>
                        <Box sx={{p: 2}}>
                            <TextField
                                sx={{width: '100%'}}
                                label={localize('New password', this.props.lang)}
                                type={'password'}
                                onChange={(e) => {this.onChange('newPassword', e.target.value)}}/>
                        </Box>
                        <Box sx={{p: 2}}>
                            <TextField
                                sx={{width: '100%'}}
                                label={localize('Confirm password', this.props.lang)}
                                type={'password'}
                                onChange={(e) => {this.onChange('confirmPassword', e.target.value)}}/>
                        </Box>
                        <Box sx={{p:4, display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box>
                                    {this.state.passwordValidation.length ?
                                        <CheckCircleOutlineIcon color={'success'} fontSize="small" />
                                        :
                                        <ErrorOutlineIcon color={'error'} fontSize="small" />
                                    }
                                </Box>
                                <Box sx={{pl: 1}}>
                                    <Typography variant={'body2'}><Text>Length between 8 and 20 characters</Text></Typography>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box>
                                    {this.state.passwordValidation.lowerChar ?
                                        <CheckCircleOutlineIcon color={'success'} fontSize="small" />
                                        :
                                        <ErrorOutlineIcon color={'error'} fontSize="small" />
                                    }
                                </Box>
                                <Box sx={{pl: 1}}>
                                    <Typography variant={'body2'}><Text>At least one lower case character</Text></Typography>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box>
                                    {this.state.passwordValidation.upperChar ?
                                        <CheckCircleOutlineIcon color={'success'} fontSize="small" />
                                        :
                                        <ErrorOutlineIcon color={'error'} fontSize="small" />
                                    }
                                </Box>
                                <Box sx={{pl: 1}}>
                                    <Typography variant={'body2'}><Text>At least one upper case character</Text></Typography>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box>
                                    {this.state.passwordValidation.numericChar ?
                                        <CheckCircleOutlineIcon color={'success'} fontSize="small" />
                                        :
                                        <ErrorOutlineIcon color={'error'} fontSize="small" />
                                    }
                                </Box>
                                <Box sx={{pl: 1}}>
                                    <Typography variant={'body2'}><Text>At least one numeric case character</Text></Typography>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box>
                                    {this.state.passwordMatch ?
                                        <CheckCircleOutlineIcon color={'success'} fontSize="small" />
                                        :
                                        <ErrorOutlineIcon color={'error'} fontSize="small" />
                                    }
                                </Box>
                                <Box sx={{pl: 1}}>
                                    <Typography variant={'body2'}><Text>Password confirmation</Text></Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {
                        this.props.canCancel === true ?
                            <Button color={'error'} onClick={this.props.onClose}><Text>Cancel</Text></Button>
                            :
                            null
                    }
                    <Button color={'success'} onClick={this.changePassword.bind(this)} disabled={!this.state.valid}><Text>Change password</Text></Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
