class Place{
    public _id?: string = undefined;
    public name?: string = undefined;

    public constructor(init?:Partial<Place>) {
        Object.assign(this, init);
    }
}

export {Place};
