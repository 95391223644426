import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import ProfessionalDashboard from "./components/professional";
import {ROLES} from "../../../constants/roles";
import AssistantDashboard from "./components/assistant";
import AdministratorDashboard from "./components/administrator";
import {logException} from "../../../controllers/system";
import {getAnnouncements, getImportanceColor} from "../../../controllers/announcement";
import {Announcement} from "../../../models/announcement";
import {Alert} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

type IState = {
    announcements: Array<Announcement>
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        userRole: state.user.role
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Dashboard extends React.Component<ReduxType> {
    public readonly state: IState = {
        announcements: []
    }

    componentDidMount() {
        this.getAnnouncements();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    async getAnnouncements() {
        let announcements = await getAnnouncements();
        this.setState((state: IState) => {
            return {...state, announcements};
        });
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.announcements.map((announcement) => {
                        return (
                            <Alert
                                key={announcement._id}
                                severity={getImportanceColor(announcement.importance)}
                                title={announcement.title}
                                sx={{mb: 1, color:'white'}}
                                variant="filled"
                                icon={<InfoIcon fontSize="inherit"/>}
                            >
                                {announcement.message}
                            </Alert>
                        );
                    })
                }
                {this.props.userRole as ROLES === ROLES.Assistant ?
                    <AssistantDashboard/>
                    :
                    this.props.userRole as ROLES === ROLES.Professional ?
                        <ProfessionalDashboard/>
                        :
                        this.props.userRole as ROLES === ROLES.Administrator ?
                            <AdministratorDashboard/>
                            :
                            null
                }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
