import {combineReducers} from "redux";
import app from './app';
import user from './user';
import session from './session';
import settings from './settings';

export default combineReducers({
    settings,
    app,
    user,
    session
});
