import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Button} from "@mui/material";
import {localize} from "../../../../helpers/localization";
import Text from "../../../app/text/text";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {getSignatureBase64, uploadSignature} from "../../../../controllers/user";
import {logException} from "../../../../controllers/system";

type IState = {
    signature: string | null
}

type IProps = {
    visible: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        userId: state.user._id
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Signature extends React.Component<ReduxType, IState> {
    public state: IState = {
        signature: null
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.getSignature();
    }

    async importSignature(files: FileList | null) {
        if (files) {
            let signatureBase64: string | null = await uploadSignature(this.props.userId as string, files);

            if (signatureBase64) {
                this.props.setAppNotification({severity: 'success', message: localize('New signature saved', this.props.lang)})
                this.getSignature(signatureBase64);
            } else {
                this.props.setAppNotification({severity: 'error', message: localize('An error occured. Please check your file and try again.', this.props.lang)})
            }
        }
    }

    getSignature(signature?: string) {
        if (!signature) {
            getSignatureBase64(this.props.userId).then((signature: string | null) => {
                this.setState((state: IState) => {
                    return {...state, signature};
                });
            });
        } else {
            this.setState((state: IState) => {
                return {...state, signature};
            });
        }
    }

    render() {
        if (!this.props.visible) return null;
        return (
            <React.Fragment>
                <Box sx={{p:2}}>
                    <Button variant={'contained'} component={'label'}>
                        <Text>Import signature</Text>
                        <input
                            type="file"
                            hidden
                            onChange={(e) => {this.importSignature(e.target.files)}}
                        />
                    </Button>
                </Box>

                {this.state.signature ?
                    <Box sx={{p: 2, backgroundColor: 'white'}}>
                        <img alt={'signature'} style={{maxWidth: '250px'}} src={`data:image/png;base64,${this.state.signature}`}/>
                    </Box>
                    :
                    null
                }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signature);
