import {AppDispatch, RootState} from "../../../../store/store";
import {connect} from "react-redux";
import User, {USER_STATUS} from "../../../../models/user";
import React, {ChangeEvent, RefObject, useEffect, useState} from "react";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridRenderCellParams
} from "@mui/x-data-grid";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {getMUILocale, localize} from "../../../../helpers/localization";
import {Box, Button, IconButton, MenuItem, TextField} from "@mui/material";
import {ROLES} from "../../../../constants/roles";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import KeyIcon from "@mui/icons-material/Key";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import {
    getUsers,
    setCanUncompleteFollowUp,
    setColor, setContractor,
    setEmail,
    setPhone,
    setRole,
    setStatus
} from "../../../../controllers/user";
import InviteUser from "./components/inviteUser";
import {getEnumValue, getEnumValues} from "../../../../helpers/enums";
import Text from '../../../app/text/text';
import ColorPicker from '../../../ui/colorPicker/colorPicker';
import {validateEmail, validatePhone} from "../../../../helpers/string";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {formatNumber} from "../../../../helpers/phone";
import { darken, lighten } from '@mui/material/styles';
import {GridActionsCellItemProps} from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import {changeName} from "../../../../controllers/auth";

interface IProps {
    visible: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

let externalLoadData: () => void;

function Users(props: ReduxType) {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<User[]>([]);
    const [editingUser, setEditingUser] = useState<User | null>(null);

    const inviteUserDialogRef: RefObject<any> = React.createRef<any>();
    const colorPickerDialogRef: RefObject<any> = React.createRef<any>();

    const getBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    // ComponentDidMount
    useEffect(() => {
        loadData();
    }, []);

    const onRowUpdate = async (newRow: User, oldRow: User): Promise<User> => {
        let success: boolean = false;
        if (newRow.firstName !== oldRow.firstName || newRow.lastName !== oldRow.lastName) {
            setUserName(newRow._id as string, newRow.firstName, newRow.lastName);
            success = true;
        }
        if (newRow.email !== oldRow.email) {
            if (newRow.email && validateEmail(newRow.email as string)) {
                setUserEmail(newRow._id as string, newRow.email);
                success = true;
            } else {
                props.setAppNotification({severity: 'error', message: 'invalid format'});
            }
        }
        if (newRow.phone !== oldRow.phone) {
            if (newRow.phone === '' || (newRow.phone && validatePhone(newRow.phone as string))) {
                setUserPhone(newRow._id as string, newRow.phone);
                success = true;
            } else {
                props.setAppNotification({severity: 'error', message: 'invalid format'});
            }
        }
        return success ? newRow : oldRow;
    };

    const getColumns = ():(GridColDef | GridActionsColDef)[] => {
        return [
            {
                field: 'firstName',
                headerName: localize('Name', props.lang),
                minWidth: 100,
                flex: 1,
                editable: true
            },
            {
                field: 'lastName',
                headerName: localize('Name', props.lang),
                minWidth: 100,
                flex: 1,
                editable: true
            },
            {
                field: 'email',
                headerName: localize('Email', props.lang),
                minWidth: 100,
                flex: 1.5,
                editable: true
            },
            {
                field: 'phone',
                headerName: localize('Phone', props.lang),
                minWidth: 100,
                flex: 0.5,
                editable: true,
                type: "string",
                renderCell: (params: any) => {
                    if (params.row.phone)
                        return formatNumber(params.row.phone);
                    else
                        return '';
                }
            },
            {
                field: 'role',
                headerName: localize('Role', props.lang),
                minWidth: 100,
                flex: 1,
                editable: true,
                renderCell: (params: any) => {
                    return params.row.contractor ? localize('Contractor', props.lang) :
                    localize(ROLES[params.row.role], props.lang);
                },
                renderEditCell: (params: any) => {
                    if (params.row.contractor) return (
                        <div>
                            {params.row.contractor ? localize('Contractor', props.lang) :
                                localize(ROLES[params.row.role], props.lang)}
                        </div>
                    );
                    return <SelectEditInputCell {...params} {...props} />;
                }
            },
            {
                field: 'color',
                headerName: localize('Color', props.lang),
                minWidth: 50,
                flex: 0.5,
                renderCell: (params) => {
                    return !params.row.contractor ? (
                        <IconButton onClick={() => {chooseColor(params.row as User, params.row.color)}}>
                            <Box sx={{mr: 'auto', ml: 'auto', borderRadius: '50%', width: '30px', height: '30px', backgroundColor: params.row.color, cursor: 'pointer'}} />
                        </IconButton>
                    ) : '';
                }
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 30,
                flex: 0.25,
                getActions: (params) => {
                    let actions: React.ReactElement<GridActionsCellItemProps>[] = [];

                        if (!params.row.mainUser) {
                            actions.push(
                                params.row.status === USER_STATUS.ACTIVE ?
                                    <GridActionsCellItem
                                        icon={<KeyOffIcon />}
                                        label={localize(!params.row.contractor ? 'Remove the access' : 'Deactivate', props.lang)}
                                        onClick={() => {setUserStatus(params.row as User, USER_STATUS.INACTIVE)}}
                                        showInMenu
                                    />
                                    :
                                    <GridActionsCellItem
                                        icon={<KeyIcon />}
                                        label={localize(!params.row.contractor ? 'Give access' : 'Activate', props.lang)}
                                        onClick={() => {setUserStatus(params.row as User, USER_STATUS.ACTIVE)}}
                                        showInMenu
                                    />
                            );
                        }

                        if (!params.row.contractor) {
                            if (params.row.role !== ROLES.Assistant) {
                                actions.push(
                                    params.row.canUncompleteFollowUp !== true ?
                                        <GridActionsCellItem
                                            icon={<AssignmentTurnedInIcon/>}
                                            label={localize('Allow to uncomplete', props.lang)}
                                            onClick={() => {
                                                setUserUncompletePermission(params.row as User, true)
                                            }}
                                            showInMenu
                                        />
                                        :
                                        <GridActionsCellItem
                                            icon={<AssignmentLateIcon/>}
                                            label={localize('Disallow to uncomplete', props.lang)}
                                            onClick={() => {
                                                setUserUncompletePermission(params.row as User, false)
                                            }}
                                            showInMenu
                                        />
                                );
                            }

                            if (params.row.role === ROLES.Professional) {
                                actions.push(
                                    <GridActionsCellItem
                                        icon={<MoveDownIcon/>}
                                        label={localize('Transfer to contractors', props.lang)}
                                        onClick={() => {
                                            changeContractor(params.row as User, true)
                                        }}
                                        showInMenu
                                    />
                                );
                            }
                        } else {
                            actions.push(
                                <GridActionsCellItem
                                    icon={<MoveUpIcon/>}
                                    label={localize('Transfer to professionals', props.lang)}
                                    onClick={() => {
                                        changeContractor(params.row as User, false)
                                    }}
                                    showInMenu
                                />
                            );
                        }

                    return actions;
                }
            }
        ];
    }

    const inviteUser = () => {
        inviteUserDialogRef.current.openDialog();
    }

    const setUserStatus = (user: User, status: USER_STATUS) => {
        setStatus(user, status).then(() => {
            props.setAppNotification({severity: 'success', message: 'Profile changed successfully'});
            loadData();
        });
    }

    const setUserUncompletePermission = (user: User, canUncompleteFollowUp: boolean) => {
        setCanUncompleteFollowUp(user, canUncompleteFollowUp).then(() => {
            props.setAppNotification({severity: 'success', message: 'Profile changed successfully'});
            loadData();
        });
    };

    const changeContractor = (user: User, contractor: boolean) => {
        setContractor(user._id as string, contractor).then(() => {
            props.setAppNotification({severity: 'success', message: 'Profile changed successfully'});
            loadData();
        });
    };

    const setUserName = (id: string, firstName: string | undefined, lastName: string | undefined) => {
        changeName(id, firstName, lastName).then(() => {
            props.setAppNotification({severity: 'success', message: 'Profile changed successfully'});
            loadData();
        });
    }

    const setUserEmail = (id: string, email: string) => {
        setEmail(id, email).then(() => {
            props.setAppNotification({severity: 'success', message: 'Profile changed successfully'});
            loadData();
        });
    }

    const setUserPhone = (id: string, phone: string) => {
        setPhone(id, phone).then(() => {
            props.setAppNotification({severity: 'success', message: 'Profile changed successfully'});
            loadData();
        });
    }

    const loadData = () => {
        setLoading(true);
        setData([]);

        getUsers().then((users: User[]) => {
            setData(users);
            setLoading(false);
        });

    }
    externalLoadData = loadData;

    const chooseColor = (user: User, selectedColor: string) => {
        setEditingUser(user);
        colorPickerDialogRef.current.openDialog(selectedColor);
    }

    const onColorSelected = (color: string) => {
        setColor(editingUser as User, color).then(() => {
            colorPickerDialogRef.current.closeDialog();
            loadData();
        });
    }

    if (props.visible) {
        return (
            <Box sx={{p: 2, height: '100%', display: 'flex', flexDirection: 'column'}} hidden={!props.visible}>
                <Box sx={{pb: 2}}>
                    <Button variant={'contained'} onClick={inviteUser}><Text>Invite user</Text></Button>
                </Box>
                <Box sx={{
                    flexGrow: 1,
                    '& .row--contractor': {
                        bgcolor: (theme) =>
                            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        '&:hover': {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        },
                    }
                }}>
                    <DataGrid
                        columns={getColumns()}
                        rows={data}
                        getRowId={x => x._id || ''}
                        loading={loading}
                        localeText={getMUILocale(props.lang).components.MuiDataGrid.defaultProps.localeText}
                        processRowUpdate={onRowUpdate}
                        getRowClassName={(params) => params.row.contractor ? 'row--contractor' : ''}
                    />
                </Box>
                <InviteUser ref={inviteUserDialogRef} onInviteSent={loadData}/>
                <ColorPicker ref={colorPickerDialogRef} onColorSelected={onColorSelected}/>
            </Box>
        );
    } else {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);

function SelectEditInputCell(props: GridRenderCellParams & ReduxType) {
    const { id, value } = props;

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        await setRole(new User({_id: id.toString()}), getEnumValue(ROLES, event.target.value));
        externalLoadData();
    };

    return (
        <TextField
            sx={{width: '100%'}}
            select
            value={value || ''}
            onChange={handleChange}
        >
            {getEnumValues(ROLES).map((value: number) => (
                <MenuItem key={value} value={value}>
                    {localize(ROLES[value], props.lang)}
                </MenuItem>
            ))}
        </TextField>
    );
}


