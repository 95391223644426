class Contact {
    public _id?: string = undefined;
    public fileNumber?: number = undefined;
    public firstName?: string = undefined;
    public lastName?: string = undefined;
    public email?: string = undefined;
    public phone?: string = undefined;

    public constructor(init?:Partial<Contact>) {
        Object.assign(this, init);
    }
}
export {Contact};
