import {createTheme} from "@mui/material/styles";

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: "#efefef"
        },
        primary: {
            main: '#263A46'
        }
    },
});
