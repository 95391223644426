import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Checkbox, Grid, IconButton} from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import {localize} from "../../../../helpers/localization";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ScheduleModel, {IScheduleDay, IScheduleSlot} from "../../../../models/schedule";
import {capitalizeFirstLetter} from "../../../../helpers/string";
import {logException} from "../../../../controllers/system";
import {deepCopy} from "../../../../helpers/object";
import {dayjsOrAny} from "../../../../helpers/dayjs";
dayjs.extend(utc);
dayjs.extend(timezone);

type IState = {
    formValidation: any,
    schedule: IScheduleDay[]
}

type IProps = {
    visible: boolean,
    userId: string,
    schedule: IScheduleDay[],
    saveSchedule: (schedule: ScheduleModel) => void
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

const baseSchedule = {
    start: '08:00',
    end: '17:00'
};
const startOfYear = dayjs().startOf('year').tz('America/Montreal');

class Schedule extends React.Component<ReduxType, IState> {
    public state: IState = {
        formValidation: {},
        schedule: []
    }

    constructor(props: ReduxType) {
        super(props);
        this.state = {
            formValidation: {},
            schedule: deepCopy(props.schedule)
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.userId !== this.props.userId) {
            this.setState((state: IState) => {
                return {
                    ...state,
                    schedule: deepCopy(this.props.schedule)
                }
            })
        }
    }

    toggleSchedule(day: number) {
        this.setState((state: IState) => {
            let scheduleDay: IScheduleDay = Object.assign({}, state.schedule[day]);
            scheduleDay.working = !state.schedule[day].working;

            if (scheduleDay.working) {
                scheduleDay.slots = [Object.assign({}, baseSchedule)];
            } else {
                scheduleDay.slots = [];
            }

            state.schedule[day] = scheduleDay;
            return state;
        }, this.saveSchedule.bind(this));
    }

    changeSchedule(day: number, slotIndex: number, property: string, value: Date | undefined) {
        if (!value) value = dayjs().startOf('day').toDate();
        this.setState((state: IState) => {
            let scheduleDay: IScheduleDay = Object.assign({}, state.schedule[day]);
            if (!scheduleDay.slots) scheduleDay.slots = [];
            if (!scheduleDay.slots[slotIndex]) {
                scheduleDay.slots[slotIndex] = Object.assign({}, baseSchedule);
            }
            let slotStart: Date = dayjs(`${startOfYear.format('YYYY-MM-DD')} ${scheduleDay.slots[slotIndex].start}`).toDate();
            let slotEnd: Date = dayjs(`${startOfYear.format('YYYY-MM-DD')} ${scheduleDay.slots[slotIndex].end}`).toDate();
            switch(property) {
                case 'start':
                    slotStart = dayjs(`${startOfYear.format('YYYY-MM-DD')} ${dayjs(value).format('HH:mm')}`).toDate();
                    if (dayjs(slotEnd).isBefore(slotStart)) slotEnd = slotStart;
                    break;

                case 'end':
                    slotEnd = dayjs(`${startOfYear.format('YYYY-MM-DD')} ${dayjs(value).format('HH:mm')}`).toDate();
                    if (dayjs(slotEnd).isBefore(slotStart)) slotStart = slotEnd;
                    break;
            }
            scheduleDay.slots[slotIndex].start = dayjs(slotStart).format('HH:mm');
            scheduleDay.slots[slotIndex].end = dayjs(slotEnd).format('HH:mm');
            state.schedule[day] = scheduleDay;
            return state;
        }, this.saveSchedule.bind(this));
    }

    changeScheduleSlots(day: number, action: number) {
        this.setState((state: IState) => {
            let scheduleDay: IScheduleDay = Object.assign({}, state.schedule[day]);
            if (!scheduleDay.slots) scheduleDay.slots = [];

            if (action === 1) {
                if (scheduleDay.slots.length <= 4) scheduleDay.slots.push(Object.assign({}, baseSchedule));
            } else {
                if (scheduleDay.slots.length > 1) scheduleDay.slots.pop();
            }
            state.schedule[day] = scheduleDay;
            return state;
        }, () => {
            this.saveSchedule();
        });
    }

    validateForm() {
        let formValidation: any = { schedule:[] };
        let scheduleInError: boolean = false;

        for (let dayIndex: number = 0; dayIndex < this.state.schedule.length; dayIndex++) {
            let scheduleDay: IScheduleDay = this.state.schedule[dayIndex];
            formValidation.schedule[dayIndex] = [];

            if (scheduleDay.slots && Array.isArray(scheduleDay.slots)) {

                for (let slotIndex: number = 0; slotIndex < scheduleDay.slots.length; slotIndex++) {
                    let scheduleSlot: IScheduleSlot = scheduleDay.slots[slotIndex];

                    formValidation.schedule[dayIndex][slotIndex] = {start: undefined, end: undefined};

                    if (slotIndex > 0 && dayjs(scheduleDay.slots[slotIndex - 1].end).isAfter(scheduleSlot.start)) {
                        formValidation.schedule[dayIndex][slotIndex].start = 'error';
                        scheduleInError = true;
                    }

                    if (dayjs(scheduleSlot.start).isAfter(scheduleSlot.end)) {
                        formValidation.schedule[dayIndex][slotIndex].end = 'error';
                        scheduleInError = true;
                    }
                }
            }
        }

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return !scheduleInError;
    }

    saveSchedule() {
        if (this.validateForm()) {
            this.props.saveSchedule(new ScheduleModel({
                base: this.state.schedule
            }));
        }
    }

    isScheduleSlotInError(dayIndex: number, slotIndex: number, part: string): boolean {
        if (!this.state.formValidation || !this.state.formValidation.schedule || !this.state.formValidation.schedule[dayIndex] || !this.state.formValidation.schedule[dayIndex][slotIndex]) return false;
        return this.state.formValidation.schedule[dayIndex][slotIndex][part] === 'error';
    }

    render() {
        if (!this.props.visible) return null;
        return (
            <React.Fragment>
                <Box sx={{display: 'flex', flexDirection: 'column', p:2}}>
                    {this.state.schedule.map((schedule: IScheduleDay, dayIndex: number) => {
                        return (
                            <React.Fragment key={dayIndex}>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <Box sx={{width: '50px', p:1}}>
                                        <Checkbox checked={schedule.working} onChange={this.toggleSchedule.bind(this, dayIndex)} />
                                    </Box>
                                    <Box sx={{width: '150px', p:1}}>
                                        {capitalizeFirstLetter(dayjs().day(dayIndex).format('dddd'))}
                                    </Box>
                                    {
                                        schedule.slots && Array.isArray(schedule.slots) && schedule.slots.length > 0 ?
                                            <Box sx={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                                <Box>
                                                    <Box>
                                                        <IconButton size={'small'} color={'success'} onClick={this.changeScheduleSlots.bind(this, dayIndex, 1)}>
                                                            <AddBoxIcon />
                                                        </IconButton>
                                                    </Box>
                                                    {
                                                        schedule.slots.length > 1 ?
                                                            <Box>
                                                                <IconButton size={'small'} color={'error'} onClick={this.changeScheduleSlots.bind(this, dayIndex, -1)}>
                                                                    <IndeterminateCheckBoxIcon />
                                                                </IconButton>
                                                            </Box>
                                                            :
                                                            null
                                                    }
                                                </Box>
                                                <Box sx={{width: '100%'}}>
                                                    <Grid container>
                                                        {
                                                            schedule.slots.map((slot: IScheduleSlot, slotIndex: number) => {
                                                                return (
                                                                    <Grid item key={slotIndex} sx={{display: 'flex'}}>
                                                                        <Box sx={{width: '150px', p: 1}}>
                                                                            <TimePicker
                                                                                sx={{width: '100%'}}
                                                                                label={localize('Start', this.props.lang)}
                                                                                value={dayjsOrAny(schedule.working ? dayjs(`${startOfYear.format('YYYY-MM-DD')} ${slot.start}`) : dayjs().startOf('day').tz('America/Montreal'))}
                                                                                onChange={(newValue: any) => this.changeSchedule(dayIndex, slotIndex, 'start', newValue?.toDate())}
                                                                                slotProps={{textField: { error: this.isScheduleSlotInError(dayIndex, slotIndex, 'start')}}}
                                                                                ampm={false}
                                                                                minutesStep={15}
                                                                                readOnly={!schedule.working}
                                                                                format={'HH:mm'}
                                                                            />
                                                                        </Box>
                                                                        <Box sx={{width: '150px', p: 1}}>
                                                                            <TimePicker
                                                                                sx={{width: '100%'}}
                                                                                label={localize('End', this.props.lang)}
                                                                                value={dayjsOrAny(schedule.working ? dayjs(`${startOfYear.format('YYYY-MM-DD')} ${slot.end}`) : dayjs().startOf('day').tz('America/Montreal'))}
                                                                                onChange={(newValue: any) => this.changeSchedule(dayIndex, slotIndex, 'end', newValue?.toDate())}
                                                                                slotProps={{textField: { error: this.isScheduleSlotInError(dayIndex, slotIndex, 'end')}}}
                                                                                ampm={false}
                                                                                minutesStep={15}
                                                                                readOnly={!schedule.working}
                                                                                format={'HH:mm'}
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                );
                                                            })
                                                        }
                                                    </Grid>
                                                </Box>
                                            </Box>
                                            :
                                            null
                                    }

                                </Box>
                                <Box>
                                    <hr/>
                                </Box>
                            </React.Fragment>
                        );
                    })}
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
