import {ROLES} from "../constants/roles";
import Schedule from "./schedule";

export enum USER_STATUS {
    ACTIVE = 1,
    INACTIVE = 2
}

export default class User {
    public isAuthenticated?: boolean = undefined
    public _id?: string = undefined
    public id?: string = undefined
    public role?: ROLES = undefined;
    public avatarURL?: string = undefined
    public firstName?: string = undefined
    public lastName?: string = undefined
    public name?: string = undefined
    public email?: string = undefined
    public phone?: string = undefined;
    public changePassword?: boolean = undefined;
    public language?: string = undefined
    public color?: string = undefined
    public status?: USER_STATUS = undefined
    public schedule?: Schedule = undefined;
    public canUncompleteFollowUp?: boolean = false;
    public contractor?: boolean = false;
    public notificationPreferences?: NotificationPreferences = new NotificationPreferences();
    public mainUser?: boolean = false;
    public professionalRates: Rate[] = [];
    public professionalLicenceNumber?: string = undefined;
    public settings?: UserSettings;

    public constructor(init?:Partial<User>) {
        Object.assign(this, init);
    }
}

export class NotificationPreferences {
    public missingReports: boolean = true;
    public missingCycleReports: boolean = true;
    public unfinishedCycle: boolean = true;
    public unpaidMeetings?: boolean = true;
    public newContactRequest?: boolean = true;
    public smsMessages?: boolean = true;

    public constructor(init?:Partial<NotificationPreferences>) {
        Object.assign(this, init);
    }
}

export class UserSettings {
    canBeScheduled?: boolean = false;

    public constructor(init?:Partial<UserSettings>) {
        Object.assign(this, init);
    }
}

export class Rate {
    public from?: Date;
    public rate?: number;

    public constructor(init?:Partial<Rate>) {
        Object.assign(this, init);
    }
}
