import {localize} from "./localization";

export function getEnumKeysValues(enums: any) {
    let keys: (string | any)[] = Object.values(enums);
    let numberOfValues: number = keys.length / 2;
    let values: (string | any)[] = keys.splice(numberOfValues, numberOfValues);
    return [keys, values];
}

export function getEnumKeys(enums: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [keys, values] = getEnumKeysValues(enums);
    return keys;
}

export function getEnumValues(enums: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [keys, values] = getEnumKeysValues(enums);
    return values;
}

export function getEnumValue(enums: any, value: any): any {
    let [keys, values] = getEnumKeysValues(enums);
    let enumIndex = -1;
    enumIndex = keys.indexOf(value);
    if (enumIndex < 0) enumIndex = values.indexOf(value);
    if (enumIndex >= 0)
        return values[enumIndex];
    else
        return getEnumValueFromText(enums, value);
}

function getEnumValueFromText(enums: any, value: any): any {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [keys, values] = getEnumKeysValues(enums);
    for (let key of keys) {
        try {
            if (localize(key, 'fr').toLowerCase() === value.toLowerCase() || localize(key, 'en').toLowerCase() === value.toLowerCase()) {
                return enums[key];
            }
        } catch(ex) {

        }
    }
    return null;
}
