import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    AutocompleteValue,
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {localize} from "../../../helpers/localization";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Text from '../../app/text/text';
import MeetingList from "../../reports/meetingList/meetingList";
import {getAccountStatistics, getActiveAccounts} from "../../../controllers/account";
import {Account} from "../../../models/account";
import {getMeetings, IMeetingFilter} from "../../../controllers/meetings";
import {MEETING_STATUS, MeetingWithPatient} from "../../../models/meeting";
import {ROLES} from "../../../constants/roles";
import withRouter from "../../app/router/withRouter";
import {IRouter} from "../../app/router/router";
import {getContactRequests} from "../../../controllers/contactRequests";
import {ContactRequest} from "../../../models/contactRequest";
import ContactRequestsListReport from "../../reports/contactRequestsList/contactRequestsList";
import {getMissedRates, logException} from "../../../controllers/system";
import TimeSheetReport from "../../reports/timeSheet/timeSheet";
import {getUserFullName, getUsers} from "../../../controllers/user";
import User from "../../../models/user";
import RemainingMeetingsReport from "../../reports/meettingsSummary/remainingMeetings";
import {
    formatFileNumber,
    getPatients,
    getRemainingMeetingsSummary,
    RemainingMeetingsFilter
} from "../../../controllers/patients";
import {Patient} from "../../../models/patient";
import {executeJSReport} from "../../../helpers/reports";
import PaymentReceiptsReport from "../../reports/paymentReceipts/paymentReceipts";
import {MissedRate} from "../../../models/missedRate";
import ReportViewer from "../../reports/reportViewer";
import {Contact} from "../../../models/contact";
import {dayjsOrAny} from "../../../helpers/dayjs";

const ALLOWED_ROLES: ROLES[] = [
    ROLES.Administrator,
    ROLES.Assistant
]

type IState = {
    reports: any,
    formValidation: any,
    users: User[],
    professionals: User[],
    patients: Patient[],
    accounts: Account[],
    executing: boolean
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat,
        userRole: state.user.role
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class Reports extends React.Component<ReduxType, IState> {
    public state: IState = {
        formValidation: {},
        professionals: [],
        users: [],
        patients: [],
        accounts: [],
        executing: false,
        reports: {
            meetingList: {
                from: dayjs().startOf('month').toDate(),
                to: dayjs().endOf('day').toDate(),
                meetings: [],
                accounts: [],
                includeDetails: false
            },
            contactRequestsList: {
                from: dayjs().startOf('month').toDate(),
                to: dayjs().endOf('day').toDate(),
                contactRequests: [],
                accounts: []
            },
            timeSheet: {
                from: dayjs().startOf('month').toDate(),
                to: dayjs().endOf('day').toDate(),
                accounts: [],
                professionals: [],
                meetings: [],
                professionalUserId: ''
            },
            remainingMeetings: {
                from: dayjs().startOf('month').toDate(),
                to: dayjs().endOf('day').toDate(),
                summary: {}
            },
            statistics: {
                from: dayjs().startOf('year').toDate(),
                to: dayjs().endOf('year').toDate(),
                accountId: null
            },
            paymentReceipts: {
                from: dayjs().startOf('month').toDate(),
                to: dayjs().endOf('day').toDate(),
                professionals: [],
                meetings: [],
                missedRates: [],
                patientId: undefined,
                includeUnpaidMeetings: false
            }
        }
    }

    private reportMeetingListRef: RefObject<any>;
    private reportContactRequestsListRef: RefObject<any>;
    private reportTimesheetRef: RefObject<any>;
    private reportRemainingMeetingsRef: RefObject<any>;
    private reportPaymentReceiptsRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.reportMeetingListRef = React.createRef();
        this.reportContactRequestsListRef = React.createRef();
        this.reportTimesheetRef = React.createRef();
        this.reportRemainingMeetingsRef = React.createRef();
        this.reportPaymentReceiptsRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        if (!this.isAllowed()) {
            window.setTimeout(() => {
                this.props.router.navigate('/');
            }, 100);
        } else {
            this.loadUsers();
            this.loadProfessionals();
            this.loadPatients();
            this.loadAccounts();
        }
    }

    loadUsers() {
        getUsers({}).then((users: User[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    users
                };
            });
        });
    }

    loadProfessionals() {
        getUsers({role: ROLES.Professional}).then((professionals: User[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    professionals
                };
            });
        });
    }

    loadPatients() {
        getPatients({}).then((patients: Patient[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    patients
                };
            });
        });
    }

    loadAccounts() {
        getActiveAccounts().then((accounts: Account[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    accounts
                };
            });
        });
    }

    isAllowed(): boolean {
        return ALLOWED_ROLES.indexOf(this.props.userRole as ROLES) !== -1;
    }

    onChange(reportName: string, property: string, value: any) {
        this.setState((state: IState) => {
            let reports: any = Object.assign({}, state.reports);
            reports[reportName][property] = value;
            return {
                ...state,
                reports
            };
        });
    }

    runReport(reportName: string, type?: string) {
        this.setState((state: IState) => {
            return {...state, executing: true};
        }, () => {
            switch(reportName) {
                case 'meetingList':
                    let filter: IMeetingFilter = {
                        dateFrom: this.state.reports.meetingList.from,
                        dateTo: this.state.reports.meetingList.to,
                        excludeAbsence: true,
                        meetingStatus: [
                            MEETING_STATUS.Created,
                            MEETING_STATUS.Confirmed,
                            MEETING_STATUS.Completed,
                            MEETING_STATUS.Reported,
                            MEETING_STATUS.Missed
                        ]
                    };
                    let remainingMeetingsFilterForMeetingList: RemainingMeetingsFilter = {
                        dateFrom: this.state.reports.meetingList.from,
                        dateTo: this.state.reports.meetingList.to,
                    };
                    if (this.state.reports.meetingList.patientId) {
                        filter.patientId = this.state.reports.meetingList.patientId;
                        remainingMeetingsFilterForMeetingList.patientId = this.state.reports.meetingList.patientId;
                    }
                    if (this.state.reports.meetingList.accountId) {
                        if (this.state.reports.meetingList.accountId !== '-1') {
                            filter.accountId = this.state.reports.meetingList.accountId;
                            remainingMeetingsFilterForMeetingList.accountId = this.state.reports.meetingList.accountId;
                        } else {
                            filter.paidByPatient = true;
                            remainingMeetingsFilterForMeetingList.paidByPatient = true;
                        }
                    }
                    getRemainingMeetingsSummary(remainingMeetingsFilterForMeetingList).then((remainingMeetings: any) => {
                        getMeetings(filter, false).then((meetings: MeetingWithPatient[]) => {
                            this.setState((state: IState) => {
                                let reports: any = Object.assign({}, state.reports);
                                reports.meetingList.meetings = meetings;
                                reports.meetingList.accounts = this.state.accounts;
                                reports.meetingList.remainingMeetings = remainingMeetings;
                                return {
                                    ...state,
                                    reports,
                                    executing: false
                                };
                            }, () => {
                                if (!type || type === 'html') {
                                    this.reportMeetingListRef.current?.run();
                                } else if (type === 'excel') {
                                    executeJSReport('MeetingList-Excel', this.state.reports.meetingList, 'meeting-list', 'excel');
                                }
                            });
                        });
                    });
                    break;
                case 'contactRequestsList':
                    getContactRequests({
                        createdFrom: this.state.reports.contactRequestsList.from,
                        createdTo: this.state.reports.contactRequestsList.to,
                        includeDeleted: true
                    }).then((contactRequests: ContactRequest[]) => {
                        this.setState((state: IState) => {
                            let reports: any = Object.assign({}, state.reports);
                            reports.contactRequestsList.contactRequests = contactRequests;
                            reports.contactRequestsList.accounts = this.state.accounts;
                            return {
                                ...state,
                                reports,
                                executing: false
                            };
                        }, () => {
                            this.reportContactRequestsListRef.current?.run();
                        });
                    });
                    break;
                case 'timeSheet':
                    let timeSheetFilter: IMeetingFilter = {
                        dateFrom: this.state.reports.timeSheet.from,
                        dateTo: this.state.reports.timeSheet.to,
                        userIds: this.state.reports.timeSheet.professionalUserId === '' ? undefined : [this.state.reports.timeSheet.professionalUserId],
                        excludeAbsence: true,
                        meetingStatus: [MEETING_STATUS.Completed, MEETING_STATUS.Confirmed, MEETING_STATUS.Missed]
                    };
                    let remainingMeetingsFilterForTimeSheet: RemainingMeetingsFilter = {
                        dateFrom: this.state.reports.timeSheet.from,
                        dateTo: this.state.reports.timeSheet.to,
                        patientId: undefined,
                    };
                    if (this.state.reports.timeSheet.accountId) {
                        timeSheetFilter.accountId = this.state.reports.timeSheet.accountId;
                        remainingMeetingsFilterForTimeSheet.accountId = this.state.reports.timeSheet.accountId;
                    }

                    getRemainingMeetingsSummary(remainingMeetingsFilterForTimeSheet).then((remainingMeetings: any) => {
                        getMeetings(timeSheetFilter, false).then((meetings: MeetingWithPatient[]) => {
                            this.setState((state: IState) => {
                                let reports: any = Object.assign({}, state.reports);
                                reports.timeSheet.meetings = meetings;
                                reports.timeSheet.accounts = this.state.accounts;
                                reports.timeSheet.professionals = this.state.reports.timeSheet.professionalUserId === '' ? this.state.professionals : [this.getSelectedUser()];
                                reports.timeSheet.remainingMeetings = remainingMeetings;
                                return {
                                    ...state,
                                    reports,
                                    executing: false
                                };
                            }, () => {
                                this.reportTimesheetRef.current?.run();
                            });
                        });
                    });
                    break;
                case 'remainingMeetings':
                    let remainingMeetingsFilter: RemainingMeetingsFilter = {
                        dateFrom: this.state.reports.remainingMeetings.from,
                        dateTo: this.state.reports.remainingMeetings.to,
                        patientId: this.state.reports.remainingMeetings.patientId || undefined,
                        accountId: this.state.reports.remainingMeetings.accountId || undefined,
                    };
                    getRemainingMeetingsSummary(remainingMeetingsFilter).then((summary: any) => {
                        this.setState((state: IState) => {
                            let reports: any = Object.assign({}, state.reports);
                            reports.remainingMeetings.summary = summary;
                            return {
                                ...state,
                                reports,
                                executing: false
                            };
                        }, () => {
                            this.reportRemainingMeetingsRef.current?.run();
                        });
                    });
                    break;

                case 'statistics':
                    let accountId: string = this.state.reports.statistics.accountId;
                    let from: Date = this.state.reports.statistics.from;
                    let to: Date = this.state.reports.statistics.to;
                    if (!this.state.reports.statistics.accountId) {
                        // ERROR

                        return;
                    }
                    getAccountStatistics(accountId, from, to).then((statistics: any) => {
                        executeJSReport('Stats', statistics, 'statistics', 'pdf');
                        this.setState((state: IState) => {return {executing: false};});
                    });
                    break;

                case 'paymentReceipts':
                    let paymentReceiptsFilter: IMeetingFilter = {
                        dateFrom: this.state.reports.paymentReceipts.from,
                        dateTo: this.state.reports.paymentReceipts.to,
                        patientId: this.state.reports.paymentReceipts.patientId,
                        paidByPatient: true,
                        meetingStatus: [
                            MEETING_STATUS.Missed,
                            MEETING_STATUS.Confirmed,
                            MEETING_STATUS.Created,
                            MEETING_STATUS.Completed,
                            MEETING_STATUS.Reported
                        ]
                    };
                    getMissedRates().then((missedRates: MissedRate[]) => {
                        getMeetings(paymentReceiptsFilter, false).then((meetings: MeetingWithPatient[]) => {
                            this.setState((state: IState) => {
                                let reports: any = Object.assign({}, state.reports);
                                reports.paymentReceipts.meetings = meetings;
                                reports.paymentReceipts.missedRates = missedRates;
                                reports.paymentReceipts.patient = this.getPatient(state.reports.paymentReceipts.patientId);
                                return {
                                    ...state,
                                    reports,
                                    executing: false
                                };
                            }, () => {
                                this.reportPaymentReceiptsRef.current?.run();
                            });
                        });
                    });

                    break;
            }
        });

    }

    getSelectedUser(): User | undefined {
        for (let professional of this.state.professionals) {
            if (professional._id === this.state.reports.timeSheet.professionalUserId) {
                return professional;
            }
        }
        return undefined;
    }

    getPatient(id: string) {
        if (id === '') return null;
        for (let patient of this.state.patients) {
            if (patient._id === id) return patient;
        }
        return null;
    }

    render() {
        if (!this.isAllowed()) return null;
        return (
            <React.Fragment>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{localize('Meetings list', this.props.lang)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('From', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.meetingList.from)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('meetingList', 'from', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.meetingList?.from === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('To', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.meetingList.to)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('meetingList', 'to', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.meetingList?.to === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2, flexGrow: 1, textAlign: 'right'}}>
                                <Box>
                                    <Button
                                        onClick={this.runReport.bind(this, 'meetingList', 'html')}
                                        color={'primary'}
                                        variant={'contained'}
                                        disabled={
                                            !dayjs(this.state.reports.meetingList.from).isValid()
                                            || !dayjs(this.state.reports.meetingList.to).isValid()
                                            || dayjs(this.state.reports.meetingList.from).isAfter(this.state.reports.meetingList.to)
                                        }
                                    >
                                        <Text>Run report</Text>
                                    </Button>
                                </Box>
                                <Box sx={{pt: 2}}>
                                    <Button
                                        onClick={this.runReport.bind(this, 'meetingList', 'excel')}
                                        color={'success'}
                                        variant={'contained'}
                                        disabled={
                                            !dayjs(this.state.reports.meetingList.from).isValid()
                                            || !dayjs(this.state.reports.meetingList.to).isValid()
                                            || dayjs(this.state.reports.meetingList.from).isAfter(this.state.reports.meetingList.to)
                                        }
                                    >
                                        <Text>Export to excel</Text>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            <Box sx={{p:2}}>
                                <Autocomplete
                                    sx={{width: '100%', minWidth: '250px'}}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    getOptionLabel={(option) => `${formatFileNumber(option.fileNumber)} - ${option.firstName} ${option.lastName} (${dayjs(option.birthdate).format('DD/MM/YYYY')})`}
                                    options={this.state.patients}
                                    value={this.getPatient(this.state.reports.meetingList.patientId || '')}
                                    onChange={((e: any, value: AutocompleteValue<any, any, any, any>) => {this.onChange('meetingList', 'patientId', value ? value._id : null);})}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label={localize('Patient', this.props.lang)}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{p:2}}>
                                <TextField
                                    sx={{width: '100%', minWidth: '200px'}}
                                    select
                                    label={localize('Account', this.props.lang)}
                                    value={this.state.reports.meetingList.accountId || ''}
                                    onChange={((e: any) => {this.onChange('meetingList', 'accountId', e.target.value)})}
                                >
                                    <MenuItem value={''}>
                                        <Text>All accounts</Text>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem value={'-1'}>
                                        <Text>On the patient's account</Text>
                                    </MenuItem>
                                    {this.state.accounts.map((account: Account) => {
                                        return (
                                            <MenuItem key={account._id} value={account._id}>
                                                {account.name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Box>
                        </Box>
                        <Box sx={{p:2}}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={this.state.reports.meetingList.includeDetails} onChange={(e) => this.onChange('meetingList', 'includeDetails', e.target.checked)} />
                                }
                                label={localize('Include details', this.props.lang)}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{localize('Contact requests list', this.props.lang)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('From', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.contactRequestsList.from)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('contactRequestsList', 'from', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.contactRequestsList?.from === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('To', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.contactRequestsList.to)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('contactRequestsList', 'to', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.contactRequestsList?.to === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2, flexGrow: 1, textAlign: 'right'}}>
                                <Button
                                    onClick={this.runReport.bind(this, 'contactRequestsList', 'html')}
                                    color={'primary'}
                                    variant={'contained'}
                                    disabled={
                                        !dayjs(this.state.reports.contactRequestsList.from).isValid()
                                        || !dayjs(this.state.reports.contactRequestsList.to).isValid()
                                        || dayjs(this.state.reports.contactRequestsList.from).isAfter(this.state.reports.contactRequestsList.to)
                                    }
                                >
                                    <Text>Run report</Text>
                                </Button>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{localize('Timesheet', this.props.lang)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('From', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.timeSheet.from)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('timeSheet', 'from', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.timeSheet?.from === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('To', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.timeSheet.to)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('timeSheet', 'to', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.timeSheet?.to === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2, flexGrow: 1, textAlign: 'right'}}>
                                <Button
                                    onClick={this.runReport.bind(this, 'timeSheet', 'html')}
                                    color={'primary'}
                                    variant={'contained'}
                                    disabled={
                                        !dayjs(this.state.reports.timeSheet.from).isValid()
                                        || !dayjs(this.state.reports.timeSheet.to).isValid()
                                        || dayjs(this.state.reports.timeSheet.from).isAfter(this.state.reports.timeSheet.to)
                                    }
                                >
                                    <Text>Run report</Text>
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            <Box sx={{p:2}}>
                                <TextField
                                    id="professionalUserId"
                                    sx={{width: '100%', minWidth: '200px'}}
                                    select
                                    label={localize('Professional', this.props.lang)}
                                    value={this.state.reports.timeSheet.professionalUserId || ''}
                                    onChange={((e: any) => {this.onChange('timeSheet', 'professionalUserId', e.target.value)})}
                                >
                                    <MenuItem value={''}>
                                        <Text>All professionals</Text>
                                    </MenuItem>
                                    <Divider />
                                    {this.state.professionals.map((professional: User) => {
                                        return (
                                            <MenuItem key={professional._id} value={professional._id}>
                                                {getUserFullName(professional)}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Box>
                            <Box sx={{p:2}}>
                                <TextField
                                    sx={{width: '100%', minWidth: '200px'}}
                                    select
                                    label={localize('Account', this.props.lang)}
                                    value={this.state.reports.timeSheet.accountId || ''}
                                    onChange={((e: any) => {this.onChange('timeSheet', 'accountId', e.target.value)})}
                                >
                                    <MenuItem value={''}>
                                        <Text>All accounts</Text>
                                    </MenuItem>
                                    <Divider />
                                    {this.state.accounts.map((account: Account) => {
                                        return (
                                            <MenuItem key={account._id} value={account._id}>
                                                {account.name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{localize('Remaining meetings', this.props.lang)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('From', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.remainingMeetings.from)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('remainingMeetings', 'from', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.remainingMeetings?.from === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('To', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.remainingMeetings.to)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('remainingMeetings', 'to', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.remainingMeetings?.to === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2, flexGrow: 1, textAlign: 'right'}}>
                                <Button
                                    onClick={this.runReport.bind(this, 'remainingMeetings', 'html')}
                                    color={'primary'}
                                    variant={'contained'}
                                    disabled={
                                        !dayjs(this.state.reports.remainingMeetings.from).isValid()
                                        || !dayjs(this.state.reports.remainingMeetings.to).isValid()
                                        || dayjs(this.state.reports.remainingMeetings.from).isAfter(this.state.reports.remainingMeetings.to)
                                    }
                                >
                                    <Text>Run report</Text>
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            <Box sx={{p:2}}>
                                <Autocomplete
                                    sx={{width: '100%', minWidth: '250px'}}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    getOptionLabel={(option) => `${formatFileNumber(option.fileNumber)} - ${option.firstName} ${option.lastName} (${dayjs(option.birthdate).format('DD/MM/YYYY')})`}
                                    options={this.state.patients}
                                    value={this.getPatient(this.state.reports.remainingMeetings.patientId || '')}
                                    onChange={((e: any, value: AutocompleteValue<any, any, any, any>) => {this.onChange('remainingMeetings', 'patientId', value ? value._id : null);})}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label={localize('Patient', this.props.lang)}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{p:2}}>
                                <TextField
                                    sx={{width: '100%', minWidth: '200px'}}
                                    select
                                    label={localize('Account', this.props.lang)}
                                    value={this.state.reports.remainingMeetings.accountId || ''}
                                    onChange={((e: any) => {this.onChange('remainingMeetings', 'accountId', e.target.value)})}
                                >
                                    <MenuItem value={''}>
                                        <Text>All accounts</Text>
                                    </MenuItem>
                                    <Divider />
                                    {this.state.accounts.map((account: Account) => {
                                        return (
                                            <MenuItem key={account._id} value={account._id}>
                                                {account.name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{localize('Statistics', this.props.lang)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('From', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.statistics.from)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('statistics', 'from', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.statistics?.from === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('To', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.statistics.to)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('statistics', 'to', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.statistics?.to === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2, flexGrow: 1, textAlign: 'right'}}>
                                <Button
                                    onClick={this.runReport.bind(this, 'statistics', 'pdf')}
                                    color={'primary'}
                                    variant={'contained'}
                                    disabled={
                                        !dayjs(this.state.reports.statistics.from).isValid()
                                        || !dayjs(this.state.reports.statistics.to).isValid()
                                        || dayjs(this.state.reports.statistics.from).isAfter(this.state.reports.statistics.to)
                                        || !this.state.reports.statistics.accountId
                                    }
                                >
                                    <Text>Run report</Text>
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            <Box sx={{p:2}}>
                                <TextField
                                    sx={{width: '100%', minWidth: '200px'}}
                                    select
                                    label={localize('Account', this.props.lang)}
                                    value={this.state.reports.statistics.accountId || ''}
                                    onChange={((e: any) => {this.onChange('statistics', 'accountId', e.target.value)})}
                                >
                                    {this.state.accounts.map((account: Account) => {
                                        return (
                                            <MenuItem key={account._id} value={account._id}>
                                                {account.name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{localize('Payment receipts', this.props.lang)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('From', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.paymentReceipts.from)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('paymentReceipts', 'from', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.paymentReceipts?.from === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2}}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label={localize('To', this.props.lang)}
                                    value={dayjsOrAny(this.state.reports.paymentReceipts.to)}
                                    format={this.props.dateFormat}
                                    onChange={((newValue: string | null | undefined) => {
                                        this.onChange('paymentReceipts', 'to', dayjs(newValue).toDate());
                                    })}
                                    slotProps={{textField: { required: true, error: this.state.formValidation.paymentReceipts?.to === 'error'}}}
                                />
                            </Box>
                            <Box sx={{p:2, flexGrow: 1, textAlign: 'right'}}>
                                <Button
                                    onClick={this.runReport.bind(this, 'paymentReceipts', 'html')}
                                    color={'primary'}
                                    variant={'contained'}
                                    disabled={
                                        !dayjs(this.state.reports.paymentReceipts.from).isValid()
                                        || !dayjs(this.state.reports.paymentReceipts.to).isValid()
                                        || dayjs(this.state.reports.paymentReceipts.from).isAfter(this.state.reports.paymentReceipts.to)
                                        || !this.state.reports.paymentReceipts.patientId
                                    }
                                >
                                    <Text>Run report</Text>
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            <Box sx={{p:2}}>
                                <Autocomplete
                                    sx={{width: '100%', minWidth: '250px'}}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    getOptionLabel={(option) => `${formatFileNumber(option.fileNumber)} - ${option.firstName} ${option.lastName} (${dayjs(option.birthdate).format('DD/MM/YYYY')})`}
                                    options={this.state.patients}
                                    value={this.getPatient(this.state.reports.paymentReceipts.patientId || '')}
                                    onChange={((e: any, value: AutocompleteValue<any, any, any, any>) => {this.onChange('paymentReceipts', 'patientId', value ? value._id : null);})}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label={localize('Patient', this.props.lang)}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Box>

                            <Box sx={{p:2}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={this.state.reports.paymentReceipts.includeUnpaidMeetings} onChange={(e) => this.onChange('paymentReceipts', 'includeUnpaidMeetings', e.target.checked)} />
                                    }
                                    label={localize('Include unpaid meetings', this.props.lang)}
                                />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <ReportViewer
                    title={localize('Meetings list', this.props.lang)}
                    ref={this.reportMeetingListRef}
                    users={this.state.users}
                >
                    <MeetingList
                        accounts={this.state.reports.meetingList.accounts}
                        meetings={this.state.reports.meetingList.meetings}
                        includeDetails={this.state.reports.meetingList.includeDetails}
                        remainingMeetings={this.state.reports.meetingList.remainingMeetings}
                        from={this.state.reports.meetingList.from}
                        to={this.state.reports.meetingList.to}
                        dateFormat={this.props.dateFormat}
                        lang={this.props.lang} />
                </ReportViewer>
                <ReportViewer
                    title={localize('Contact requests list', this.props.lang)}
                    ref={this.reportContactRequestsListRef}
                    users={this.state.users}
                >
                    <ContactRequestsListReport
                        accounts={this.state.reports.contactRequestsList.accounts}
                        contactRequests={this.state.reports.contactRequestsList.contactRequests}
                        from={this.state.reports.contactRequestsList.from}
                        to={this.state.reports.contactRequestsList.to}
                        dateFormat={this.props.dateFormat}
                        lang={this.props.lang} />
                </ReportViewer>
                <ReportViewer
                    title={localize('Timesheet', this.props.lang)}
                    ref={this.reportTimesheetRef}
                    users={this.state.users}
                >
                    <TimeSheetReport
                        accounts={this.state.reports.timeSheet.accounts}
                        professionnals={this.state.reports.timeSheet.professionals}
                        meetings={this.state.reports.timeSheet.meetings}
                        remainingMeetings={this.state.reports.timeSheet.remainingMeetings}
                        from={this.state.reports.timeSheet.from}
                        to={this.state.reports.timeSheet.to}
                        dateFormat={this.props.dateFormat}
                        lang={this.props.lang} />
                </ReportViewer>
                <ReportViewer
                    title={localize('Remaining meetings', this.props.lang)}
                    ref={this.reportRemainingMeetingsRef}
                    users={this.state.users}
                >
                    <RemainingMeetingsReport
                        summary={this.state.reports.remainingMeetings.summary}
                        from={this.state.reports.remainingMeetings.from}
                        to={this.state.reports.remainingMeetings.to}
                        dateFormat={this.props.dateFormat}
                        lang={this.props.lang} />
                </ReportViewer>
                <ReportViewer
                    title={localize('Payment receipts', this.props.lang)}
                    ref={this.reportPaymentReceiptsRef}
                    users={this.state.users}
                    patients={this.state.patients}
                    defaultSendToContacts={this.state.reports.paymentReceipts.patient ? [new Contact(
                        {
                            _id: this.state.reports.paymentReceipts.patient?._id,
                            firstName: this.state.reports.paymentReceipts.patient?.firstName,
                            lastName: this.state.reports.paymentReceipts.patient?.lastName,
                            email: this.state.reports.paymentReceipts.patient?.email,
                            phone: this.state.reports.paymentReceipts.patient?.phoneCell,
                            fileNumber: this.state.reports.paymentReceipts.patient?.fileNumber
                        }
                    )] : []}
                >
                    <PaymentReceiptsReport
                        from={this.state.reports.paymentReceipts.from}
                        to={this.state.reports.paymentReceipts.to}
                        meetings={this.state.reports.paymentReceipts.meetings}
                        patient={this.state.reports.paymentReceipts.patient}
                        includeUnpaid={this.state.reports.paymentReceipts.includeUnpaidMeetings}
                        professionnals={this.state.professionals}
                        missedRates={this.state.reports.paymentReceipts.missedRates}
                        dateFormat={this.props.dateFormat}
                        lang={this.props.lang} />
                </ReportViewer>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.executing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reports));
