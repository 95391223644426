import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const GlobalStyle = css`
  html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    color: initial;
    text-decoration: initial;
  }
`;

export const AppContainer = styled.div`
    width: 100%;
    height: 100%;
`;
