export function randomString(charNumber: number, uppercase: boolean = true, lowercase: boolean = true, numeric: boolean = true): string {
    let generatedString: string = '';
    let chars: string = '';
    if (uppercase) chars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (lowercase) chars += 'abcdefghijklmnopqrstuvwxyz';
    if (numeric) chars += '0123456789';
    let charsLength: number = chars.length;

    for ( var i = 0; i < charNumber; i++ ) {
        generatedString += chars.charAt(Math.floor(Math.random() * charsLength));
    }

    return generatedString;
}
