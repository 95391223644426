import {get, post} from "../helpers/ajax";
import {AxiosResponse} from "axios";
import {SignedDocument} from "../models/signedDocument";

export async function getDocument(documentId: string): Promise<SignedDocument | null> {
    let response: AxiosResponse<SignedDocument> = await post<SignedDocument>('/external/signed-document/get', {documentId});

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

interface getAnswerResponse {
    answer: boolean | null
}
export async function getAnswer(documentId: string, patientId: string): Promise<boolean | null> {
    let response: AxiosResponse<getAnswerResponse> = await post<getAnswerResponse>('/external/signed-document/get-answer', {documentId, patientId});

    if (response.status === 200 && response.data)
        return response.data.answer;
    else
        return false;
}

export async function answer(documentId: string, patientId: string, answer: boolean): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>('/external/signed-document/answer', {documentId, patientId, answer});

    return response.status === 200;
}

export async function getAll(): Promise<SignedDocument[]> {
    let response: AxiosResponse<SignedDocument[]> = await get<SignedDocument[]>('/signed-document/getAll');

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function save(signedDocument: SignedDocument): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>('/signed-document/save', {signedDocument});

    if (response.status === 200)
        return response.data;
    else
        return false;
}

export async function remove(signedDocumentId: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>('/signed-document/remove', {signedDocumentId});

    if (response.status === 200)
        return response.data;
    else
        return false;
}

export async function send(patientId: string, signedDocumentId: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>('/signed-document/send', {patientId, signedDocumentId});

    if (response.status === 200)
        return response.data;
    else
        return false;
}
