import {createStore, compose} from "redux";
import reducers from "./reducers/reducers";
import middlewares from './middlewares/middlewares';
import initialState from "./initialState";

const storeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(reducers, initialState, storeEnhancers(middlewares));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch


