import {frFR, enUS} from "@mui/x-data-grid";
import {Localization} from "@mui/x-data-grid/utils/getGridLocalization";
import local_fr from '../localizations/fr';
import local_en from '../localizations/en';

export interface ILocal {
    [key: string]: string;
}

export function localize(text:string, lang: string) {
    let localized: string | undefined = undefined;

    switch(lang) {
        case 'EN':
            localized = local_en[text];
            break;

        case 'FR':
        default:
            localized = local_fr[text];
            break;
    }

    if (!localized) {
        console.log('localization not set', text, lang, `"${text}": "${text}",`);
        logMissingLocal(text);
        return local_fr[text] || text;
    }
    return localized;
}

function logMissingLocal(text: string) {
    let w = (window as any);
    w.missingLocals = w.missingLocals || [];
    if (w.missingLocals.indexOf(text) === -1) {
        w.missingLocals.push(text);
    }
    if (!w.getMissingLocals) {
        w.getMissingLocals = () => {
            return w.missingLocals.map((ml: string) => {
                return `"${ml}": "${ml}"`;
            }).join(',') + ',';
        }
    }
}

export function getMUILocale(lang: string): Localization {
    switch(lang) {
        case 'EN':
            return enUS;

        case 'FR':
        default:
            return frFR
    }
}
