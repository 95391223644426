import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {getEnv, logException} from "../../../controllers/system";
import * as styled from './env.styled';

type IProps = {
}

type IState = {
    env: string
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Env extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        env: ''
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        getEnv().then((env) => {
            this.setState((state: IState) => {
                return { ...state, env};
            });
        });
    }

    render() {
        if (this.state.env === 'production' || this.state.env === '') return null;
        return (
            <styled.EnvNotification>
                <div>{this.state.env}</div>
            </styled.EnvNotification>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Env);
