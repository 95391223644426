import dayjs from "dayjs";

class Contract {
    public _id?: string = undefined;
    public code?: string = undefined;
    public name?: string = undefined;
    public openedOn?: Date = undefined;
    public closedOn?: Date = undefined;
    public meetingsEmployee: Number = 0;
    public meetingsSpouse: Number = 0;
    public meetingsChildren: Number = 0;
    public extension: boolean = false;
    public eligibleEmployees: number = 0;

    public constructor(init?:Partial<Contract>) {
        if (init && init.openedOn) init.openedOn = dayjs(init.openedOn).isValid() ? dayjs(init.openedOn).toDate() : undefined;
        if (init && init.closedOn) init.closedOn = dayjs(init.closedOn).isValid() ? dayjs(init.closedOn).toDate() : undefined;
        Object.assign(this, init);
    }
}

export {Contract};
