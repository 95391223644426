
export default class FollowUp {
    public _id?: string = undefined;
    public duration?: number = undefined;
    public notes?: string = undefined;
    public completed?: boolean = undefined;
    public completedOn?: Date = undefined;

    public constructor(init?:Partial<FollowUp>) {
        Object.assign(this, init);
    }
}
