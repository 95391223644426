import {Action, createAction} from "../action";
import Schedule from "../../models/schedule";
import User, {NotificationPreferences, UserSettings} from "../../models/user";

export const LOGIN = "LOGIN";
export function login(sessionId: string, user: User): Action<string, any> {
    return createAction(
        LOGIN,
        {
            sessionId,
            user
        }
    );
}

export const LOGOUT = "LOGOUT";
export function logout(): Action<string, any> {
    return createAction(
        LOGOUT
    );
}

export const AUTH_CHECK = "AUTH_CHECK";
export function authCheck(): Action<string, any> {
    return createAction(
        AUTH_CHECK,
        {}
    );
}

export const LOGGED_IN = "LOGGED_IN";
export function loggedIn(): Action<string, any> {
    return createAction(
        LOGGED_IN,
        {
        }
    );
}

export const REFRESH_USER = "REFRESH_USER";
export function refreshUser(): Action<string, any> {
    return createAction(
        REFRESH_USER
    );
}

export const REFRESH_USER_NOTIFICATIONS = "REFRESH_USER_NOTIFICATIONS";
export function refreshUserNotifications(): Action<string, any> {
    return createAction(
        REFRESH_USER_NOTIFICATIONS,
        {
            userNotifications: []
        }
    );
}

export const REFRESH_UNREAD_EMAILS = "REFRESH_UNREAD_EMAILS";
export function refreshUnreadEmails(): Action<string, any> {
    return createAction(
        REFRESH_UNREAD_EMAILS,
        {
            unreadEmailsCount: 0
        }
    );
}

export const SET_USER_NOTIFICATION_READ = "SET_USER_NOTIFICATION_READ";
export function setUserNotificationRead(userNotificationId: string): Action<string, any> {
    return createAction(
        SET_USER_NOTIFICATION_READ,
        {
            userNotificationId
        }
    );
}

export const SAVE_SCHEDULE = "SAVE_SCHEDULE";
export function saveSchedule(userId: string, schedule: Schedule): Action<string, any> {
    return createAction(
        SAVE_SCHEDULE,
        {
            userId,
            schedule
        }
    );
}

export const SET_PASSWORD_CHANGED = "SET_PASSWORD_CHANGED";
export function setPasswordChanged(): Action<string, any> {
    return createAction(
        SET_PASSWORD_CHANGED
    );
}

export const SET_NOTIFICATION_PREFERENCES = "SET_NOTIFICATION_PREFERENCES";
export function setNotificationPreferences(notificationPreferences: NotificationPreferences): Action<string, any> {
    return createAction(
        SET_NOTIFICATION_PREFERENCES,
        {
            notificationPreferences
        }
    );
}

export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export function setUserSettings(userSettings: UserSettings): Action<string, any> {
    return createAction(
        SET_USER_SETTINGS,
        {
            userSettings
        }
    );
}
