import {createTheme} from "@mui/material/styles";

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            paper: "#112734",
            default: "#284154"
        },
        text: {
            primary: "#ffffff"
        }
    },
});
