import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import Text from "../../app/text/text";
import {localize} from "../../../helpers/localization";
import Users from "./users/users";
import {ROLES} from "../../../constants/roles";
import {IRouter} from "../../app/router/router";
import withRouter from "../../app/router/withRouter";
import {logException} from "../../../controllers/system";
import Places from "./places/places";
import Professionals from "./professionals/professionals";
import MissedRates from "./missedRates/missedRates";
import SignedDocuments from "./signedDocuments/signedDocuments";

const ALLOWED_ROLES: ROLES[] = [
    ROLES.Administrator,
    ROLES.Assistant
]

type IState = {
    panel: number
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        userRole: state.user.role
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class Configurations extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        panel: 0
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        if (!this.isAllowed()) {
            window.setTimeout(() => {
                this.props.router.navigate('/');
            }, 100);
        }
    }

    isAllowed(): boolean {
        return ALLOWED_ROLES.indexOf(this.props.userRole as ROLES) !== -1;
    }

    setPanel(panel: number) {
        this.setState((state: IState) => {
            return {
                ...state,
                panel
            };
        });
    }

    isAccessible(roles: ROLES[]): boolean {
        return roles.indexOf(this.props.userRole) !== -1;
    }

    render() {
        if (!this.isAllowed()) return null;
        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    height: '100%'
                }}>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant={'h4'}>
                                <Text>Configurations</Text>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={this.state.panel}>
                                <Tab label={localize('Professionals', this.props.lang)} onClick={this.setPanel.bind(this, 0)} />
                                <Tab label={localize('Users', this.props.lang)} onClick={this.setPanel.bind(this, 1)} />
                                <Tab disabled={!this.isAccessible([ROLES.Assistant, ROLES.Administrator])}  label={localize('Places', this.props.lang)} onClick={this.setPanel.bind(this, 2)} />
                                <Tab label={localize('Missed meetings rates', this.props.lang)} onClick={this.setPanel.bind(this, 3)} />
                                <Tab disabled={!this.isAccessible([ROLES.Assistant, ROLES.Administrator])}  label={localize('Signed documents', this.props.lang)} onClick={this.setPanel.bind(this, 4)} />
                            </Tabs>
                        </Box>
                        <Box sx={{flexGrow: 1, overflow: 'auto'}}>
                            <Professionals visible={this.state.panel === 0}/>
                            <Users visible={this.state.panel === 1}/>
                            <Places visible={this.state.panel === 2}/>
                            <MissedRates visible={this.state.panel === 3}/>
                            <SignedDocuments visible={this.state.panel === 4}/>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Configurations));
