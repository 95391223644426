import styled from '@emotion/styled';

export const EnvNotification = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: transparent;
  color: white;
  padding: 30px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0.6;
  z-index: 9999;
  
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 60px 60px;
    border-color: transparent transparent #608A32 transparent;
    right: 0;
    bottom: 0;
    position: absolute;
  }
  
  > div {
    position:fixed;
    bottom: 6px;
    right: 6px;
    z-index: 99999;
    transform: rotate(-45deg);
    pointer-events: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
`;
