import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {localize} from "../../../../helpers/localization";
import FullScreenDialog from "../../../ui/fullScreenDialog/fullScreenDialog";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import {
    Box,
    TextField
} from "@mui/material";
import {setAppNotification} from "../../../../store/actions/app";
import {Patient} from "../../../../models/patient";
import {
    formatFileNumber,
    savePatient
} from '../../../../controllers/patients';
import {logException} from "../../../../controllers/system";
import {IAppNotification} from "../../../../models/appNotification";

type IState = {
    saving: boolean,
    changed: boolean,
    info: string
}

type IProps = {
    patient: Patient,
    infoLabel: string,
    infoAttr: string,
    saveChanges: boolean,
    onSave: (patient: Patient) => void,
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class AdditionalInfoDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        saving: false,
        changed: false,
        info: ''
    }

    private editDialogRef: RefObject<any>;
    private confirmCloseDialogRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.editDialogRef = React.createRef();
        this.confirmCloseDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog() {
        this.setState((state: IState) => {
            return {
                ...state,
                changed: false,
                info: `${(this.props.patient as any)[this.props.infoAttr] || ''}`
            }
        }, () => {
            this.editDialogRef.current.openDialog();
        });
    }

    closeDialogRequest(): boolean {
        if (this.state.changed) {
            this.confirmCloseDialogRef.current?.openDialog();
            return false
        } else {
            this.closeDialog();
            return true;
        }
    }

    closeDialog() {
        this.editDialogRef.current.closeDialog();
    }

    savePatient(callback? : () => void) {
        if (this.state.changed) {
            let data: any = {};
            data[this.props.infoAttr] = this.state.info;
            let patient: Patient = new Patient({
                ...this.props.patient,
                ...data
            })
            if (this.props.saveChanges) {
                savePatient(patient).then((patient: Patient | null) => {
                    this.setState((state: IState) => {
                        return {
                            ...state,
                            changed: false
                        }
                    }, () => {
                        this.props.setAppNotification({
                            severity: 'success',
                            message: 'Successfully saved'
                        });
                        this.props.onSave(patient as Patient);
                        if (callback) callback();
                    });
                }).catch(() => {
                    this.props.setAppNotification({
                        severity: 'error',
                        message: 'An error occurred, please try again'
                    });
                });
            } else {
                this.props.onSave(patient as Patient);
                if (callback) callback();
                this.setState((state: IState) => {
                    return {
                        ...state,
                        changed: false
                    }
                }, () => {
                    this.closeDialog();
                });
            }
        }
    }

    onChange(info: string) {
        this.setState((state: IState) => {
            return {
                ...state,
                changed: true,
                info
            }
        });
    }

    onModalButtonClick(key: string) {
        switch(key) {
            case 'save':
                this.savePatient();
                break;
        }
    }

    render() {
        return (
            <FullScreenDialog
                ref={this.editDialogRef}
                title={`${this.props.infoLabel} ${this.props.patient?.fileNumber ? ` - ${formatFileNumber(this.props.patient?.fileNumber)}` : ''}`}
                onButtonClick={this.onModalButtonClick.bind(this)}
                onCloseRequest={this.closeDialogRequest.bind(this)}
                buttons={[
                    {key: 'save', text: localize(this.props.saveChanges ? 'Save' : 'Apply', this.props.lang), color: 'success', keepDialogOpen: true, disabled: !this.state.changed}
                ]}
            >
                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', height: '90%;'}}>
                    <TextField
                        id={'info'}
                        sx={{width: '100%'}}
                        label={this.props.infoLabel}
                        defaultValue={this.state.info}
                        onChange={((e: any) => {this.onChange(e.target.value)})}
                        multiline={true}
                        rows={20}
                    />
                </Box>
                <ConfirmDialog
                    ref={this.confirmCloseDialogRef}
                    title={localize('Unsaved changes', this.props.lang)}
                    text={localize('All unsaved changes will be lost. Do you want to continue?', this.props.lang)}
                    onAgree={this.closeDialog.bind(this)}
                />
            </FullScreenDialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(AdditionalInfoDialog);
