import React from 'react';
import {connect} from 'react-redux';
import {
    Backdrop,
    CircularProgress
} from '@mui/material';
import {AppDispatch, RootState} from "../../../store/store";
import {logException} from "../../../controllers/system";

type IProps = {
}

type IState = {
}

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.app.loading
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Loading extends React.Component<ReduxType, IState> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    render() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.props.loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
