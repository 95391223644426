import {PARENT_RELATION} from "../models/patient";
import {localize} from "./localization";
import dayjs from "dayjs";

export function getRelationText(relation: PARENT_RELATION, lang: string): string | null {
    switch (relation) {
        case PARENT_RELATION.FATHER:
            return localize('Father', lang);
        case PARENT_RELATION.MOTHER:
            return localize('Mother', lang);
        default:
            return localize('Other', lang);
    }
}

export function isMinor(birthdate: Date | undefined): boolean {
    if (!birthdate || !dayjs(birthdate).isValid()) return false;
    return dayjs(new Date()).diff(birthdate, 'year') < 14;
}
