import React from "react";
import {Routes, Route} from "react-router-dom";
import SignDocument from "../../components/views/signDocument";
import {returnToHome} from "../../../helpers/location";
import RedirectLink from "../../components/views/redirectLink";
import ContactRequest from "../../components/views/contactRequest";
import WelcomePatient from "../../components/views/welcomePatient";

export interface IRouter {
    router: {
        location: any,
        navigate: (url: string) => void,
        params: any,
        searchParams: any
    }
}

function Redirect() {
    returnToHome();
    return <></>;
}

function ExternalRouter() {

    return (
        <Routes>
            <Route path={'*'} element={<Redirect />} />
            <Route path={'/e/ln'} element={<RedirectLink/>} />
            <Route path={'/e/document'} element={<SignDocument/>} />
            <Route path={'/e/request'} element={<ContactRequest/>} />
            <Route path={'/e/welcome'} element={<WelcomePatient/>} />
        </Routes>
    );
}

export default ExternalRouter
