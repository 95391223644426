import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {IRouter} from "../../../components/app/router/router";
import withRouter from "../../../components/app/router/withRouter";
import {Alert, Box, Button, Paper, Skeleton, Typography} from "@mui/material";
import {IAppNotification} from "../../../models/appNotification";
import {setAppNotification, setLoading} from "../../../store/actions/app";
import {logException} from "../../../controllers/system";
import querystring from "query-string";
import {returnToHome} from "../../../helpers/location";
import {SignedDocument} from "../../../models/signedDocument";
import * as signedDocumentController from "../../../controllers/signedDocument";
import Text from "../../../components/app/text/text";
import {localize} from "../../../helpers/localization";
import ConfirmDialog from "../../../components/ui/confirmDialog/confirmDialog";

type IState = {
    viewedAll: boolean,
    signedDocument?: SignedDocument,
    answer: boolean | null,
    saving: boolean
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        },
        setLoading: (loading: boolean) => {
            dispatch(setLoading(loading));
        },
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class SignDocument extends React.Component<ReduxType> {
    public readonly state: IState = {
        viewedAll: false,
        answer: null,
        saving: false
    }

    private scrollBoxRef: RefObject<any>;
    private confirmDeclineDialogRef: RefObject<any>;
    private patientId: string | null = null;

    constructor(props: ReduxType) {
        super(props);
        this.scrollBoxRef = React.createRef();
        this.confirmDeclineDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.props.setLoading(true);
        this.loadData();
    }

    async loadData() {
        let params: any = querystring.parse(window.location.search);
        if (params.p && params.d) {
            this.patientId = params.p;
            let signedDocument: SignedDocument | null = await signedDocumentController.getDocument(params.d);
            if (signedDocument) {
                let answer: boolean | null = await signedDocumentController.getAnswer(params.d, params.p);
                this.setState((state: IState) => {
                    return {
                        ...state,
                        signedDocument,
                        answer
                    };
                }, () => {
                    this.props.setLoading(false);
                    this.checkScroll();
                });
            } else {
                returnToHome();
            }
        } else {
            returnToHome();
        }
    }

    checkScroll() {
        if (this.state.viewedAll) return;
        let box = this.scrollBoxRef.current;
        if (box.getBoundingClientRect().height + box.scrollTop >= box.scrollHeight) {
            this.setState((state: IState) => {
                return {
                    ...state,
                    viewedAll: true
                };
            });
        }
    }

    async sendAnswer(answer: boolean) {
        if (this.state.signedDocument && this.state.signedDocument._id && this.patientId) {
            await this.setSaving(true);
            let success: boolean = await signedDocumentController.answer(this.state.signedDocument._id, this.patientId, answer);

            if (success) {
                this.props.setAppNotification({severity: 'success', message: 'Answer has been received. Thank you'});
                this.setState((state: IState) => {
                    return {
                        ...state,
                        answer,
                        saving: false
                    };
                });
            } else {
                this.props.setAppNotification({severity: 'error', message: 'An error occurred, please try again'});
            }
        }
    }

    setSaving(saving: boolean): Promise<void> {
        return new Promise((resolve) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    saving
                };
            }, resolve);
        });
    }

    confirmDecline() {
        this.confirmDeclineDialogRef.current.openDialog();
    }

    render() {
        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '960px',
                        width: '100%',
                        height: '90%'
                    }}>
                        {
                            this.state.answer !== null ?
                                <Box sx={{pb:1}}>
                                    <Alert
                                        severity={this.state.answer ? 'success' : 'error'}
                                        sx={{width: '100%', color: 'white'}}
                                        variant={'outlined'}
                                    >
                                        {
                                            this.state.answer ?
                                                <Text>You already accepted this document</Text>
                                                :
                                                <Text>You already declined this document</Text>
                                        }
                                    </Alert>
                                </Box>
                                : null
                        }
                        <Box sx={{flexGrow: 1, overflowY: 'auto'}}>
                            <Paper ref={this.scrollBoxRef} variant={'outlined'} sx={{p:2, height: '95%', overflowY: 'auto'}} onScroll={this.checkScroll.bind(this)}>
                                <Typography variant="h4" gutterBottom>
                                    {
                                        this.state.signedDocument ?
                                            this.state.signedDocument.title
                                            :
                                            <Skeleton />
                                    }
                                </Typography>
                                <React.Fragment>
                                    {
                                        this.state.signedDocument ?
                                            <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{__html: this.state.signedDocument.document || ''}}/>
                                        :
                                            <Typography variant="body1" gutterBottom>
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                            </Typography>

                                    }
                                </React.Fragment>
                            </Paper>

                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                variant={'contained'}
                                color={'error'}
                                disabled={this.state.saving || !this.state.signedDocument || !this.state.viewedAll || this.state.answer !== null}
                                onClick={this.confirmDecline.bind(this)}
                            >
                                <Text>Decline</Text>
                            </Button>
                            <Button
                                variant={'contained'}
                                color={'success'}
                                disabled={this.state.saving || !this.state.signedDocument || !this.state.viewedAll || this.state.answer === true} sx={{ml: 1}}
                                onClick={this.sendAnswer.bind(this, true)}
                            >
                                <Text>Accept</Text>
                            </Button>
                            <ConfirmDialog
                                ref={this.confirmDeclineDialogRef}
                                title={localize('Decline this document', this.props.lang)}
                                text={localize('Are you sure you want to decline this document?', this.props.lang)}
                                onAgree={this.sendAnswer.bind(this, false)}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignDocument));
