import React from 'react';
import {connect} from 'react-redux';
import {
    Dialog,
    DialogTitle, DialogContent, DialogActions, Button, Autocomplete, TextField, Box, Alert
} from '@mui/material';
import {AppDispatch, RootState} from "../../../store/store";
import Text from "../../app/text/text";
import {logException} from "../../../controllers/system";
import {Patient} from "../../../models/patient";
import User from "../../../models/user";
import {Contact} from "../../../models/contact";
import {localize} from "../../../helpers/localization";
import {validateEmail} from "../../../helpers/string";

type IProps = {
    title: string
    patients?: Array<Patient>,
    users?: Array<User>,
    defaultContacts?: Array<Contact>,
    onSend: (contacts: Array<Contact | string>, subject: string, body: string) => void
}

type IState = {
    open: boolean,
    contacts: Array<Contact>,
    defaultContacts?: Array<Contact>,
    selectedContacts: Array<Contact | string>,
    emailSubject: string,
    emailBody: string,
    sending: boolean,
    sendToExtern: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class SendToDialog extends React.Component<ReduxType, IState> {

    public readonly state: IState = {
        open: false,
        contacts: [],
        defaultContacts: [],
        selectedContacts: [],
        emailSubject: '',
        emailBody: '',
        sending: false,
        sendToExtern: false
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog() {
        this.setState((state: IState) => {
            let contacts = this.generateContactList();
            let defaultContacts = this.getDefaultContacts(contacts);
            return {
                ...state,
                open: true,
                contacts,
                defaultContacts,
                selectedContacts: defaultContacts
            };
        })
    }

    closeDialog() {
        this.setState((state: IState) => {
            return {
                ...state,
                open: false,
                sending: false
            };
        });
    }

    onClose() {
        this.closeDialog();
    }

    canSend() :boolean {
        return !this.state.sending && this.state.selectedContacts.length > 0 && this.state.emailSubject.length > 0 && this.state.emailBody.length > 0;
    }

    generateContactList(): Array<Contact> {
        let contacts: Array<Contact> = [];

        if (this.props.patients) {
            this.props.patients.map((contact: Patient) => {
                if (contact.email)
                    contacts.push(new Contact({
                        _id: contact._id,
                        firstName: contact.firstName,
                        lastName: contact.lastName,
                        email: contact.email,
                        phone: contact.phoneCell,
                        fileNumber: contact.fileNumber
                    }));
                return null;
            })
        }

        if (this.props.users) {
            this.props.users.map((contact: User) => {
                if (contact.email)
                    contacts.push(new Contact({
                        _id: contact._id,
                        firstName: contact.firstName,
                        lastName: contact.lastName,
                        email: contact.email,
                        phone: contact.phone
                    }));
                return null;
            });
        }

        contacts.sort((a, b): number => {
            let sort = 0;

            sort = (a.firstName || 'zz').localeCompare(b.firstName || 'zz');
            if (sort !== 0) return sort;

            sort = (a.lastName || 'zz').localeCompare(b.lastName || 'zz');
            if (sort !== 0) return sort;

            return sort;
        });

        return contacts;
    }

    getDefaultContacts(contacts: Array<Contact>): Array<Contact> {
        let defaultContacts: Array<Contact> = [];

        if (this.props.defaultContacts) {
            contacts.map((contact) => {
                if (this.props.defaultContacts) {
                    for (let defaultContact of this.props.defaultContacts)
                        if (contact._id === defaultContact._id)
                            defaultContacts.push(contact);
                }
                return null;
            });
        }

        return defaultContacts;
    }

    getContactLabel(contact: Contact | string): string {
        if (typeof contact === 'string') return contact;

        let label: string = '';

        if (contact.fileNumber) label = `${contact.fileNumber} `;
        if (contact.firstName) label += `${contact.firstName} `;
        if (contact.lastName) label += contact.lastName;

        return label;
    }

    setSelectedContacts(selectedContacts: Array<Contact | string>) {
        let sendToExtern: boolean = false;
        selectedContacts = selectedContacts.filter((selectedContact) => {
            if (typeof selectedContact === 'string') {
                if (validateEmail(selectedContact)) {
                    sendToExtern = true;
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        })
        this.setState((state: IState) => {return {...state, sendToExtern, selectedContacts};});
    }

    setEmailSubject(emailSubject: string) {
        this.setState((state: IState) => {return {...state, emailSubject};});
    }

    setEmailBody(emailBody: string) {
        this.setState((state: IState) => {return {...state, emailBody};});
    }

    setSending(sending: boolean): Promise<void> {
        return new Promise((resolve) => {
            this.setState((state: IState) => {
                return {...state, sending};
            }, () => {resolve();});
        });
    }

    async send() {
        await this.setSending(true);
        await this.props.onSend(
            this.state.selectedContacts,
            this.state.emailSubject,
            this.state.emailBody
        );
        await this.setSending(false);
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.closeDialog.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    {
                        this.state.sendToExtern ?
                            <Box sx={{p: 2}}>
                                <Alert
                                    severity={'warning'}
                                    sx={{width: '100%'}}
                                    variant={'filled'}
                                >
                                    <Text>You will send email to external contact</Text>
                                </Alert>
                            </Box>
                            :
                            null
                    }
                    <Box sx={{p: 2}}>
                        <Autocomplete
                            multiple
                            freeSolo
                            options={this.state.contacts}
                            getOptionLabel={(option) => this.getContactLabel(option)}
                            defaultValue={this.state.defaultContacts}
                            value={this.state.selectedContacts}
                            onChange={(event, contacts) => {this.setSelectedContacts(contacts);}}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    label={localize('To', this.props.lang)}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={{p: 2}}>
                        <TextField
                            label={localize('Email subject', this.props.lang)}
                            type={'text'}
                            sx={{width: '100%'}}
                            value={this.state.emailSubject || ''}
                            onChange={(e) => {this.setEmailSubject(e.target.value)}}
                        />
                    </Box>
                    <Box sx={{p: 2}}>
                        <TextField
                            label={localize('Email body', this.props.lang)}
                            type={'text'}
                            sx={{width: '100%'}}
                            value={this.state.emailBody || ''}
                            onChange={(e) => {this.setEmailBody(e.target.value)}}
                            multiline={true}
                            rows={5}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeDialog.bind(this)} variant={'contained'} color={'error'}>
                        <Text>Cancel</Text>
                    </Button>
                    <Button onClick={this.send.bind(this)} variant={'contained'} color={'success'} autoFocus disabled={!this.canSend()}>
                        <Text>Send</Text>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SendToDialog);
