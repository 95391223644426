import React, {ReactNode} from 'react';
import {connect} from 'react-redux';
import {
    Box,
    Dialog,
    Grid,
    IconButton, DialogTitle, DialogActions, Button
} from '@mui/material';
import {AppDispatch, RootState} from "../../../store/store";
import Text from '../../app/text/text';
import {logException} from "../../../controllers/system";
import { HexColorPicker } from "react-colorful";

type IProps = {
    onClose?: () => void,
    onColorSelected: (color: string) => void
}

type IState = {
    open: boolean,
    selectedColor?: string,
    changed: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class ColorPicker extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        open: false,
        changed: false
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog(selectedColor?: string) {
        this.setState((state: IState) => {
            return {
                ...state,
                open: true,
                changed: false,
                selectedColor
            };
        });
    }

    closeDialog() {
        this.setState((state: IState) => {
            return {
                ...state,
                open: false
            };
        });
    }

    renderColorSelector(color: string): ReactNode {
        return (
            <Grid item sx={{p: 1, textAlign: 'center'}} xs={2}>
                <IconButton
                    onClick={this.onColorSelected.bind(this, color)}
                    sx={{backgroundColor: this.state.selectedColor === color ? 'rgba(255,255,255,0.4)' : 'transparent'}}
                >
                    <Box sx={{mr: 'auto', ml: 'auto', borderRadius: '50%', width: '30px', height: '30px', backgroundColor: color, cursor: 'pointer'}}/>
                </IconButton>
            </Grid>
        );
    }

    onColorSelected(selectedColor: string) {
        this.setState((state: IState) => {
            return {
                ...state,
                selectedColor,
                changed: true
            };
        }, () => {

        });
    }

    onSelectionEnd() {
        this.props.onColorSelected(this.state.selectedColor as string);
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.props.onClose}
            >
                <DialogTitle>
                    <Text>Choose a color</Text>
                </DialogTitle>
                <Box sx={{p: 2, display: 'flex', justifyContent: 'center'}}>
                    <HexColorPicker color={this.state.selectedColor || 'white'} onChange={this.onColorSelected.bind(this)} />
                </Box>
                <DialogActions>
                    <Button variant={'contained'} color={'error'} onClick={this.closeDialog.bind(this)}>
                        <Text>Close</Text>
                    </Button>
                    <Button variant={'contained'} color={'success'} onClick={this.onSelectionEnd.bind(this)} autoFocus disabled={!this.state.changed}>
                        <Text>Select</Text>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ColorPicker);
