import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../../store/store";
import {IAppNotification} from "../../../../../models/appNotification";
import {setAppNotification} from "../../../../../store/actions/app";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem, Tab, Tabs,
    TextField
} from "@mui/material";
import Text from "../../../../app/text/text";
import User from "../../../../../models/user";
import {ROLES} from "../../../../../constants/roles";
import {localize} from "../../../../../helpers/localization";
import {sendInvite, validUniqueEmail} from "../../../../../controllers/user";
import {getEnumValues} from "../../../../../helpers/enums";
import {validateEmail, validatePhone} from "../../../../../helpers/string";
import InputMask from "react-input-mask";
import {logException} from "../../../../../controllers/system";

type IState = {
    open: boolean,
    valid: boolean,
    firstName: string,
    lastName: string,
    email: string,
    name: string,
    phone: string,
    role: ROLES | null,
    formValidation: any,
    panel: number
}

type IProps = {
    onInviteSent: () => void
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class InviteUserDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        open: false,
        valid: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        name: '',
        role: null,
        formValidation: {},
        panel: 0
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog() {
        this.setState((state: IState) => {
            return {
                ...state,
                open: true,
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                role: null,
                formValidation: {}
            };
        });
    }

    closeDialog() {
        this.setState((state: IState) => {
            return {...state, open: false};
        });
    }

    setPanel(panel: number) {
        this.setState((state: IState) => {
            return {
                ...state,
                panel
            };
        });
    }

    onChange(property: string, value: any) {
        switch(property) {
            case 'role':
                value = value as ROLES;
                break;
        }

        this.setState((state: IState) => {
            (state as any)[property] = value;
            return {...state};
        });
    }

    async validateInvite():Promise<boolean> {
        let formValidation: any = {};

        if (this.state.email.trim().length === 0
            || !validateEmail(this.state.email)
            || !await validUniqueEmail(this.state.email)
        ) formValidation.email = 'error';

        if (this.state.phone.trim().length > 0 && !validatePhone(this.state.phone)) formValidation.phone = 'error';

        if (this.state.panel === 0) {
            if (!this.state.role) formValidation.role = 'error';
        } else if (this.state.panel === 1) {
            if (!this.state.firstName) formValidation.firstName = 'error';
            if (!this.state.lastName) formValidation.lastName = 'error';
        }

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return Object.keys(formValidation).length === 0;
    }

    getNewUser(): User | undefined {
        if (this.state.panel === 0) {
            return new User({
                email: this.state.email,
                phone: this.state.phone,
                role: this.state.role || undefined,
                contractor: false
            });
        } else if (this.state.panel === 1) {
            return new User({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                contractor: true
            });
        }
    }

    sendInvite() {
        this.validateInvite().then((valid: boolean) => {
            if (valid) {
                let newUser: User | undefined = this.getNewUser();
                if (newUser) {
                    // send invite
                    sendInvite(newUser).then((user: User) => {
                        // notify
                        this.props.onInviteSent();
                        this.closeDialog();
                    });
                }
            }
        });

    }

    render() {
        return (
            <Dialog open={this.state.open}>
                <DialogTitle><Text>Invite user</Text></DialogTitle>
                <DialogContent>
                    <Box>
                        <Tabs value={this.state.panel}>
                            <Tab label={localize('User', this.props.lang)} onClick={this.setPanel.bind(this, 0)} />
                            <Tab label={localize('Contractor', this.props.lang)} onClick={this.setPanel.bind(this, 1)} />
                        </Tabs>
                    </Box>
                    <Box sx={{width: '350px'}}>
                        <Box sx={{p: 2}} hidden={this.state.panel !== 1}>
                            <TextField
                                id={'firstName'}
                                sx={{width: '100%'}}
                                label={localize('First name', this.props.lang)}
                                onChange={((e: any) => {this.onChange('firstName', e.target.value)})}
                                error={this.state.formValidation.firstName === 'error'}
                            />
                        </Box>
                        <Box sx={{p: 2}} hidden={this.state.panel !== 1}>
                            <TextField
                                id={'lastName'}
                                sx={{width: '100%'}}
                                label={localize('Last name', this.props.lang)}
                                onChange={((e: any) => {this.onChange('lastName', e.target.value)})}
                                error={this.state.formValidation.lastName === 'error'}
                            />
                        </Box>
                        <Box sx={{p: 2}}>
                            <TextField
                                id={'email'}
                                sx={{width: '100%'}}
                                label={localize('Email', this.props.lang)}
                                onChange={((e: any) => {this.onChange('email', e.target.value)})}
                                error={this.state.formValidation.email === 'error'}
                            />
                        </Box>
                        <Box sx={{p: 2}}>
                            <InputMask
                                mask="(+1) (999) 999-9999"
                                value={this.state.phone}
                                disabled={false}
                                onChange={((e: any) => {this.onChange('phone', e.target.value)})}
                            >
                                {() => <TextField
                                    id={'phone'}
                                    sx={{width: '100%'}}
                                    label={localize('Phone', this.props.lang)}
                                    error={this.state.formValidation.phone === 'error'}
                                />}
                            </InputMask>

                        </Box>
                        <Box sx={{p: 2}} hidden={this.state.panel !== 0}>
                            <TextField
                                id={'role'}
                                sx={{width: '100%'}}
                                select
                                value={this.state.role || ''}
                                label={localize('Role', this.props.lang)}
                                onChange={((e: any) => {this.onChange('role', e.target.value)})}
                                error={this.state.formValidation.role === 'error'}
                            >
                                {getEnumValues(ROLES).map((value: number) => (
                                    <MenuItem key={value} value={value}>
                                        {localize(ROLES[value], this.props.lang)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} sx={{mr: 2}} color={'error'} onClick={this.closeDialog.bind(this)}><Text>Cancel</Text></Button>
                    <Button variant={'contained'} color={'success'} onClick={this.sendInvite.bind(this)}><Text>Send</Text></Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(InviteUserDialog);
