import {Contract} from "./contract";

export enum ACCOUNT_STATUS {
    ACTIVE = 1,
    INACTIVE = 2
}

class Account {
    public _id?: string = undefined;
    public name?: string = undefined;
    public status?: ACCOUNT_STATUS = undefined;
    public contracts?: Contract[] = [];
    public system?: boolean = undefined;
    public allowedPatients?: AllowedPatient[] = [];

    static collection: string = "account";

    public constructor(init?:Partial<Account>) {
        Object.assign(this, init);
    }
}

export class AllowedPatient {
    public index?: number = undefined;
    public firstName?: string = undefined;
    public lastName?: string = undefined;
    public birthdate?: Date = undefined;

    public constructor(init?:Partial<AllowedPatient>) {
        Object.assign(this, init);
    }
}

export {Account};
