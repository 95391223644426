import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {localize} from "../../../../helpers/localization";
import FullScreenDialog from "../../../ui/fullScreenDialog/fullScreenDialog";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {logException} from "../../../../controllers/system";
import {Announcement, IMPORTANCE} from "../../../../models/announcement";
import {getImportanceColor, removeAnnouncement, saveAnnouncement} from "../../../../controllers/announcement";
import {Grid, MenuItem, TextField} from "@mui/material";
import {dayjsOrAny} from "../../../../helpers/dayjs";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Text from "../../../app/text/text";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";

type IState = {
    readonly: boolean,
    announcement: Announcement | null,
    changed: boolean,
    formValidation: any,
}

type IProps = {
    onSave: (announcement?: Announcement) => void,
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat,
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class AnnouncementDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        readonly: false,
        announcement: null,
        changed: false,
        formValidation: {},
    }

    private editDialogRef: RefObject<any>;
    private confirmDeleteDialogRef: RefObject<any>;
    private confirmCloseDialogRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.editDialogRef = React.createRef();
        this.confirmDeleteDialogRef = React.createRef();
        this.confirmCloseDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog(announcement: Announcement) {
        this.setState((state: IState) => {
            return {
                ...state,
                announcement: Object.assign({}, announcement),
                changed: false,
                formValidation: {},
            };
        }, () => {
            this.editDialogRef.current.openDialog();
        });
    }

    closeDialogRequest(): boolean {
        if (this.state.changed) {
            this.confirmCloseDialogRef.current?.openDialog();
            return false
        } else {
            this.closeDialog();
            return true;
        }
    }

    closeDialog() {
        this.editDialogRef.current.closeDialog();
    }

    onAnnouncementDetailButtonClick(key: string) {
        switch(key) {
            case 'delete':
                this.confirmDeleteDialogRef.current.openDialog();
                break;
            case 'save':
                this.saveAnnouncement();
                break;
        }
    }

    validateAnnouncement(): boolean {
        let formValidation: any = {};

        if (!this.state.announcement?.title) {
            formValidation.title = 'error';
        }

        if (!this.state.announcement?.message) {
            formValidation.message = 'error';
        }

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return Object.keys(formValidation).length === 0;
    }

    async deleteAnnouncement() {
        if (this.state.announcement && this.state.announcement._id) {
            await removeAnnouncement(this.state.announcement._id as string);
            this.props.onSave();
            this.closeDialog();
        }
    }

    saveAnnouncement(callback? : () => void) {
        if (this.validateAnnouncement()) {
            saveAnnouncement(this.state.announcement as Announcement).then((announcement: Announcement | null) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        changed: false,
                        announcement
                    };
                }, () => {
                    this.props.setAppNotification({
                        severity: 'success',
                        message: 'Successfully saved'
                    });
                    this.props.onSave(announcement as Announcement);
                    if (callback) callback();
                });
            }).catch(() => {
                this.props.setAppNotification({
                    severity: 'error',
                    message: 'An error occurred, please try again'
                });
            });
        }
    }

    onChange(property: string, value: any, change: boolean = true) {
        this.setState((state: IState) => {
            let announcement: any = Object.assign({}, state.announcement);

            switch(property) {
                case 'expiryDate':
                    if (value) {
                        value = dayjs(value).endOf('day');
                    } else {
                        value = undefined;
                    }
                    break;
            }

            announcement[property] = value;
            return {
                ...state,
                announcement,
                changed: state.changed || change
            }
        });
    }



    render() {
        return (
            <FullScreenDialog
                ref={this.editDialogRef}
                title={`${localize('Announcement', this.props.lang)}`}
                onButtonClick={this.onAnnouncementDetailButtonClick.bind(this)}
                onCloseRequest={this.closeDialogRequest.bind(this)}
                buttons={[
                    this.state.announcement?._id ? {key: 'delete', text: localize('Delete', this.props.lang), color: 'error', keepDialogOpen: true, disabled: this.state.changed} : null,
                    {key: 'save', text: localize('Save', this.props.lang), color: 'success', keepDialogOpen: true, disabled: !this.state.changed}
                ]}
            >
                <Grid container>
                    <Grid item  sx={{p:2}} md={4} xs={12}>
                        <TextField
                            required
                            error={this.state.formValidation.title === 'error'}
                            id={'title'}
                            sx={{width: '100%'}}
                            label={localize('Title', this.props.lang)}
                            defaultValue={this.state.announcement?.title}
                            onChange={(e) => this.onChange('title', e.target.value)}
                        />
                    </Grid>
                    <Grid item  sx={{p:2}} md={4} xs={12}>
                        <TextField
                            id="importance"
                            sx={{
                                width: '100%'
                            }}
                            color={getImportanceColor(this.state.announcement?.importance || IMPORTANCE.None)}
                            focused
                            select
                            label={localize('Importance', this.props.lang)}
                            value={this.state.announcement?.importance || IMPORTANCE.None}
                            onChange={((e: any) => {this.onChange('importance', e.target.value)})}
                            InputProps={{
                                readOnly: this.state.readonly,
                            }}
                        >
                            <MenuItem value={IMPORTANCE.None}>
                                <Text>Information</Text>
                            </MenuItem>
                            <MenuItem value={IMPORTANCE.Low}>
                                <Text>Normal</Text>
                            </MenuItem>
                            <MenuItem value={IMPORTANCE.Medium}>
                                <Text>Warning</Text>
                            </MenuItem>
                            <MenuItem value={IMPORTANCE.High}>
                                <Text>Urgent</Text>
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item  sx={{p:2}} xs={12}>
                        <TextField
                            required
                            error={this.state.formValidation.message === 'error'}
                            id={'message'}
                            sx={{width: '100%'}}
                            label={localize('Message', this.props.lang)}
                            defaultValue={this.state.announcement?.message}
                            onChange={(e) => this.onChange('message', e.target.value)}
                            multiline={true}
                            rows={3}
                        />
                    </Grid>
                    <Grid item  sx={{p:2}} md={4} xs={12}>
                        <DatePicker
                            sx={{width: '100%'}}
                            label={localize('Expiry', this.props.lang)}
                            value={dayjsOrAny(this.state.announcement?.expiryDate)}
                            format={this.props.dateFormat}
                            onChange={((newValue: any) => {
                                this.onChange('expiryDate', dayjs(newValue).toDate());
                            })}
                            slotProps={{textField: { error: this.state.formValidation.expiry === 'error'}}}
                            minDate={dayjs(this.state.announcement?.expiryDate || new Date())}
                        />
                    </Grid>
                </Grid>
                <ConfirmDialog
                    ref={this.confirmDeleteDialogRef}
                    title={localize('Delete this announcement?', this.props.lang)}
                    text={localize('Are you sure you want to delete this announcement?', this.props.lang)}
                    onAgree={this.deleteAnnouncement.bind(this)}
                />
                <ConfirmDialog
                    ref={this.confirmCloseDialogRef}
                    title={localize('Unsaved changes', this.props.lang)}
                    text={localize('All unsaved changes will be lost. Do you want to continue?', this.props.lang)}
                    onAgree={this.closeDialog.bind(this)}
                />
            </FullScreenDialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(AnnouncementDialog);
