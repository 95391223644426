import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Grid} from "@mui/material";
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import ContactRequests from "../graphs/contactRequests";
import {logException} from "../../../../controllers/system";
import HoursSummary from "../graphs/hoursSummary";
Chart.register(ArcElement, Tooltip, Legend);

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class AdministratorDashboard extends React.Component<ReduxType> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    render() {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} md={6} lg={4} sx={{p:2}}>
                        <ContactRequests />
                    </Grid>
                    <Grid item xs={12} md={6} lg={8} sx={{p:2}}>
                        <HoursSummary />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorDashboard);
