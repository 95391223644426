import {get, post} from "../helpers/ajax";
import {AxiosResponse} from "axios";
import {Announcement, IMPORTANCE} from "../models/announcement";

export async function getAnnouncements(): Promise<Announcement[]> {
    let response: AxiosResponse<Announcement[]> = await get<Announcement[]>('/announcement/getAll');

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function removeAnnouncement(announcementId: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>('/announcement/remove', {announcementId});

    if (response.status === 200 && response.data)
        return response.data;
    else
        return false;
}

export async function saveAnnouncement(announcement: Announcement): Promise<Announcement | null> {
    let response: AxiosResponse<Announcement | null> = await post<Announcement | null>('/announcement/save', announcement);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}

export function getImportanceColor(importance: IMPORTANCE): 'success' | 'info' | 'warning' | 'error' {
    switch(importance) {
        case IMPORTANCE.Low:
            return "success";
        case IMPORTANCE.Medium:
            return "warning";
        case IMPORTANCE.High:
            return "error";
        default:
            return "info";
    }
}
