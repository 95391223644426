import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {logException} from "../../../controllers/system";
import {getConfig} from "../../../store/actions/settings";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';

type IProps = {
    children: React.ReactElement,
    onVerify: (token: string) => void
}

type IState = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        googleRecaptchaKey: state.settings?.configs?.GOOGLE_RECAPTCHA_KEY
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        getConfig: () => {
            dispatch(getConfig('GOOGLE_RECAPTCHA_KEY'));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Recaptcha extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
    };
    componentDidMount() {
        if (!this.props.googleRecaptchaKey) {
            this.props.getConfig();
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    handleVerify(token: string) {
        this.props.onVerify(token);
    }

    render() {
        if (!this.props.googleRecaptchaKey) return null;
        return (
            <GoogleReCaptchaProvider reCaptchaKey={this.props.googleRecaptchaKey}>
                {
                    this.props.children
                }
                <GoogleReCaptcha onVerify={this.handleVerify.bind(this)} />
            </GoogleReCaptchaProvider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recaptcha);
