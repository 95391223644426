import dayjs from "dayjs";

class Email{
    public _id?: string = undefined;
    public fromUserId?: string = undefined;
    public toUserId?: string = undefined;
    public sentOn?: Date = undefined;
    public readOn?: Date = undefined;
    public subject?: string = undefined;
    public message?: string = undefined;

    public constructor(init?:Partial<Email>) {
        if (init && init.sentOn) init.sentOn = dayjs(init.sentOn).isValid() ? dayjs(init.sentOn).toDate() : undefined;
        if (init && init.readOn) init.readOn = dayjs(init.readOn).isValid() ? dayjs(init.readOn).toDate() : undefined;
        Object.assign(this, init);
    }
}

export {Email};
