import React from 'react';
import {connect} from 'react-redux';
import {
    AutocompleteValue, TextField, Autocomplete
} from '@mui/material';
import {AppDispatch, RootState} from "../../../store/store";
import {localize} from "../../../helpers/localization";
import {logException} from "../../../controllers/system";
import {getConfig} from "../../../store/actions/settings";
import load from 'load-script';

type IProps = {
    defaultValue?: string,
    onChange: (value: string) => void
}

type IState = {
    suggestions: any[],
    googleScriptLoaded: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        googlePlacesAPIKey: state.settings?.configs?.GOOGLE_PLACES_APIKEY
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        getConfig: () => {
            dispatch(getConfig('GOOGLE_PLACES_APIKEY'));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class AddressPicker extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        suggestions: [],
        googleScriptLoaded: false
    };
    private service: any = null;
    private searchTimeout: number | undefined = undefined;
    private defaultValue: any = null;

    constructor(props: ReduxType) {
        super(props);
        this.defaultValue = this.getDefaultValue();
    }

    componentDidMount() {
        if (!this.props.googlePlacesAPIKey) {
            this.props.getConfig();
        } else {
            this.loadScript();
        }
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        if (!prevProps.googlePlacesAPIKey && this.props.googlePlacesAPIKey) {
            this.loadScript();
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    loadScript() {
        if (!window.google) {
            load(`https://maps.googleapis.com/maps/api/js?key=${this.props.googlePlacesAPIKey}&libraries=places`, {async: true}, () => {
                this.setGoogleScriptLoaded();
            });
        } else {
            this.setGoogleScriptLoaded();
        }
    }

    setGoogleScriptLoaded() {
        this.service = new window.google.maps.places.AutocompleteService();
        this.setState((state: IState) => {
            return {
                ...state,
                googleScriptLoaded: true
            };
        });
    }

    async search(input: string) {
        if (!this.service) return;
        window.clearTimeout(this.searchTimeout);
        this.searchTimeout = window.setTimeout(() => {
            this.service.getPlacePredictions(
                {
                    input
                },
                (suggestions?: any[]) => {
                    if (!suggestions) suggestions = [];
                    this.setState((state: IState) => {
                        return {...state, suggestions: suggestions || []}
                    })
                });
        }, 500);

    }

    onChange(value: any) {
        this.props.onChange(value?.description);
    }

    getSuggestions() {
        if (this.state.suggestions.length > 0) {
            return this.state.suggestions;
        }
        if (this.props.defaultValue !== undefined) return [{description: this.props.defaultValue}];
        return [];
    }

    getDefaultValue() {
        if (this.props.defaultValue === undefined) return undefined;
        return {description: this.props.defaultValue};
    }

    render() {
        return (
            <Autocomplete
                sx={{width: '100%'}}
                disabled={!this.props.googlePlacesAPIKey || !this.state.googleScriptLoaded}
                isOptionEqualToValue={(option, value) => option.description === value.description}
                getOptionLabel={(option) => option?.description || ''}
                options={this.getSuggestions()}
                onChange={((e: any, value: AutocompleteValue<any, any, any, any>) => {this.onChange(value);})}
                onInputChange={(event, newInputValue) => {
                    this.search(newInputValue);
                }}
                defaultValue={this.defaultValue}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        autoComplete={'off'}
                        label={localize('Address', this.props.lang)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressPicker);
