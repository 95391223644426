import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {Box} from '@mui/material';
import {AppDispatch, RootState} from "../../../store/store";
import {logException} from "../../../controllers/system";

type IProps = {
    children: ReactElement | ReactElement[]
}

const mapStateToProps = (state: RootState) => {
    return {
        menuState: state.app.menuState
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Main extends React.Component<ReduxType> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    render() {
        return (
            <Box component="main" sx={(theme) => ({
                flexGrow: 1,
                p: 3,
                overflow: 'auto',
                marginLeft: this.props.menuState === 'open' ? 0 : '-250px',
                transition: theme.transitions.create(
                ['margin-left'],
                { duration: this.props.menuState === 'open' ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen }
                )
            })} height={'100%'}>
                {this.props.children}
            </Box>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
