import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {
    Box,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {localize} from "../../../helpers/localization";
import Text from '../../app/text/text';
import Schedule from "./components/schedule";
import Signature from "./components/signature";
import Security from "./components/security";
import {logException} from "../../../controllers/system";
import NotificationSettings from "./components/notificationSettings";
import ScheduleModel from "../../../models/schedule";
import {saveSchedule} from "../../../store/actions/user";
import ProfessionalLicence from "./components/licence";
import UserSettingsView from "./components/userSettings";

type IState = {
    formValidation: any,
    panel: number
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat,
        userId: state.user._id,
        schedule: state.user.schedule.base
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        saveSchedule: (userId: string, schedule: ScheduleModel) => {
            dispatch(saveSchedule(userId, schedule));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Profile extends React.Component<ReduxType, IState> {
    public state: IState = {
        formValidation: {},
        panel: 0
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    setPanel(index:number) {
        this.setState((state: IState) => {return {...state, panel: index};});
    }

    saveSchedule(schedule: ScheduleModel) {
        this.props.saveSchedule(this.props.userId, schedule);
    }

    render() {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }} height={'100%'}>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                    <Box>
                        <Typography variant={'h4'}>
                            <Text>Profile</Text>
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={this.state.panel}>
                            <Tab label={localize('Schedule', this.props.lang)} onClick={this.setPanel.bind(this, 0)} />
                            <Tab label={localize('Identity', this.props.lang)} onClick={this.setPanel.bind(this, 1)} />
                            <Tab label={localize('Security', this.props.lang)} onClick={this.setPanel.bind(this, 2)} />
                            <Tab label={localize('Notifications', this.props.lang)} onClick={this.setPanel.bind(this, 3)} />
                            <Tab label={localize('Settings', this.props.lang)} onClick={this.setPanel.bind(this, 4)} />
                        </Tabs>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        <Schedule visible={this.state.panel === 0} userId={this.props.userId} schedule={this.props.schedule} saveSchedule={this.saveSchedule.bind(this)} />
                        {
                            this.state.panel === 1 ?
                                <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                                    <Box sx={{p: 2}}>
                                        <Signature visible={this.state.panel === 1} />
                                    </Box>
                                    <hr style={{width: '100%'}}/>
                                    <ProfessionalLicence userId={this.props.userId} visible={this.state.panel === 1} />
                                </div>
                                : null
                        }
                        <Security visible={this.state.panel === 2} />
                        <NotificationSettings visible={this.state.panel === 3} />
                        <UserSettingsView visible={this.state.panel === 4} />
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
