import {connect} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import Layout from "../components/ui/layout/layout";
import ExternalRouter from "./router/router";
import AppNotifications from "../../components/ui/alerts/notification";
import React from "react";

interface IProps {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

function App(props: ReduxType) {
    return (
        <Layout>
            <AppNotifications />
            <ExternalRouter />
        </Layout>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
