import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {
    Alert,
    Autocomplete,
    AutocompleteValue,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import Text from "../../../app/text/text";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import NotesIcon from '@mui/icons-material/Notes';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ApartmentIcon from '@mui/icons-material/Apartment';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import {ContactRequest} from "../../../../models/contactRequest";
import {Patient, PATIENT_STATUS} from "../../../../models/patient";
import Meeting, {
    COMMUNCATION_CHANNEL,
    MEETING_STATUS,
    MeetingWithPatient,
    PATIENT_ANSWER
} from "../../../../models/meeting";
import {LoadingButton} from "@mui/lab";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {localize} from "../../../../helpers/localization";
import dayjs from "dayjs";
import User, {USER_STATUS} from "../../../../models/user";
import {getUserFullName, isAvailable} from "../../../../controllers/user";
import {
    formatFileNumber,
    getPatient,
    getPatients,
    getRemainingMeetings,
    hasUnusedContractExtension,
    IMeetingCount
} from "../../../../controllers/patients";
import {
    getMeetings,
    MeetingCreationResult,
    saveMeeting,
    scheduleBestMeeting,
    setMeetingStatus
} from "../../../../controllers/meetings";
import {getContactRequest} from "../../../../controllers/contactRequests";
import FollowUpMeetingDialog from "./followUpMeetingDialog";
import Business from "@mui/icons-material/Business";
import Phonelink from "@mui/icons-material/Phonelink";
import {Account} from "../../../../models/account";
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import EventIcon from '@mui/icons-material/Event';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";
import {ORGANIZATION_LINK} from "../../../../constants/enums";
import {getEnumValues} from "../../../../helpers/enums";
import PatientDialog from "../../patients/components/dialog";
import AccountDialog from "../../accounts/components/dialog";
import FollowUp from "../../../../models/followUp";
import OneDaySchedule from "./oneDaySchedule";
import {ROLES} from "../../../../constants/roles";
import {logException} from "../../../../controllers/system";
import {Place} from "../../../../models/place";
import {isPatientAllowedForAccount} from "../../../../controllers/account";
import {dayjsOrAny} from "../../../../helpers/dayjs";
import {SignedDocument} from "../../../../models/signedDocument";

type IProps = {
    professionals: User[],
    accounts: Account[],
    places: Place[],
    signedDocuments: SignedDocument[],
    onMeetingChanged: () => void
}

type IState = {
    open: boolean,
    contactRequest?: ContactRequest,
    patient?: Patient,
    meeting?: Meeting,
    formValidation: any,
    patientListSearch: string,
    patientListLoading: boolean,
    patientList: Patient[],
    changed: boolean,
    dateChanged: boolean,
    saving: boolean,
    meetingHistory: Meeting[],
    remainingMeetings: number,
    canUseExtension?: boolean,
    openLinkMenu: boolean,
    allowedPatientForAccount: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        dateFormat: state.settings.dateFormat,
        user: state.user
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class MeetingDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        open: false,
        formValidation: {},
        patientListSearch: '',
        patientListLoading: false,
        patientList: [],
        changed: false,
        dateChanged: false,
        saving: false,
        meetingHistory: [],
        remainingMeetings: Infinity,
        openLinkMenu: false,
        allowedPatientForAccount: true
    }

    private followUpDialogRef: RefObject<any>;
    private completeFollowUpConfirmDialog: RefObject<any>;
    private uncompleteFollowUpConfirmDialog: RefObject<any>;
    private cancelMeetingConfirmDialog: RefObject<any>;
    private meetingMissedConfirmDialog: RefObject<any>;
    private restoreMeetingConfirmDialog: RefObject<any>;
    private confirmCloseDialogRef: RefObject<any>;
    private patientDialogRef: RefObject<any>;
    private accountDialogRef: RefObject<any>;
    private confirmUseExtensionDialogRef: RefObject<any>;
    private confirmNotAvailableDialogRef: RefObject<any>;
    private oneDayScheduleRef: RefObject<any>;
    private linkRef: RefObject<any>;
    private specialAccount: string | undefined = undefined;
    private systemAccounts: string[] = [];

    constructor(props: ReduxType) {
        super(props);
        this.followUpDialogRef = React.createRef();
        this.completeFollowUpConfirmDialog = React.createRef();
        this.uncompleteFollowUpConfirmDialog = React.createRef();
        this.cancelMeetingConfirmDialog = React.createRef();
        this.meetingMissedConfirmDialog = React.createRef();
        this.restoreMeetingConfirmDialog = React.createRef();
        this.confirmCloseDialogRef = React.createRef();
        this.patientDialogRef = React.createRef();
        this.accountDialogRef = React.createRef();
        this.confirmUseExtensionDialogRef = React.createRef();
        this.confirmNotAvailableDialogRef = React.createRef();
        this.oneDayScheduleRef = React.createRef();
        this.linkRef = React.createRef();
        this.setSpecialAccounts();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        this.setSpecialAccounts();
    }

    setSpecialAccounts() {
        if (this.specialAccount) return;
        this.props.accounts.map((account: Account) => {
            if (account.name === 'Spécial') this.specialAccount = account._id;
            if (account.name === 'IVAC' || account.name === 'CNESST') this.systemAccounts.push(account._id as string);
            return null;
        });
    }

    resetState(open: boolean): Promise<void> {
        return new Promise<void>((resolve) => {
            this.setState((state: IState) => {
                return {
                    open,
                    formValidation: {},
                    patientListSearch: '',
                    patientListLoading: false,
                    patientList: [],
                    changed: false,
                    dateChanged: false,
                    saving: false,
                    meetingHistory: [],
                    remainingMeetings: Infinity,
                    canUseExtension: undefined,
                    meeting: undefined,
                    patient: undefined,
                    contactRequest: undefined,
                    allowedPatientForAccount: true
                };
            }, resolve);
        });
    }

    openDialog(patient?: Patient, contactRequest?: ContactRequest, meeting?: Meeting) {
        this.resetState(true).then(() => {
            if (meeting) meeting = Object.assign({}, meeting);
            this.loadData(patient, contactRequest, meeting).then(([patient, contactRequest, meeting]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        open: true,
                        changed: !meeting._id,
                        contactRequest,
                        patient,
                        meeting
                    };
                }, () => {
                    this.loadPreviousMeetings();
                    this.loadRemainingMeetings();
                    this.loadLastPatientMeeting();
                    this.isPatientAllowedForAccount();
                });
            });
        });

    }

    async loadData(patient?: Patient, contactRequest?: ContactRequest, meeting?: Meeting): Promise<any> {
        if (!meeting) {
            meeting = new Meeting({
                date: dayjs().startOf('day').toDate(),
                timeFrom: dayjs().add(2, 'hour').startOf('hour').toDate(),
                timeTo: dayjs().add(3, 'hour').startOf('hour').toDate()
            });
        } else {
            if (meeting.timeFrom) {
                meeting.date = dayjs(meeting.timeFrom).startOf('day').toDate();
                meeting.timeFrom = dayjs(meeting.timeFrom).toDate();
            }
            if (meeting.timeTo) meeting.timeTo = dayjs(meeting.timeTo).toDate();
        }
        if (!meeting._id && this.props.user.role === ROLES.Professional) meeting.professionalUserId = this.props.user._id;
        if (!patient && meeting?.patientId) patient = await getPatient(meeting.patientId) || undefined;
        if (!contactRequest && meeting?.contactRequestId) contactRequest = await getContactRequest(meeting.contactRequestId) || undefined;
        if (patient && !meeting._id) {
            meeting.patientId = patient._id;
        }
        if (contactRequest && !meeting._id) {
            meeting.contactRequestId = contactRequest._id;
            meeting.accountId = contactRequest.organization;
            meeting.organizationLink = contactRequest.organizationLink;
        }
        if (meeting.accountId === '-1') {
            meeting.accountId = undefined;
            meeting.organizationLink = undefined;
            meeting.paidByPatient = true;
        }
        return [patient, contactRequest, meeting];
    }

    loadPreviousMeetings() {
        if (this.state.meeting?.patientId) {
            getMeetings({
                patientId: this.state.meeting?.patientId,
                meetingStatus: [
                    MEETING_STATUS.Created,
                    MEETING_STATUS.Confirmed,
                    MEETING_STATUS.Completed,
                    MEETING_STATUS.Canceled,
                    MEETING_STATUS.Reported,
                    MEETING_STATUS.Missed
                ]
            }, false).then((meetings: MeetingWithPatient[]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        meetingHistory: meetings.map((m) => {
                            return new Meeting(m);
                        })
                    };
                });
            });
        } else {
            this.setState((state: IState) => {
                return {...state, meetingHistory: []}
            });
        }
    }

    loadRemainingMeetings() {
        let meeting: Meeting | undefined = this.state.meeting;
        if (meeting
            && meeting.patientId
            && meeting.accountId
            && (meeting.organizationLink
                || this.systemAccounts.indexOf(meeting.accountId) !== -1)
            && meeting.accountId !== this.specialAccount) {
            getRemainingMeetings(meeting.date || new Date(), meeting.patientId, meeting.accountId, meeting.organizationLink, this.state.canUseExtension || false).then((meetingCount: IMeetingCount | null) => {
                this.setState((state: IState) => {
                    let meeting: any = Object.assign({}, state.meeting);
                    meeting.contractCode = meetingCount?.contractCode;
                    meeting.onContractExtension = meetingCount?.contractExtension;

                    return {
                        ...state,
                        remainingMeetings: Math.max(meetingCount?.remainingMeetings || 0, 0),
                        meeting
                    }
                }, this.checkExtensionNeeds.bind(this));
            }).catch(() => {
                this.setState((state: IState) => {
                    return {...state, remainingMeetings: Infinity}
                });
            });
        } else {
            this.setState((state: IState) => {
                return {...state, remainingMeetings: Infinity}
            });
        }
    }

    checkExtensionNeeds() {
        if (this.state.canUseExtension === undefined && !this.state.meeting?._id && this.state.remainingMeetings === 0 && this.props.user.role !== ROLES.Professional) {
            hasUnusedContractExtension(this.state.meeting?.date || new Date(), this.state.meeting?.patientId as string, this.state.meeting?.accountId as string).then((result) => {
                if (result === true) {
                    this.confirmUseExtensionDialogRef.current?.openDialog();
                }
            });
        }
    }

    canUseExtension(canUseExtension: boolean) {
        this.setState((state: IState) => {
            return {...state, canUseExtension};
        }, () => {
            if (canUseExtension) {
                this.loadRemainingMeetings();
            }
        });
    }

    requestCloseDialog() {
        if (!this.state.changed) {
            this.closeDialog();
        } else {
            this.confirmCloseDialogRef.current?.openDialog();
        }
    }

    closeDialog() {
        this.setState((state: IState) => {
            return {...state, open: false};
        });
    }

    fixMeetingTimes(meeting: Meeting): boolean {
        if (dayjs(meeting.date).isValid() && dayjs(meeting.timeFrom).isValid() && dayjs(meeting.timeTo).isValid()) {
            meeting.timeFrom = dayjs(meeting.date).startOf('day').hour(dayjs(meeting.timeFrom).hour()).minute(dayjs(meeting.timeFrom).minute()).toDate();
            meeting.timeTo = dayjs(meeting.date).startOf('day').hour(dayjs(meeting.timeTo).hour()).minute(dayjs(meeting.timeTo).minute()).toDate();

            return true;
        } else {
            return false;
        }
    }

    changeMeeting(property: string, value: any) {
        this.setState((state: IState) => {
            let patient: Patient | undefined = state.patient;
            let meeting: any = Object.assign({}, state.meeting);
            let dateChanged: boolean = this.state.dateChanged;

            switch (property) {
                case 'date':
                    let newDate: dayjs.Dayjs = dayjs(value);
                    value = newDate.toDate();
                    if (dayjs(value).isValid()) {
                        meeting.timeFrom = dayjs(meeting.timeFrom).year(newDate.year()).month(newDate.month()).date(newDate.date()).toDate();
                        meeting.timeTo = dayjs(meeting.timeTo).year(newDate.year()).month(newDate.month()).date(newDate.date()).toDate();
                        dateChanged = true;
                    }
                    break;
                case 'timeFrom':
                    value = dayjs(value).toDate();
                    if (dayjs(value).isValid()) {
                        meeting.timeTo = dayjs(value).add(dayjs(meeting.timeFrom).diff(meeting.timeTo, 'minute'), 'minute').toDate();
                    }
                    dateChanged = true;
                    break;
                case 'timeTo':
                    value = dayjs(value).toDate();
                    dateChanged = true;
                    break;
                case 'accountId':
                    meeting.paidByPatient = value === '-1';
                    if (!meeting.paidByPatient) meeting.isPaid = false;
                    if (value === '-1') {
                        value = null;
                        meeting.contractCode = null;
                    }
                    break;
                case 'patientId':
                    patient = value;
                    value = patient?._id;
                    break;
                case 'isPaid':
                    if (!value) meeting.paidAmount = null;
                    break;
                case 'paidAmount':
                    if (value) {
                        value = parseFloat(`${value}`);
                        meeting.isPaid = true;
                    } else {
                        value = null;
                    }
                    break;
            }

            meeting[property] = value;

            if (dateChanged) this.fixMeetingTimes(meeting);

            return {
                ...state,
                meeting,
                patient,
                changed: true,
                dateChanged
            };
        }, () => {
            this.validateForm.bind(this);

            switch (property) {
                case 'accountId':
                    this.loadRemainingMeetings();
                    this.isPatientAllowedForAccount();
                    break;
                case 'patientId':
                    this.loadPreviousMeetings();
                    this.loadRemainingMeetings();
                    this.loadLastPatientMeeting();
                    this.isPatientAllowedForAccount();
                    break;
                case 'organizationLink':
                    this.loadRemainingMeetings();
                    this.isPatientAllowedForAccount();
                    break;
            }
        });
    }

    loadLastPatientMeeting() {
        if (this.state.meeting?.patientId) {
            if (!this.state.meeting?.organizationLink || !this.state.meeting.accountId || !this.state.meeting.professionalUserId) {
                getMeetings({patientId: this.state.meeting?.patientId}, false).then((meetings: MeetingWithPatient[]) => {
                    if (meetings.length > 0) {
                        // Fill with last meeting
                        let lastMeeting: MeetingWithPatient = meetings[meetings.length - 1];

                        this.setState((state: IState) => {
                            let meeting: Meeting = Object.assign({}, this.state.meeting);
                            if (!meeting.accountId) meeting.accountId = lastMeeting.accountId;
                            if (!meeting.organizationLink) meeting.organizationLink = lastMeeting.organizationLink;
                            if (!meeting.professionalUserId) meeting.professionalUserId = lastMeeting.professionalUserId;
                            return {
                                ...state,
                                meeting
                            };
                        }, () => {
                            this.loadRemainingMeetings();
                            this.isPatientAllowedForAccount();
                        });
                    } else {
                        // Get patient data
                        getPatient(this.state.meeting?.patientId as string).then((patient: Patient | null) => {
                            if (patient) {
                                if (patient.professionalUserId) this.changeMeeting('professionalUserId', patient.professionalUserId);
                                if (patient.accountId) this.changeMeeting('accountId', patient.accountId);
                                if (patient.organizationLink) this.changeMeeting('organizationLink', patient.organizationLink);
                            }
                        });
                    }
                });
            }
        }
    }

    validateForm(forSave: boolean = false) {
        let formValidation: any = {};

        if (!this.state.meeting?.date || !dayjs(this.state.meeting?.date).isValid()) {
            formValidation.date = 'error';
        }

        if (!this.state.meeting?.timeFrom || !dayjs(this.state.meeting?.timeFrom).isValid() || !this.state.meeting?.timeTo || !dayjs(this.state.meeting?.timeTo).isValid()) {
            formValidation.time = 'error';
        }

        if (this.state.dateChanged || !this.state.meeting?._id) {
            let timeDiff: any = dayjs(this.state.meeting?.timeTo).diff(this.state.meeting?.timeFrom, 'minute');
            if (timeDiff < 15) {
                formValidation.time = 'error';
            }

            if (this.props.user.role === ROLES.Professional && dayjs(this.state.meeting?.date).diff(new Date(), 'day') < 0) {
                formValidation.date = 'error';
            }
        }

        if (!this.state.meeting?.professionalUserId) formValidation.professional = 'error';

        if (!this.state.meeting?.isBlock) {
            if (!this.state.meeting?.patientId) formValidation.patient = 'error';
            if (!this.state.meeting?.accountId && !this.state.meeting?.paidByPatient) formValidation.account = 'error';
            if (this.state.meeting?.paidByPatient !== true && !this.state.meeting?.organizationLink && !(this.state.meeting?.accountId === this.specialAccount || (this.state.meeting?.accountId != null && (this.systemAccounts.indexOf(this.state.meeting?.accountId) !== -1)))) formValidation.organizationLink = 'error';
        }

        this.setState((state: IState) => {
            this.fixMeetingTimes(state.meeting as Meeting);
            return {...state, formValidation};
        });

        return Object.keys(formValidation).length === 0;
    }

    searchPatient(search: string) {
        this.setState((state: IState) => {
            let meeting: any = Object.assign({}, state.meeting);
            meeting.patientId = undefined;
            return {
                ...state,
                patientListSearch: search,
                patientListLoading: true,
                patientList: [],
                meetingHistory: [],
                meeting,
                patient: undefined
            }
        }, () => {
            if (search.trim() !== '') {
                let filter: any = {search, active: true};
                if (this.props.user.role === ROLES.Professional) {
                    filter.professionalUserId = this.props.user._id;
                }
                getPatients(filter).then((patients: Patient[]) => {
                    this.setState((state: IState) => {
                        return {
                            ...state,
                            patientListLoading: false,
                            patientList: patients
                        };
                    });
                });
            } else {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        patientListLoading: false,
                        patientList: []
                    };
                });
            }
        });
    }

    saveMeeting() {
        this.setState((state: IState) => {
            return {
                ...state,
                saving: true
            };
        }, () => {
            if (this.validateForm()) {
                if (!this.state.meeting?.isBlock) {
                    isAvailable(this.state.meeting?.professionalUserId as string, this.state.meeting?.timeFrom as Date, this.state.meeting?.timeTo as Date, this.state.meeting?._id).then((isAvailable: boolean) => {
                        if (isAvailable) {
                            this.commitMeeting();
                        } else {
                            this.confirmNotAvailableDialogRef.current.openDialog();
                        }
                    });
                } else {
                    this.commitMeeting();
                }
            } else {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        saving: false
                    };
                });
            }
        });
    }

    commitMeeting() {
        saveMeeting(this.getMeetingToSave()).then((result: MeetingCreationResult | null) => {
            if (result) this.props.onMeetingChanged();
            this.setState((state: IState) => {
                return {
                    ...state,
                    changed: false,
                    saving: false,
                    meeting: result?.meeting || state.meeting
                };
            });
            this.props.setAppNotification({severity: 'success', message: 'Successfully saved'});
            this.oneDayScheduleRef.current.refreshSchedule();
        }).catch((ex) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    changed: true,
                    saving: false
                };
            });
            this.props.setAppNotification({severity: 'error', message: 'An error occurred, please try again'});
        });
    }

    getMeetingToSave(): Meeting {
        if (!this.state.meeting?.isBlock) {
            return this.state.meeting as Meeting;
        } else {
            return new Meeting({
                _id: this.state.meeting?._id,
                createdDate: this.state.meeting?.createdDate,
                date: this.state.meeting?.date,
                timeFrom: this.state.meeting?.timeFrom,
                timeTo: this.state.meeting?.timeTo,
                note: this.state.meeting?.note,
                professionalUserId: this.state.meeting?.professionalUserId,
                status: this.state.meeting?.status,
                isBlock: true
            });
        }
    }

    cancelSaving() {
        this.setState((state: IState) => {
            return {
                ...state,
                saving: false
            };
        });
    }

    setMeetingStatus(status: MEETING_STATUS) {
        setMeetingStatus(this.state.meeting?._id as string, status).then((success: boolean) => {
            if (success) {
                this.setState((state: IState) => {
                    let meeting: any = Object.assign({}, state.meeting);
                    meeting.status = status;
                    return {
                        ...state,
                        meeting
                    };
                }, () => {
                    this.props.onMeetingChanged();
                    switch (status) {
                        case MEETING_STATUS.Canceled:
                        case MEETING_STATUS.Missed:
                            this.closeDialog();
                            break;
                        case MEETING_STATUS.Confirmed:
                            this.props.setAppNotification({severity: 'success', message: 'Presence confirmed'});
                            break;
                        case MEETING_STATUS.Completed:
                            this.props.setAppNotification({severity: 'success', message: 'Meeting completed'});
                            break;
                    }
                })
            }
        })
    }

    cancelMeeting() {
        this.cancelMeetingConfirmDialog.current?.openDialog();
    }

    meetingMissed() {
        this.meetingMissedConfirmDialog.current?.openDialog();
    }

    restoreMeeting() {
        this.restoreMeetingConfirmDialog.current?.openDialog();
    }

    unconfirmMeeting() {
        if (this.validateForm(true)) {
            this.setMeetingStatus(MEETING_STATUS.Created);
        }
    }

    confirmPresence() {
        if (this.validateForm(true)) {
            this.setMeetingStatus(MEETING_STATUS.Confirmed);
        }
    }

    followUp() {
        this.followUpDialogRef.current?.openDialog(this.state.patient, this.state.meeting);
    }

    completeFollowUp() {
        this.completeFollowUpConfirmDialog.current?.openDialog();
    }

    uncompleteFollowUp() {
        this.uncompleteFollowUpConfirmDialog.current?.openDialog();
    }

    takeFollowUpMeeting() {
        scheduleBestMeeting(this.state.patient as Patient).then((meeting: Meeting | null) => {
            this.openDialog(this.state.patient, undefined, new Meeting({
                patientId: this.state.meeting?.patientId,
                professionalUserId: this.state.meeting?.professionalUserId || '',
                accountId: this.state.meeting?.accountId,
                organizationLink: this.state.meeting?.organizationLink,
                status: MEETING_STATUS.Created,
                date: meeting?.date,
                timeFrom: meeting?.timeFrom,
                timeTo: meeting?.timeTo,
                contractCode: this.state.meeting?.contractCode,
                createdByUserId: this.props.user._id
            }));
        });
    }

    canScheduleFollowUp(): boolean {
        return (this.state.meeting?.status === MEETING_STATUS.Confirmed || this.state.meeting?.status === MEETING_STATUS.Completed);
    }

    canCreateFollowUp(): boolean {
        return (
                this.state.meeting?.status === MEETING_STATUS.Confirmed
                || this.state.meeting?.status === MEETING_STATUS.Completed
                || this.state.meeting?.status === MEETING_STATUS.Missed
            )
            && (
                this.state.meeting.professionalUserId === this.props.user._id
                || this.state.patient?.professionalUserId === this.props.user._id
                || this.props.user.role === ROLES.Administrator
            );
    }

    canCompleteFollowUp(): boolean {
        return this.state.meeting?.status === MEETING_STATUS.Confirmed && (this.props.user.role === ROLES.Administrator || this.props.user.role === ROLES.Assistant) && dayjs(this.state.meeting?.timeFrom).diff(dayjs(), 'hour') < -2;
    }

    canUnCompleteFollowUp(): boolean {
        return this.state.meeting?.status === MEETING_STATUS.Completed && this.props.user.canUncompleteFollowUp === true;
    }

    canConfirmPresence(): boolean {
        return !this.state.changed && (this.state.meeting?.status === MEETING_STATUS.Created || this.state.meeting?.status === MEETING_STATUS.Reported);
    }

    getProfessionalName(userId: string): string {
        for (let user of this.props.professionals) {
            if (user._id === userId) return getUserFullName(user);
        }
        return '';
    }

    getProfessional(userId?: string): User | null {
        if (userId) {
            for (let user of this.props.professionals) {
                if (user._id === userId) return user;
            }
        }
        return null;
    }

    getScheduleProfessional(): User | null {
        if (this.props.user.role === ROLES.Professional) return this.getProfessional(this.props.user._id);
        return this.getProfessional(this.state.meeting?.professionalUserId);
    }

    isEditable(): boolean {
        return !this.state.meeting?._id
            || ((
                this.state.meeting?.status === MEETING_STATUS.Created
                || this.state.meeting?.status === MEETING_STATUS.Reported
                || this.props.user.role === ROLES.Administrator
                || this.props.user.role === ROLES.Assistant
            ) && !this.state.meeting?.isBlock);
    }

    isInStatus(statusList: MEETING_STATUS[]): boolean {
        return statusList.indexOf(this.state.meeting?.status as MEETING_STATUS) !== -1;
    }

    hasRemainingMeetings(): boolean {
        return this.state.remainingMeetings > 0 || this.state.meeting?.paidByPatient === true;
    }

    onPatientLinkClick() {
        if (this.state.meeting?.patientId) {
            getPatient(this.state.meeting?.patientId).then((patient: Patient | null) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        patient: patient || undefined
                    };
                }, () => {
                    this.patientDialogRef.current?.openDialog(this.state.patient);
                });
            });
        } else {
            this.patientDialogRef.current?.openDialog(new Patient({status: PATIENT_STATUS.ACTIVE}));
        }
    }

    onPatientChanged(patient: Patient) {
        this.changeMeeting('patientId', patient);
    }

    isSpecialRequest() {

    }

    onAccountLinkClick() {
        if (this.state.meeting?.accountId) {
            for (let account of this.props.accounts) {
                if (account._id === this.state.meeting?.accountId) {
                    this.accountDialogRef.current?.openDialog(account);
                    break;
                }
            }
        }
    }

    onAccountSave(account: Account) {
        this.changeMeeting('accountId', account._id);
    }

    getMeetingStatusIcon(status: MEETING_STATUS): JSX.Element {
        switch (status) {
            case MEETING_STATUS.Created:
            case MEETING_STATUS.Reported:
                return (
                    <Tooltip title={localize('To come', this.props.lang)}><AccessTimeIcon color={'info'}/></Tooltip>);
            case MEETING_STATUS.Confirmed:
                return (
                    <Tooltip title={localize('Confirmed', this.props.lang)}><CheckIcon color={'warning'}/></Tooltip>);
            case MEETING_STATUS.Completed:
                return (
                    <Tooltip title={localize('Completed', this.props.lang)}><CheckIcon color={'success'}/></Tooltip>);
            case MEETING_STATUS.Missed:
                return (
                    <Tooltip title={localize('Missed', this.props.lang)}><CallMissedIcon color={'error'}/></Tooltip>)
            case MEETING_STATUS.Canceled:
                return (<Tooltip title={localize('Canceled', this.props.lang)}><DoDisturbIcon
                    color={'warning'}/></Tooltip>);
        }
    }

    onFollowUpSave(followUp: FollowUp) {
        this.setState((state: IState) => {
            let meeting: Meeting = Object.assign({}, this.state.meeting);
            meeting.followUp = followUp;
            return {
                ...state,
                meeting
            };
        }, () => {
            this.props.onMeetingChanged();
        });
    }

    onPatientSave(patient: Patient) {
        this.setState((state: IState) => {
            return {
                ...state,
                patient
            };
        }, () => {
            this.props.onMeetingChanged();
        });
    }

    isSystemAccount(): boolean {
        return this.state.meeting?.paidByPatient === true || this.state.meeting?.accountId === this.specialAccount || (this.state.meeting?.accountId != null && (this.systemAccounts.indexOf(this.state.meeting?.accountId) !== -1));
    }

    openLinkMenu(open: boolean): void {
        this.setState((state: IState) => {
            return {...state, openLinkMenu: open};
        });
    }

    linkOpenExternal(link: string): void {
        window.open(link, '_blank');
        this.openLinkMenu(false);
    }

    getRealTookMeetingCount(): number {
        let tookMeetings = 0;

        for (let meeting of this.state.meetingHistory) {
            if (dayjs(meeting.timeFrom).isBefore(new Date()) && meeting.status !== MEETING_STATUS.Canceled && meeting.status !== MEETING_STATUS.Missed) {
                tookMeetings++;
            }
        }

        return tookMeetings;
    }

    canSave(): boolean {
        return !(
            this.state.saving
            || !this.state.changed
            || (!this.hasRemainingMeetings() && !this.state.meeting?._id)
            || (!this.state.meeting?._id && this.getProfessional(this.state.meeting?.professionalUserId)?.status === USER_STATUS.INACTIVE)
            || !this.state.allowedPatientForAccount
        );
    }

    async isPatientAllowedForAccount() {
        let allowedPatientForAccount: boolean = false;

        if (this.state.meeting?.accountId && this.state.meeting.patientId && this.state.meeting.organizationLink === ORGANIZATION_LINK.EMPLOYEE) {
            allowedPatientForAccount = await isPatientAllowedForAccount(this.state.meeting?.accountId, this.state.meeting.patientId);
        } else {
            allowedPatientForAccount = true;
        }

        this.setState((state: IState) => {
            return {
                ...state,
                allowedPatientForAccount
            };
        });
    }

    render() {
        return (
            <Dialog open={this.state.open} maxWidth={false}>
                <DialogTitle>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        {
                            !this.state.meeting?._id ?
                                <Box>
                                    <Tabs value={this.state.meeting?.isBlock === true ? 1 : 0}>
                                        <Tab label={localize('Meeting', this.props.lang)}
                                             onClick={this.changeMeeting.bind(this, 'isBlock', false)}/>
                                        <Tab label={localize('Absence', this.props.lang)}
                                             onClick={this.changeMeeting.bind(this, 'isBlock', true)}/>
                                    </Tabs>
                                </Box>
                                : null
                        }
                        {
                            this.state.meeting?._id ?
                                !this.state.meeting?.isBlock ?
                                    <Box>
                                        <Text>Meeting</Text>
                                        {this.state.meeting?.status === MEETING_STATUS.Completed ?
                                            <span style={{color: '#388E3C'}}> - <Text>Completed</Text></span>
                                            :
                                            null
                                        }
                                    </Box>
                                    :
                                    <Box>
                                        <Text>Absence</Text>
                                    </Box>
                                : null
                        }
                        {this.state.meeting?._id ?
                            <React.Fragment>
                                <Box sx={{display: !this.state.meeting?.isBlock ? 'flex' : 'none'}}>
                                    <Box
                                        hidden={(this.state.meeting.status as MEETING_STATUS) !== MEETING_STATUS.Confirmed}>
                                        <Tooltip title={localize('Unconfirm presence', this.props.lang)}>
                                            <IconButton color={'error'} onClick={this.unconfirmMeeting.bind(this)}>
                                                <EventBusyIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box hidden={!this.isInStatus([MEETING_STATUS.Missed, MEETING_STATUS.Canceled])}>
                                        <Tooltip title={localize('Restore the meeting', this.props.lang)}>
                                            <IconButton color={'success'} onClick={this.restoreMeeting.bind(this)}>
                                                <RestoreFromTrashIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box
                                        hidden={!this.isEditable() || !this.isInStatus([MEETING_STATUS.Created, MEETING_STATUS.Reported])}>
                                        <Tooltip title={localize('Cancel the meeting', this.props.lang)}>
                                            <IconButton color={'error'} onClick={this.cancelMeeting.bind(this)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box
                                        hidden={!this.isEditable() || !this.isInStatus([MEETING_STATUS.Created, MEETING_STATUS.Reported])}>
                                        <Tooltip title={localize('Meeting missed', this.props.lang)}>
                                            <IconButton color={'error'} onClick={this.meetingMissed.bind(this)}>
                                                <CallMissedIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box
                                        hidden={!this.isEditable() || !this.isInStatus([MEETING_STATUS.Created, MEETING_STATUS.Reported])}>
                                        <Tooltip title={localize('Confirm presence', this.props.lang)}>
                                            <span>
                                                <IconButton color={'success'} disabled={!this.canConfirmPresence()}
                                                            onClick={this.confirmPresence.bind(this)}>
                                                    <CheckIcon/>
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                    <Box hidden={!this.canScheduleFollowUp()}>
                                        <Tooltip title={localize('Schedule a follow-up appointment', this.props.lang)}>
                                            <IconButton color={'info'} onClick={this.takeFollowUpMeeting.bind(this)}>
                                                <EventIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box hidden={!this.canCreateFollowUp()}>
                                        <Tooltip title={localize('Follow-up', this.props.lang)}>
                                            <IconButton color={'primary'} onClick={this.followUp.bind(this)}>
                                                <ArticleIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box hidden={!this.canCompleteFollowUp()}>
                                        <Tooltip title={localize('Complete follow-up', this.props.lang)}>
                                            <IconButton color={'warning'} onClick={this.completeFollowUp.bind(this)}>
                                                <AssignmentTurnedInIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box hidden={!this.canUnCompleteFollowUp()}>
                                        <Tooltip title={localize('Uncomplete follow-up', this.props.lang)}>
                                            <IconButton color={'error'} onClick={this.uncompleteFollowUp.bind(this)}>
                                                <AssignmentReturnIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                                <Box sx={{display: this.state.meeting?.isBlock ? 'flex' : 'none'}}>
                                    <Box>
                                        <Tooltip title={localize('Cancel the absence', this.props.lang)}>
                                            <IconButton color={'error'} onClick={this.cancelMeeting.bind(this)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </React.Fragment>
                            :
                            null
                        }
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        pt: 2,
                        pb: 2,
                        '& > div': {mb: 1}
                    }} hidden={this.state.meeting?.isBlock}>
                        {this.state.meeting?.patientAnswer === PATIENT_ANSWER.Canceled ?
                            <Alert
                                severity={'warning'}
                                sx={{width: '100%', color: 'white'}}
                                variant={'outlined'}
                                iconMapping={{
                                    success: <i className={'fa-solid fa-triangle-exclamation fa-beat-fade'}></i>,
                                }}
                            >
                                <Text>The patient asked to cancel this meeting</Text>
                                {this.state.meeting?.patientAnswerReceivedOn ?
                                    <React.Fragment>
                                        &nbsp;
                                        <Text>on</Text>
                                        &nbsp;
                                        {dayjs(this.state.meeting?.patientAnswerReceivedOn).format(this.props.dateFormat)}
                                        &nbsp;
                                        <Text>at</Text>
                                        &nbsp;
                                        {dayjs(this.state.meeting?.patientAnswerReceivedOn).format('HH:mm')}
                                    </React.Fragment>
                                    : null
                                }
                            </Alert>
                            :
                            null
                        }
                        {this.state.meeting?.paidByPatient === true ?
                            <Alert
                                severity={'warning'}
                                sx={{width: '100%', color: 'white'}}
                                variant={'filled'}
                            >
                                <Text>This meeting is at charge of the patient.</Text>
                                <br/>
                                <Text>Please ask for payment before the meeting.</Text>
                            </Alert>
                            :
                            null
                        }
                        {this.state.meeting?.accountId && this.state.meeting?.accountId === this.specialAccount ?
                            <Alert
                                severity={'warning'}
                                sx={{width: '100%', color: 'white'}}
                                variant={'filled'}
                            >
                                <Text>Special account</Text>
                                <br/>
                                <Text>Ensure that you're authorized to set this meeting on the special account!</Text>
                            </Alert>
                            :
                            null
                        }
                        {!this.hasRemainingMeetings() && this.state.meeting?.patientId && !this.state.meeting?._id ?
                            <Alert
                                severity={'error'}
                                sx={{width: '100%', color: 'white'}}
                                variant={'filled'}
                            >
                                <Text>There is no remaining meetings for this patient.</Text>
                                <br/>
                                <Text>Please change the meeting to be at charge of the patient.</Text>
                            </Alert>
                            :
                            null
                        }
                        {this.state.meeting?.onContractExtension === true ?
                            <Alert
                                severity={'info'}
                                sx={{width: '100%'}}
                                variant={'filled'}
                            >
                                <Text>This meeting is on a contract extension.</Text>
                                <br/>
                                <Text>Please make sure you have permission to schedule this meeting.</Text>
                            </Alert>
                            :
                            null
                        }
                        {!this.state.allowedPatientForAccount ?
                            <Alert
                                severity={'error'}
                                sx={{width: '100%'}}
                                variant={'filled'}
                            >
                                <Text>This patient is not authorized for this account</Text>
                                <br/>
                                <Text>Please make sure you have permission to schedule this meeting.</Text>
                            </Alert>
                            :
                            null
                        }
                    </Box>
                    <Grid container>
                        <Grid item xs={12} md={!this.state.meeting?.isBlock ? 5 : 6}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box sx={{minWidth: '40px', textAlign: 'center'}}>
                                    <AccessTimeIcon/>
                                </Box>
                                <Box sx={{flexGrow: 1, alignItems: 'center'}}>
                                    <Grid container>
                                        <Grid item xs={12} md={5} sx={{p: 1}}>
                                            <DatePicker
                                                sx={{width: '100%'}}
                                                label={localize('date', this.props.lang)}
                                                value={dayjsOrAny(this.state.meeting?.date)}
                                                readOnly={!this.isEditable()}
                                                format={this.props.dateFormat}
                                                onChange={((newValue: any) => {
                                                    this.changeMeeting('date', newValue)
                                                })}
                                                slotProps={{textField: { error: this.state.formValidation.date === 'error'}}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3.5} sx={{p: 1}}>
                                            <TimePicker
                                                sx={{width: '100%'}}
                                                label={localize('from', this.props.lang)}
                                                value={dayjsOrAny(this.state.meeting?.timeFrom)}
                                                readOnly={!this.isEditable()}
                                                onChange={(newValue: any) => this.changeMeeting('timeFrom', newValue)}
                                                slotProps={{textField: { error: this.state.formValidation.time === 'error'}}}
                                                ampm={false}
                                                minutesStep={5}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3.5} sx={{p:1}}>
                                            <TimePicker
                                                sx={{width: '100%'}}
                                                label={localize('to', this.props.lang)}
                                                value={dayjsOrAny(this.state.meeting?.timeTo)}
                                                minTime={dayjsOrAny(this.state.meeting?.timeFrom)}
                                                readOnly={!this.isEditable()}
                                                onChange={(newValue: any) => this.changeMeeting('timeTo', newValue)}
                                                slotProps={{textField: { error: this.state.formValidation.time === 'error'}}}
                                                ampm={false}
                                                minutesStep={5}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box sx={{display: !this.state.meeting?.isBlock ? 'flex' : 'none', alignItems: 'center'}}>
                                <Box sx={{minWidth: '40px', textAlign: 'center'}}>
                                    <IconButton onClick={this.onPatientLinkClick.bind(this)} disabled={this.state.patient?.status === PATIENT_STATUS.INACTIVE}>
                                        <PersonIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{flexGrow: 1, p:1}}>
                                    <Autocomplete
                                        sx={{width: '100%'}}
                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                        getOptionLabel={(option) => `${formatFileNumber(option.fileNumber)} - ${option.firstName} ${option.lastName} (${dayjs(option.birthdate).format('DD/MM/YYYY')})`}
                                        options={this.state.patientList}
                                        value={this.state.patient || null}
                                        readOnly={(!!this.state.contactRequest && !this.state.meeting?._id) || !this.isEditable()}
                                        onChange={((e: any, value: AutocompleteValue<any, any, any, any>) => {this.changeMeeting('patientId', value)})}
                                        loading={this.state.patientListLoading}
                                        noOptionsText={localize(this.state.patientListSearch.length === 0 ? 'Start typing to search' : 'No patient found', this.props.lang)}
                                        onInputChange={(event, value, reason) => {if (reason !== 'reset') this.searchPatient(value);}}
                                        filterOptions={() => this.state.patientList}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                label={localize('Patient', this.props.lang)}
                                                error={this.state.formValidation.patient === 'error'}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {this.state.patientListLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{display: !this.state.meeting?.isBlock ? 'flex' : 'none', alignItems: 'center'}}>
                                <Box sx={{minWidth: '40px', textAlign: 'center'}}>
                                    <IconButton onClick={this.onAccountLinkClick.bind(this)} disabled={this.isSystemAccount()}>
                                        <Business />
                                    </IconButton>
                                </Box>
                                <Box sx={{flexGrow: 1, p:1}}>
                                    <Box>
                                        <TextField
                                            id="accountId"
                                            sx={{width: '100%'}}
                                            select
                                            label={localize('Account', this.props.lang)}
                                            value={this.state.meeting?.paidByPatient === true ? '-1' : (this.state.meeting?.accountId || '')}
                                            InputProps={{
                                                readOnly: !this.isEditable(),
                                            }}
                                            onChange={((e: any) => {this.changeMeeting('accountId', e.target.value)})}
                                            error={this.state.formValidation.account === 'error'}
                                        >
                                            <MenuItem value={'-1'}>
                                                <Text>On the patient's account</Text>
                                            </MenuItem>
                                            {this.props.accounts.map((account: Account) => (
                                                account.system === true ?
                                                    <MenuItem key={account._id} value={account._id}>
                                                        {account.name}
                                                    </MenuItem>
                                                    : null
                                            ))}
                                            <Divider />
                                            {this.props.accounts.map((account: Account) => (
                                                account.system !== true ?
                                                    <MenuItem key={account._id} value={account._id}>
                                                        {account.name}
                                                    </MenuItem>
                                                    : null
                                            ))}
                                        </TextField>
                                    </Box>
                                    <Box sx={{pt:2}} hidden={this.isSystemAccount()}>
                                        <TextField
                                            id="organizationLink"
                                            sx={{width: '100%'}}
                                            select
                                            label={localize('Organization link', this.props.lang)}
                                            value={this.state.meeting?.paidByPatient === true ? '' : (this.state.meeting?.organizationLink || '')}
                                            InputProps={{
                                                readOnly: !this.isEditable(),
                                            }}
                                            onChange={((e: any) => {this.changeMeeting('organizationLink', e.target.value)})}
                                            error={this.state.formValidation.organizationLink === 'error'}
                                        >
                                            {getEnumValues(ORGANIZATION_LINK).map((value: number) => (
                                                <MenuItem key={value} value={value}>
                                                    {localize(ORGANIZATION_LINK[value], this.props.lang)}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box sx={{minWidth: '40px', textAlign: 'center'}}>
                                    <PermContactCalendarIcon />
                                </Box>
                                <Box sx={{flexGrow: 1, p:1}}>
                                    <TextField
                                        id="professionalUserId"
                                        sx={{width: '100%'}}
                                        select
                                        label={localize('Professional', this.props.lang)}
                                        value={this.state.meeting?.professionalUserId || ''}
                                        InputProps={{
                                            readOnly: !this.isEditable(),
                                        }}
                                        onChange={((e: any) => {this.changeMeeting('professionalUserId', e.target.value)})}
                                        error={this.state.formValidation.professional === 'error'}
                                        disabled={this.props.user.role === ROLES.Professional}
                                    >
                                        {this.props.professionals.map((professional: User) => {
                                            if (professional.settings?.canBeScheduled !== true && this.state.meeting?.professionalUserId !== professional._id) return null;
                                            return (
                                                <MenuItem key={professional._id} value={professional._id}>
                                                    {getUserFullName(professional)}{professional.contractor ? ` - ${localize('Contractor', this.props.lang)}` : '' }
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Box>
                            </Box>
                            <Box sx={{display: !this.state.meeting?.isBlock ? 'flex' : 'none', alignItems: 'center'}}>
                                <Box sx={{minWidth: '40px', textAlign: 'center'}}>
                                    <LocalPhoneIcon />
                                </Box>
                                <Box sx={{flexGrow: 1, p:1}}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            name="communicationChannel"
                                        >
                                            <FormControlLabel
                                                value="person"
                                                control={
                                                    <Radio
                                                        onChange={
                                                            ((e: any) => {
                                                                if (e.target.checked) this.changeMeeting('communicationChannel', COMMUNCATION_CHANNEL.Person)
                                                            })} />
                                                }
                                                label={localize('In person', this.props.lang)}
                                                checked={this.state.meeting?.communicationChannel === COMMUNCATION_CHANNEL.Person}
                                            />
                                            <FormControlLabel
                                                value="phone"
                                                control={
                                                    <Radio
                                                        onChange={
                                                            ((e: any) => {
                                                                if (e.target.checked) this.changeMeeting('communicationChannel', COMMUNCATION_CHANNEL.Phone)
                                                            })} />
                                                }
                                                label={localize('Phone', this.props.lang)}
                                                checked={this.state.meeting?.communicationChannel === COMMUNCATION_CHANNEL.Phone}
                                            />
                                            <FormControlLabel
                                                value="videoconference"
                                                control={
                                                    <Radio
                                                        onChange={
                                                            ((e: any) => {
                                                                if (e.target.checked) this.changeMeeting('communicationChannel', COMMUNCATION_CHANNEL.Visio)
                                                            })} />
                                                }
                                                label={localize('Videoconference', this.props.lang)}
                                                checked={this.state.meeting?.communicationChannel === COMMUNCATION_CHANNEL.Visio}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box sx={{display: !this.state.meeting?.isBlock && this.state.meeting?.communicationChannel === COMMUNCATION_CHANNEL.Visio ? 'flex' : 'none', alignItems: 'center'}}>
                                <Box sx={{minWidth: '40px', textAlign: 'center'}}>
                                    <IconButton onClick={this.openLinkMenu.bind(this, true)}>
                                        <Phonelink ref={this.linkRef} />
                                    </IconButton>
                                    <Menu
                                        id="menu-link"
                                        anchorEl={this.linkRef.current}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={this.state.openLinkMenu}
                                        onClose={this.openLinkMenu.bind(this, false)}
                                    >
                                        <MenuItem onClick={this.linkOpenExternal.bind(this, 'https://meet.google.com/')}>
                                            <ListItemText><Text>Google meet</Text></ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={this.linkOpenExternal.bind(this, 'https://teams.microsoft.com/')}>
                                            <ListItemText><Text>Microsoft Teams</Text></ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={this.linkOpenExternal.bind(this, 'https://zoom.us/meeting#/upcoming')}>
                                            <ListItemText><Text>Zoom</Text></ListItemText>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                                <Box sx={{flexGrow: 1, p:1}}>
                                    <TextField
                                        id="link"
                                        sx={{width: '100%'}}
                                        label={localize('Link', this.props.lang)}
                                        value={this.state.meeting?.link || ''}
                                        onChange={((e: any) => {this.changeMeeting('link', e.target.value)})}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{display: !this.state.meeting?.isBlock && this.state.meeting?.communicationChannel === COMMUNCATION_CHANNEL.Person ? 'flex' : 'none', alignItems: 'center'}}>
                                <Box sx={{minWidth: '40px', textAlign: 'center'}}>
                                    <ApartmentIcon />
                                </Box>
                                <Box sx={{flexGrow: 1, p:1}}>
                                    <TextField
                                        id="placeId"
                                        sx={{width: '100%'}}
                                        select
                                        label={localize('Place', this.props.lang)}
                                        value={this.state.meeting?.placeId || ''}
                                        InputProps={{
                                            readOnly: !this.isEditable(),
                                        }}
                                        onChange={((e: any) => {this.changeMeeting('placeId', e.target.value)})}
                                        error={this.state.formValidation.place === 'error'}
                                    >
                                        {this.props.places.map((place: Place) => {
                                            return (
                                                <MenuItem key={place._id} value={place._id}>
                                                    {place.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box sx={{minWidth: '40px', textAlign: 'center'}}>
                                    <NotesIcon />
                                </Box>
                                <Box sx={{flexGrow: 1, p:1}}>
                                    <TextField
                                        id="note"
                                        sx={{width: '100%'}}
                                        label={localize('Note', this.props.lang)}
                                        value={this.state.meeting?.note || ''}
                                        onChange={((e: any) => {this.changeMeeting('note', e.target.value)})}
                                        rows={2}
                                        multiline={true}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{display: !this.state.meeting?.isBlock && this.state.meeting?.paidByPatient === true ? 'flex' : 'none', alignItems: 'center'}}>
                                <Box sx={{minWidth: '40px', textAlign: 'center'}}>
                                    <RequestQuoteIcon />
                                </Box>
                                <Box sx={{p:1}}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={this.state.meeting?.isPaid || false}
                                            onChange={((e: any) => {this.changeMeeting('isPaid', e.target.checked || false)})}
                                        />}
                                      label={localize('Paid', this.props.lang)}
                                    />
                                </Box>
                                <Box sx={{flexGrow: 1, p:1}}>
                                    <TextField
                                        id="paidAmount"
                                        sx={{width: '100%'}}
                                        label={localize('Paid amount', this.props.lang)}
                                        value={this.state.meeting?.paidAmount || ''}
                                        type={'number'}
                                        onChange={((e: any) => {this.changeMeeting('paidAmount', e.target.value)})}
                                        disabled={!this.state.meeting?.isPaid}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={!this.state.meeting?.isBlock ? 4 : 6} sx={{pl: 2}} px={{xs: 0, md: 1}}>
                            <OneDaySchedule ref={this.oneDayScheduleRef} places={this.props.places} date={this.state.meeting?.date || new Date()} professional={this.getScheduleProfessional()} />
                        </Grid>
                        <Grid item xs={12} md={3} sx={{pl: 2}} px={{xs: 0, md: 1}} hidden={this.state.meeting?.isBlock}>
                            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                <Box sx={{pb: 1}}>
                                    <Text>Patient history</Text>
                                </Box>
                                <Box sx={{pb: 1}}>
                                    <Grid container>
                                        <Grid item sx={{pr:1}} xs={6}>
                                            <Paper sx={{p:1, textAlign: 'center'}} variant="outlined">
                                                <Box>
                                                    <FormLabel><Text>Remaining</Text></FormLabel>
                                                </Box>
                                                <Box>
                                                    {this.state.meeting?.paidByPatient !== true && this.state.remainingMeetings !== Infinity ?
                                                        <Typography variant={'h4'}>{this.state.remainingMeetings}</Typography>
                                                        :
                                                        <AllInclusiveIcon sx={{fontSize: '2.2rem'}} />
                                                    }
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        <Grid item sx={{pl:1}} xs={6}>
                                            <Paper sx={{p:1, textAlign: 'center'}} variant="outlined">
                                                <FormLabel><Text>Previous</Text></FormLabel>
                                                <Typography variant={'h4'}>{this.getRealTookMeetingCount()}</Typography>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{flexGrow: 1, overflow: 'auto', maxHeight: '275px'}}>
                                    {this.state.meetingHistory.length > 0 ?
                                        <List sx={{ width: '100%'}}>
                                            {this.state.meetingHistory.slice(0).reverse().map((meeting: Meeting) => {
                                                    return (
                                                        <ListItem key={meeting._id}>
                                                            <ListItemIcon>
                                                                {this.getMeetingStatusIcon(meeting.status as MEETING_STATUS)}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={dayjs(meeting.timeFrom).format(this.props.dateFormat + " HH:mm")}
                                                                secondary={this.getProfessionalName(meeting.professionalUserId as string)} />
                                                        </ListItem>
                                                    );
                                                })}
                                        </List>
                                        :
                                        <Text>There is no previous meeting for this patient</Text>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <FollowUpMeetingDialog
                        ref={this.followUpDialogRef}
                        onSave={this.onFollowUpSave.bind(this)}
                        onPatientSave={this.onPatientSave.bind(this)}
                        accounts={this.props.accounts}
                    />
                    <ConfirmDialog
                        ref={this.restoreMeetingConfirmDialog}
                        title={localize('Restore the meeting', this.props.lang)}
                        text={localize('Are-you sure you want to restore this meeting?', this.props.lang)}
                        onAgree={this.setMeetingStatus.bind(this, MEETING_STATUS.Created)} />
                    <ConfirmDialog
                        ref={this.cancelMeetingConfirmDialog}
                        title={localize(!this.state.meeting?.isBlock ? 'Cancel the meeting' : 'Cancel the absence', this.props.lang)}
                        text={localize(!this.state.meeting?.isBlock ? 'Are-you sure you want to cancel this meeting?' : 'Are-you sure you want to cancel this absence?', this.props.lang)}
                        onAgree={this.setMeetingStatus.bind(this, MEETING_STATUS.Canceled)} />
                    <ConfirmDialog
                        ref={this.completeFollowUpConfirmDialog}
                        title={localize('Complete follow-up', this.props.lang)}
                        text={localize('Are-you sure you want to complete the follow-up of this meeting?', this.props.lang)}
                        onAgree={this.setMeetingStatus.bind(this, MEETING_STATUS.Completed)} />
                    <ConfirmDialog
                        ref={this.uncompleteFollowUpConfirmDialog}
                        title={localize('Uncomplete follow-up', this.props.lang)}
                        text={localize('Are-you sure you want to uncomplete the follow-up of this meeting?', this.props.lang)}
                        onAgree={this.setMeetingStatus.bind(this, MEETING_STATUS.Confirmed)} />
                    <ConfirmDialog
                        ref={this.meetingMissedConfirmDialog}
                        title={localize('Meeting missed', this.props.lang)}
                        text={localize('Are-you sure you want to mark this meeting as missed?', this.props.lang)}
                        onAgree={this.setMeetingStatus.bind(this, MEETING_STATUS.Missed)} />
                    <ConfirmDialog
                        ref={this.confirmCloseDialogRef}
                        title={localize('Unsaved changes', this.props.lang)}
                        text={localize('All unsaved changes will be lost. Do you want to continue?', this.props.lang)}
                        onAgree={this.closeDialog.bind(this)}
                    />
                    <ConfirmDialog
                        ref={this.confirmUseExtensionDialogRef}
                        title={localize('Use a contract extension', this.props.lang)}
                        text={localize('There is no remaining meetings left. Can this patient use a contract extension?', this.props.lang)}
                        onAgree={this.canUseExtension.bind(this, true)}
                        onDisagree={this.canUseExtension.bind(this, false)}
                    />
                    <ConfirmDialog
                        ref={this.confirmNotAvailableDialogRef}
                        title={localize('Professional not available', this.props.lang)}
                        text={localize('The professional is not available at this schedule. Are you sure you want to schedule this meeting?', this.props.lang)}
                        onAgree={this.commitMeeting.bind(this, true)}
                        onDisagree={this.cancelSaving.bind(this)}
                    />
                    <PatientDialog
                        ref={this.patientDialogRef}
                        accounts={this.props.accounts}
                        professionals={this.props.professionals}
                        places={this.props.places}
                        signedDocuments={this.props.signedDocuments}
                        onSave={this.onPatientChanged.bind(this)}
                    />
                    <AccountDialog
                        ref={this.accountDialogRef}
                        onSave={this.onAccountSave.bind(this)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} sx={{mr: 2}} color={'error'} onClick={this.requestCloseDialog.bind(this)}><Text>Close</Text></Button>
                    <LoadingButton
                        loading={this.state.saving}
                        variant={'contained'}
                        sx={{mr: 2}}
                        color={'success'}
                        disabled={!this.canSave()}
                        onClick={this.saveMeeting.bind(this)}
                    >
                        <Text>Save</Text>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(MeetingDialog);
