import {ROLES} from "./roles";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {SvgIconTypeMap} from "@mui/material/SvgIcon/SvgIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Business from "@mui/icons-material/Business";
import Event from "@mui/icons-material/Event";
import Person from "@mui/icons-material/Person";
import TextSnippet from "@mui/icons-material/TextSnippet";
import PieChart from "@mui/icons-material/PieChart";
import SettingsIcon from '@mui/icons-material/Settings';
import CampaignIcon from '@mui/icons-material/Campaign';

export interface IMenuItem {
    name: string,
    title: string,
    roles: ROLES[],
    route: string,
    icon: OverridableComponent<SvgIconTypeMap>,
    childs?: IMenuItem[]
}

export interface IMenuSeparator {
    separator: boolean
}

export const menu: (IMenuItem | IMenuSeparator)[] = [
    {
        name: 'dashboard',
        title: 'Dashboard',
        roles: [ROLES.Assistant, ROLES.Professional, ROLES.Administrator],
        route: '/',
        icon: PieChart
    },
    {
        name: 'contactRequests',
        title: 'Requests',
        roles: [ROLES.Assistant, ROLES.Administrator],
        route: '/contact-requests',
        icon: InboxIcon
    },
    {
        name: 'meetings',
        title: 'Meetings',
        roles: [ROLES.Assistant, ROLES.Professional, ROLES.Administrator],
        route: '/meetings',
        icon: Event
    },
    {
        separator: true
    },
    {
        name: 'accounts',
        title: 'Accounts',
        roles: [ROLES.Assistant, ROLES.Administrator],
        route: '/accounts',
        icon: Business
    },
    {
        name: 'patients',
        title: 'Patients',
        roles: [ROLES.Assistant, ROLES.Professional, ROLES.Administrator],
        route: '/patients',
        icon: Person
    },
    {
        separator: true
    },
    {
        name: 'announcements',
        title: 'Announcements',
        roles: [ROLES.Assistant, ROLES.Administrator],
        route: '/announcements',
        icon: CampaignIcon
    },
    {
        name: 'configurations',
        title: 'Configurations',
        roles: [ROLES.Assistant, ROLES.Administrator],
        route: '/configurations',
        icon: SettingsIcon
    },
    {
        name: 'reports',
        title: 'Reports',
        roles: [ROLES.Assistant, ROLES.Administrator],
        route: '/reports',
        icon: TextSnippet
    },
    {
        name: 'logs',
        title: 'System logs',
        roles: [ROLES.Administrator],
        route: '/logs',
        icon: TextSnippet
    }
];
