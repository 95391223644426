import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, Button, Checkbox, List, ListItem, ListItemButton, TextField, Typography} from "@mui/material";
import {logException} from "../../../../controllers/system";
import {SignedDocument} from "../../../../models/signedDocument";
import Text from "../../../app/text/text";
import Add from "@mui/icons-material/Add";
import {localize} from "../../../../helpers/localization";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import ReactQuill from "react-quill";
import * as signedDocumentController from '../../../../controllers/signedDocument';
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification} from "../../../../store/actions/app";

type IState = {
    loading: boolean,
    signedDocuments: SignedDocument[],
    editingDocument?: SignedDocument,
    formValidation: any,
    changed: boolean
}

type IProps = {
    visible: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class SignedDocuments extends React.Component<ReduxType, IState> {
    public state: IState = {
        loading: false,
        signedDocuments: [],
        formValidation: {},
        changed: false
    }

    private confirmLeaveWritingDialogRef: RefObject<any>;
    private confirmDeleteDialogRef: RefObject<any>;
    private saveTimeout: number | null = null;
    private saving: boolean = false;
    private tempEditingDocument: SignedDocument | undefined = undefined;

    constructor(props: ReduxType) {
        super(props);
        this.confirmLeaveWritingDialogRef = React.createRef();
        this.confirmDeleteDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadDocuments();
    }

    loadDocuments() {
        let openDocumentId: string | undefined = this.state.editingDocument?._id;

        this.setState((state: IState) => {
            return {
                ...state,
                loading: true,
                editingDocument: undefined,
                changed: false
            };
        }, () => {
            signedDocumentController.getAll().then((signedDocuments: SignedDocument[]) => {
                let editingDocument: SignedDocument | undefined = undefined;
                if (openDocumentId) {
                    let foundDocuments = signedDocuments.filter((signedDocument) => {return signedDocument._id === openDocumentId;});
                    if (foundDocuments.length > 0) editingDocument = foundDocuments[0];
                }

                this.setState((state: IState) => {
                    return {
                        ...state,
                        signedDocuments,
                        loading: false,
                        editingDocument
                    };
                });
            });
        });
    }

    newDocument() {
        this.tempEditingDocument = new SignedDocument();
        this.onSelectDocument(new SignedDocument());
    }

    onSelectDocument(editingDocument: SignedDocument) {
        if (!this.state.editingDocument) {
            this.tempEditingDocument = editingDocument;
            this.selectDocument();
        } else {
            this.tempEditingDocument = editingDocument;
            this.confirmLeaveWritingDialogRef.current.openDialog();
        }
    }

    selectDocument() {
        this.setState((state: IState) => {
            return {
                ...state,
                editingDocument: this.tempEditingDocument,
                changed: false
            };
        }, () => {
            this.tempEditingDocument = undefined;
        });
    }

    onChange(property: string, value: any) {
        this.setState((state: IState) => {
            let editingDocument: any = state.editingDocument || new SignedDocument();
            editingDocument[property] = value;
            return {
                ...state,
                editingDocument,
                changed: true
            };
        });
    }

    async save() {
        if (this.state.editingDocument && this.validateForm()) {
            let success: boolean = await signedDocumentController.save(this.state.editingDocument);

            if (success) {
                this.props.setAppNotification({
                    severity: 'success',
                    message: 'Successfully saved'
                });
                this.loadDocuments();
            } else {
                this.props.setAppNotification({
                    severity: 'error',
                    message: 'An error occurred, please try again'
                });
            }
        }
    }

    validateForm(): boolean {
        let formValidation: any = {};

        if (!this.state.editingDocument?.title) {
            formValidation.title = 'error';
        }

        if (!this.state.editingDocument?.document) {
            formValidation.document = 'error';
        }

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return Object.keys(formValidation).length === 0;
    }

    confirmDelete() {
        this.confirmDeleteDialogRef.current.openDialog();
    }

    async delete() {
        await signedDocumentController.remove(this.state.editingDocument?._id as string);
        this.loadDocuments();
    }

    render() {
        if (!this.props.visible) return null;
        return (
            <React.Fragment>
                <Box sx={{display: 'flex', p:2, height: '100%', overflow: 'auto'}}>
                    <Box sx={{height: '100%', minWidth: '15%', overflowY: 'auto', overflowX: 'visible'}}>
                        <List sx={{borderRight: 1, borderColor: 'divider', height: '100%', overflowY: 'auto', overflowX: 'visible'}}>
                            <ListItemButton disabled={false} alignItems={'center'} onClick={this.newDocument.bind(this, false)}>
                                <Add sx={{mr:1}} />
                                <Text>Add document</Text>
                            </ListItemButton>
                            {
                                this.state.signedDocuments.map((signedDocument: SignedDocument) => {
                                    if (!signedDocument.enabled) return null;
                                    return (
                                        <ListItem key={signedDocument._id} disablePadding>
                                            <ListItemButton
                                                onClick={this.onSelectDocument.bind(this, signedDocument, false)}
                                                selected={this.state.editingDocument?._id === signedDocument._id}
                                            >
                                                {signedDocument.title}
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                    </Box>
                    <Box sx={{p: 1, height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        {
                            this.state.editingDocument ?
                                <React.Fragment>
                                    <Box sx={{pb:1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Box>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <Box>
                                                    <Checkbox checked={this.state.editingDocument.sendOnPatientCreation || false} onChange={(e) => {this.onChange('sendOnPatientCreation', e.target.checked)}} />
                                                </Box>
                                                <Box>
                                                    <Typography><Text>Send on patient creation (only one document can be selected)</Text></Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                            {
                                                this.state.editingDocument._id ?
                                                    <Button sx={{mr:1}} color={'error'} variant={'contained'} onClick={this.confirmDelete.bind(this)}><Text>Delete</Text></Button>
                                                    : null
                                            }
                                            <Box>
                                                <Button color={'success'} variant={'contained'} onClick={this.save.bind(this)}><Text>Save</Text></Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{pb:1, display: 'flex', alignItems: 'center'}}>
                                        <Box sx={{flexGrow: 1}}>
                                            <TextField
                                                id={'title'}
                                                sx={{width: '100%'}}
                                                label={localize('Title', this.props.lang)}
                                                value={this.state.editingDocument?.title || ''}
                                                onChange={((e: any) => {this.onChange('title', e.target.value)})}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{pb:1, flexGrow: 1}}>
                                        <ReactQuill
                                            theme="snow"
                                            value={this.state.editingDocument?.document || ''}
                                            onChange={(newValue: string) => {this.onChange('document', newValue);}}
                                            style={{backgroundColor: 'white', height: '90%', color: 'black'}}/>
                                    </Box>
                                </React.Fragment>
                                : null
                        }

                        <ConfirmDialog
                            ref={this.confirmLeaveWritingDialogRef}
                            title={localize(
                                !this.state.editingDocument?._id ?
                                    'Discard draft'
                                    :
                                    'Unsaved changes'
                                , this.props.lang)}
                            text={localize(
                                !this.state.editingDocument?._id ?
                                    'Are you sure you want to discard your draft document?'
                                    :
                                    'All unsaved changes will be lost. Do you want to continue?'
                                , this.props.lang)}
                            onAgree={this.selectDocument.bind(this)}
                        />
                        <ConfirmDialog
                            ref={this.confirmDeleteDialogRef}
                            title={localize('Delete document', this.props.lang)}
                            text={localize('Are you sure you want to delete this document? Patients will not be able to view it anymore.', this.props.lang)}
                            onAgree={this.delete.bind(this)}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedDocuments);
