import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {login} from "../../../store/actions/user";
import imgLogo from '../../../assets/img/GRAP - logo.png';
import authLandscape from '../../../assets/img/authLandscape.jpg';
import {Alert, Box, Button, CircularProgress, Grid, Link, Paper, TextField, Typography} from "@mui/material";
import {ImgLogo} from "./auth.styled";
import {localize} from "../../../helpers/localization";
import {withTheme} from "@mui/styles";
import {ThemeContext} from "@emotion/react";
import {lightTheme} from "../theme/root/light";
import Text from '../text/text';
import {IAppNotification} from "../../../models/appNotification";
import {
    request2FACode,
    requestResetPasswordCode,
    resetPassword,
    SessionValidation,
    signin,
    validate2FACode
} from "../../../controllers/auth";
import User from "../../../models/user";
import {validateEmail} from "../../../helpers/string";
import {logException} from "../../../controllers/system";
import {TWOFA_TYPE} from "../../../models/twoFA";

enum FORM_STATE {
    SIGNIN = 1,
    FORGOT_PASSWORD = 2,
    RESET_PASSWORD = 3,
    REQUEST_2FA = 4,
    VALIDATE_2FA = 5
}

type IState = {
    formState: FORM_STATE,
    form: any,
    notification?: IAppNotification,
    sessionValidation?: SessionValidation | null,
    twoFAType: TWOFA_TYPE
}

type IProps = {
    children: JSX.Element | JSX.Element[]
}

const mapStateToProps = (state: RootState) => {
    return {
        authChecked: state.session?.authChecked,
        sessionId: state.session?.sessionId,
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        login: (sessionId: string, user: User) => {
            dispatch(login(sessionId, user));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class Auth extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        formState: FORM_STATE.SIGNIN,
        form: {
            email: "",
            password: "",
            twoFACode: ""
        },
        twoFAType: TWOFA_TYPE.SMS
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        if (!this.props.sessionId && prevProps.sessionId) this.resetState();
    }

    resetState() {
        this.setState((state: IState) => {
            return {
                formState: FORM_STATE.SIGNIN,
                form: {
                    email: "",
                    password: "",
                    twoFACode: ""
                },
                notification: undefined,
                sessionValidation: undefined
            };
        });
    }

    setForm(property: string, value: string) {
        this.setState((state: IState) => {
            let form: any = Object.assign({}, state.form);
            form[property] = value;
            return {
                ...state,
                form
            };
        });
    }

    authUser() {
        this.setNotification().then(() => {
            signin(this.state.form.email, this.state.form.password).then((sessionValidation: SessionValidation | null) => {
                if (sessionValidation) {
                    this.setState((state: IState) => {
                        return {...state, sessionValidation};
                    }, () => {
                        if (!sessionValidation.blockedBy2FA) {
                            this.props.login(sessionValidation.sessionId, sessionValidation.user);
                        } else {
                            this.setFormState(FORM_STATE.REQUEST_2FA);
                        }
                    });
                } else {
                    this.setNotification({severity: 'error', message: 'Bad email or password'});
                }
            });
        });
    }

    request2FA(type: TWOFA_TYPE) {
        this.setState((state: IState) => {
            return {
                ...state,
                twoFAType: type
            };
        }, () => {
            request2FACode(this.state.sessionValidation?.user._id as string, this.state.sessionValidation?.sessionId as string, type).then((success: boolean) => {
                if (success) {
                    this.setFormState(FORM_STATE.VALIDATE_2FA);
                } else {
                    this.setNotification({severity: 'error', message: 'An error occured. Please check with your administrator.'});
                }
            });
        });
    }

    validate2FA() {
        validate2FACode(this.state.sessionValidation?.user._id as string, this.state.sessionValidation?.sessionId as string, this.state.form.twoFACode).then((success: boolean) => {
            if (success) {
                this.props.login(this.state.sessionValidation?.sessionId as string, this.state.sessionValidation?.user as User);
            } else {
                this.setNotification({severity: 'error', message: 'The two-factor authentication is not valid'});
            }
        });
    }

    requestPasswordReset() {
        requestResetPasswordCode(this.state.form.email).then((success: boolean) => {
            this.setNotification({severity: 'success', message: 'If this email has access, you will receive a reset code.'}).then(() => {
                this.setState((state: IState) => {
                    return {...state, formState: FORM_STATE.RESET_PASSWORD};
                });
            });
        });
    }

    resetPassword() {
        resetPassword(this.state.form.email, this.state.form.code).then((success: boolean) => {
            if (success) {
                this.setNotification({
                    severity: 'success',
                    message: 'An email has been sent with the new password'
                }).then(() => {
                    this.setState((state: IState) => {
                        return {...state, formState: FORM_STATE.SIGNIN};
                    });
                });
            } else {
                this.setNotification({
                    severity: 'error',
                    message: 'The reset code is not valid'
                });
            }
        });
    }

    setNotification(notification?: IAppNotification): Promise<void> {
        return new Promise((resolve) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    notification
                };
            }, resolve);
        });
    }

    getForm(): JSX.Element {
        switch (this.state.formState) {
            case FORM_STATE.FORGOT_PASSWORD:
                return this.getForgotPasswordForm();
            case FORM_STATE.RESET_PASSWORD:
                return this.getResetPasswordForm();
            case FORM_STATE.REQUEST_2FA:
                return this.getRequest2FAForm();
            case FORM_STATE.VALIDATE_2FA:
                return this.getValidate2FAForm();
            case FORM_STATE.SIGNIN:
            default:
                return this.getSignInForm();
        }
    }

    getSignInForm(): JSX.Element {
        return (
            <form onSubmit={(e) => {e.preventDefault();this.authUser.bind(this)}}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{p:2}}>
                        <TextField
                            label={localize('Email', this.props.lang)}
                            type={'email'}
                            sx={{width: '100%'}}
                            value={this.state.form.email}
                            onChange={(e) => {this.setForm('email', e.target.value)}}
                        />
                    </Box>
                    <Box sx={{p:2}}>
                        <Box>
                            <TextField
                                label={localize('Password', this.props.lang)}
                                type={'password'}
                                sx={{width: '100%'}}
                                value={this.state.form.password}
                                onChange={(e) => {this.setForm('password', e.target.value)}}
                            />
                        </Box>
                        <Box sx={{textAlign: 'right'}}>
                            <Typography variant={'button'}>
                                <Link href="#" onClick={this.setFormState.bind(this, FORM_STATE.FORGOT_PASSWORD)} underline={'none'}><Text>Forgot password</Text></Link>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{p:2, textAlign: 'center'}}>
                        <Button
                            type={'submit'}
                            onClick={this.authUser.bind(this)}
                            disabled={!this.state.form.email || !this.state.form.password || !validateEmail(this.state.form.email)}
                        ><Text>Sign in</Text></Button>
                    </Box>
                </Box>
            </form>
        );
    }

    getForgotPasswordForm(): JSX.Element {
        return (
            <form onSubmit={(e) => {e.preventDefault();this.requestPasswordReset.bind(this)}}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{p:2}}>
                        <TextField
                            label={localize('Email', this.props.lang)}
                            type={'email'}
                            sx={{width: '100%'}}
                            value={this.state.form.email}
                            onChange={(e) => {this.setForm('email', e.target.value)}}
                        />
                        <Box sx={{textAlign: 'right'}}>
                            <Typography variant={'button'}>
                                <Link href="#" onClick={this.setFormState.bind(this, FORM_STATE.SIGNIN)} underline={'none'}><Text>Cancel</Text></Link>
                            </Typography>
                        </Box>
                        <Box sx={{textAlign: 'right'}}>
                            <Typography variant={'button'}>
                                <Link href="#" onClick={this.setFormState.bind(this, FORM_STATE.RESET_PASSWORD)} underline={'none'}><Text>Have a code?</Text></Link>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{p:2}}>
                        <Box sx={{p:2, textAlign: 'center'}}>
                            <Button
                                type={'submit'}
                                onClick={this.requestPasswordReset.bind(this)}
                                disabled={!this.state.form.email || !validateEmail(this.state.form.email)}
                            ><Text>Get a password reset code</Text></Button>
                        </Box>
                    </Box>
                </Box>
            </form>
        );
    }

    getResetPasswordForm(): JSX.Element {
        return (
            <form onSubmit={(e) => {e.preventDefault();this.resetPassword.bind(this)}}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{p:2}}>
                        <Box>
                            <TextField
                                label={localize('Code', this.props.lang)}
                                sx={{width: '100%'}}
                                onChange={(e) => {this.setForm('code', e.target.value)}}
                            />
                        </Box>
                        <Box sx={{textAlign: 'right'}}>
                            <Typography variant={'button'}>
                                <Link href="#" onClick={this.setFormState.bind(this, FORM_STATE.SIGNIN)} underline={'none'}><Text>Cancel</Text></Link>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{p:2}}>
                        <Box sx={{p:2, textAlign: 'center'}}>
                            <Button
                                type={'submit'}
                                onClick={this.resetPassword.bind(this)}
                                disabled={!this.state.form.code || this.state.form.code.length !== 6}
                            ><Text>Reset password</Text></Button>
                        </Box>
                    </Box>
                </Box>
            </form>
        );
    }

    getRequest2FAForm(): JSX.Element {
        return (
            <form onSubmit={(e) => {e.preventDefault();this.request2FA.bind(this)}}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{p:2}}>
                        <Alert severity={'info'}>
                            {localize('You must pass a two-factor authentication to continue', this.props.lang)}
                        </Alert>
                        <Box sx={{textAlign: 'right'}}>
                            <Typography variant={'button'}>
                                <Link href="#" onClick={this.setFormState.bind(this, FORM_STATE.SIGNIN)} underline={'none'}><Text>Cancel</Text></Link>
                            </Typography>
                        </Box>
                    </Box>
                    {
                        this.state.sessionValidation?.user.phone ?
                            <Box sx={{p:2, textAlign: 'center'}}>
                                <Button sx={{width: '100%'}} type={'submit'} onClick={this.request2FA.bind(this, TWOFA_TYPE.SMS)}>
                                    <div>
                                        <Text>Obtain code by SMS</Text>
                                        <br/>
                                        <div>{this.displayMaskedPhoneNumber()}</div>
                                    </div>
                                </Button>
                            </Box>
                            : null
                    }
                    {
                        this.state.sessionValidation?.user.email ?
                            <Box sx={{p:2, textAlign: 'center'}}>
                                <Button sx={{width: '100%'}} type={'submit'} onClick={this.request2FA.bind(this, TWOFA_TYPE.EMAIL)}>
                                    <div>
                                        <Text>Obtain code by Email</Text>
                                        <br/>
                                        <div>{this.displayMaskedEmail()}</div>
                                    </div>
                                </Button>
                            </Box>
                            : null
                    }
                </Box>
            </form>
        );
    }

    displayMaskedPhoneNumber(): string {
        let phone: string = this.state.sessionValidation?.user.phone || "";
        let maskedPhone: string = phone.substring(phone.length - 4, phone.length);
        return `(***) ***-${maskedPhone}`;
    }

    displayMaskedEmail(): string {
        let email: string = this.state.sessionValidation?.user.email || "";
        return `${email.substring(0, 4)}*********${email.substring(email.length - 5, email.length)}`;
    }

    getValidate2FAForm(): JSX.Element {
        return (
            <form onSubmit={(e) => {e.preventDefault();this.validate2FA.bind(this)}}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{p:2}}>
                        <Alert severity={'info'}>
                            {`${localize('A code has been sent to', this.props.lang)}`}
                            <br/>
                            {
                                this.state.twoFAType === TWOFA_TYPE.SMS ?
                                    this.displayMaskedPhoneNumber()
                                    :
                                    this.displayMaskedEmail()
                            }
                        </Alert>
                    </Box>
                    <Box sx={{p:2}}>
                        <TextField
                            label={localize('Code', this.props.lang)}
                            sx={{width: '100%'}}
                            onChange={(e) => {this.setForm('twoFACode', e.target.value)}}
                        />
                        <Box sx={{textAlign: 'right'}}>
                            <Typography variant={'button'}>
                                <Link href="#" onClick={this.setFormState.bind(this, FORM_STATE.SIGNIN)} underline={'none'}><Text>Cancel</Text></Link>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{p:2, textAlign: 'center'}}>
                        <Button type={'submit'} onClick={this.validate2FA.bind(this)} disabled={!this.state.form.twoFACode}><Text>Validate</Text></Button>
                    </Box>
                </Box>
            </form>
        );
    }

    setFormState(formState: FORM_STATE) {
        this.setState((state: IState) => {
            return {...state, formState, notification: undefined};
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.props.sessionId != null ?
                    this.props.children
                    :
                    <ThemeContext.Provider value={lightTheme}>
                        <Grid container
                              direction={'column'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              height={'100%'}
                              sx={{
                                  backgroundImage: `url(${authLandscape})`,
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat'
                              }}
                        >
                            <Grid item
                                sx={{
                                    width: '20rem',
                                    maxWidth: '95%'
                                }}
                            >
                                <Paper elevation={12} sx={{backgroundColor: 'rgba(255,255,255,0.9)'}}>
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{
                                            p:2
                                        }}>
                                            <ImgLogo src={imgLogo}/>
                                        </Box>
                                        {this.state.notification ?
                                            <Box>
                                                <Alert severity={this.state.notification.severity}><Text>{this.state.notification.message}</Text></Alert>
                                            </Box>
                                            :
                                            null
                                        }
                                        <Box>
                                            {this.props.authChecked ?
                                                this.getForm()
                                                :
                                                <Box sx={{p:2, textAlign: 'center'}}>
                                                    <CircularProgress color="primary" />
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </ThemeContext.Provider>
                }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Auth));
