import {AxiosResponse} from "axios";
import {post} from "./ajax";
import {Contact} from "../models/contact";

export async function executeJSReport(templateName: string, data: any, reportName: string, fileType: string): Promise<any> {
    let response: AxiosResponse<any> = await post<any>(`/reports/execute/${templateName}`,
        {data},
        {
            responseType: 'arraybuffer'
        });


    if (response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${reportName}.${(fileType === 'excel' ? 'xlsx' : 'pdf')}`);
        document.body.appendChild(link);
        link.click();

        return response.data;
    } else {
        return null;
    }
}

export async function sendReport(reportData: string, contacts: Array<Contact | string>, subject: string, body: string): Promise<boolean> {
    let response: AxiosResponse<any> = await post<any>(`/reports/send`,
        {
            reportData,
            contacts,
            subject,
            body
        }
    );

    return response.status === 200;
}
