import {AnyAction} from 'redux';
import initialState from "../initialState";
import {AUTH_CHECK, LOGIN, LOGOUT} from "../actions/user";

export default function session(state = initialState.session, action: AnyAction) {
    switch(action.type) {
        case AUTH_CHECK:
        case LOGIN:
            return {
                sessionId: action.payload.sessionId,
                authChecked: true
            }
        case LOGOUT:
            return {
                ...initialState.session,
                authChecked: true
            }
        default:
            return state;
    }
}
