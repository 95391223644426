import React from 'react';
import {logException} from "../../controllers/system";

type IState = {
}

type IProps = {

}

class ReportsStyle extends React.Component<IProps, IState> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    render() {
        return (
            <style dangerouslySetInnerHTML={{__html: `
                h1 {
                    color: #28b6f6;
                }
                .signatureBox {
                    width: 250px;
                    height: 25px;
                    border-style: solid;
                    border-width: 0 0 1px 0;
                }
                .modal {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255, 255, 255, 0.7);
                }
                
                .modal > div {
                    color: white;
                    width: 100%;
                    max-width: 600px;
                    height: 100%;
                    max-height: 450px;
                    background-color: #284154;
                    border-style: solid;
                    border-width: 1px;
                    border-color: #1C313D;
                }
                
                .modal input {
                    color: black;
                    padding: 0.5em;
                    border-radius: 5px;
                }
            `}} />
        );
    }
}

export default ReportsStyle;
