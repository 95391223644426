export enum KEYS {
    SESSION_ID= 'SESSION_ID',
    THEME= 'THEME',
    SCHEDULER_SELECTION= 'SCHEDULER_SELECTION',
    SHOW_24H= 'SHOW_24H'
}

export function get(userId: string | undefined, key:KEYS): string | null {
    if (window.localStorage) {
        let value = window.localStorage.getItem(key + (userId ? userId : ''));
        if (value === '') value = null;
        return value;
    } else {
        return null;
    }
}

export function set(userId: string | undefined, key:KEYS, value: string | null) {
    if (window.localStorage) {
        window.localStorage.setItem(key + (userId ? userId : ''), value || '');
    }
}
