import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {
    AppBar,
    Avatar,
    Badge,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Notifications from '@mui/icons-material/Notifications';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TextsmsIcon from '@mui/icons-material/Textsms';
import EmailIcon from '@mui/icons-material/Email';
import imgLogo from '../../../assets/img/GRAP.webp';
import * as styled from './topBar.styled';
import {setMenuState} from "../../../store/actions/app";
import {localize} from "../../../helpers/localization";
import {setTheme} from "../../../store/actions/settings";
import Text from "../../app/text/text";
import {UserNotification} from "../../../models/userNotification";
import withRouter from "../../app/router/withRouter";
import {IRouter} from "../../app/router/router";
import {
    logout,
    refreshUnreadEmails,
    refreshUserNotifications,
    setUserNotificationRead
} from "../../../store/actions/user";
import {darkTheme} from "../../app/theme/root/dark";
import {ThemeContext} from '@emotion/react';
import {logException} from "../../../controllers/system";
import {PATIENT_ANSWER} from "../../../models/meeting";
import {formatFileNumber} from "../../../controllers/patients";
import {Patient} from "../../../models/patient";

type IState = {
    openProfileMenu: boolean,
    openUserNotifications: boolean
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        username: state.user?.name,
        avatarURL: state.user?.avatarURL,
        menuState: state.app.menuState,
        lang: state.settings.lang,
        theme: state.settings.theme,
        userNotifications: state.user.notifications,
        unreadEmailsCount: state.user.unreadEmailsCount
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setMenuState: (menuState: string) => {
            dispatch(setMenuState(menuState));
        },
        setTheme: (theme: string) => {
            dispatch(setTheme(theme));
        },
        setUserNotificationRead: (userNotificationId: string) => {
            dispatch(setUserNotificationRead(userNotificationId));
        },
        refreshUserNotifications: () => {
            dispatch(refreshUserNotifications());
        },
        refreshUnreadEmails: () => {
            dispatch(refreshUnreadEmails());
        },
        logout: () => {
            dispatch(logout());
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class TopBar extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        openProfileMenu: false,
        openUserNotifications: false
    }

    private avatarRef: RefObject<any>;
    private userNotificationsIconRef: RefObject<any>;
    private refreshUserNotificationsTimeout: number | undefined;
    private refreshEmailsTimeout: number | undefined;

    constructor(props: ReduxType) {
        super(props);
        this.avatarRef = React.createRef<any>();
        this.userNotificationsIconRef = React.createRef<any>();
        this.refreshUserNotificationsTimeout = undefined;
        this.refreshEmailsTimeout = undefined;
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.refreshUserNotifications();
        this.refreshEmails();
    }

    componentWillUnmount() {
        window.clearTimeout(this.refreshUserNotificationsTimeout);
        window.clearTimeout(this.refreshEmailsTimeout);
    }

    toggleMainMenu() {
        this.props.setMenuState(this.props.menuState === 'open' ? 'close' : 'open');
    }

    openProfileMenu() {
        this.setState((state: IState) => {
            return { ...state, openProfileMenu: true, openUserNotifications: false };
        });
    }

    goToProfile() {
        this.closeProfileMenu();
        this.props.router.navigate('/profile');
    }

    goToEmails() {
        this.props.router.navigate('/emails');
    }

    closeProfileMenu() {
        this.setState((state: IState) => {
            return { ...state, openProfileMenu: false };
        });
    }

    openUserNotificationsMenu() {
        this.setState((state: IState) => {
            return { ...state, openProfileMenu: false, openUserNotifications: true };
        });
    }

    closeUserNotificationsMenu() {
        this.setState((state: IState) => {
            return { ...state, openUserNotifications: false };
        });
    }

    toggleTheme() {
        this.closeProfileMenu();
        this.props.setTheme(this.props.theme === 'dark' ? 'light' : 'dark');
    }

    refreshUserNotifications() {
        this.props.refreshUserNotifications();
        this.refreshUserNotificationsTimeout = window.setTimeout(this.refreshUserNotifications.bind(this), 30000);
    }

    async refreshEmails() {
        this.props.refreshUnreadEmails();
        this.refreshEmailsTimeout = window.setTimeout(this.refreshEmails.bind(this), 30000);
    }

    getNotificationIcon(userNotification: UserNotification) {
        switch(userNotification.message) {
            case 'A new meeting request has been recorded':
                return (
                    <InboxIcon fontSize={'small'} />
                );
            case 'A cycle report must be uploaded':
                return (
                    <SummarizeIcon fontSize={'small'} />
                );
            case 'Patient has answered to the meeting confirmation':
                return (
                    <TextsmsIcon fontSize={'small'} color={'warning'} />
                );
            case 'A patient arrived':
                return (
                    <PersonIcon fontSize={'small'} color={'warning'} />
                );
        }
    }

    getNotificationText(userNotification: UserNotification): string {
        switch(userNotification.message) {
            case 'A new meeting request has been recorded':
            case 'A cycle report must be uploaded':
                return localize(userNotification.message, this.props.lang);
            case 'Patient has answered to the meeting confirmation':
                if (userNotification.data.patientAnswer === PATIENT_ANSWER.Confirmed) {
                    return localize('A patient has confirmed their presence at an appointment', this.props.lang);
                } else if (userNotification.data.patientAnswer === PATIENT_ANSWER.Canceled) {
                    return localize('A patient has requested the cancellation of an appointment', this.props.lang);
                } else {
                    return localize(userNotification.message, this.props.lang);
                }
            case 'A patient arrived':
                let patient: Patient = userNotification.data.patient;
                return `${localize('A patient arrived', this.props.lang)}: ${formatFileNumber(patient.fileNumber)} - ${patient.firstName} ${patient.lastName}`;
            default:
                return localize(userNotification.message as string, this.props.lang);
        }
    }

    onUserNotificationClick(userNotification: UserNotification) {
        switch (userNotification.message) {
            case 'A new meeting request has been recorded':
                this.props.router.navigate(`/contact-requests?id=${userNotification.data.id}`);
                break;
            case 'A cycle report must be uploaded':
                this.props.router.navigate(`/patients?id=${userNotification.data.id}`);
                break;
            case 'Patient has answered to the meeting confirmation':
                this.props.router.navigate(`/meetings?meeting=${userNotification.data.id}`);
                break;
        }

        this.props.setUserNotificationRead(userNotification._id as string);
        this.closeUserNotificationsMenu();
    }

    logout() {
        this.props.logout();
    }

    render() {
        return <React.Fragment>
            <ThemeContext.Provider value={darkTheme}>
            <Toolbar/>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={this.toggleMainMenu.bind(this)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <styled.LogoContainer style={{ flexGrow: 1 }}>
                        <img alt={'logo'} src={imgLogo} />
                    </styled.LogoContainer>

                    <div style={{marginRight: '15px'}}>
                        <Tooltip title={localize('Emails', this.props.lang)}>
                            <IconButton onClick={this.goToEmails.bind(this)}>
                                <Badge badgeContent={this.props.unreadEmailsCount} color="primary" invisible={this.props.unreadEmailsCount === 0}>
                                    <EmailIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{marginRight: '15px'}}>
                        <Tooltip title={localize('Notifications', this.props.lang)}>
                            <IconButton onClick={this.openUserNotificationsMenu.bind(this)}>
                                <Badge badgeContent={this.props.userNotifications.length} color="primary" invisible={this.props.userNotifications.length === 0}>
                                    <Notifications ref={this.userNotificationsIconRef} />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="menu-notifications"
                            anchorEl={this.userNotificationsIconRef.current}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={this.state.openUserNotifications}
                            onClose={this.closeUserNotificationsMenu.bind(this)}
                        >
                            {this.props.userNotifications.length > 0 ?
                                this.props.userNotifications.map((userNotification: UserNotification) => {
                                    return (
                                        <MenuItem key={userNotification._id} onClick={this.onUserNotificationClick.bind(this, userNotification)}>
                                            <ListItemIcon>
                                                {this.getNotificationIcon(userNotification)}
                                            </ListItemIcon>
                                            <ListItemText>
                                                {this.getNotificationText(userNotification)}
                                            </ListItemText>
                                        </MenuItem>
                                    );
                                })
                                :
                                <MenuItem onClick={this.closeUserNotificationsMenu.bind(this)}>
                                    <ListItemText><Text>No notifications</Text></ListItemText>
                                </MenuItem>
                            }

                        </Menu>
                    </div>

                    <div>
                        <Tooltip title={localize('Menu', this.props.lang)}>
                            <IconButton onClick={this.openProfileMenu.bind(this)} sx={{ p: 0 }}>
                                <Avatar ref={this.avatarRef} src={this.props.avatarURL} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="menu-appbar"
                            anchorEl={this.avatarRef.current}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={this.state.openProfileMenu}
                            onClose={this.closeProfileMenu.bind(this)}
                        >
                            <MenuItem onClick={this.toggleTheme.bind(this)}>
                                <ListItemIcon>
                                    <Brightness4Icon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText><Text>Change theme</Text></ListItemText>
                            </MenuItem>
                            <MenuItem onClick={this.goToProfile.bind(this)}>
                                <ListItemIcon>
                                    <PersonIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText><Text>Profile</Text></ListItemText>
                            </MenuItem>
                            <MenuItem onClick={this.logout.bind(this)}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText><Text>Log out</Text></ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            </ThemeContext.Provider>
        </React.Fragment>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBar));
