import {Action, createAction} from "../action";
import {IAppNotification} from "../../models/appNotification";

export const INIT_APP = "INIT_APP";
export function initApp(): Action<string, any> {
    return createAction(
        INIT_APP
    );
}

export const SET_MENU_STATE = "SET_MENU_STATE";
export function setMenuState(menuState: string): Action<string, any> {
    return createAction(
        SET_MENU_STATE,
        {
            menuState
        }
    );
}

export const SET_APP_NOTIFICATION = "SET_APP_NOTIFICATION";
export function setAppNotification(appNotification: IAppNotification | null) {
    return createAction(
        SET_APP_NOTIFICATION,
        {
            appNotification
        }
    );
}

export const REDIRECT = "REDIRECT";
export function redirect(url: string) {
    return createAction(
        REDIRECT,
        {
            url
        }
    );
}

export const INIT_SOCKET = "INIT_SOCKET";
export function initSocket() {
    return createAction(
        INIT_SOCKET,
        {
        }
    );
}

export const SET_LOADING = "SET_LOADING";
export function setLoading(loading: boolean) {
    return createAction(
        SET_LOADING,
        {
            loading
        }
    );
}

export const SET_PREVENTCLOSE = "SET_PREVENTCLOSE";
export function setPreventClose(preventClose: boolean) {
    return createAction(
        SET_PREVENTCLOSE,
        {
            preventClose
        }
    );
}
