import React from 'react';
import {connect} from 'react-redux';
import {
    Snackbar,
    Alert
} from '@mui/material';
import {AppDispatch, RootState} from "../../../store/store";
import Text from "../../app/text/text";
import {IAppNotification} from "../../../models/appNotification";
import {setAppNotification} from "../../../store/actions/app";
import {logException} from "../../../controllers/system";

type IProps = {
}

type IState = {
    open: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        notification: state.app.notification
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class AppNotification extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        open: false
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        if (!prevProps || (this.props.notification && (!prevProps.notification || prevProps.notification.id !== this.props.notification.id))) {
            this.setState((state: IState) => {
                return {
                    ...state,
                    open: true
                };
            })
        }
    }

    handleClose() {
        this.setState((state: IState) => {
            return {
                ...state,
                open: false
            };
        }, () => {
            this.props.setAppNotification(null);
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.props.notification ?
                    <Snackbar
                        open={this.state.open}
                        autoHideDuration={6000}
                        onClose={this.handleClose.bind(this)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert
                            onClose={this.handleClose.bind(this)}
                            severity={this.props.notification.severity}
                            sx={{ width: '100%', color:'white' }}
                            variant={'filled'}
                        >
                            <Text>{this.props.notification.message}</Text>
                        </Alert>
                    </Snackbar>
                    :
                    null
                }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppNotification);
