import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import Text from '../../../app/text/text';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement, BarController, LineController} from 'chart.js';
import {Chart} from 'react-chartjs-2';
import {localize} from "../../../../helpers/localization";
import {withTheme} from "@mui/styles";
import {WithTheme} from "@mui/styles/withTheme/withTheme";
import {logException} from "../../../../controllers/system";
import {getMeetingHoursSummary, MeetingHoursSummary} from "../../../../controllers/meetings";
import dayjs from "dayjs";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement, BarController, LineController);

type IProps = {}

type IState = {
    data: MeetingHoursSummary[],
    labels: string[],
    lastPeriodDays: number[],
    lastPeriodAcc: number[],
    currentPeriodDays: number[],
    currentPeriodAcc: number[],
    plannedPeriodDays: number[],
    plannedPeriodAcc: number[],
    period: 'week' | 'month'
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {};
};

type ReduxType =
    ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>
    & IProps
    & WithTheme<unknown>;

class HoursSummary extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        data: [],
        labels: [],
        lastPeriodDays: [],
        lastPeriodAcc: [],
        currentPeriodDays: [],
        currentPeriodAcc: [],
        plannedPeriodDays: [],
        plannedPeriodAcc: [],
        period: 'week'
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let data = await getMeetingHoursSummary(this.state.period);

        this.setState((state: IState) => {
            return {
                ...state,
                labels: this.getLabels(data, state.period),
                lastPeriodDays: data.map((m) => m.lastPeriodDays),
                lastPeriodAcc: data.map((m) => m.lastPeriodAcc),
                currentPeriodDays: data.map((m) => m.currentPeriodDays),
                currentPeriodAcc: data.map((m) => m.currentPeriodAcc),
                plannedPeriodDays: data.map((m) => m.plannedPeriodDays),
                plannedPeriodAcc: data.map((m) => m.plannedPeriodAcc),
            }
        });
    }

    onPeriodChange(period: 'week' | 'month') {
        this.setState((state: IState) => {
            return {
                ...state,
                period
            };
        }, () => {
            this.loadData();
        });
    }

    getLabels(data: MeetingHoursSummary[], period: 'week' | 'month'): Array<string> {
        return data.map((m, index) => {
            if (period === 'week') {
                return dayjs().day(0).add(index, 'day').format('dddd');
            } else {
                return `${index + 1}`;
            }
        });
    }

    render() {
        return (
            <Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant={'h5'}><Text>Hours Follow-up</Text></Typography>
                    <ToggleButtonGroup
                        sx={{ml: 2}}
                        color="primary"
                        value={this.state.period}
                        exclusive
                        onChange={(e, value) => {this.onPeriodChange(value)}}
                        aria-label="Platform"
                    >
                        <ToggleButton value="week"><Text>Week</Text></ToggleButton>
                        <ToggleButton value="month"><Text>Month</Text></ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box sx={{flexGrow: 1, color: 'white'}}>
                    <Chart
                        type='bar'
                        data={
                            {
                                labels: this.state.labels,
                                datasets: [
                                    {
                                        type: 'line' as const,
                                        label: localize('Previous (sum)', this.props.lang),
                                        borderColor: 'rgb(77,210,210)',
                                        borderWidth: 2,
                                        fill: false,
                                        data: this.state.lastPeriodAcc,
                                    },
                                    {
                                        type: 'line' as const,
                                        label: localize('Current (sum)', this.props.lang),
                                        borderColor: 'rgb(21,213,82)',
                                        borderWidth: 2,
                                        fill: false,
                                        data: this.state.currentPeriodAcc,
                                    },
                                    {
                                        type: 'line' as const,
                                        label: localize('Planned (sum)', this.props.lang),
                                        borderColor: 'rgb(213,130,21)',
                                        borderWidth: 2,
                                        fill: false,
                                        data: this.state.plannedPeriodAcc,
                                    },
                                    {
                                        type: 'bar' as const,
                                        label: localize('Previous (daily)', this.props.lang),
                                        backgroundColor: 'rgb(77,210,210)',
                                        data: this.state.lastPeriodDays,
                                    },
                                    {
                                        type: 'bar' as const,
                                        label: localize('Current (daily)', this.props.lang),
                                        backgroundColor: 'rgb(21,213,82)',
                                        data: this.state.currentPeriodDays,
                                    },
                                    {
                                        type: 'bar' as const,
                                        label: localize('Planned (daily)', this.props.lang),
                                        backgroundColor: 'rgb(213,130,21)',
                                        data: this.state.plannedPeriodDays,
                                    },
                                ]
                            }
                        }
                        options={{
                            color: (this.props.theme as any).palette.text.primary,
                            plugins: {
                                legend: {
                                    labels: {
                                        color: (this.props.theme as any).palette.text.primary
                                    }
                                },
                            },
                            scales: {
                                yAxes:{
                                    ticks:{
                                        color: (this.props.theme as any).palette.text.primary,
                                    }
                                },
                                xAxes: {
                                    ticks:{
                                        color: (this.props.theme as any).palette.text.primary,
                                    }
                                },
                            }
                        }}
                    />
                </Box>
            </Box>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(HoursSummary));
