import {AnyAction, Middleware} from "redux";
import {authCheck} from "../actions/user";
import {INIT_APP, /*initSocket, */SET_APP_NOTIFICATION} from "../actions/app";
import {randomString} from "../../helpers/random";
//import * as socket from '../../controllers/socket';

const user: Middleware<{}>= store => (next: Function) => (action: AnyAction) => {
    switch(action.type) {
        case INIT_APP: {
            next(action);
            store.dispatch(authCheck());
            /*socket.connect().then(() => {
                store.dispatch(initSocket());
            });*/
            break;
        }
        case SET_APP_NOTIFICATION: {
            if (action.payload.appNotification) {
                action.payload.appNotification.id = randomString(5);
            }
            next(action);
            break;
        }
        default:
            next(action);
    }
}

export default user;
