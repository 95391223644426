import React from 'react';
import {connect} from 'react-redux';
import {
    Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, Button
} from '@mui/material';
import {AppDispatch, RootState} from "../../../store/store";
import Text from "../../app/text/text";
import {logException} from "../../../controllers/system";

type IProps = {
    title: string
    text?: string
    html?: string
    onClose?: () => void
    onDisagree?: () => void
    onAgree: () => void,
    canAgree?: boolean
}

type IState = {
    open: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class ConfirmDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        open: false
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog() {
        this.setState((state: IState) => {
            return {
                ...state,
                open: true
            };
        })
    }

    closeDialog() {
        this.setState((state: IState) => {
            return {
                ...state,
                open: false
            };
        })
    }

    onClose() {
        if (this.props.onClose) this.props.onClose();
        this.closeDialog();
    }

    onAgree() {
        this.props.onAgree();
        this.closeDialog();
    }

    onDisagree() {
        if (this.props.onDisagree) this.props.onDisagree();
        this.closeDialog();
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.closeDialog.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            this.props.text ?
                                this.props.text
                                :
                                this.props.html ?
                                    <div style={{maxHeight: '500px',  overflow: 'auto', padding: '2rem'}} dangerouslySetInnerHTML={{__html: this.props.html}} />
                                    :
                                    null
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onDisagree.bind(this)}>
                        <Text>No</Text>
                    </Button>
                    <Button onClick={this.onAgree.bind(this)} autoFocus disabled={this.props.canAgree === false}>
                        <Text>Yes</Text>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ConfirmDialog);
