import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {ROLES} from "../../../constants/roles";
import {IRouter} from "../../app/router/router";
import withRouter from "../../app/router/withRouter";
import {getMUILocale, localize} from "../../../helpers/localization";
import {
    DataGrid,
    GridCallbackDetails,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    MuiEvent
} from "@mui/x-data-grid";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Log} from "../../../models/log";
import {getLogs} from "../../../controllers/log";
import {Box, Typography} from "@mui/material";
import Text from "../../app/text/text";
import {getUserFullName, getUsers} from "../../../controllers/user";
import User from "../../../models/user";
import {IAppNotification} from "../../../models/appNotification";
import {setAppNotification} from "../../../store/actions/app";
import dayjs from "dayjs";
import {logException} from "../../../controllers/system";

const ALLOWED_ROLES: ROLES[] = [
    ROLES.Administrator
]

type IState = {
    data: Log[],
    users: User[],
    indexedUsers: any,
    loading: boolean
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        userRole: state.user.role,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class Logs extends React.Component<ReduxType> {
    public readonly state: IState = {
        data: [],
        users: [],
        indexedUsers: {},
        loading: false
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        if (!this.isAllowed()) {
            window.setTimeout(() => {
                this.props.router.navigate('/');
            }, 100);
            return;
        }
        this.loadData();
    }

    isAllowed(): boolean {
        return ALLOWED_ROLES.indexOf(this.props.userRole as ROLES) !== -1;
    }

    getColumns(): (GridColDef | GridActionsColDef)[] {
        return [
            {
                field: 'date',
                headerName: localize('Date', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    return dayjs(params.row.date).format(`${this.props.dateFormat} HH:mm:ss`);
                }
            },
            {
                field: 'user',
                headerName: localize('User', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    return getUserFullName(this.state.indexedUsers[params.row.userId]);
                }
            },
            {
                field: 'url',
                headerName: localize('Request url', this.props.lang),
                minWidth: 200,
                flex: 2
            },
            {
                field: 'body',
                headerName: localize('Request body', this.props.lang),
                minWidth: 200,
                flex: 2
            },
            {
                field: 'action',
                headerName: localize('Action', this.props.lang),
                minWidth: 150,
                flex: 1.5,
                renderCell: (params: GridRenderCellParams) => {
                    return localize(params.row.action, this.props.lang);
                }
            },
        ];
    }

    onRowClick(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) {
        navigator.clipboard.writeText(params.row.body);
        this.props.setAppNotification({
            severity: 'warning',
            message: 'Request body copied to clipboard'
        })
    }

    loadData() {
        this.setState((state: IState) => {
            return {...state, loading: true};
        }, () => {
            getUsers().then((users: User[]) => {
                let indexedUsers: any = {};
                for (let user of users) {
                    indexedUsers[user._id as string] = user;
                }

                getLogs().then((data: Log[]) => {
                    this.setState((state: IState) => {
                        return {
                            ...state,
                            loading: false,
                            data,
                            users,
                            indexedUsers
                        };
                    });
                });
            });
        });
    }

    render() {
        if (!this.isAllowed()) return null;
        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }} height={'100%'}>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant={'h4'}>
                                <Text>Logs</Text>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <DataGrid
                            columns={this.getColumns()}
                            rows={this.state.data}
                            getRowId={x => x._id}
                            loading={this.state.loading}
                            localeText={getMUILocale(this.props.lang).components.MuiDataGrid.defaultProps.localeText}
                            onRowClick={this.onRowClick.bind(this)}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logs));
