import {get, post} from '../helpers/ajax';
import {AxiosResponse} from "axios";
import {Place} from "../models/place";

export async function getPlaces(): Promise<Place[]> {
    let response: AxiosResponse<Place[]> = await get<Place[]>(`/place/get`);

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function savePlaces(places: Place[]): Promise<Place[]> {
    let response: AxiosResponse<Place[]> = await post<Place[]>(`/place/save`, {places});

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function removePlace(placeId: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await post<boolean>(`/place/remove`, {placeId});

    if (response.status === 200)
        return response.data;
    else
        return false;
}

export function getPlaceAcronym(place: Place) {
    if (!place) return '';
    return place.name?.split(' ').map((word) => {return word.slice(0, 1)}).join('').toUpperCase();
}
