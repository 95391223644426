import {get, post, upload} from '../helpers/ajax';
import {AxiosResponse} from "axios";
import User, {NotificationPreferences, Rate, USER_STATUS, UserSettings} from "../models/user";
import {ROLES} from "../constants/roles";
import {UserNotification} from "../models/userNotification";
import {getEnumValue} from "../helpers/enums";
import Schedule from "../models/schedule";
import {localize} from "../helpers/localization";
import {store} from "../store/store";
import dayjs from "dayjs";
import {MissedRate} from "../models/missedRate";

export async function getUser(userId: string): Promise<User | null> {
    let response: AxiosResponse<User> = await get<User>(`/user/get/${userId}`);

    if (response.status === 200 && response.data)
        return response.data;
    else
        return null;
}
export async function getUsers(filter: any = {}): Promise<User[]> {
    let response: AxiosResponse<User[]> = await post<User[]>('/user/find', filter);

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function validUniqueEmail(email: string): Promise<boolean> {
    let response: AxiosResponse<boolean> = await get(`/user/validate-invite/${email}`);

    return response.data;
}

export async function sendInvite(user: User): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/send-invite/', {user});

    return response.data;
}

export async function setStatus(user: User, status: USER_STATUS): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/set-status/', {user, status: getEnumValue(USER_STATUS, status)});

    return response.data;
}

export async function setContractor(userId: string, contractor: boolean): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/set-contractor/', {userId, contractor});

    return response.data;
}

export async function setCanUncompleteFollowUp(user: User, canUncompleteFollowUp: boolean): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/set-can-uncomplete-followup/', {user, canUncompleteFollowUp});

    return response.data;
}

export async function setEmail(userId: string, email: string): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/set-email/', {userId, email});

    return response.data;
}

export async function setPhone(userId: string, phone: string): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/set-phone/', {userId, phone});

    return response.data;
}

export async function setColor(user: User, color: string): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/set-color/', {user, color});

    return response.data;
}

export async function setRole(user: User, role: ROLES): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/set-role/', {user, role: getEnumValue(ROLES, role)});

    return response.data;
}

export async function setLanguage(language: string): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/set-language/', {language});

    return response.data;
}

export async function setLicence(userId: string, licence: string): Promise<User> {
    let response: AxiosResponse<User> = await post('/user/set-licence/', {userId, licence});

    return response.data;
}

export function getUserFullName(user: User): string {
    if (!user) return localize('Unknown', store.getState().settings.lang);
    return user.name || (
            user.firstName || user.lastName ?
            `${user.firstName || ''} ${user.lastName || ''}`
            :
            user.email || '');
}

export async function getUserNotifications(): Promise<UserNotification[]> {
    let response: AxiosResponse<UserNotification[]> = await get<UserNotification[]>('/user/notifications');

    if (response.status === 200 && Array.isArray(response.data))
        return response.data;
    else
        return [];
}

export async function setUserNotificationRead(notificationId: string): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post('/user/notification-read', {notificationId});

        return response.data;
    } catch(ex) {
        return false;
    }
}

export async function saveUserSchedule(userId: string, schedule: Schedule): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post('/user/save-schedule', {userId, schedule});

        return response.data;
    } catch(ex) {
        return false;
    }
}

export async function saveUserRates(userId: string, rates: Rate[]): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post('/user/save-rates', {userId, rates});

        return response.data;
    } catch(ex) {
        return false;
    }
}

export async function isAvailable(userId: string, timeFrom: Date, timeTo: Date, ignoreMeeting?: string): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post('/user/is-available', {userId, timeFrom, timeTo, ignoreMeeting});

        return response.data;
    } catch(ex) {
        return false;
    }
}

export async function uploadSignature(userId: string, files: FileList): Promise<string | null> {
    let file: string | null = null;

    if (files.length === 1) {
        let formData = new FormData();
        formData.append("fileupload", files[0]);

        let response: AxiosResponse<string | null> = await upload(`/user/upload-signature/${userId}`, formData);

        if (response.status === 200 && response.data)
            file = response.data;
    }

    return file;
}

export async function getSignatureBase64(userId: string): Promise<string | null> {
    let base64Signature: string | null = null;

    try {
        let response: AxiosResponse<string> = await post('/user/download-signature', {userId});

        if (response.status === 200 && response.data) {
            base64Signature = response.data;
        }
    } catch(ex) {
    }

    return base64Signature;
}

export async function setNotificationPreferences(notificationPreferences: NotificationPreferences): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post('/user/set-notification-preferences', {notificationPreferences});

        return response.data;
    } catch(ex) {
        return false;
    }
}

export async function setUserSettings(userId: string, settings: UserSettings): Promise<boolean> {
    try {
        let response: AxiosResponse<boolean> = await post('/user/set-settings', {userId, settings});

        return response.data;
    } catch(ex) {
        return false;
    }
}

export function getProfessionnalRate(user: User, date: Date): number {
    let rateValue: number = 0;

    if (user && Array.isArray(user.professionalRates)) {
        for (let rate of user.professionalRates) {
            if (dayjs(date).isAfter(rate.from)) {
                rateValue = rate.rate || 0;
            }
        }
    }

    return rateValue;
}

export function getMissedRate(missedRates: MissedRate[], date: Date): number {
    let rateValue: number = 0;

    if (Array.isArray(missedRates)) {
        for (let rate of missedRates) {
            if (dayjs(date).isAfter(rate.from)) {
                rateValue = rate.rate || 0;
            }
        }
    }

    return rateValue;
}
