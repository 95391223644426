import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Grid} from "@mui/material";
import MeetingRealtime from "../graphs/meetingsRealtime";
import ContactRequests from "../graphs/contactRequests";
import {logException} from "../../../../controllers/system";

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class AssistantDashboard extends React.Component<ReduxType> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    render() {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} md={6} lg={4} sx={{p:2}}>
                        <MeetingRealtime />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{p:2}}>
                        <ContactRequests />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssistantDashboard);
