export enum CIVIL_STATUS {
    SINGLE = 1,
    SPOUSE = 2,
    MARIED = 3,
    DIVORCED = 4,
    WIDOWER = 5
}

export enum SERVICES {
    SERVICES_EAP = 1,
    SERVICES_WORK = 2,
    SERVICES_PRIVATE = 3,
    SERVICES_NEURO = 4,
    SERVICES_ABC = 5,
    SERVICES_MEDIATION = 6,
    PSYCHIATRY = 7
}

export enum ORGANIZATION_LINK {
    EMPLOYEE = 1,
    SPOUSE = 2,
    CHILD = 3
}

export enum EMPLOYEE_STATUS {
    EXECUTIVE = 1,
    SYNDICATED = 2,
    PERMANENT = 3,
    PARTTIME = 4,
    TPO = 5,
    TEMPORARY = 6,
    CONTRACTUAL = 7,
    RETIRED = 8
}

export enum DIRECTED_BY {
    EAP_AD = 1,
    RECOMMENDATION_DOCTOR = 2,
    IVAC = 3,
    SUGGESTION_FRIEND = 4,
    RECOMMENDATION_EMPLOYER = 5,
    SAAQ = 6,
    SUGGESTION_FAMILY = 7,
    RECOMMENDATION_UNION = 8,
    GVT_QC = 9,
    ALREADY_VIEWED = 10,
    CNESST = 11,
    GVT_CA = 12
}

export enum USED_SERVICE_BEFORE {
    YES = 1,
    NO = 2
}
