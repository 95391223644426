import React from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import * as styled from './app.styled';
import Auth from './auth/auth';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../store/store";
import {initApp} from "../../store/actions/app";
import Layout from "../ui/layout/layout";
import {CssBaseline} from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import AppNotifications from "../ui/alerts/notification";
import {BrowserRouter} from "react-router-dom";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import {Global} from '@emotion/react';
import {GlobalStyle} from "./app.styled";
import {darkTheme} from "./theme/root/dark";
import {lightTheme} from "./theme/root/light";
import Wizard from "../ui/wizard/wizard";
import Env from "./env/env";
import Loading from "../ui/loading/loading";
import {logException} from "../../controllers/system";
import PreventClose from "./preventClose/preventClose";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import ExternalApp from '../../external/app/app';

dayjs.extend(utc);
//dayjs.extend(advancedFormat);

dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        theme: state.settings.theme,
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        initApp: () => {
            dispatch(initApp());
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class App extends React.Component<ReduxType> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.props.initApp();
        this.setLocale();
    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<{}>): any {
        this.setLocale();
    }

    setLocale() {
        if (dayjs.locale() !== this.props.lang.toLowerCase()) dayjs.locale(this.props.lang.toLowerCase());
    }

    isExternalRequest(): boolean {
        console.log(document.documentURI, `/e/`, document.documentURI.indexOf(`/e/`));
        return document.documentURI.indexOf(`/e/`) !== -1;
    }

    render() {
        return (
            <BrowserRouter>
                <Global styles={GlobalStyle} />
                <ThemeProvider theme={this.props.theme === 'dark' ? darkTheme : lightTheme}>
                    <CssBaseline enableColorScheme />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={this.props.lang}>
                        <styled.AppContainer>
                            {
                                !this.isExternalRequest() ?
                                    <Auth>
                                        <PreventClose />
                                        <AppNotifications />
                                        <Layout />
                                        <Wizard />
                                    </Auth>
                                    :
                                    <ExternalApp />
                            }

                            <Env />
                            <Loading />
                        </styled.AppContainer>
                    </LocalizationProvider>
                </ThemeProvider>
            </BrowserRouter>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
