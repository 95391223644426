import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {localize} from "../../../../helpers/localization";
import FullScreenDialog from "../../../ui/fullScreenDialog/fullScreenDialog";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import {
    Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup,
    TextField
} from "@mui/material";
import {setAppNotification} from "../../../../store/actions/app";
import {ConsultationReason, ConsultationReasons, ConsultationReasonType, Patient} from "../../../../models/patient";
import {
    savePatient
} from '../../../../controllers/patients';
import {logException} from "../../../../controllers/system";
import {IAppNotification} from "../../../../models/appNotification";
import {deepCopy} from "../../../../helpers/object";
import {getEnumKeys, getEnumValue} from "../../../../helpers/enums";
import Text from "../../../app/text/text";

type IState = {
    changed: boolean,
    consultationReasons?: ConsultationReasons
}

type IProps = {
    patient: Patient,
    saveChanges: boolean,
    onSave: (patient: Patient) => void,
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class ConsultationReasonsDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        changed: false,
        consultationReasons: undefined
    }

    private editDialogRef: RefObject<any>;
    private confirmCloseDialogRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.editDialogRef = React.createRef();
        this.confirmCloseDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    openDialog() {
        this.setState((state: IState) => {
            return {
                ...state,
                changed: false,
                consultationReasons: deepCopy(this.props.patient.consultationReasons) ||
                    new ConsultationReasons()
            }
        }, () => {
            this.editDialogRef.current.openDialog();
        });
    }

    closeDialogRequest(): boolean {
        if (this.state.changed) {
            this.confirmCloseDialogRef.current?.openDialog();
            return false
        } else {
            this.closeDialog();
            return true;
        }
    }

    closeDialog() {
        this.editDialogRef.current.closeDialog();
    }

    savePatient(callback? : () => void) {
        if (this.state.changed) {
            let data: any = {};
            data.consultationReasons = this.state.consultationReasons;
            let patient: Patient = new Patient({
                ...this.props.patient,
                ...data
            });

            if (this.props.saveChanges) {
                savePatient(patient).then((patient: Patient | null) => {
                    this.setState((state: IState) => {
                        return {
                            ...state,
                            changed: false
                        }
                    }, () => {
                        this.props.setAppNotification({
                            severity: 'success',
                            message: 'Successfully saved'
                        });
                        this.props.onSave(patient as Patient);
                        if (callback) callback();
                    });
                }).catch(() => {
                    this.props.setAppNotification({
                        severity: 'error',
                        message: 'An error occurred, please try again'
                    });
                });
            } else {
                this.props.onSave(patient as Patient);
                if (callback) callback();
                this.setState((state: IState) => {
                    return {
                        ...state,
                        changed: false
                    }
                }, () => {
                    this.closeDialog();
                });
            }
        }
    }

    onModalButtonClick(key: string) {
        switch(key) {
            case 'save':
                this.savePatient();
                break;
        }
    }

    getConsultationReason(type: ConsultationReasonType) {
        if (this.state.consultationReasons) {
            for (let consultationReason of this.state.consultationReasons.reasons) {
                if (consultationReason.type === type) {
                    return consultationReason
                }
            }
        }
        return new ConsultationReason();
    }

    onChange(property: string, value: any) {
        this.setState((state: IState) => {
            let consultationReasons: any = state.consultationReasons;
            consultationReasons[property] = value;
            return {
                ...state,
                consultationReasons,
                changed: true
            };
        });
    }

    changeConsultationReason(type: ConsultationReasonType, property: string, value: any) {
        this.setState((state: IState) => {
            let consultationReasons: any = state.consultationReasons;
            let found: boolean = false;
            for (let consultationReason of consultationReasons.reasons) {
                if (consultationReason.type === type) {
                    consultationReason[property] = value;
                    found = true;
                }
            }
            if (!found) {
                let consultationReason: ConsultationReason = new ConsultationReason();
                consultationReason.type = type;
                (consultationReason as any)[property] = value;
                consultationReasons.reasons.push(consultationReason);
            }
            return {
                ...state,
                consultationReasons,
                changed: true
            };
        });
    }

    render() {
        return (
            <FullScreenDialog
                ref={this.editDialogRef}
                title={localize('Consultation reasons', this.props.lang)}
                onButtonClick={this.onModalButtonClick.bind(this)}
                onCloseRequest={this.closeDialogRequest.bind(this)}
                buttons={[
                    {key: 'save', text: localize(this.props.saveChanges ? 'Save' : 'Apply', this.props.lang), color: 'success', keepDialogOpen: true, disabled: !this.state.changed}
                ]}
            >
                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', height: '90%'}}>
                    <Box>
                        <Grid container sx={{p: 2}}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    sx={{width: '75%'}}
                                    label={localize('Name of treating doctor', this.props.lang)}
                                    value={this.state.consultationReasons?.doctorName || ''}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {this.onChange('doctorName', event.target.value)}}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                        <Text>Suicidal ideation</Text>
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        name="sucidal-ideation-group"
                                        value={(this.state.consultationReasons?.suicidal === true ? 'yes' : (this.state.consultationReasons?.suicidal === false ? 'no' : ''))}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {this.onChange('suicidal', (event.target as HTMLInputElement).value === 'yes')}}
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label={localize('YES', this.props.lang)} />
                                        <FormControlLabel value="no" control={<Radio />} label={localize('NO', this.props.lang)} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', overflow: 'auto', flexGrow: 1}}>
                        {getEnumKeys(ConsultationReasonType).map((key: string) => {
                            let consultationReasonType: ConsultationReasonType = getEnumValue(ConsultationReasonType, key);
                            let consultationReason: ConsultationReason = this.getConsultationReason(consultationReasonType);
                            return (
                                <Box key={key} sx={{display: 'flex', alignItems: 'center'}}>
                                    <Box sx={{px: 2, py:1, width: '20%'}}>
                                        {localize(key, this.props.lang)}
                                    </Box>
                                    <Box sx={{px: 2, py:1, width: '40%'}}>
                                        <Box sx={{display: 'flex'}}>
                                            <Box>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={consultationReason.importance === 1}
                                                            onChange={(e) => this.changeConsultationReason(consultationReasonType, 'importance', e.target.checked ? 1 : 0)}
                                                        />
                                                    }
                                                    label={localize('Primary', this.props.lang)}
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={consultationReason.importance === 2}
                                                            onChange={(e) => this.changeConsultationReason(consultationReasonType, 'importance', e.target.checked ? 2 : 0)}
                                                        />
                                                    }
                                                    label={localize('Secondary', this.props.lang)}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{px: 2, py:1, width: '40%'}}>
                                        <TextField
                                            sx={{width: '100%'}}
                                            label={localize('Remarks', this.props.lang)}
                                            value={consultationReason.notes || ''}
                                            onChange={(e: any) => {this.changeConsultationReason(consultationReasonType, 'notes', e.target.value)}}
                                        />
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <ConfirmDialog
                    ref={this.confirmCloseDialogRef}
                    title={localize('Unsaved changes', this.props.lang)}
                    text={localize('All unsaved changes will be lost. Do you want to continue?', this.props.lang)}
                    onAgree={this.closeDialog.bind(this)}
                />
            </FullScreenDialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ConsultationReasonsDialog);
