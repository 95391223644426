import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Box, IconButton, Typography} from "@mui/material";
import Text from "../../../app/text/text";
import {
    DataGrid, GridActionsCellItem,
    GridCallbackDetails,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    MuiEvent
} from "@mui/x-data-grid";
import {getMUILocale, localize} from "../../../../helpers/localization";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Account} from "../../../../models/account";
import {getActiveAccounts} from "../../../../controllers/account";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from "@mui/icons-material/Edit";
import {logException} from "../../../../controllers/system";

type IState = {
    data: Account[],
    loading: boolean
}

type IProps = {
    onAccountSelected: (account: Account) => void,
    onAccountAdd: () => void
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class AccountsGrid extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        data: [],
        loading: false
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState((state: IState) => {
            return {
                ...state,
                loading: true,
                data: []
            }
        }, () => {
            getActiveAccounts().then((accounts: Account[]) => {
                let tmpAccounts: Account[] = [];
                for (let account of accounts) {
                    if (account.system !== true) tmpAccounts.push(account);
                }
                this.setState((state: IState) => {
                    return {
                        ...state,
                        loading: false,
                        data: tmpAccounts
                    };
                });
            });
        });
    }

    getColumns(): (GridColDef | GridActionsColDef)[] {
        return [
            {
                field: 'name',
                headerName: localize('Name', this.props.lang),
                minWidth: 100,
                flex: 2
            },
            {
                field: 'status',
                headerName: localize('Status', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams): React.ReactNode => {
                    return (
                        <React.Fragment>
                            {params.row.status === 1 ?
                                <Text>Active</Text>
                                :
                                <Text>Inactive</Text>
                            }
                        </React.Fragment>
                    );
                }
            },
            {
                field: 'Contracts',
                headerName: localize('Contracts', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams): React.ReactNode => {
                    return (
                        <React.Fragment>
                            {params.row.contracts.length}
                        </React.Fragment>
                    );
                }
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 30,
                flex: 0.25,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={localize('Edit', this.props.lang)}
                        onClick={this.props.onAccountSelected.bind(this, params.row as Account)}
                    />
                ]
            }
        ];
    }

    onRowClick(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) {
        this.props.onAccountSelected(params.row as Account);
    }

    onAddClick() {
        this.props.onAccountAdd();
    }

    render() {
        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }} height={'100%'}>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant={'h4'}>
                                <Text>Accounts</Text>
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton color={'primary'} size={'large'} onClick={this.onAddClick.bind(this)}>
                                <AddCircleIcon sx={{fontSize: '2rem'}}/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <DataGrid
                            columns={this.getColumns()}
                            rows={this.state.data}
                            getRowId={x => x._id}
                            loading={this.state.loading}
                            localeText={getMUILocale(this.props.lang).components.MuiDataGrid.defaultProps.localeText}
                            onRowClick={this.onRowClick.bind(this)}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(AccountsGrid);
